import _ from 'lodash';

import ApolloClientHelper from './ApolloClientHelper';
import Queries from './graphqlQueries';
import Answer from './Answer';
import filterRecord from './filterRecord';

// Note: This logic is TMS-specific one. It should be removed in the future.
export default {
  getList: async (type, resource, params) => {
    const apolloClient = ApolloClientHelper.getInstance();

    const {
      pagination: { perPage: limit, page },
      filter,
      sort,
    } = params;

    const {
      learningLanguageCode,
      published,
      willBePublic,
      fragmentKey,
      authorId,
      qnaPostedTo: qnaPostedToFilter,
      ...restFilters
    } = filter;

    const offset = limit * (page - 1);

    const {
      data: {
        questions: { records, pagination },
      },
    } = await apolloClient.query({
      query: Queries.GET_QUESTIONS,
      variables: {
        input: {
          learningLanguageCode,
          published,
          willBePublic,
          pagination: { limit, offset },
          fragmentKey,
          authorId,
        },
      },
    });

    let results = records;

    if (filter != null) {
      results = results.filter((r) =>
        filterRecord(r, restFilters, ['originalTextTranslations'], ['answer']),
      );
    }

    if (qnaPostedToFilter != null) {
      results = _.filter(results, (r) =>
        _.find(r.qnaUploadLog, { uploadTo: qnaPostedToFilter }),
      );
    }

    if (sort != null) {
      results = _.orderBy(results, [sort.field], [sort.order.toLowerCase()]);
    }

    const total =
      pagination.nextStartAt != null
        ? offset + results.length + 1 // Not support the correct total
        : offset + results.length;

    return {
      data: results,
      total,
    };
  },
  getOne: async (type, resource, params) => {
    const apolloClient = ApolloClientHelper.getInstance();

    const {
      data: { question },
    } = await apolloClient.query({
      query: Queries.GET_QUESTION,
      variables: { input: { id: params.id } },
    });

    return { data: question };
  },
  create: async (type, resource, params) => {
    const apolloClient = ApolloClientHelper.getInstance();

    const {
      data: {
        fragment,
        learningLanguageCode,
        originalText,
        originalTextTranslations = [],
        published,
        willBePublic,
        isReusable,
        qnaPostingDate,
        label,
        isContextual,
        abandoned,
      },
    } = params;

    const {
      data: { createQuestion: question },
    } = await apolloClient.mutate({
      mutation: Queries.CREATE_QUESTION,
      variables: {
        input: {
          fragmentKey: fragment.key,
          learningLanguageCode,
          originalText,
          originalTextTranslations: originalTextTranslations.map((t) => ({
            languageCode: t.languageCode,
            text: t.text,
          })),
          published,
          willBePublic,
          isReusable,
          qnaPostingDate: _.isNil(qnaPostingDate)
            ? []
            : Object.keys(_.omitBy(qnaPostingDate, _.isNil)).map(
                (uploadTo) => ({
                  uploadTo,
                  postingDate: qnaPostingDate[uploadTo],
                }),
              ),
          label,
          isContextual,
          abandoned,
        },
      },
    });

    if (params.data.answer == null) {
      return { data: question };
    }

    const answer = await Answer.create(
      question.id,
      params.data.answer.originalText,
      params.data.answer,
    );

    return { data: { ...question, answer } };
  },
  update: async (type, resource, params) => {
    const apolloClient = ApolloClientHelper.getInstance();

    const {
      data: {
        id,
        fragment,
        originalText,
        originalTextTranslations = [],
        published,
        willBePublic,
        isReusable,
        qnaPostingDate,
        label,
        isContextual,
        abandoned,
      },
    } = params;

    const {
      data: { updateQuestion: question },
    } = await apolloClient.mutate({
      mutation: Queries.UPDATE_QUESTION,
      variables: {
        input: {
          id,
          fragmentKey: fragment.key,
          originalText,
          originalTextTranslations: originalTextTranslations.map((t) => ({
            languageCode: t.languageCode,
            text: t.text,
          })),
          published,
          willBePublic,
          isReusable,
          qnaPostingDate: _.isNil(qnaPostingDate)
            ? []
            : Object.keys(_.omitBy(qnaPostingDate, _.isNil)).map(
                (uploadTo) => ({
                  uploadTo,
                  postingDate: qnaPostingDate[uploadTo],
                }),
              ),
          label,
          isContextual,
          abandoned,
        },
      },
    });

    if (params.data.answer == null) {
      return { data: question };
    }

    if (params.data.answer.id != null) {
      const answer = await Answer.update(
        params.data.answer.id,
        params.data.answer,
      );

      return { data: { ...question, answer } };
    }

    const answer = await Answer.create(
      question.id,
      params.data.answer.originalText,
      params.data.answer,
    );

    return { data: { ...question, answer } };
  },
  delete: async (type, resource, params) => {
    const apolloClient = ApolloClientHelper.getInstance();

    await apolloClient.mutate({
      mutation: Queries.DELETE_QUESTION,
      variables: { input: { id: params.id } },
    });

    return { data: null };
  },
  deleteMany: async (type, resource, params) => {
    const apolloClient = ApolloClientHelper.getInstance();

    const deletePromises = params.ids.map((id) =>
      apolloClient.mutate({
        mutation: Queries.DELETE_QUESTION,
        variables: { id },
      }),
    );

    const results = await Promise.all(deletePromises);
    const deletedIds = results.map((r) => r.data.deleteQuestion);

    return { data: deletedIds };
  },
  qnaBotPost: async (params) => {
    const apolloClient = ApolloClientHelper.getInstance();

    const {
      answerOriginalText: answerText,
      originalText: questionText,
      id,
      isAsking,
      uploadTo,
      questionUrl,
    } = params;

    try {
      const {
        data: {
          qnaBotPost: { qnaUploadLog },
        },
      } = await apolloClient.mutate({
        mutation: Queries.QNA_BOT_POST,
        variables: {
          input: {
            id,
            answerText,
            questionText,
            url: questionUrl,
            isAsking,
            uploadTo,
          },
        },
      });

      const { qnaUploadLogResult } = _.find(qnaUploadLog, [
        'uploadTo',
        uploadTo,
      ]);

      return { data: qnaUploadLogResult };
    } catch (error) {
      const errorMessage = `TMS qnaBotPost failed with error: ${error}`;
      // eslint-disable-next-line no-console
      console.log(errorMessage);
      return { data: errorMessage };
    }
  },
};
