import {
  __DEV__,
  ENGLISH_CODE,
  KOREAN_CODE,
  JAPANESE_CODE,
  // CHINESE_CODE,
  VIETNAMESE_CODE,
  SPANISH_CODE,
} from '../../constants';

export const ContentMembers = __DEV__
  ? [
      // For dev DB test
      { id: '6vY4FlomLGTCs8yjenbYz5Wt7Ee2', name: 'Daehoon' },
      { id: '7NLWhZtBLEMg5Sdt3WTgstCqF0c2', name: 'Soyoung' },
      { id: 'IVeOQ0dVvtY2u4PdvnHwecWjCze2', name: 'Jeungmin' },
      { id: 'pQGt0IUFE7YffscxiBT5J4Clruy2', name: 'Umi' },
      // FAKE ACCOUNTS FOR VARIOUS USERS
      { id: 'IZa4xe1lMSOdJcHtZr671p585xt1', name: 'Kiwi' },
    ]
  : [
      // ENGLISH MANAGERS
      { id: 'iYAppwu6NlUY5CFlvlYlyNkp4R43', name: 'Rebecca' },
      { id: 'Is1fk3u1NwZS0MyQdoNiNwPNhV33', name: 'Brynie' },
      { id: 'IhyQnBCebpbbWO4G44cY1DdAjFh1', name: 'Kai' }, // Kainan
      // TRANSLATORS
      { id: 'GZWprtEyaSerV6qa0Gz9QBSgs0L2', name: 'Daniel' }, // Inbum
      { id: '8GOaZcjEhQQleuk1lIo9OKa47oh2', name: 'Aria' }, // Umi
      { id: 'wFpLOtwhdTRqRyl8Dd4Lc4kdkOD3', name: 'Jenny' }, // Mikiko
      { id: 'OfoR9gkSpZgK7uLDFJfZgBwwUtB2', name: 'Jade' }, // Hee
      { id: 'cnpYAfjVufMK5Jz7YlcjbP0KiZj2', name: 'Damian' }, // Junseong
      { id: 'NgEtLxbcSja6cHmn1PJhAHXvxcQ2', name: 'Kat' }, // Kayo
      { id: 'mBmjOR2HXxQj5zWHxesU9OfQXYl1', name: 'Nami' }, // Manami
      { id: 'zU6OLYW3O2UqIaM1qb8QDiyJPX23', name: 'Mia' }, // Atsumi
      { id: 'qaTQEQKSe0MLFzbnaK43s0UfJMG2', name: 'Eunice' },
      // FAKE ACCOUNTS FOR VARIOUS USERS
      { id: 'dN6oWPR2aihsXgZbN4WESDvA9wt2', name: 'Kiwi' },
      { id: 'CrlwpUIGSea9G7ZqBveLKsZrLt63', name: 'Jia' },
      { id: 'Bg3eluUEG1Zns0OMlUBp2NLuiX82', name: 'Roban' },
      { id: 'ZUhtQZo10lNxP7NvrLbxoXXWWxR2', name: 'Choo' },
      { id: '9rybWccDRmP5AQvFNIUsdk6fE283', name: 'Jason' },
      { id: 'xKDfDEzy92Zlx9vBcOjHhW7wTxk2', name: '星' },
      { id: '0uE42BWUESUuOAF5oiILtiztJfq1', name: 'Slime' },
      { id: 'baKNgSc3NyfXfDW2RdWW49FfnKU2', name: '07Her' },
      { id: '6VFVGhrpTEhfTh2xG31UobD3c2m2', name: 'Brandy' },
      { id: '96YBXGPVUjhDUam4eZKpZeY0NrD2', name: 'O9O9' },
      { id: 'LI6yGGmSIeXa7HkU3Yz8bM2FFrT2', name: 'DECEMBER12' },
      { id: 'b6ZZjoHNQkWW9JSoV8gC9oQXwdC2', name: 'Creamy' },
      { id: 'iwlA5CqLn7fYdPELiJwQ8TqoSQb2', name: 'Anonymous' },
      // Leavers
      { id: 'xLttugz6iwZedXZJSDbCsXkkXB62', name: 'Emma' }, // Emma
      { id: 'QGkgVDk1pnTD19iXa5yej9eQia72', name: 'Scharlie' }, // Scharlie
      { id: '8YD7HSUywGWU51T0HSKPXaTiaZ72', name: 'Emily' },
      { id: 'SVAGqdy4eYYYqvqitYGIMl4mfTF3', name: 'Jiyeon' },
      { id: '3NSvBdqspsdCiILcw7delRZuy3w2', name: 'JP' }, // Jinsuk
      { id: 'pspSj8UeZGWnarse8l48QWd7UT52', name: 'Joanna' }, // Jooyeon
    ];

// redkiwi@hayanmind.com (PW: redkiwi)
export const DefaultKiwiUserId = __DEV__
  ? 'IZa4xe1lMSOdJcHtZr671p585xt1'
  : 'dN6oWPR2aihsXgZbN4WESDvA9wt2';

/* eslint-disable max-len */
export const CommonAnswers = [
  {
    id: 'INCOMPREHENSIBLE_Q',
    name: 'Incomprehensible',
    originalText:
      'We are sorry 😢 We are having trouble understanding your question. Please upload the question again with a bit more detail.',
    originalTextTranslations: [
      {
        languageCode: ENGLISH_CODE,
        text: 'We are sorry 😢 We are having trouble understanding your question. Please upload the question again with a bit more detail.',
      },
      {
        languageCode: KOREAN_CODE,
        text: '죄송합니다 😢 보내주신 질문을 이해하는 데 어려움을 겪고 있습니다. 조금 더 자세히 적어서 질문을 다시 업로드해주시기 바랍니다.',
      },
      {
        languageCode: JAPANESE_CODE,
        text: '申し訳ございません 😢 頂いた質問の意味がよくわかりませんでしたので、もう少し詳しくご説明いただき、再送していただけますと幸いです。',
      },
      {
        languageCode: VIETNAMESE_CODE,
        text: 'Chân thành xin lỗi bạn 😢 Chúng tôi chưa hiểu rõ câu hỏi của bạn. Mong bạn gửi lại câu hỏi với thông tin chi tiết hơn cho chúng tôi nhé.',
      },
      {
        languageCode: SPANISH_CODE,
        text: 'Lo sentimos 😢 Tenemos dificultades para entender tu pregunta. Por favor, súbela de nuevo de manera más concreta.',
      },
      // { languageCode: CHINESE_CODE, text: '对不起， 😢 我们听不懂您的问题。麻烦您再细解释一下您的问题。' },
    ],
  },
  {
    id: 'SIMPLE_DEFINITION_Q',
    name: 'Simple Definition',
    originalText:
      'ここでの「」は「」という意味です。\n例：（）\n\n（字幕の英単語をタップすると辞書に移り、単語の意味を確認することができます。是非辞書も併せてご利用ください。ありがとうございます。）',
    originalTextTranslations: [
      {
        languageCode: KOREAN_CODE,
        text: '""은 ""라는 의미입니다.\n예: ()\n\n(영어 대본에서 단어를 누르시면 사전에서 뜻을 바로 확인하실 수 있습니다. 감사합니다.)',
      },
    ],
  },
  {
    id: 'NOT_SUPPORTED_LANGUAGE_Q',
    name: 'Unsupported Lang.',
    originalText:
      'We only support questions that are in English, Japanese, Korean, Vietnamese, and Spanish for now. Please upload the question again in those languages 🙏 Sorry for the inconvenience.',
    originalTextTranslations: [
      {
        languageCode: ENGLISH_CODE,
        text: 'We only support questions that are in English, Japanese, Korean, Vietnamese, and Spanish for now. Please upload the question again in those languages 🙏 Sorry for the inconvenience.',
      },
      {
        languageCode: KOREAN_CODE,
        text: '현재 영어, 일본어, 한국어, 베트남어, 스페인어로 작성된 질문만 지원해드리고 있습니다. 지원되는 언어로 질문을 다시 올려주시면 감사하겠습니다 🙏 불편을 끼쳐드려 죄송합니다.',
      },
      {
        languageCode: JAPANESE_CODE,
        text: '現在、ご質問のサポートは英語、日本語、韓国語、ベトナム語、スパニッシュのみとなっております。それらの言語でご質問いただけますと幸いです 🙏 ご不便をお掛けして申し訳ございません。',
      },
      {
        languageCode: VIETNAMESE_CODE,
        text: 'Hiện tại chúng tôi chỉ hỗ trợ giải đáp các câu hỏi bằng tiếng Anh, tiếng Nhật, tiếng Hàn, tiếng Việt và tiếng Tây Ban Nha. Bạn cập nhật lại câu hỏi bằng một trong các ngôn ngữ trên nhé 🙏 Chân thành xin lỗi bạn vì sự bất tiện này.',
      },
      {
        languageCode: SPANISH_CODE,
        text: 'Por ahora, solo respondemos a las preguntas que estén en inglés, coreano, vietnamita y español. Por favor, sube tu pregunta de nuevo en uno de esos idiomas 🙏 Sentimos los inconvenientes causados.',
      },
      // { languageCode: CHINESE_CODE, text: '现在我们只提供英语、日语、韩语和越南语询问服务。麻烦您用这些语言来重新上传您的问题。🙏 很抱歉给您带来的不便。' },
    ],
  },
  {
    id: 'DB_ERROR_Q',
    name: 'Script Error',
    originalText:
      'We found the wrong part in the script while checking your question. We have fixed the part you reported from "" to "". Let us know if you have any further question about it.',
    originalTextTranslations: [
      {
        languageCode: ENGLISH_CODE,
        text: 'We found the wrong part in the script while checking your question. We have fixed the part you reported from "" to "". Let us know if you have any further question about it.',
      },
      {
        languageCode: KOREAN_CODE,
        text: '질문을 확인하던 중에 대사에 틀린 부분이 발견되어 X에서 X로 수정하였습니다. 바뀐 부분에 대해 추가로 질문이 있으시다면, 다시 보내주세요.',
      },
      {
        languageCode: JAPANESE_CODE,
        text: '質問を確認している際、セリフに一部誤りがございましたので、XからXに修正させていただきました。変更箇所についてさらにご質問がございましたら、再度ご連絡ください。',
      },
      // { languageCode: VIETNAMESE_CODE, text: 'Xin chân thành cảm ơn bạn đã chỉ ra lỗi sai của chúng tôi qua câu hỏi này 🥰 Chúng tôi đã sửa lỗi ấy. Hãy gửi cho chúng tôi bất cứ câu hỏi nào bạn băn khoăn nhé.' },
      // { languageCode: SPANISH_CODE, text: 'Gracias por hacernos mejorar con tus valiosos comentarios 🥰 Hemos arreglado la cuestión que nos comunicaste. Si tienes alguna otra pregunta al respecto, háznoslo saber.' },
      // { languageCode: CHINESE_CODE, text: '谢谢您修改您宝贵的问题。🥰 我们已经修复好了您报告的问题。如果有任何问题，请您再次找我们帮助中心。谢谢。' },
    ],
  },
  {
    id: 'RIGHT_SCRIPT_Q',
    name: 'Right Script',
    originalText:
      'We have checked your report and we can confirm that the speaker does say "". Please understand that it may sound unclear because it has been pronounced quickly. If you find it difficult to hear, please try listening again after reducing the video play speed from 1.0x to 0.75x or 0.5x. Thank you!',
    originalTextTranslations: [
      {
        languageCode: ENGLISH_CODE,
        text: 'We have checked your report and we can confirm that the speaker does say "". Please understand that it may sound unclear because it has been pronounced quickly. If you find it difficult to hear, please try listening again after reducing the video play speed from 1.0x to 0.75x or 0.5x. Thank you!',
      },
      {
        languageCode: KOREAN_CODE,
        text: '문의하신 발음에 대해 확인해보았습니다만, 화자는 실제로 ""를 말하고 있는 것으로 확인됩니다. 너무 빨리 발음하다 보니 조금 명확하지 않게 들릴 수 있는 점 양해 바랍니다. 만약 기존 속도로 확인이 어려우실 경우, 재생 속도를 1.0x에서 0.75x나 0.5x로 변경해서 다시 한번 들어보시는 걸 추천드립니다. 감사합니다.',
      },
      {
        languageCode: JAPANESE_CODE,
        text: 'お問合せについて確認させていただきましたが、話し手は実際に""と発音している事を確認しました。早く話しているため、はっきり聞こえない場合がございます。もし確認が難しい場合、再生速度を1.0xから0.75x、または0.5xに変更し、もう一度お試しください。ありがとうございます。',
      },
      {
        languageCode: VIETNAMESE_CODE,
        text: 'Chúng tôi đã kiểm tra lại và đảm bảo "" là chính xác. Có lẽ do người nói nói quá nhanh nên bạn không nghe rõ. Nếu bạn vẫn thấy khó nghe, thì hãy thử giảm tốc độ nghe từ 1.0x xuống 0.75x hoặc 0.5x rồi nghe lại bạn nhé. Chân thành cảm ơn bạn!',
      },
      {
        languageCode: SPANISH_CODE,
        text: 'Hemos analizado lo que nos comentaste y confirmamos que el orador dice "". Por favor, piensa que puede sonar de manera poco clara porque se pronuncia rápidamente. Si te resulta difícil oírlo, intenta escucharlo de nuevo reduciendo la velocidad de reproducción del vídeo de 1.0x a 0.75x o 0.5x. ¡Gracias!',
      },
      // { languageCode: CHINESE_CODE, text: '我们检查了您的报告，确认了视频里的演员是在发音“”。这可能因为演员说得很快而很难听懂。如果您觉得听不清楚，请把视频播放速度从1.0x降到0.75x后再试一次。谢谢!' },
    ],
  },
  {
    id: 'IRRELEVANT_Q',
    name: 'Irrelevant',
    originalText:
      'You can ask content-related questions here 🤓 If you leave us a question, we will make sure to answer them with the most utmost sincerity!',
    originalTextTranslations: [
      {
        languageCode: ENGLISH_CODE,
        text: 'You can ask content-related questions here 🤓 If you leave us a question, we will make sure to answer them with the most utmost sincerity!',
      },
      {
        languageCode: KOREAN_CODE,
        text: '이곳은 콘텐츠와 관련 있는 질문을 남기는 공간입니다 🤓 콘텐츠에 관련된 질문을 남겨주시면 질문에 대한 답변을 최대한 성심성의껏 드리도록 하겠습니다!',
      },
      {
        languageCode: JAPANESE_CODE,
        text: 'こちらでコンテンツに関する質問が可能です 🤓 ご質問をいただければ、最大限誠意を持って回答させていただきます！',
      },
      {
        languageCode: VIETNAMESE_CODE,
        text: 'Bạn có thể hỏi bất cứ câu hỏi nào liên quan đến nội dung bài học ở đây 🤓 Mọi câu hỏi sẽ được trả lời bằng tất cả nhiệt huyết của chúng tôi!',
      },
      {
        languageCode: SPANISH_CODE,
        text: 'Puedes hacer preguntas relacionadas con el contenido, aquí 🤓 ¡Si nos haces una pregunta, nos aseguraremos de responderte con toda sinceridad!',
      },
      // { languageCode: CHINESE_CODE, text: '您可以在此处提出与视频相关的问题。我们一定会以最大的诚意回答您留下的问题！' },
    ],
  },
  {
    id: 'THANK_YOU',
    name: 'Thank You',
    originalText:
      "We're happy to hear that our answer helped 😃 We will try hard to help you get the answers to all of your questions! If you leave us a message here, it will stack up in your Q&A history, so we recommend that you leave us a review in the App Store instead! Thank you, and please keep asking us questions! 😁",
    originalTextTranslations: [
      {
        languageCode: ENGLISH_CODE,
        text: "We're happy to hear that our answer helped 😃 We will try hard to help you get the answers to all of your questions! If you leave us a message here, it will stack up in your Q&A history, so we recommend that you leave us a review in the App Store instead! Thank you, and please keep asking us questions! 😁",
      },
      {
        languageCode: KOREAN_CODE,
        text: '답변이 만족스러우셨다니 다행입니다 😃 앞으로도 궁금증을 말끔하게 해결해드릴 수 있도록 노력하겠습니다! 다만 여기로 보내는 메세지는 모두 기록으로 남아 회원님의 질문 리스트에 모두 쌓이게 되니, 감사 인사는 여기 말고 앱 스토어 리뷰로 보내주시면 더 좋을 것 같아요! 앞으로도 좋은 질문 많이 보내주세요~ 😁',
      },
      {
        languageCode: JAPANESE_CODE,
        text: '回答にご満足いただき嬉しく思います 😃 今後もお客様のご質問に回答できるよう努力してまいります！こちらに送信されたメッセージは、会員様の質問履歴に全て残ります。そのため、レビューはApple StoreまたはPlay storeに送信いただけますと幸いです！これからもたくさんの質問をお願いします 😁',
      },
      {
        languageCode: VIETNAMESE_CODE,
        text: 'RedKiwi rất vui khi giúp được bạn 😃 RedKiwi sẽ cố gắng trả lời tất cả các câu hỏi của bạn! Nếu bạn để lại lời nhắn ở đây, tin nhắn sẽ được lưu lại trong lịch sử Hỏi & Đáp, RedKiwi rất mong bạn có thể để lại đánh giá trên App Store nữa! Chân thành cảm ơn bạn, và hãy tiếp tục gửi câu hỏi đến cho RedKiwi nhé! 😁',
      },
      {
        languageCode: SPANISH_CODE,
        text: 'Nos alegra saber que nuestra respuesta te ha ayudado 😀 ¡Trabajaremos duro para conseguir la respuesta a todas las preguntas que tengas! Si nos dejas un mensaje por aquí, se registrará en tu historial de P&R; así que te recomendamos que, en vez de eso, ¡nos dejes una reseña en el App Store / Play store! Muchas gracias y por favor ¡sigue preguntándonos cosas! 😁',
      },
    ],
  },
  {
    id: 'PRONUNCIATION',
    name: 'Pronunciation',
    originalText:
      'Sorry. Currently, we can only give an answer in the text format and question about the pronunciation is not supported. If you press a word in the script, you can listen to the pronunciation and view the definition of the word in the dictionary. Also, you can listen again by reducing the video play speed from 1.0x to 0.75x or 0.5x. Please use that feature instead. Thanks.',
    originalTextTranslations: [
      {
        languageCode: ENGLISH_CODE,
        text: 'Sorry. Currently, we can only give an answer in the text format and question about the pronunciation is not supported. If you press a word in the script, you can listen to the pronunciation and view the definition of the word in the dictionary. Also, you can listen again by reducing the video play speed from 1.0x to 0.75x or 0.5x. Please use that feature instead. Thanks.',
      },
      {
        languageCode: KOREAN_CODE,
        text: '현재는 답변이 텍스트로만 지원되어 발음에 대한 내용은 정확한 답변이 어렵습니다. 한글로는 영어의 발음을 정확하게 표현할 수 없기 때문에 여러 번 들어보시는 걸 권장드립니다. 대본에서 단어를 직접 누르면 사전에서 발음을 쉽게 확인하실 수 있으니 이용 바랍니다. 재생속도를 1.0x에서 0.75x나 0.5x로 변경해서 듣는 것도 가능하니 참고 바랍니다. 감사합니다.',
      },
      {
        languageCode: JAPANESE_CODE,
        text: '恐れ入りますが、現時点では文字での回答のみとなっており、発音に関する内容は回答できかねます。カタカナでの発音表記は正確でないので、リピート機能をご利用いただき、繰り返しお聞きください。また、セリフの単語を押すと辞書で発音を確認できますので、そちらをご利用ください。再生速度を1.0xから0.75xまたは0.5xに変更して聴くことも可能なので、ご参考ください。ありがとうございます。',
      },
      // { languageCode: VIETNAMESE_CODE, text: 'Rất xin lỗi bạn. Hiện nay RedKiwi chỉ có thể trả lời câu hỏi dưới dạng chữ và chưa hỗ trợ câu trả lời về phát âm được. Nếu bạn ấn vào từ đó, bạn có thể nghe phát âm và xem nghĩa của nó trong từ điển. Mong bạn sẽ sử dụng tính năng ấy thay thế. Chân thành cảm ơn bạn.' },
      // { languageCode: SPANISH_CODE, text: 'Lo sentimos. De momento solo podemos responder por escrito, por lo que todavía no podemos ofrecer una corrección a la pronunciación del usuario. Si tocas encima de una palabra de la transcripción, podrás oír como se pronuncia y ver su definición en el diccionario. Por favor, utiliza estas herramientas. Gracias.' },
    ],
  },
];
/* eslint-enable max-len */

export const QnAUploadToSites = {
  HI_NATIVE: 'hiNative',
  // NAVER_KIN: 'naverKin',
  // STACK_EXCHANGE: 'stackExchange',
};

export const QnAUploadToOptions = [
  { id: null, name: 'X' },
  { id: QnAUploadToSites.HI_NATIVE, name: 'Hi Native' },
  // { id: QnAUploadToSites.NAVER_KIN, name: 'Naver Kin', disabled: true },
  // { id: QnAUploadToSites.STACK_EXCHANGE, name: 'Stack Exchange', disabled: true },
];
