import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  SimpleForm,
  TextInput,
  required,
  regex,
  SelectInput,
  BooleanInput,
} from 'react-admin';
import _ from 'lodash';
import moment from 'moment';
import { useForm } from 'react-final-form';
import Button from '@material-ui/core/Button';
import { CustomEditToolbar, TranslationField } from '../../common';
import { RegexNoOnlySpaceOrNewline, GrammarTypes } from '../../constants';
import GrammarLemmasField, {
  defaultLemmaIdValue,
} from './component/GrammarLemmasField';

const styles = {
  verticalView: {
    display: 'flex',
    flexDirection: 'column',
  },
  textInput: {
    width: 350,
  },
  selectInput: {
    width: 350,
  },
  switch: {
    width: 350,
  },
  textHighlight: {
    borderBottom: '5px solid #fffda1',
  },
  updated: {
    backgroundColor: '#fad0c3',
  },
  textSubmitButton: {
    marginTop: 5,
    marginBottom: 5,
  },
};

const validateGrammarFormValues = (values) => {
  const {
    text,
    textTranslations,
    definition,
    definitionTranslations,
    lemmaIds,
    published,
  } = values;
  if (!published) {
    return undefined;
  }
  const errors = {};
  const emptyFieldError = 'Please fill the field';

  if (text == null || _.trim(text) === '') {
    errors.text = emptyFieldError;
  }

  if (
    _.isNil(lemmaIds) ||
    _.isEmpty(lemmaIds) ||
    lemmaIds.includes(defaultLemmaIdValue)
  ) {
    // there is no direct lemma ids input so show error under text field
    errors.text = 'Please select the lemmas';
  }

  if (definition == null || _.trim(definition) === '') {
    errors.definition = emptyFieldError;
  }

  if (definitionTranslations == null) {
    errors.definitionTranslations = emptyFieldError;
  } else {
    const missingTranslations = _.filter(
      definitionTranslations,
      (textTranslation) =>
        textTranslation == null || _.trim(textTranslation) === '',
    );
    if (missingTranslations.length !== 0) {
      errors.definitionTranslations =
        'Please check if all translations are filled';
    }
  }

  if (textTranslations == null) {
    errors.textTranslations = emptyFieldError;
  } else {
    const missingTextTranslations = _.filter(
      textTranslations,
      (textTranslation) =>
        textTranslation == null || _.trim(textTranslation) === '',
    );
    if (missingTextTranslations.length !== 0) {
      errors.textTranslations = 'Please check if all translations are filled';
    }
  }
  return errors;
};

const GrammarForm = (props) => {
  const { mode, record, ...otherProps } = props;

  return (
    <>
      <SimpleForm
        {...props}
        toolbar={
          <CustomEditToolbar
            {...otherProps}
            mode={mode}
            record={record}
            createButtonLabel="Create grammar"
            saveButtonLabel="Save grammar"
          />
        }
        redirect={false}
        submitOnEnter={false}
        validate={validateGrammarFormValues}
        keepDirtyOnReinitialize={false}
      >
        <GrammarFormContent mode={mode} record={record} />
      </SimpleForm>
    </>
  );
};

const GrammarFormContent = (props) => {
  const { mode } = props;
  const [dirtyFields, setDirtyFields] = useState({});
  const [formData, setFormData] = useState({});
  const [isTextDone, setIsTextDone] = useState(false);
  const validateText = [
    required('Please fill the text.'),
    regex(RegexNoOnlySpaceOrNewline, 'Just space or newline is forbidden.'),
  ];

  const form = useForm();

  useEffect(() => {
    const unsubscribe = form.subscribe(
      (s) => {
        setDirtyFields(s.dirtyFields);
        setFormData(s.values);
        setIsTextDone(formData.lemmaIds != null);
      },
      { dirtyFields: true, values: true },
    );
    return () => {
      unsubscribe();
    };
  }, [form, formData.lemmaIds]);

  const isCreating = mode === 'create';

  const onClickTextDone = useCallback(() => {
    setIsTextDone(true);
  }, []);

  const onPublishedChange = useCallback(
    (published) => {
      if (published) {
        form.change('publishedAt', moment().toDate());
      } else {
        form.change('publishedAt', null);
      }
    },
    [form],
  );

  return (
    <div style={styles.verticalView}>
      {!isCreating && (
        <>
          <TextInput
            disabled
            label="Grammar Id"
            source="id"
            style={styles.textInput}
          />
          <TextInput
            label="Fragments"
            source="fragmentCount"
            style={{ width: 120 }}
            disabled
          />
        </>
      )}
      <BooleanInput
        label="Published"
        source="published"
        onChange={onPublishedChange}
        style={{
          ...styles.switch,
          ...(dirtyFields.published ? styles.updated : null),
        }}
        defaultValue={isCreating ? false : undefined}
      />
      <TextInput
        style={dirtyFields.text ? styles.updated : null}
        multiline
        source="text"
        fullWidth
        validate={validateText}
      />
      {isTextDone ? (
        <div>
          <GrammarLemmasField record={formData} />
        </div>
      ) : (
        <div>
          <Button
            style={styles.textSubmitButton}
            variant="outlined"
            onClick={onClickTextDone}
          >
            Submit Text
          </Button>
        </div>
      )}
      <TranslationField
        label="Text Translation"
        source="textTranslations"
        record={formData}
        dirtyFields={dirtyFields}
      />
      <TextInput
        label="Definition"
        source="definition"
        record={formData}
        style={{
          ...styles.textInput,
          ...(dirtyFields.definition ? styles.updated : null),
        }}
      />
      <TranslationField
        label="Definition Translation"
        source="definitionTranslations"
        record={formData}
        dirtyFields={dirtyFields}
      />
      <SelectInput
        label="Grammar Type"
        source="type"
        choices={GrammarTypes}
        allowEmpty={false}
        defaultValue="phrasalVerbs"
        style={{
          ...styles.selectInput,
          ...(dirtyFields.type ? styles.updated : null),
        }}
      />
    </div>
  );
};

const CommonPropTypesForGrammarFormAndContent = {
  mode: PropTypes.oneOf(['create', 'update']).isRequired,
  record: PropTypes.shape({}),
};

GrammarForm.propTypes = {
  ...CommonPropTypesForGrammarFormAndContent,
};

GrammarFormContent.propTypes = {
  ...CommonPropTypesForGrammarFormAndContent,
};

export default GrammarForm;
