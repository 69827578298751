import React from 'react';
import PropTypes from 'prop-types';
import { Edit } from 'react-admin';

import SktContentActions from './SktContentActions';
import SktContentFormWithFragmentForms from './SktContentFormWithFragmentForms';

const SktContentTitle = ({ record }) => (
  <span>{record ? `"${record.title}"` : ''}</span>
);

const SktContentEdit = (props) => (
  <Edit
    title={<SktContentTitle />}
    actions={<SktContentActions basePath="/contents" />}
    {...props}
  >
    <SktContentFormWithFragmentForms mode="update" />
  </Edit>
);

SktContentTitle.propTypes = {
  record: PropTypes.shape({
    title: PropTypes.string,
  }),
};

SktContentTitle.defaultProps = {
  record: {
    title: null,
  },
};

export default SktContentEdit;
