import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

const NestedObjectKeyTypeField = ({ data = {}, source = null }) => {
  const nestedObjectKeys = Object.entries(data).map((obj) =>
    _.get(obj[1], source, null),
  );

  if (nestedObjectKeys == null || _.isEqual(nestedObjectKeys, [])) {
    return '-';
  }

  return nestedObjectKeys.map((key, index) => {
    const isLastIndex = index === nestedObjectKeys.length - 1;

    return (
      <span key={`object-key-field-${source}-${key}`}>
        {key}
        {isLastIndex ? '' : ', '}
      </span>
    );
  });
};

NestedObjectKeyTypeField.defaultProps = { addLabel: true };

NestedObjectKeyTypeField.propTypes = {
  // eslint-disable-next-line react/require-default-props
  data: PropTypes.shape({}),
  // eslint-disable-next-line react/require-default-props
  source: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  addLabel: PropTypes.bool,
};

export default NestedObjectKeyTypeField;
