import React from 'react';
import PropTypes from 'prop-types';
import { TextField, SimpleShowLayout, Show } from 'react-admin';

const ReviewShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="content" />
      <TextField source="published" />
    </SimpleShowLayout>
  </Show>
);

ReviewShow.propTypes = {
  // eslint-disable-next-line react/require-default-props
  record: PropTypes.shape({
    id: PropTypes.string.isRequired,
    isAnonymous: PropTypes.bool,
    langToCompletedFragmentCount: PropTypes.shape({}),
    subscription: PropTypes.shape({}),
  }),
};

ReviewShow.defaultProps = {
  record: null,
};

export default ReviewShow;
