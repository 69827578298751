import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Container } from '@material-ui/core';
import PropTypes from 'prop-types';

const styles = {
  popupOuter: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  popupInner: {
    position: 'absolute',
    left: '25%',
    right: '25%',
    top: '25%',
    bottom: '25%',
    margin: 'auto',
    background: 'white',
    borderRadius: 25,
  },
  aligning: {
    position: 'relative',
    top: '25%',
    bottom: '25%',
    alignItems: 'center',
  },
  msg: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: 'medium',
  },
  goBack: {
    textDecoration: 'none',
  },
  buttonContainer: {
    marginTop: '5%',
  },
};

const Popup = (props) => (
  <div style={styles.popupOuter}>
    <div style={styles.popupInner}>
      <div style={styles.aligning}>
        <div style={styles.msg}>{props.textElement}</div>
        <Container maxWidth="xs" style={styles.buttonContainer}>
          {/* Reference: https://github.com/airbnb/javascript/pull/1648 */}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link to={props.pathBack} style={styles.goBack}>
            <Button fullWidth variant="contained" color="primary">
              {props.buttonText}
            </Button>
          </Link>
        </Container>
      </div>
    </div>
  </div>
);

Popup.propTypes = {
  textElement: PropTypes.shape(),
  pathBack: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
};

Popup.defaultProps = {
  textElement: null,
};

export default Popup;
