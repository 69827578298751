import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';

import { getFirebaseConfig } from '../constants';

export default (function ApolloClientHelper() {
  let apolloClient;

  function initialize(isProduction = false, tokenExtractor) {
    if (apolloClient != null) {
      // eslint-disable-next-line no-console
      console.warn('ApolloClient was already initialized!');
    }

    // const FIREBASE_APP_ID = FirebaseConfig.projectId;
    const firebaseConfig = getFirebaseConfig(isProduction);
    const firebaseAppId = firebaseConfig.projectId;

    const graphqlUri = `https://us-central1-${firebaseAppId}.cloudfunctions.net/graphql`;
    console.log(`Setting GraphQL EndPoint: ${graphqlUri}`); // eslint-disable-line no-console

    const httpLink = createHttpLink({
      uri: graphqlUri,
    });

    const authLink = setContext(async (_, { headers }) => {
      const token = await tokenExtractor();

      return {
        headers: {
          ...headers,
          authorization: token != null ? `Bearer ${token}` : '',
        },
      };
    });

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors != null) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          // eslint-disable-next-line no-console
          console.warn(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          );
        });
      }
      if (networkError != null) {
        // eslint-disable-next-line no-console
        console.warn(`[Network error]: ${networkError}`);
      }
    });

    apolloClient = new ApolloClient({
      link: ApolloLink.from([errorLink, authLink, httpLink]),
      // TODO: `engine` is missing!
      cache: new InMemoryCache(),
      defaultOptions: {
        query: {
          fetchPolicy: 'network-only',
        },
      },
    });

    return apolloClient;
  }

  function getInstance() {
    if (apolloClient == null) {
      throw new Error('ApolloClient is not initialized');
    }

    return apolloClient;
  }

  return {
    initialize,
    getInstance,
  };
})();
