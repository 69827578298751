import React from 'react';
import PropTypes from 'prop-types';
import { Loading, useGetList } from 'react-admin';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import CallMadeOutlinedIcon from '@material-ui/icons/CallMadeOutlined';
import _ from 'lodash';

import JSUtility from '../../../utilities/JSUtility';

const useStyles = makeStyles({
  header: {
    backgroundColor: '#cccccc',
  },
});

const RelatedQuestions = ({ userId }) => {
  const classes = useStyles();

  // ToDo #7691
  const {
    data: fetchedData,
    loading,
    error,
  } = useGetList(
    'questions',
    { perPage: 100, page: 1 },
    { field: 'createdAt', order: 'DESC' },
    { authorId: userId },
  );

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <p>ERROR</p>;
  }

  const questions = Object.values(fetchedData);
  const currentUserQuestions = questions.filter(
    (data) => data.author.id === userId,
  );

  const rows = currentUserQuestions.map((data) => {
    const questionEngTextArray = _.get(
      data,
      'originalTextTranslations',
      [],
    ).filter((v) => v.languageCode === 'en');

    const questionText =
      _.get(questionEngTextArray[0], 'text') || data.originalText;
    // const learningLanguages = data.learningLanguageCode;
    const questionCreatedAt = data.createdAt;
    const questionId = data.id;
    const questionPublished = data.published;
    const answerPublished = _.get(data, 'answer.published');

    return (
      /* eslint-disable max-len */
      JSUtility.createData(
        [
          'questionText',
          /* 'learningLanguage', */ 'questionCreatedAt',
          'questionPublished',
          'answerPublished',
          'link',
          'questionId',
        ],
        [
          questionText,
          /* learningLanguages, */ questionCreatedAt,
          questionPublished,
          answerPublished,
          null,
          questionId,
        ],
      )
      /* eslint-enable max-len */
    );
  });

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow className={classes.header}>
            <TableCell>Question</TableCell>
            {/* <TableCell>Learning Language</TableCell> */}
            <TableCell>Created At</TableCell>
            <TableCell>Is Question Published?</TableCell>
            <TableCell>Is Answer Published?</TableCell>
            <TableCell>Link</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={`${row.questionId} + 1`}>
              <TableCell>
                {row.questionText.length > 30
                  ? row.questionText.slice(0, 30).concat('...')
                  : row.questionText}
              </TableCell>
              {/* <TableCell>
                {row.learningLanguage}
              </TableCell> */}
              <TableCell>{row.questionCreatedAt.slice(0, 10)}</TableCell>
              <TableCell>{row.questionPublished ? 'O' : 'X'}</TableCell>
              <TableCell>{row.answerPublished ? 'O' : 'X'}</TableCell>
              <TableCell>
                {/* Reference: https://github.com/airbnb/javascript/pull/1648 */}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link to={`/questions/${row.questionId}`} target="_blank">
                  <Button variant="outlined">
                    {row.link}
                    <CallMadeOutlinedIcon />
                  </Button>
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

RelatedQuestions.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default RelatedQuestions;
