import React from 'react';
import { SimpleForm, TextInput } from 'react-admin';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { CustomEditToolbar } from '../../common';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  margin: {
    marginLeft: 20,
  },
  textInput: {
    width: 'fit-content',
  },
});

const ContentRewardForm = (props) => {
  const { mode, record, ...otherProps } = props;

  return (
    <>
      <SimpleForm
        {...props}
        toolbar={
          <CustomEditToolbar {...otherProps} mode={mode} record={record} />
        }
        redirect={false}
        submitOnEnter={false}
        keepDirtyOnReinitialize={false}
      >
        <ContentRewardFormContent mode={mode} record={record} />
      </SimpleForm>
    </>
  );
};

const ContentRewardFormContent = () => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <span>
        <TextInput
          disabled
          label="Id"
          source="id"
          className={classes.textInput}
        />
        <TextInput
          disabled
          label="Content Id"
          source="contentId"
          className={classes.margin}
        />
      </span>
      <TextInput
        disabled
        label="Free Points"
        source="freePoints"
        className={classes.textInput}
      />
      <TextInput
        disabled
        label="Member Points"
        source="memberPoints"
        className={classes.textInput}
      />
      <TextInput
        disabled
        label="Paid Points"
        source="paidPoints"
        className={classes.textInput}
      />
      <TextInput
        disabled
        label="Total Points"
        source="totalPoints"
        className={classes.textInput}
      />
    </div>
  );
};

const CommonPropTypesForContentRewardForm = {
  mode: PropTypes.oneOf(['create', 'update']).isRequired,
  record: PropTypes.shape({}),
};

ContentRewardForm.propTypes = {
  ...CommonPropTypesForContentRewardForm,
};

ContentRewardFormContent.propTypes = {
  ...CommonPropTypesForContentRewardForm,
};
export default ContentRewardForm;
