import React, { useEffect } from 'react';
import moment from 'moment';

import ResultsList from './ResultsList';

import { ENGLISH_CODE, ContentLanguageCodeToName } from '../../../constants';
import '../../search.css';
import QuestionResultItem, { QuestionResult } from './items/QuestionResultItem';
import withSearchEngine, { SearchProps } from '../../modules/withSearchEngine';

import * as ElasticAppSearch from '../../modules/ElasticAppSearch';

const connector = ElasticAppSearch.createConnector('questions-en');
const searchEngineConfig = {
  apiConnector: connector,
  searchQuery: {
    precision: 8,
    search_fields: {
      original_text: { weight: 10 },
      fragment_text: { weight: 5 },
    },
    result_fields: {
      original_text: {
        raw: {},
        snippet: {},
      },
      fragment_text: {
        raw: {},
        snippet: {},
      },
      created_at: { raw: {} },
      published: { raw: {} },
      original_text_translations: { raw: {} },
      answer: { raw: {} },
    },
  },
};

const transformResult = (result): QuestionResult => ({
  id: result.id,
  originalText: result.original_text,
  fragmentText: result.fragment_text,
  createdAt: moment(result.created_at).toDate(),
  published: result.published,
  originalTextTranslationsLangs: Object.keys(
    result.original_text_translations ?? {},
  ),
  answer:
    result.answer != null
      ? {
          originalText: result.answer.original_text,
          createdAt: moment(result.answer.createdAt).toDate(),
          published: result.answer.published,
          originalTextTranslationsLangs: Object.keys(
            result.answer.original_text_translations ?? {},
          ),
        }
      : undefined,
  href: `#/questions/${result.id}`,
});

interface QuestionSearchProps {
  onChangeResultQuery: (query: string) => void;
  onChangeSearch: (search: (query: string) => void) => void;
  onChangeResults: (results: QuestionResult[]) => void;
}

type SearchResultsWithEngineProps = QuestionSearchProps &
  SearchProps<QuestionResult>;

const FragmentSearch = ({
  onChangeSearch,
  onChangeResultQuery,
  onChangeResults,
  search,
  resultQuery,
  results,
  setResultsPerPage,
}: SearchResultsWithEngineProps) => {
  useEffect(() => {
    setResultsPerPage(20);
  }, [setResultsPerPage]);

  useEffect(() => {
    onChangeSearch(search);
  }, [onChangeSearch, search]);

  useEffect(() => {
    onChangeResultQuery(resultQuery);
  }, [onChangeResultQuery, resultQuery]);

  useEffect(() => {
    onChangeResults(results);
  }, [onChangeResults, results]);

  return (
    <ResultsList
      title={ContentLanguageCodeToName[ENGLISH_CODE]}
      results={results}
      renderItem={(result) => <QuestionResultItem result={result} />}
    />
  );
};

export default withSearchEngine<unknown, QuestionResult, QuestionSearchProps>(
  searchEngineConfig,
  transformResult,
  FragmentSearch,
);
