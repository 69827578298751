import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  SimpleForm,
  TextInput,
  BooleanInput,
  useGetOne,
  Loading,
  ImageInput,
  ImageField,
} from 'react-admin';
import _ from 'lodash';
import { useForm } from 'react-final-form';
import firebase from 'firebase';

import { CustomEditToolbar, TranslationField } from '../../common';
import JSUtility from '../../utilities/JSUtility';

const styles = {
  verticalView: {
    display: 'flex',
    flexDirection: 'column',
  },
  textInput: {
    width: 350,
  },
  updated: {
    backgroundColor: '#fad0c3',
  },
  image: {
    margin: '0.5rem',
    maxHeight: '10rem',
    objectFit: 'contain',
  },
  colorRectangle: {
    width: 50,
    height: 50,
    backgroundColor: 'black',
  },
};

const validateSurveyAnswerOptionFormValues = (values) => {
  const {
    published,
    answer,
    answerTranslations,
    description,
    descriptionTranslations,
    valueToLog,
  } = values;
  if (!published) {
    return undefined;
  }
  const errors = {};
  const emptyFieldError = 'Please fill the field';

  if (answer == null || _.trim(answer) === '') {
    errors.answer = emptyFieldError;
  }

  if (valueToLog == null || _.trim(valueToLog) === '') {
    errors.valueToLog = emptyFieldError;
  }

  if (answerTranslations == null) {
    errors.answerTranslations = emptyFieldError;
  } else {
    const missingTranslations = _.filter(
      answerTranslations,
      (text) => text == null || _.trim(text) === '',
    );
    if (missingTranslations.length !== 0) {
      errors.answerTranslations = 'Please check if all translations are filled';
    }
  }

  if (description != null && _.trim(description) !== '') {
    // if description field is filled it requires description translations
    if (descriptionTranslations == null) {
      errors.descriptionTranslations = emptyFieldError;
    } else {
      const missingTranslations = _.filter(
        descriptionTranslations,
        (text) => text == null || _.trim(text) === '',
      );
      if (missingTranslations.length !== 0) {
        errors.descriptionTranslations =
          'Please check if all translations are filled';
      }
    }
  }
  return errors;
};

const SurveyAnswerOptionForm = (props) => {
  const { mode, record, ...otherProps } = props;
  return (
    <SimpleForm
      {...props}
      toolbar={
        <CustomEditToolbar
          {...otherProps}
          mode={mode}
          record={record}
          createButtonLabel="Create answer option"
          saveButtonLabel="Save answer option"
        />
      }
      redirect={false}
      submitOnEnter={false}
      validate={validateSurveyAnswerOptionFormValues}
      keepDirtyOnReinitialize={false}
    >
      <SurveyAnswerOptionFormContent mode={mode} record={record} />
    </SimpleForm>
  );
};

const ImageFieldByPath = ({ imageUrl, imagePath, onFinishLoadingImageUrl }) => {
  if (imageUrl == null || imageUrl === '') {
    const imageRef = firebase.storage().ref().child(imagePath);
    imageRef.getDownloadURL().then((url) => onFinishLoadingImageUrl(url));
  }
  return <img src={imageUrl} alt="Previous_Tag_Image" style={styles.image} />;
};

ImageFieldByPath.propTypes = {
  imagePath: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  onFinishLoadingImageUrl: PropTypes.func,
};

ImageFieldByPath.propTypes = {
  imageUrl: null,
  onFinishLoadingImageUrl: () => null,
};

const SurveyAnswerOptionFormContent = (props) => {
  const { mode } = props;
  const [dirtyFields, setDirtyFields] = useState({});
  const [formData, setFormData] = useState({});
  const [defaultImageUrl, setDefaultImageUrl] = useState('');
  const [selectedImageUrl, setSelectedImageUrl] = useState('');
  const form = useForm();

  useEffect(() => {
    const unsubscribe = form.subscribe(
      (s) => {
        setDirtyFields(s.dirtyFields);
        setFormData(s.values);
      },
      { dirtyFields: true, values: true },
    );
    return () => {
      unsubscribe();
    };
  }, [form]);

  const isCreating = mode === 'create';
  const optionColorRectangleStyle = useMemo(
    () => ({
      ...styles.colorRectangle,
      ...(formData.selectedOptionColor != null
        ? { backgroundColor: formData.selectedOptionColor }
        : {}),
    }),
    [formData.selectedOptionColor],
  );

  return (
    <div style={styles.verticalView}>
      {!isCreating && (
        <TextInput
          disabled
          label="Answer Option Id"
          source="id"
          style={styles.textInput}
        />
      )}
      <BooleanInput
        label="Published"
        source="published"
        style={{
          ...styles.switch,
          ...(dirtyFields.published ? styles.updated : null),
        }}
        defaultValue={isCreating ? false : undefined}
      />
      <TextInput
        label="Value to log on GA"
        source="valueToLog"
        record={formData}
        style={{
          ...styles.textInput,
          ...(dirtyFields.valueToLog ? styles.updated : {}),
        }}
        parse={(value) => JSUtility.hasValidQuote(value)}
      />
      <TextInput
        label="Answer"
        source="answer"
        record={formData}
        style={{
          ...styles.textInput,
          ...(dirtyFields.answer ? styles.updated : {}),
        }}
        parse={(value) => JSUtility.hasValidQuote(value)}
      />
      <TranslationField
        source="answerTranslations"
        record={formData}
        dirtyFields={dirtyFields}
      />
      <TextInput
        label="Description"
        source="description"
        record={formData}
        style={{
          ...styles.textInput,
          ...(dirtyFields.description ? styles.updated : {}),
        }}
        parse={(value) => JSUtility.hasValidQuote(value)}
      />
      <TranslationField
        source="descriptionTranslations"
        record={formData}
        dirtyFields={dirtyFields}
      />
      <TextInput
        label="Selected option color"
        source="selectedOptionColor"
        record={formData}
        style={{
          ...styles.textInput,
          ...(dirtyFields.selectedOptionColor ? styles.updated : {}),
        }}
        parse={(value) => JSUtility.hasValidQuote(value)}
      />
      <div style={optionColorRectangleStyle} />
      <ImageInput
        label="Default Image"
        source="defaultImage"
        placeholder={<p>Drop or Select image</p>}
      >
        <ImageField source="src" />
      </ImageInput>
      {_.get(formData, 'defaultImagePath', null) &&
      !_.get(formData, 'defaultImage', null) ? (
        <ImageFieldByPath
          imagePath={formData.defaultImagePath}
          imageUrl={defaultImageUrl}
          onFinishLoadingImageUrl={setDefaultImageUrl}
        />
      ) : null}
      <ImageInput
        label="Selected Image"
        source="selectedImage"
        placeholder={<p>Drop or Select image</p>}
      >
        <ImageField source="src" />
      </ImageInput>
      {_.get(formData, 'selectedImagePath', null) &&
      !_.get(formData, 'selectedImage', null) ? (
        <ImageFieldByPath
          imagePath={formData.selectedImagePath}
          imageUrl={selectedImageUrl}
          onFinishLoadingImageUrl={setSelectedImageUrl}
        />
      ) : null}
    </div>
  );
};

export const SurveyAnswerOptionFormContainer = ({
  recordId,
  ...otherProps
}) => {
  const { data, loading, error } = useGetOne('surveyAnswerOptions', recordId);

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <p>ERROR</p>;
  }

  return <SurveyAnswerOptionForm record={data} {...otherProps} />;
};

SurveyAnswerOptionFormContainer.propTypes = {
  recordId: PropTypes.string,
};

SurveyAnswerOptionFormContainer.defaultProps = {
  recordId: null,
};

const CommonPropTypesForSurveyAnswerOptionFormAndContent = {
  mode: PropTypes.oneOf(['create', 'update']).isRequired,
  record: PropTypes.PropTypes.shape({}),
};

SurveyAnswerOptionForm.propTypes = {
  ...CommonPropTypesForSurveyAnswerOptionFormAndContent,
};

SurveyAnswerOptionFormContent.propTypes = {
  ...CommonPropTypesForSurveyAnswerOptionFormAndContent,
};

export default SurveyAnswerOptionForm;
