import React from 'react';
import { EditButton, TextField, Show, SimpleShowLayout } from 'react-admin';

import { ArrayTypeField, UnixTimestampField } from '../../common';

const styles = {
  buttons: {
    marginLeft: 5,
  },
  playButton: {
    color: 'red',
  },
  languageCodeText: {
    fontWeight: 'bold',
  },
};

const LanguageCodeToNameField = ({ record }) =>
  record.languageCodeToName != null
    ? Object.keys(record.languageCodeToName).map((languageCode) => (
        <div key={`name-translations-field-${languageCode}`}>
          <span style={styles.languageCodeText}>{languageCode}</span>
          &nbsp;
          <span>{record.languageCodeToName[languageCode]}</span>
        </div>
      ))
    : null;

const LanguageCodeToDescriptionField = ({ record }) =>
  record.languageCodeToDescription != null
    ? Object.keys(record.languageCodeToDescription).map((languageCode) => (
        <div key={`description-translations-field-${languageCode}`}>
          <span style={styles.languageCodeText}>{languageCode}</span>
          &nbsp;
          <span>{record.languageCodeToDescription[languageCode]}</span>
        </div>
      ))
    : null;

const TagShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <EditButton {...props} />
      <UnixTimestampField label="Created Date" source="createdAt.seconds" />
      <TextField label="Tag Type" source="category" />
      <ArrayTypeField
        label="Learning Language Code"
        source="learningLanguageCodes"
      />
      <LanguageCodeToNameField label="Name" />
      <LanguageCodeToDescriptionField label="Description" />
    </SimpleShowLayout>
  </Show>
);

LanguageCodeToNameField.defaultProps = { addLabel: true };
LanguageCodeToDescriptionField.defaultProps = { addLabel: true };

export default TagShow;
