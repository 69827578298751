import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar } from 'react-admin';

import { version } from '../../../package.json';
import Search from '../../search/Search';
import { __DEV__ } from '../../constants';

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  spacer: {
    flex: 1,
  },
});

const styles = {
  redKiwiColor: {
    backgroundColor: '#ee4e48',
  },
  hyperlink: {
    color: 'white',
    marginRight: 5,
  },
};

const CustomAppBar = (props) => {
  const classes = useStyles();
  return (
    <AppBar position="fixed" style={styles.redKiwiColor} {...props}>
      <Typography
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      <span className={classes.spacer} />
      <Search />
      <p>
        Connected to {__DEV__ ? 'Dev. DB' : 'Prod. DB'} / TMS ver.
        <a
          href="https://www.notion.so/hayanmind/RedKiwi-TMS-release-note-ff5c332e6c7d4aa19aae1160b6ff3c35"
          style={styles.hyperlink}
        >
          {version}
        </a>
      </p>
    </AppBar>
  );
};

export default CustomAppBar;
