import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  TextInput,
  TabbedForm,
  FormTab,
  SaveButton,
  Toolbar,
  regex,
} from 'react-admin';
import {
  Button,
  Typography,
  Card,
  CardContent,
  Divider,
  Chip,
} from '@material-ui/core';
import { Link as LinkIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import moment from 'moment';

import {
  DB_PATH,
  ContentLanguages,
  LINK_TO_CHANNELTALK,
  RegexNoOnlySpaceOrNewline,
} from '../../constants';
import { PremiumInput } from '../../common';
import RelatedQuestions from './components/RelatedQuestions';
import LevelInput from './components/LevelInput';

const useStyles = makeStyles((theme) => ({
  card: {
    minWidth: 250,
    maxWidth: 500,
  },
  wrapper: {
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  margin: {
    marginLeft: 20,
  },
  wideTextInput: {
    width: 230,
    marginLeft: 20,
  },
  complete: {
    marginRight: 20,
    width: 270,
  },
  button: {
    marginLeft: 20,
  },
  wide: {
    width: 270,
  },
}));

const styles = {
  grayText: {
    color: 'gray',
  },
  horizontalDiv: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  bottomMargin: {
    marginBottom: 20,
  },
};

const ltcfc = 'langToCompletedFragmentCount';

const validateLevelInput = regex(
  RegexNoOnlySpaceOrNewline,
  'Just space or newline is forbidden.',
);

const UserEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton label="SAVE User" disabled />
  </Toolbar>
);

const UserForm = (props) => {
  const { record = {} } = props;
  const classes = useStyles();
  const userId = _.get(record, 'id', '');

  const premiumExpireDateInSeconds = _.get(
    record,
    'subscription.premium.expiredAt.seconds',
  );
  const membershipExpireDateInSeconds = _.get(
    record,
    'subscription.membership.expiredAt.seconds',
  );
  const currentDateInSeconds = Math.floor(new Date().getTime() / 1000);

  const isValidPremium = premiumExpireDateInSeconds >= currentDateInSeconds;
  const premiumSubscriptionId = _.get(
    record,
    DB_PATH.subscriptionPremiumSubscriptionId,
    null,
  );
  // There is user who purchased premium with non-subscription.
  // (Subscription is implemented at May, 2020.)

  const isValidMembership =
    membershipExpireDateInSeconds >= currentDateInSeconds;
  const isMembershipSubscription =
    _.get(record, DB_PATH.subscriptionMembershipType) != null;
  const membershipSubscriptionId = _.get(
    record,
    DB_PATH.subscriptionMembershipSubscriptionId,
    null,
  );

  const timestampFormatter = (v) =>
    v != null ? moment(v.toDate()).format('YYYY-MM-DD, HH:mm:ss') : null;

  const [validChannelTalkUser, setValidChannelTalkUser] = useState(false);
  const [loading, setLoading] = useState(true);

  const linkToChannelTalk = `${LINK_TO_CHANNELTALK}${userId}`;

  const checkUserExist = useCallback(async () => {
    fetch(linkToChannelTalk, { method: 'HEAD' }).then((response) => {
      setValidChannelTalkUser(response?.ok);
      setLoading(false);
    });
  }, [linkToChannelTalk]);

  useEffect(() => {
    checkUserExist();
  }, [checkUserExist]);

  return (
    <TabbedForm
      {...props}
      toolbar={<UserEditToolbar />}
      keepDirtyOnReinitialize={false}
    >
      <FormTab label="Info">
        <Typography variant="h6" gutterBottom>
          Basic Info
        </Typography>
        <span className={classes.wrapper}>
          <TextInput disabled source="id" className={classes.wide} />
          <Button
            variant="outlined"
            className={classes.button}
            href={linkToChannelTalk}
            target="_blank"
            rel="noopener noreferrer"
            disabled={!validChannelTalkUser}
          >
            {loading ? 'Loading ...' : 'Go to ChannelTalk'}
          </Button>
        </span>
        <span>
          <TextInput
            disabled
            label="Is anonymous user?"
            source="isAnonymous"
            format={(v) => {
              if (v === true) {
                return 'O';
              }
              return 'X';
            }}
          />
          <TextInput
            disabled
            label="Device languages"
            source="userLanguageCodes"
            className={classes.margin}
          />
        </span>
        <span>
          <TextInput
            disabled
            label="First seen at"
            source="firstSeenAt"
            format={timestampFormatter}
          />
          <TextInput
            disabled
            label="Last seen at"
            source="lastSeenAt"
            format={timestampFormatter}
            className={classes.margin}
          />
        </span>
        <br />
        <Typography variant="h6" gutterBottom>
          Premium Info
        </Typography>
        <Chip
          style={
            isValidPremium || isValidMembership
              ? { backgroundColor: '#EE4E48', color: 'white', fontWeight: 900 }
              : null
          }
          label={
            // eslint-disable-next-line no-nested-ternary
            isValidPremium
              ? 'Premium User :)'
              : isValidMembership
              ? 'Membership User :)'
              : 'Expired or Not a purchaser :('
          }
        />
        <span>
          <p style={styles.grayText}>Deprecated - Premium</p>
          <div style={styles.horizontalDiv}>
            <TextInput
              disabled
              label="Premium created date"
              source={DB_PATH.subscriptionPremiumCreatedAt}
              format={timestampFormatter}
            />
            <TextInput
              disabled
              label="Premium expire date"
              source={DB_PATH.subscriptionPremiumExpiredAt}
              format={timestampFormatter}
              className={classes.margin}
            />
            <TextInput
              disabled
              label="Is subscription user?"
              source={DB_PATH.subscriptionPremiumSubscriptionId}
              format={(v) => {
                if (v == null) {
                  return 'X';
                }
                return 'O';
              }}
              className={classes.margin}
            />
            {premiumSubscriptionId == null ? null : (
              // Reference: https://github.com/airbnb/javascript/pull/1648
              <Link
                to={`/subscriptions/${premiumSubscriptionId}`}
                target="_blank"
                style={styles.bottomMargin}
              >
                <Button>
                  <LinkIcon />
                </Button>
              </Link>
            )}
          </div>
        </span>
        <span>
          <p style={styles.grayText}>
            New - Membership (User can buy this membership only in the version
            after than 0.6.77.)
          </p>
          <div style={styles.horizontalDiv}>
            <TextInput
              disabled
              label="Membership type"
              source={DB_PATH.subscriptionMembershipType}
              format={(v) => (v || '').toUpperCase()}
            />
            <TextInput
              disabled
              label="Membership updated at"
              source={DB_PATH.subscriptionMembershipUpdatedAt}
              format={timestampFormatter}
              className={classes.margin}
            />
            <TextInput
              disabled
              label="Membership expired at"
              source={DB_PATH.subscriptionMembershipExpiredAt}
              format={timestampFormatter}
              className={classes.margin}
            />
            <TextInput
              disabled
              label="Membership migrated from"
              source={DB_PATH.subscriptionMembershipMigratedFromUserId}
              className={classes.wideTextInput}
            />
            {membershipSubscriptionId == null ? null : (
              // Reference: https://github.com/airbnb/javascript/pull/1648
              <Link
                to={`/subscriptions/${membershipSubscriptionId}`}
                target="_blank"
                style={styles.bottomMargin}
              >
                <Button>
                  <LinkIcon />
                </Button>
              </Link>
            )}
          </div>
        </span>
        {(premiumSubscriptionId != null && isValidPremium) ||
        (isMembershipSubscription && isValidMembership) ? (
          <span>
            (Manually applying Premium feature is not supported for
            auto-subscription user.)
          </span>
        ) : (
          <PremiumInput
            subscriptionObj={record.subscription}
            userId={userId}
            premiumExpireDateInSeconds={premiumExpireDateInSeconds}
            currentDateInSeconds={currentDateInSeconds}
          />
        )}
      </FormTab>
      <FormTab label="Learning">
        <div>
          <Card>
            <CardContent>
              <Typography variant="h6">Learning Language</Typography>
              <TextInput disabled source="learningLanguageCode" />
            </CardContent>
          </Card>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.wrapper}>
          <Card className={classes.card}>
            <CardContent>
              <Typography variant="h6" component="h2">
                Learning History &amp; Level
              </Typography>
              {ContentLanguages.map((lang) => (
                <div className={classes.wrapper} key={lang.id}>
                  <TextInput
                    disabled
                    label={`Completed ${lang.name.slice(0, 3)} fragments`}
                    source={`${ltcfc}.${lang.id}`}
                    key={`${lang.id}+1`}
                    className={classes.complete}
                  />
                  <LevelInput
                    learningLanguageCode={lang.id}
                    record={record}
                    key={`${lang.id}+2`}
                    validate={validateLevelInput}
                  />
                </div>
              ))}
            </CardContent>
          </Card>
        </div>
      </FormTab>
      <FormTab label="Q &amp; A">
        <RelatedQuestions userId={userId} />
      </FormTab>
    </TabbedForm>
  );
};

UserForm.propTypes = {
  record: PropTypes.shape({
    langToCompletedFragmentCount: PropTypes.shape({}),
    id: PropTypes.string,
  }),
};

UserForm.defaultProps = {
  record: {
    langToCompletedFragmentCount: null,
  },
};

export default UserForm;
