import React from 'react';
import { Create } from 'react-admin';

import LemmaActions from './LemmaActions';
import LemmaForm from './LemmaForm';

const LemmaCreate = (props) => (
  <Create actions={<LemmaActions basePath="/lemmas" />} {...props}>
    <LemmaForm mode="create" />
  </Create>
);

export default LemmaCreate;
