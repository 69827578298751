import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  SimpleForm,
  ReferenceInput,
  SelectInput,
  BooleanInput,
  TextInput,
  useGetOne,
  Loading,
  required,
  regex,
} from 'react-admin';
import Button from '@material-ui/core/Button';
import { useForm } from 'react-final-form'; // eslint-disable-line import/no-extraneous-dependencies
import {
  YouTube as YouTubeIcon,
  MusicNote as MusicNoteIcon,
  Close as MultiplyIcon,
  AddBox as CreateIcon,
  FileCopy as CopyIcon,
} from '@material-ui/icons';
import { styled } from '@material-ui/styles';
import _ from 'lodash';

import {
  LINK_TO_TMS,
  ContentLanguages,
  LanguageKeyToForeignLanguageText,
  RegexNoOnlySpaceOrNewline,
} from '../../constants';
import {
  TimestampInput,
  CustomEditToolbar,
  TranslationField,
  TextInputWithButton,
} from '../../common';
import JSUtility from '../../utilities/JSUtility';
import WordsField from './component/WordsField';
import { QuestionsOnSpecificFragment } from '../questions/components';
import GrammarsField from './component/GrammarsField';
import TopicsField from './component/TopicsField';

const styles = {
  flex: { display: 'flex' },
  adjacentField: { marginRight: 10 },
  updated: {
    backgroundColor: '#fad0c3',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  textHighlight: {
    borderBottom: '5px solid #fffda1',
  },
  flexRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 50,
  },
  iconButton: {
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 10,
  },
  textSubmitButton: {
    marginTop: 5,
    marginBottom: 5,
  },
  createQuestionButton: {
    marginTop: '1.33em',
    marginBottom: '1.33em',
    width: 210,
    height: 40,
  },
  icon: {
    marginRight: 7,
  },
};

const CustomYouTubeIcon = styled(YouTubeIcon)({
  color: '#c4302b',
});

const CreateQuestionButton = ({ record: fragmentRecord }) => (
  <Button
    component={Link}
    to={{
      pathname: '/questions/create',
      search: `?source=${JSON.stringify({
        fragment: {
          key: fragmentRecord.id,
        },
        learningLanguageCode: fragmentRecord.languageCode,
        willBePublic: true,
      })}`,
    }}
    target="_blank"
    style={styles.createQuestionButton}
    variant="outlined"
  >
    <CreateIcon style={styles.icon} />
    Create Question
  </Button>
);

CreateQuestionButton.propTypes = {
  // eslint-disable-next-line react/require-default-props
  record: PropTypes.shape({
    id: PropTypes.string,
    languageCode: PropTypes.string,
  }),
};

const MultiplyButton = ({ text = '' }) => {
  const form = useForm();

  const multiplyConcatenatedText = text.concat(' ×2');

  const onClickMultiplyButton = useCallback(() => {
    form.change('text', multiplyConcatenatedText);
  }, [form, multiplyConcatenatedText]);

  return (
    <Button
      style={styles.iconButton}
      variant="outlined"
      onClick={onClickMultiplyButton}
    >
      <MultiplyIcon fontSize="small" />
    </Button>
  );
};

const MusicNoteButton = ({ text = '' }) => {
  const form = useForm();

  const lines = text.split('\n');
  const linesWithMusicSymbol = lines.map((l) =>
    l.startsWith('-') ? `- ♪ ${l.slice(l.indexOf('-') + 1)}` : `♪ ${l}`,
  );
  const linesWithoutDupSymbol = linesWithMusicSymbol.map((l) =>
    JSUtility.removeDuplicateSymbol(l, '♪'),
  );
  const linesWithoutDupSpace = linesWithoutDupSymbol.map((l) =>
    JSUtility.removeDuplicateSpace(l),
  );
  const textWithMusicSymbol = linesWithoutDupSpace.join('\n');

  const onClickMusicSymbolButton = useCallback(() => {
    form.change('text', textWithMusicSymbol);
  }, [form, textWithMusicSymbol]);

  return (
    <Button
      style={styles.iconButton}
      variant="outlined"
      onClick={onClickMusicSymbolButton}
    >
      <MusicNoteIcon fontSize="small" />
    </Button>
  );
};

const ForeignLanguageButton = ({ learningLanguage = 'en' }) => {
  const form = useForm();

  const foreignLanguageText =
    LanguageKeyToForeignLanguageText[learningLanguage];

  const onClickForeignLanguageButton = useCallback(() => {
    form.change('text', foreignLanguageText);
  }, [form, foreignLanguageText]);

  return (
    <Button
      style={styles.iconButton}
      variant="outlined"
      onClick={onClickForeignLanguageButton}
    >
      Speaking Foreign Language
    </Button>
  );
};

ForeignLanguageButton.propTypes = {
  // eslint-disable-next-line react/require-default-props
  learningLanguage: PropTypes.string,
};

export const FragmentFormContainer = ({ recordId, ...otherProps }) => {
  const { data, loading, error } = useGetOne('fragments', recordId);

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <p>ERROR</p>;
  }

  return <FragmentForm record={data} {...otherProps} />;
};

const FragmentForm = (props) => {
  const {
    mode,
    indexFromFragmentList,
    youTubeVideoId,
    record = {},
    created,
    ...otherProps
  } = props;
  const { contentKey } = record;

  const validateStartAndEnd = (values) => {
    const errors = {};
    if (
      values.startTime &&
      values.endTime &&
      values.startTime >= values.endTime
    ) {
      errors.startTime = 'Start time should be before End time.';
      errors.endTime = 'End time should be after Start time.';
    }
    return errors;
  };

  return (
    <SimpleForm
      {...otherProps} // otherProps should be placed before `redirect` to provide `false`
      record={record}
      redirect={false} // "list"
      toolbar={<CustomEditToolbar {...props} />}
      submitOnEnter={false}
      keepDirtyOnReinitialize={false}
      validate={validateStartAndEnd}
    >
      {/* The wrapper below exists to support `useForm` in the `FragmentFormContent` component */}
      <FragmentFormContent
        // eslint-disable-next-line no-underscore-dangle
        textTranslationsByGengo={record._textTranslationsByGengo}
        startTime={record.startTime}
        mode={mode}
        indexFromFragmentList={indexFromFragmentList}
        youTubeVideoId={youTubeVideoId}
        contentKey={contentKey}
        record={record}
      />
    </SimpleForm>
  );
};

const FragmentFormContent = ({
  textTranslationsByGengo,
  startTime,
  mode,
  indexFromFragmentList,
  youTubeVideoId,
  contentKey,
  record,
}) => {
  const isCreating = mode === 'create';
  const [isTextDone, setIsTextDone] = useState(false);
  const [dirtyFields, setDirtyFields] = useState({});
  const [formData, setFormData] = useState({});
  const form = useForm();

  useEffect(() => {
    const unsubscribe = form.subscribe(
      (s) => {
        setDirtyFields(s.dirtyFields);
        setFormData(s.values);
        setIsTextDone(formData.words != null);
      },
      { dirtyFields: true, values: true },
    );
    return () => {
      unsubscribe();
    };
  }, [form, formData.words]);

  const { id, text, languageCode } = formData;

  const studyableWordCount = useCallback(() => {
    if (formData.words) {
      const count = formData.words.reduce(
        (acc, word) => (word.studyable ? acc + 1 : acc),
        0,
      );
      return count;
    }
    return 0;
  }, [formData.words]);

  const onClickTextDone = useCallback(() => setIsTextDone(true), []);
  const onClickCopyIdButton = useCallback(() => {
    JSUtility.copyToClipboard(id);
  }, [id]);

  const validateStartTime = required('Please fill the start time.');
  const validateEndTime = required('Please fill the end time.');
  const validateText = [
    required('Please fill the text.'),
    regex(RegexNoOnlySpaceOrNewline, 'Just space or newline is forbidden.'),
  ];

  return (
    <>
      {isCreating ? (
        <>
          <h4>New Fragment</h4>
          <SelectInput
            source="languageCode"
            choices={ContentLanguages}
            style={styles.adjacentField}
          />
          <TextInput source="contentKey" />
        </>
      ) : (
        <>
          <div style={styles.header}>
            <h4>
              <a
                href={`${LINK_TO_TMS}/contents/${contentKey}/fragments/${record.id}`}
                name={`/contents/${contentKey}/fragments/${record.id}`}
              >
                {indexFromFragmentList != null
                  ? `No.${indexFromFragmentList + 1} Fragment`
                  : null}
              </a>
            </h4>
            {record.keepable ? <CreateQuestionButton record={record} /> : null}
          </div>
          <div style={styles.flexRowContainer}>
            <TextInputWithButton
              source="id"
              label="Fragment Id"
              onClickHandler={onClickCopyIdButton}
              icon={<CopyIcon fontSize="small" />}
              width={220}
              disabled
            />
            {/* Reference: https://github.com/airbnb/javascript/pull/1648 */}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link to={`/contents/${contentKey}`} target="_blank">
              <ReferenceInput
                label="Content Title"
                disabled
                source="contentKey"
                reference="contents"
                style={styles.adjacentField}
              >
                <SelectInput optionText="title" />
              </ReferenceInput>
            </Link>
            <TextInput
              disabled
              label="Language"
              source="languageCode"
              style={{ ...styles.adjacentField, width: 100 }}
            />
          </div>
        </>
      )}
      <br />
      {youTubeVideoId != null ? (
        <div>
          <TimestampInput
            source="startTime"
            validate={validateStartTime}
            style={{
              ...(dirtyFields.startTime ? styles.updated : null),
            }}
          />
          <TimestampInput
            source="endTime"
            validate={validateEndTime}
            style={{
              ...(dirtyFields.endTime ? styles.updated : null),
            }}
          />
          <Button
            href={`https://www.youtube.com/watch?v=${youTubeVideoId}&feature=youtu.be&t=${JSUtility.convertTimestampToSeconds(
              startTime,
            )}`}
            target="_blank"
            rel="noopener noreferrer"
            style={styles.iconButton}
          >
            <CustomYouTubeIcon fontSize="large" />
          </Button>
        </div>
      ) : (
        <>
          <TimestampInput
            source="startTime"
            validate={validateStartTime}
            style={{
              ...(dirtyFields.startTime ? styles.updated : null),
            }}
          />
          <TimestampInput
            source="endTime"
            validate={validateEndTime}
            style={{
              ...(dirtyFields.endTime ? styles.updated : null),
            }}
          />
        </>
      )}
      <div
        style={{ ...styles.flexRowContainer, marginTop: 10, marginBottom: 10 }}
      >
        <BooleanInput
          label="For Study?"
          source="keepable"
          defaultValue={isCreating ? studyableWordCount() <= 20 : undefined}
          style={{
            marginRight: 5,
            ...(dirtyFields.keepable ? styles.updated : null),
          }}
        />
        <BooleanInput
          label="For Notification?"
          source="notificationEnabled"
          defaultValue={isCreating ? false : undefined}
          style={{
            marginRight: 5,
            ...(dirtyFields.notificationEnabled ? styles.updated : null),
          }}
        />
        {isTextDone ? null : (
          <>
            <MultiplyButton text={text} />
            <MusicNoteButton text={text} />
            <ForeignLanguageButton learningLanguage={languageCode} />
          </>
        )}
      </div>
      <TextInput
        disabled={isTextDone}
        style={{
          ...(_.includes(formData.text, '#') ? styles.textHighlight : null),
          ...(dirtyFields.text ? styles.updated : null),
        }}
        multiline
        source="text"
        fullWidth
        validate={validateText}
        parse={(value) => JSUtility.hasValidQuote(value)}
      />
      {isTextDone ? (
        <div>
          <WordsField record={formData} isDirty={dirtyFields.words || false} />
        </div>
      ) : (
        <div>
          <Button
            style={styles.textSubmitButton}
            variant="outlined"
            onClick={onClickTextDone}
          >
            Submit Text
          </Button>
        </div>
      )}
      <TranslationField
        source="textTranslations"
        languageCodeIdOfCurrentContent={languageCode}
        record={formData}
        dirtyFields={dirtyFields}
      />
      {textTranslationsByGengo == null ? null : (
        <TranslationField
          label="Text Translation By Gengo"
          source="_textTranslationsByGengo"
          languageCodeIdOfCurrentContent={languageCode}
          record={formData}
          dirtyFields={dirtyFields}
        />
      )}
      <GrammarsField
        record={formData}
        isDirty={dirtyFields.grammarIds || false}
      />
      <TopicsField record={formData} isDirty={dirtyFields.topicIds || false} />
      {isCreating || !formData.keepable
        ? null
        : id && (
            <QuestionsOnSpecificFragment
              questionId="fakeIdToRemoveWarning"
              fragmentKey={id}
            />
          )}
    </>
  );
};

MultiplyButton.propTypes = {
  // eslint-disable-next-line react/require-default-props
  text: PropTypes.string,
};

MusicNoteButton.propTypes = {
  // eslint-disable-next-line react/require-default-props
  text: PropTypes.string,
};

FragmentFormContainer.propTypes = {
  recordId: PropTypes.string,
};

FragmentFormContainer.defaultProps = {
  recordId: null,
};

const CommonPropTypesForFragmentFormAndContent = {
  mode: PropTypes.oneOf(['create', 'update']).isRequired,
  indexFromFragmentList: PropTypes.number,
  youTubeVideoId: PropTypes.string,
};

FragmentForm.propTypes = {
  ...CommonPropTypesForFragmentFormAndContent,
  // eslint-disable-next-line react/require-default-props
  record: PropTypes.shape({
    id: PropTypes.string,
    youTubeVideoId: PropTypes.string,
    textTranslations: PropTypes.shape(),
  }),
  // eslint-disable-next-line react/require-default-props
  created: PropTypes.func,
};

FragmentFormContent.propTypes = {
  ...CommonPropTypesForFragmentFormAndContent,
};

FragmentFormContent.defaultProps = {
  indexFromFragmentList: null,
  youTubeVideoId: null,
};

export default FragmentForm;
