import AssignmentIcon from '@material-ui/icons/Assignment';

import SurveyQuestionList from './SurveyQuestionList';
import SurveyQuestionEdit from './SurveyQuestionEdit';
import SurveyQuestionCreate from './SurveyQuestionCreate';

export default {
  list: SurveyQuestionList,
  create: SurveyQuestionCreate,
  edit: SurveyQuestionEdit,
  icon: AssignmentIcon,
};
