import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Snackbar, makeStyles } from '@material-ui/core';
import { Button } from 'react-admin';
import { Alert } from '@material-ui/lab';
import { uniqueId } from 'lodash';
import expandableSelectStyles from '../../contents/component/ExpandableSelectStyles';
import { CONTENT_AUTOMATION_API_URL } from '../../../constants';
import { useAutomationContext } from '../../../common/AutomationContextProvider';

const fetchUrl = CONTENT_AUTOMATION_API_URL;

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '1rem',
  },
}));

const useSharedStyles = expandableSelectStyles;

const FragmentsGenerator = ({ baseRecord, callback, setFragments }) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles();

  const { sonixId, startTimeParsed, endTimeParsed } = useAutomationContext();

  // Snackbar related
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  /**
   *
   * @param {'error' | 'success' | 'info'} severity
   * @param {string} message
   */
  const openSnackbar = (severity, message) => {
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
    setIsSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === `clickaway`) {
      return;
    }

    setIsSnackbarOpen(false);
  };

  // Snackbar related END

  // Fragment related
  const createFragment = useCallback(
    ({ text, textTranslations, startTime, endTime }) => {
      const newFragment = {
        ...baseRecord,
        fragmentKey: uniqueId(),
        text,
        textTranslations,
        startTime,
        endTime,
      };
      return newFragment;
    },
    [baseRecord],
  );

  const getFragments = useCallback(async () => {
    const response = await fetch(
      `${fetchUrl}/fragment/${sonixId}?start=${startTimeParsed}&end=${endTimeParsed}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    const results = await response.json();

    return results;
  }, [sonixId, startTimeParsed, endTimeParsed]);

  const onClickHandler = useCallback(async () => {
    if (!sonixId) {
      openSnackbar('error', 'No Sonix ID provided! Press "TRANSCRIBE" once.');
      return;
    }

    const newFragments = (await getFragments()).data.map((fragment) => {
      return createFragment(fragment);
    });
    setFragments((prevFragments) => [...prevFragments, ...newFragments]);
    openSnackbar('success', 'Fragments Generated');
    callback(newFragments);
  }, [sonixId, getFragments, callback, setFragments, createFragment]);
  // Fragment related END
  return (
    <>
      <Grid
        container
        spacing={4}
        className={`${sharedClasses.tree}`}
        justify="center"
      >
        <Grid item>
          <div className={classes.root}>
            <Button
              variant="contained"
              color="primary"
              label="Generate Fragments From Transcripts"
              onClick={onClickHandler}
            />
          </div>
        </Grid>
      </Grid>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

FragmentsGenerator.propTypes = {
  baseRecord: PropTypes.objectOf({
    contentKey: PropTypes.string,
    languageCode: PropTypes.string,
  }).isRequired,
  callback: PropTypes.func,
  setFragments: PropTypes.func,
};

export default FragmentsGenerator;
