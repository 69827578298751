import * as React from 'react';
import {
  List,
  MenuItem,
  ListItemIcon,
  Typography,
  Collapse,
  Tooltip,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  item: {
    paddingLeft: 16,
    '& svg': {
      paddingRight: 16,
    },
  },
  list: {
    transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    paddingLeft: 14,
  },
  icon: {
    minWidth: 3,
  },
});

const CustomSubMenu = (props) => {
  const { handleToggle, isOpen, name, icon, children } = props;
  const classes = useStyles();

  const header = (
    <MenuItem onClick={handleToggle} className={classes.item}>
      <ListItemIcon className={classes.icon}>
        {isOpen ? <ExpandMoreIcon /> : icon}
      </ListItemIcon>
      <Typography variant="inherit" color="textSecondary">
        {name}
      </Typography>
    </MenuItem>
  );

  return (
    <div>
      {isOpen ? (
        header
      ) : (
        <Tooltip title={name} placement="right">
          {header}
        </Tooltip>
      )}
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding className={classes.list}>
          {children}
        </List>
      </Collapse>
    </div>
  );
};

CustomSubMenu.propTypes = {
  handleToggle: PropTypes.func.isRequired,
  icon: PropTypes.element.isRequired,
  isOpen: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
export default CustomSubMenu;
