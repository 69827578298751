import _ from 'lodash';
import ApolloClientHelper from './ApolloClientHelper';
import Queries from './graphqlQueries';
import filterRecord from './filterRecord';

// Note: This logic is TMS-specific one. It should be removed in the future.
export default {
  getList: async (type, resource, params) => {
    const apolloClient = ApolloClientHelper.getInstance();

    const {
      pagination: { perPage: limit, page },
      filter,
      sort,
    } = params;

    const { difficultyLevel, hidden, ...restFilters } = filter;

    const offset = limit * (page - 1);

    const {
      data: {
        lemmas: { records, pagination },
      },
    } = await apolloClient.query({
      query: Queries.GET_LEMMAS,
      variables: {
        input: {
          difficultyLevel,
          hidden,
          pagination: { limit, offset },
        },
      },
    });

    let results = records;

    if (filter != null) {
      results = results.filter((r) => filterRecord(r, restFilters, [], []));
    }

    if (sort != null) {
      results = _.orderBy(results, [sort.field], [sort.order.toLowerCase()]);
    }

    const total =
      pagination.nextStartAt != null
        ? offset + results.length + 1 // Not support the correct total
        : offset + results.length;

    return {
      data: results,
      total,
    };
  },
  getOne: async (type, resource, params) => {
    const apolloClient = ApolloClientHelper.getInstance();

    const {
      data: { lemma },
    } = await apolloClient.query({
      query: Queries.GET_LEMMA,
      variables: { input: { id: params.id } },
    });

    return { data: lemma };
  },
  getMany: async (type, resource, params) => {
    const apolloClient = ApolloClientHelper.getInstance();

    const getManyPromises = params.ids.map((id) =>
      apolloClient.mutate({
        mutation: Queries.GET_LEMMA,
        variables: { input: { id } },
      }),
    );

    const results = await Promise.all(getManyPromises);
    const lemma = results.map((r) => r.data.lemma);

    return { data: lemma };
  },
  create: async (type, resource, params) => {
    const apolloClient = ApolloClientHelper.getInstance();

    const {
      data: { lemma, difficultyLevel, frequency, hidden, relatedWords },
    } = params;

    const {
      data: {
        createLemma: { lemma: newLemma },
      },
    } = await apolloClient.mutate({
      mutation: Queries.CREATE_LEMMA,
      variables: {
        input: {
          lemma,
          difficultyLevel,
          frequency,
          hidden,
          relatedWords,
        },
      },
    });

    return { data: newLemma };
  },
  update: async (type, resource, params) => {
    const apolloClient = ApolloClientHelper.getInstance();

    const {
      data: { id, lemma, difficultyLevel, frequency, hidden, relatedWords },
    } = params;

    const {
      data: {
        updateLemma: { lemma: updatedLemma },
      },
    } = await apolloClient.mutate({
      mutation: Queries.UPDATE_LEMMA,
      variables: {
        input: {
          id,
          lemma,
          difficultyLevel,
          frequency,
          hidden,
          relatedWords,
        },
      },
    });

    return { data: updatedLemma };
  },
  delete: async (type, resource, params) => {
    const apolloClient = ApolloClientHelper.getInstance();

    await apolloClient.mutate({
      mutation: Queries.DELETE_LEMMA,
      variables: { input: { id: params.id } },
    });

    return { data: null };
  },
  deleteMany: async (type, resource, params) => {
    const apolloClient = ApolloClientHelper.getInstance();

    const deletePromises = params.ids.map((id) =>
      apolloClient.mutate({
        mutation: Queries.DELETE_LEMMA,
        variables: { id },
      }),
    );

    const results = await Promise.all(deletePromises);
    const deletedIds = results.map((r) => r.data.deleteLemma);

    return { data: deletedIds };
  },
};
