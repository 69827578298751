import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SimpleForm, BooleanInput, TextInput } from 'react-admin';
import { useForm } from 'react-final-form';

import { CustomEditToolbar } from '../../common';

const styles = {
  verticalView: {
    display: 'flex',
    flexDirection: 'column',
  },
  textInput: {
    width: 350,
  },
  updated: {
    backgroundColor: '#fad0c3',
  },
};

const ReviewForm = (props) => {
  const { mode, record, ...otherProps } = props;
  return (
    <SimpleForm
      {...props}
      toolbar={
        <CustomEditToolbar {...otherProps} mode={mode} record={record} />
      }
      redirect={false}
      submitOnEnter={false}
      keepDirtyOnReinitialize={false}
    >
      <ReviewFormContent mode={mode} record={record} />
    </SimpleForm>
  );
};

const ReviewFormContent = () => {
  const [dirtyFields, setDirtyFields] = useState({});
  const [formData, setFormData] = useState({});

  const form = useForm();

  useEffect(() => {
    const unsubscribe = form.subscribe(
      (s) => {
        setDirtyFields(s.dirtyFields);
        setFormData(s.values);
      },
      { dirtyFields: true, values: true },
    );
    return () => {
      unsubscribe();
    };
  }, [form]);

  return (
    <div style={styles.verticalView}>
      <TextInput multiline label="Content" source="content" disabled />
      <BooleanInput
        label="IsPublished"
        source="published"
        record={formData}
        style={{
          marginRight: 5,
          ...(dirtyFields.hidden ? styles.updated : null),
        }}
      />
    </div>
  );
};

const CommonPropTypesForLemmaFormAndContent = {
  mode: PropTypes.oneOf(['create', 'update']).isRequired,
  record: PropTypes.PropTypes.shape({}),
};

ReviewForm.propTypes = {
  ...CommonPropTypesForLemmaFormAndContent,
};

ReviewFormContent.propTypes = {
  ...CommonPropTypesForLemmaFormAndContent,
};

export default ReviewForm;
