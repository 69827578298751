import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';

import GrammarIdSelectInput from './GrammarIdSelectInput';
import withSearchEngine, {
  SearchProps,
} from '../../../search/modules/withSearchEngine';
import * as ElasticAppSearch from '../../../search/modules/ElasticAppSearch';
import { defaultIdValue } from './GrammarsField';

interface GrammarResult {
  id: string;
  text: string;
  definition: string;
  href?: string;
}

const connector = ElasticAppSearch.createConnector('grammars-en');
const searchEngineConfig = {
  apiConnector: connector,
  searchQuery: {
    precision: 8,
    search_fields: {
      text: { weight: 10 },
    },
    result_fields: {
      text: {
        raw: {},
        snippet: {},
      },
      definition: {
        raw: {},
      },
    },
  },
};

const transformResult = (result): GrammarResult => ({
  id: result.id,
  text: result.text,
  definition: result.definition,
  href: `#/grammars/${result.id}`,
});

interface GrammarInputProps {
  record?: string;
  source?: string;
}

type GrammarInputWithSearchEngineProps = GrammarInputProps &
  SearchProps<GrammarResult>;

// <Configure hitsPerPage={3} />

const GrammarInput = ({
  source = '',
  record = '',
  search = () => {},
  results,
  clearFilters,
  addFilter,
  setResultsPerPage,
}: GrammarInputWithSearchEngineProps) => {
  const [initialGrammar, setInitialGrammar] = useState<string | undefined>();

  useEffect(() => {
    setResultsPerPage(5);
  }, [setResultsPerPage]);

  const searchWithFilter = useCallback(
    (q: string) => {
      clearFilters();
      search(q);
      // `addFilter` applys `all` for root filter.
      // This is a use case to use `any` for root filter.
    },
    [search, clearFilters],
  );

  useEffect(() => {
    if (
      !_.isEmpty(record) &&
      record !== defaultIdValue &&
      initialGrammar == null
    ) {
      clearFilters();
      addFilter('any', { id: record }, 'any');
    }
  }, [record, addFilter, clearFilters, initialGrammar]);

  useEffect(() => {
    if (initialGrammar == null) {
      const resultForCurrentGrammarId = _.find(results, ['id', record]);
      if (resultForCurrentGrammarId != null) {
        setInitialGrammar(
          resultForCurrentGrammarId.text.replace(/<em>|<\/em>/gi, ''),
        );
      }
    }
  }, [initialGrammar, record, results]);

  return (
    <GrammarIdSelectInput
      key={`grammar-input-${initialGrammar}`}
      hits={results}
      grammarId={record}
      initialGrammar={initialGrammar}
      source={source}
      search={searchWithFilter}
    />
  );
};

const GrammarInputWithSearch = withSearchEngine<
  unknown,
  GrammarResult,
  GrammarInputProps
>(searchEngineConfig, transformResult, GrammarInput);

export default React.memo(GrammarInputWithSearch);
