import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { List, Datagrid, TextField, EditButton, Pagination } from 'react-admin';
import { Button } from '@material-ui/core';
import JSUtility from '../../utilities/JSUtility';
import {
  DurationField,
  ScrollToTopButton,
  ShortenedDateField,
} from '../../common';
import { KST_OFFSET_IN_MIN } from '../../constants';

const styles = {
  youTubeVideoButton: {
    marginLeft: 5,
  },
  youtubeVideoImage: {
    width: 100,
  },
  titleContainer: {
    width: 200,
  },
  checkBoxContainer: {
    width: 'auto',
  },
  playButton: {
    color: 'red',
  },
};

const ThumbnailField = ({ record }) => {
  const convertedTimestampOfStartTime = JSUtility.convertTimestampToSeconds(
    record.startTime,
  );
  const youTubeUrl = `https://www.youtube.com/watch?v=${record.youTubeVideoId}&feature=youtu.be&t=${convertedTimestampOfStartTime}`;
  const imageUrl = `https://img.youtube.com/vi/${record.youTubeVideoId}/default.jpg`;
  return (
    <Button href={youTubeUrl} target="_blank" style={styles.youTubeVideoButton}>
      <img
        src={imageUrl}
        alt={`${record.youTubeVideoId}`}
        style={styles.youtubeVideoImage}
      />
    </Button>
  );
};

const SktContentList = ({ ...props }) => {
  useEffect(() => {
    document.title = 'TMS: SKT Content';
  }, []);

  return (
    <>
      <List
        pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
        perPage={25}
        sort={{ field: 'releaseDate.seconds', order: 'DESC' }}
        bulkActionButtons={false}
        exporter={false}
        {...props}
      >
        <Datagrid rowClick="expand">
          <ThumbnailField label="Thumbnail" />
          <TextField source="title" style={styles.titleContainer} />
          <ShortenedDateField
            label="Created Date"
            source="createdAt.seconds"
            type="timestamp"
            utcOffset={KST_OFFSET_IN_MIN}
          />
          <ShortenedDateField
            label="Release Date"
            source="releaseDate.seconds"
            type="timestamp"
            utcOffset={KST_OFFSET_IN_MIN}
          />
          <DurationField label="Duration" />
          <EditButton />
        </Datagrid>
      </List>
      <ScrollToTopButton category={0} />
    </>
  );
};

ThumbnailField.propTypes = {
  record: PropTypes.shape({
    youTubeVideoId: PropTypes.string,
    startTime: PropTypes.string,
  }),
};

ThumbnailField.defaultProps = {
  record: {
    youTubeVideoId: null,
    startTime: null,
  },
};

export default SktContentList;
