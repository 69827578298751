import React from 'react';
import PropTypes from 'prop-types';
import { Edit } from 'react-admin';

import FragmentActions from './FragmentActions';
import FragmentForm from './FragmentForm';

const FragmentEditTitle = ({ record }) => (
  <span>
    Fragment Edit
    {record ? `"${record.contentKey}"` : ''}
  </span>
);

const FragmentEdit = (props) => (
  <Edit
    title={<FragmentEditTitle />}
    actions={<FragmentActions basePath="/fragments" />}
    {...props}
  >
    <FragmentForm mode="update" />
  </Edit>
);

FragmentEditTitle.propTypes = {
  record: PropTypes.shape({
    contentKey: PropTypes.string,
  }),
};

// ToDo: Should be removed because we're using default parameter
FragmentEditTitle.defaultProps = {
  record: {
    contentKey: null,
  },
};

export default FragmentEdit;
