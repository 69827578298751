import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Edit } from 'react-admin';

import GrammarActions from './GrammarActions';
import GrammarForm from './GrammarForm';

const GrammarTitle = ({ record = null }) => (
  <>Grammar: {_.get(record, 'text', '')}</>
);

GrammarTitle.propTypes = {
  // eslint-disable-next-line react/require-default-props
  record: PropTypes.shape({}),
};

const GrammarEdit = (props) => (
  <Edit
    title={<GrammarTitle />}
    actions={<GrammarActions basePath="/grammars" />}
    {...props}
  >
    <GrammarForm mode="update" />
  </Edit>
);

export default GrammarEdit;
