import React from 'react';
import PropTypes from 'prop-types';
import { Edit } from 'react-admin';

import SktFragmentActions from './SktFragmentActions';
import SktFragmentForm from './SktFragmentForm';

const SktFragmentEditTitle = ({ record }) => (
  <span>
    SktFragment Edit
    {record ? `"${record.contentKey}"` : ''}
  </span>
);

const SktFragmentEdit = (props) => (
  <Edit
    title={<SktFragmentEditTitle />}
    actions={<SktFragmentActions basePath="/SktFragments" />}
    {...props}
  >
    <SktFragmentForm mode="update" />
  </Edit>
);

SktFragmentEditTitle.propTypes = {
  record: PropTypes.shape({
    contentKey: PropTypes.string,
  }),
};

SktFragmentEditTitle.defaultProps = {
  record: {
    contentKey: null,
  },
};

export default SktFragmentEdit;
