import React from 'react';
import moment from 'moment';
import _ from 'lodash';

export interface ContentResult {
  id: string;
  title: string;
  subtitle: string;
  releaseDate?: Date;
  hidden: boolean;
  supportedTranslationLanguages: string[];
  tags: string;
  tagTranslations: string[];
  href: string;
}

interface ContentResultItemProps {
  result: ContentResult;
}

const parseTagTranslations = (tagTranslations) => {
  const parsedTagData = tagTranslations
    .map((tag) => JSON.parse(tag))
    .map((parsedTag) => parsedTag.en)
    .join(', ');
  return parsedTagData;
};

const ContentResultItem = (props: ContentResultItemProps): JSX.Element => {
  const {
    result: {
      title,
      subtitle,
      releaseDate,
      hidden,
      supportedTranslationLanguages,
      tagTranslations,
    },
  } = props;

  const tags = parseTagTranslations(tagTranslations);

  return (
    <div>
      <div className="hit-title">
        <span dangerouslySetInnerHTML={{ __html: title }} />
      </div>
      <div className="hit-subtitle">
        <span dangerouslySetInnerHTML={{ __html: subtitle }} />
      </div>
      <div className="hit-dateAndPublic">
        Release Date:&nbsp;
        {releaseDate != null
          ? moment(releaseDate).format('YYYY-MM-DD')
          : ' - '}{' '}
        /&nbsp;
        {hidden ? 'Not Published' : 'Published'}
      </div>
      <div className="hit-supportedTranslationLanguages">
        Supported Langs:&nbsp;
        {supportedTranslationLanguages === null ||
        _.isEqual(supportedTranslationLanguages, [])
          ? ' - '
          : supportedTranslationLanguages.sort().join(', ')}
      </div>
      <div className="hit-tags">
        Tags: <span dangerouslySetInnerHTML={{ __html: tags }} />
      </div>
    </div>
  );
};

export default ContentResultItem;
