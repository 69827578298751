import React from 'react';
import {
  TextField,
  BooleanField,
  Show,
  SimpleShowLayout,
  EditButton,
} from 'react-admin';

const SurveyQuestionShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <EditButton {...props} />
      <TextField label="Question" source="question" />
      <BooleanField label="Published" source="published" />
      <TextField label="Type" source="type" />
      <TextField label="User property" source="userPropertyName" />
    </SimpleShowLayout>
  </Show>
);

export default SurveyQuestionShow;
