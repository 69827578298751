import ApolloClientHelper from './ApolloClientHelper';
import Queries from './graphqlQueries';

// Note: This logic is TMS-specific one. It should be removed in the future.
export default {
  create: async (
    questionId,
    originalText,
    { originalTextTranslations = [], published } = {},
  ) => {
    const apolloClient = ApolloClientHelper.getInstance();
    const {
      data: { createAnswerOfQuestion: answer },
    } = await apolloClient.mutate({
      mutation: Queries.CREATE_ANSWER,
      variables: {
        input: {
          questionId,
          originalText,
          originalTextTranslations: originalTextTranslations.map((t) => ({
            languageCode: t.languageCode,
            text: t.text,
          })),
          published,
        },
      },
    });

    return answer;
  },
  update: async (
    id,
    { questionId, originalText, originalTextTranslations = [], published } = {},
  ) => {
    const apolloClient = ApolloClientHelper.getInstance();
    const {
      data: { updateAnswerOfQuestion: answer },
    } = await apolloClient.mutate({
      mutation: Queries.UPDATE_ANSWER,
      variables: {
        input: {
          id,
          questionId,
          originalText,
          originalTextTranslations: originalTextTranslations.map((t) => ({
            languageCode: t.languageCode,
            text: t.text,
          })),
          published,
        },
      },
    });

    return answer;
  },
};
