import React from 'react';
import { SimpleForm, TextInput } from 'react-admin';
import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import { CustomEditToolbar } from '../../common';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  margin: {
    marginLeft: 20,
  },
  textInput: {
    width: 'fit-content',
  },
});

const EventForm = (props) => {
  const { mode, record, ...otherProps } = props;

  return (
    <>
      <SimpleForm
        {...props}
        toolbar={
          <CustomEditToolbar {...otherProps} mode={mode} record={record} />
        }
        redirect={false}
        submitOnEnter={false}
        keepDirtyOnReinitialize={false}
      >
        <EventFormContent mode={mode} record={record} />
      </SimpleForm>
    </>
  );
};

const EventFormContent = () => {
  const classes = useStyles();
  const timestampFormatter = (v) =>
    v != null ? moment(v.toDate()).format('YYYY-MM-DD, HH:mm:ss') : null;
  return (
    <div className={classes.wrapper}>
      <TextInput
        disabled
        label="Event Id"
        source="id"
        className={classes.textInput}
      />
      <TextInput
        disabled
        label="Event Name"
        source="eventName"
        className={classes.textInput}
      />
      <span>
        <TextInput
          disabled
          label="Created At"
          source="createdAt"
          format={timestampFormatter}
        />
        <TextInput
          disabled
          label="Modified At"
          source="modifiedAt"
          format={timestampFormatter}
          className={classes.margin}
        />
      </span>
      <span>
        <TextInput
          disabled
          label="Start time"
          source="start"
          format={timestampFormatter}
        />
        <TextInput
          disabled
          label="End time"
          source="end"
          format={timestampFormatter}
          className={classes.margin}
        />
      </span>
    </div>
  );
};

const CommonPropTypesForEventForm = {
  mode: PropTypes.oneOf(['create', 'update']).isRequired,
  record: PropTypes.shape({}),
};

EventForm.propTypes = {
  ...CommonPropTypesForEventForm,
};

EventFormContent.propTypes = {
  ...CommonPropTypesForEventForm,
};
export default EventForm;
