import React, { useCallback, useState, useEffect } from 'react';
import { ArrayInput } from 'react-admin';
import { useForm } from 'react-final-form';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Collapse, IconButton } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

import { CustomSimpleFormIterator } from '../../../common';
import GrammarLemmasInputWithSearch from './GrammarLemmasInputWithSearch';

const styles = {
  expandedArrow: {
    transform: 'rotate(180deg)',
  },
  notStudyableWord: {
    color: 'gray',
  },
  updated: {
    backgroundColor: '#fad0c3',
  },
};

interface Record {
  lemmaIds: string[];
  text: string;
}

interface GrammarLemmasProps {
  record?: Record;
  isDirty: boolean;
}

interface GrammaLemmasInputProps {
  words?: string[];
  source?: string;
}

export const defaultLemmaIdValue = 'Lemma ID';

const GrammarLemmasInput = ({
  words,
  source,
  ...props
}: GrammaLemmasInputProps) => {
  // extracting the field index from source to get corresponding word
  const index = Number(
    source?.substring(source.indexOf('[') + 1, source?.indexOf(']')),
  );
  return (
    <GrammarLemmasInputWithSearch
      initialLemma={words?.[index]}
      source={source}
      {...props}
    />
  );
};

const GrammarLemmasField = ({ record, isDirty }: GrammarLemmasProps) => {
  const [words, setWords] = useState<string[] | undefined>();
  const [isExpanded, setIsExpanded] = useState(false);
  const { text, lemmaIds } = record ?? {};
  const onClickExpand = useCallback(
    () => setIsExpanded(!isExpanded),
    [isExpanded],
  );
  const form = useForm();

  useEffect(() => {
    const initWords = text?.split(' ');
    if (initWords != null) {
      if (_.isEmpty(lemmaIds)) {
        form.change(
          'lemmaIds',
          initWords.map(() => defaultLemmaIdValue),
        );
      }
      setWords(initWords);
    }
  }, [form, text, lemmaIds]);

  return (
    <>
      <span>
        <IconButton
          style={isExpanded ? styles.expandedArrow : undefined}
          onClick={onClickExpand}
        >
          <ExpandMoreIcon />
        </IconButton>
        {isExpanded ? 'Hide Lemmas: ' : 'Show Lemmas: '}
        <span style={isDirty ? styles.updated : undefined}>{record?.text}</span>
      </span>
      <Collapse in={isExpanded} timeout="auto">
        <ArrayInput source="lemmaIds" record={record}>
          <CustomSimpleFormIterator defaultAddValue={defaultLemmaIdValue}>
            <GrammarLemmasInput words={words} />
          </CustomSimpleFormIterator>
        </ArrayInput>
      </Collapse>
    </>
  );
};

GrammarLemmasField.propTypes = {
  // eslint-disable-next-line react/require-default-props
  record: PropTypes.shape({
    text: PropTypes.string,
  }),
};

export default React.memo(GrammarLemmasField);
