import React from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
  NumberField,
  ShowController,
  ShowView,
  SimpleShowLayout,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
} from 'react-admin';
import _ from 'lodash';

import { ShortenedDateField } from '../../common';
import { KST_OFFSET_IN_MIN } from '../../constants';
import NestedObjectKeyTypeField from '../../common/fields/NestedObjectKeyTypeField';

const styles = {
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  buttons: {
    marginLeft: 5,
  },
  playButton: {
    color: 'red',
  },
  subtitleLanguage: {
    fontWeight: 'bold',
  },
};

const PlayTimeField = ({ record }) => {
  const isStartTimeInvalid = typeof record.startTime === 'undefined';
  const isEndTimeInvalid = typeof record.endTime === 'undefined';
  const start = '00:00:00,000';
  const end = 'The End of the Video';
  if (isStartTimeInvalid && isEndTimeInvalid) {
    return (
      <span>
        {start}
        &nbsp;~&nbsp;
        {end}
      </span>
    );
  } else if (isStartTimeInvalid) {
    return (
      <span>
        {start}
        &nbsp;~&nbsp;
        {record.endTime}
      </span>
    );
  } else if (isEndTimeInvalid) {
    return (
      <span>
        {record.startTime}
        &nbsp;~&nbsp;
        {end}
      </span>
    );
  }
  return (
    <span>
      {record.startTime}
      &nbsp;~&nbsp;
      {record.endTime}
    </span>
  );
};

const SubtitleTranslationsField = ({ record }) =>
  record.subtitleTranslations != null
    ? Object.keys(record.subtitleTranslations).map((subtitleLanguage) => (
        <div key={`subtitle-translations-field-${subtitleLanguage}`}>
          <span style={styles.subtitleLanguage}>{subtitleLanguage}</span>
          &nbsp;
          <span>{record.subtitleTranslations[subtitleLanguage]}</span>
        </div>
      ))
    : null;

const ContentShow = (props) => {
  const { record } = props;

  return (
    <ShowController {...props}>
      {(controllerProps) => (
        <ShowView {...props} {...controllerProps}>
          <SimpleShowLayout>
            <TextField label="Language Code" source="languageCode" />
            <ShortenedDateField
              label="Release Date"
              source="releaseDate.seconds"
              type="timestamp"
              utcOffset={KST_OFFSET_IN_MIN}
            />
            <TextField source="title" />
            <TextField source="subtitle" />
            {_.get(record, 'subtitleTranslations', null) ? (
              <SubtitleTranslationsField label="Subtitle Translations" />
            ) : null}
            {/* {
              _.get(record, '_gengo', null)
                ? <TextField label="Gengo Status" />
                : null
            }
            } */}
            <ReferenceArrayField label="Tags" source="tagKeys" reference="tags">
              <NestedObjectKeyTypeField source="languageCodeToName.en" />
            </ReferenceArrayField>
            <ReferenceArrayField
              label="Mission Words"
              source="lemmas"
              reference="lemmas"
            >
              <SingleFieldList linkType="show">
                <ChipField source="lemma" />
              </SingleFieldList>
            </ReferenceArrayField>
            <NumberField label="WPM" source="_system.wpm" />
          </SimpleShowLayout>
        </ShowView>
      )}
    </ShowController>
  );
};

PlayTimeField.propTypes = {
  record: PropTypes.shape({
    startTime: PropTypes.string,
    endTime: PropTypes.string,
  }),
};

PlayTimeField.defaultProps = {
  record: {
    startTime: null,
    endTime: null,
  },
};

ContentShow.propTypes = {
  record: PropTypes.shape({
    youTubeVideoId: PropTypes.string.isRequired,
  }),
};

ContentShow.defaultProps = {
  record: null,
};

SubtitleTranslationsField.defaultProps = { addLabel: true };

export default ContentShow;
