import RateReviewIcon from '@material-ui/icons/RateReview';
import ReviewList from './ReviewList';
import ReviewShow from './ReviewShow';
import ReviewForm from './ReviewForm';
import ReviewEdit from './ReviewEdit';

export default {
  list: ReviewList,
  show: ReviewShow,
  form: ReviewForm,
  edit: ReviewEdit,
  icon: RateReviewIcon,
};
