import { Edit } from 'react-admin';
import React from 'react';
import EventActions from './EventActions';
import EventForm from './EventForm';

const EventEdit = (props) => (
  <Edit actions={<EventActions basePath="/events" />} {...props}>
    <EventForm mode="update" />
  </Edit>
);

export default EventEdit;
