import AppSearchAPIConnector from '@elastic/search-ui-app-search-connector';
import _ from 'lodash';

import { __DEV__ } from '../../constants';

const ELASTIC_APP_SEARCH_PUBLIC_API_KEY = __DEV__
  ? 'search-ap2idxcvjm2kcmrb3z3vqx3g'
  : 'search-b5z3x94rb524ntj985pb1hbe';
const ELASITC_APP_SEARCH_API_BASE_ENDPOINT = __DEV__
  ? 'https://redkiwi-dev.ent.us-central1.gcp.cloud.es.io'
  : 'https://redkiwi.ent.us-central1.gcp.cloud.es.io';

// eslint-disable-next-line import/prefer-default-export
export const createConnector = (engineName: string): unknown => {
  const connector = new AppSearchAPIConnector({
    searchKey: ELASTIC_APP_SEARCH_PUBLIC_API_KEY,
    engineName,
    endpointBase: ELASITC_APP_SEARCH_API_BASE_ENDPOINT,
    cacheResponses: false,
  });

  return connector;
};

const parseStringifiedJson = (value: string): unknown => {
  try {
    return JSON.parse(value);
  } catch {
    return value;
  }
};

export const parseResult = (result: any): any =>
  _.mapValues(result, (o) => {
    const { raw, snippet } = o;
    if (snippet == null) {
      if (typeof raw === 'string') {
        return parseStringifiedJson(raw);
      }
      return raw;
    }

    if (raw instanceof Array) {
      const highlightedText = _.chain(snippet)
        .replace('<em>', '')
        .replace('</em>', '')
        .value();
      const highlightedArray = raw.map((text) =>
        text === highlightedText ? snippet : text,
      );
      return highlightedArray;
    }

    const curlyBracketsRegex = new RegExp('^{[^ ].*[^ ]}$');

    if (raw.match(curlyBracketsRegex)) {
      return parseStringifiedJson(raw);
    }

    const unescapedSnippet = _.unescape(snippet);
    return parseStringifiedJson(unescapedSnippet);
  });
