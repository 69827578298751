import React, { useCallback, useRef, useState } from 'react';
import { Button, Paper, Checkbox, ListItem, Tooltip } from '@material-ui/core';
import { useForm } from 'react-final-form'; // eslint-disable-line import/no-extraneous-dependencies
import _ from 'lodash';
import { TextInput, required } from 'react-admin';
import { Link } from 'react-router-dom';
import { Add as AddIcon, Link as LinkIcon } from '@material-ui/icons';
import SearchableTextInput from './SearchableTextInput';
import { LemmaResult } from '../../../search/components/results/items/LemmaResultItem';

const styles = {
  listContainer: {
    maxHeight: 200,
    maxWidth: 500,
    overflow: 'auto',
    marginBottom: 20,
  },
  listItemLemmaContainer: {
    marginTop: 5,
    marginLeft: 5,
  },
  listHiddenItemLemmaContainer: {
    marginTop: 5,
    marginLeft: 5,
    color: 'gray',
    textDecoration: 'line-through',
  },
  listItemLemmaAttribute: {
    marginTop: 5,
    fontSize: 14,
  },
  listItemLemmaAttributeName: {
    marginLeft: 2,
    fontWeight: 600,
  },
  listItemHighlightedAttribute: {
    fontSize: 16,
    fontWeight: 700,
  },
  addButton: {
    width: '100%',
    paddingTop: 10,
    paddingBottom: 10,
  },
  noResultText: {
    marginTop: 20,
    marginBottom: 20,
    marginRight: 10,
    marginLeft: 10,
    fontSize: 16,
    fontWeight: 600,
  },
  divider: {
    height: 1,
    width: '100%',
    backgroundColor: '#f0f0f0',
  },
  selectLemmaDescription: {
    marginBottom: 5,
    fontSize: 14,
    color: '#495057',
  },
  refreshButton: {
    marginLeft: 100,
    backgroundColor: '#F8F9FA',
  },
  lemmaIdInput: {
    width: '190px',
  },
  lemmaSelectList: {
    marginTop: 10,
  },
  linkButton: {
    marginTop: 15,
    marginLeft: 5,
  },
};

interface LemmaListItemProps {
  hit: LemmaResult;
  onClick: (result: LemmaResult) => void;
  selected: boolean;
}

const LemmaListItem = ({
  hit,
  onClick: handleClickProp,
  selected,
}: LemmaListItemProps) => {
  const handleClick = useCallback(() => {
    if (handleClickProp != null) {
      handleClickProp(hit);
    }
  }, [hit, handleClickProp]);

  return (
    <ListItem
      selected={selected}
      button
      onClick={handleClick}
      alignItems="flex-start"
      divider
    >
      <Checkbox checked={selected} />
      <div
        style={
          hit.hidden
            ? styles.listHiddenItemLemmaContainer
            : styles.listItemLemmaContainer
        }
      >
        <div style={styles.listItemLemmaAttribute}>
          <span style={styles.listItemLemmaAttributeName}>Lemma:</span>
          &nbsp;
          <span style={styles.listItemHighlightedAttribute}>
            <span dangerouslySetInnerHTML={{ __html: hit.lemma }} />
          </span>
        </div>
        <div style={styles.listItemLemmaAttribute}>
          <span style={styles.listItemLemmaAttributeName}>Related words:</span>
          &nbsp;
          <span
            dangerouslySetInnerHTML={{
              __html: hit.relatedWords?.sort().join(', '),
            }}
          />
        </div>
        <div style={styles.listItemLemmaAttribute}>
          <span style={styles.listItemLemmaAttributeName}>
            Difficulty level:
          </span>
          &nbsp;
          {hit.difficultyLevel || '(none)'}
          {hit.hidden ? ' / Hidden' : null}
          &nbsp;
        </div>
        <div style={styles.listItemLemmaAttribute}>
          <span style={styles.listItemLemmaAttributeName}>ID:</span>
          &nbsp;
          {hit.id}
        </div>
      </div>
    </ListItem>
  );
};

// eslint-disable-next-line react/prop-types
const NoResultComponent = ({ query }) => (
  <div>
    <div style={styles.noResultText}>
      {query === ''
        ? 'The Lemma field is empty. Please type something in the Lemma field.'
        : `There are no lemmas matching "${query}". Please create a new lemma.`}
    </div>
    <div style={styles.divider} />
  </div>
);

interface ValidateLemmaIdInput {
  inputValue: string;
  lemma: string;
}

interface LemmaIdSelectInputProps {
  hits: LemmaResult[];
  initialLemma?: string;
  source?: string;
  lemmaId?: string;
  validate: ({ inputValue, lemma }: ValidateLemmaIdInput) => void;
  search: (query: string) => void;
}

const LemmaIdSelectInput = ({
  hits,
  initialLemma = '',
  source = '',
  lemmaId,
  validate,
  search,
}: LemmaIdSelectInputProps): JSX.Element => {
  const form = useForm();
  const [lemma, setLemma] = useState(initialLemma);
  const inputRef = useRef<HTMLInputElement>();
  // const [select, setSelect] = useState(true);

  // const handleSelect = useCallback((hit) => {
  //   console.log(hit.id);
  //   console.log(hits[0].id);
  //   if (lemmaId == null) {
  //     setSelect(hit.id === hits[0].id)
  //   }
  //   else{
  //     setSelect(lemmaId === hit.id)
  //   }
  // }, [lemmaId, hits])

  const updateLemmaId = useRef((id) => {
    form.change(source, id);
  });

  const handleClickItem = useCallback((hit: LemmaResult) => {
    // it focuses text input manually to enable per input validation
    if (inputRef.current != null) {
      inputRef.current.selectionStart = 0;
      inputRef.current.focus();
    }
    updateLemmaId.current(hit.id);
  }, []);
  const updateLemma = useCallback(({ value: newLemma }) => {
    setLemma(newLemma);
  }, []);

  const validateLemmaId = useCallback(
    (value) => validate({ inputValue: value, lemma }),
    [lemma, validate],
  );

  const lemmaIdValidate = [required(), validateLemmaId];

  return (
    <div>
      <div>
        <TextInput
          label="Lemma Id"
          source={source}
          validate={lemmaIdValidate}
          inputRef={inputRef}
          style={styles.lemmaIdInput}
          disabled
        />
        <Tooltip title="Press here to move to lemma detail page" arrow>
          <Button
            style={styles.linkButton}
            component={Link}
            variant="outlined"
            target="_blank"
            to={{
              pathname: `/lemmas/${lemmaId}`,
            }}
          >
            <LinkIcon />
          </Button>
        </Tooltip>
      </div>
      <SearchableTextInput
        refine={search}
        label="Search Keyword"
        // ToDo #10539: It is better to searchableTextInput replace the apostrophe by itself.
        initialValue={initialLemma.replace(/’/g, "'")}
        onChange={updateLemma}
      />
      <div style={styles.lemmaSelectList}>
        <div style={styles.selectLemmaDescription}>
          Select word lemma from the list *
        </div>
        <Paper style={styles.listContainer}>
          {hits.length === 0 ? (
            <NoResultComponent query={lemma} />
          ) : (
            _.map(hits, (hit) => (
              // eslint-disable-next-line react/no-array-index-key
              <LemmaListItem
                key={hit.id}
                hit={hit}
                onClick={handleClickItem}
                selected={lemmaId === hit.id}
              />
            ))
          )}
          <Button
            style={styles.addButton}
            component={Link}
            to={{
              pathname: '/lemmas/create',
              search: `?source=${JSON.stringify({
                lemma: initialLemma.replace(/’/g, "'"),
                frequency: 0,
                difficultyLevel: 6,
                hidden: true,
                relatedWords: [initialLemma.replace(/’/g, "'")],
              })}`,
            }}
            target="_blank"
          >
            <AddIcon />
            Add new lemma
          </Button>
        </Paper>
      </div>
    </div>
  );
};

export default LemmaIdSelectInput;
