import React from 'react';
import {
  EditButton,
  TextField,
  NumberField,
  BooleanField,
  Show,
  SimpleShowLayout,
} from 'react-admin';
import { ArrayTypeField } from '../../common';

const LemmaShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <EditButton {...props} />
      <TextField label="Lemma" source="lemma" />
      <NumberField label="Frequency" source="frequency" />
      <NumberField label="Difficulty Lv." source="difficultyLevel" />
      <BooleanField label="Hidden" source="hidden" />
      <ArrayTypeField label="Related Words" source="relatedWords" />
    </SimpleShowLayout>
  </Show>
);

export default LemmaShow;
