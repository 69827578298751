import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  SimpleForm,
  TextInput,
  BooleanInput,
  required,
  regex,
} from 'react-admin';
import _ from 'lodash';
import moment from 'moment';
import { useForm } from 'react-final-form';
import { CustomEditToolbar, TranslationField } from '../../common';
import { RegexNoOnlySpaceOrNewline } from '../../constants';
import JSUtility from '../../utilities/JSUtility';

const styles = {
  verticalView: {
    display: 'flex',
    flexDirection: 'column',
  },
  textInput: {
    width: 350,
  },
  selectInput: {
    width: 350,
  },
  textHighlight: {
    borderBottom: '5px solid #fffda1',
  },
  updated: {
    backgroundColor: '#fad0c3',
  },
  textSubmitButton: {
    marginTop: 5,
    marginBottom: 5,
  },
};

const validateTopicFormValues = (values) => {
  const { text, textTranslations, published } = values;

  if (!published) {
    return undefined;
  }
  const errors = {};
  const emptyFieldError = 'Please fill the field';

  if (text == null || _.trim(text) === '') {
    errors.text = emptyFieldError;
  }

  if (textTranslations == null) {
    errors.textTranslations = emptyFieldError;
  } else {
    const missingTranslations = _.filter(
      textTranslations,
      (translation) => translation == null || _.trim(translation) === '',
    );
    if (missingTranslations.length !== 0) {
      errors.textTranslations = 'Please check if all translations are filled';
    }
  }
  return errors;
};

const TopicForm = (props) => {
  const { mode, record, ...otherProps } = props;

  return (
    <>
      <SimpleForm
        {...props}
        toolbar={
          <CustomEditToolbar
            {...otherProps}
            mode={mode}
            record={record}
            createButtonLabel="Create topic"
            saveButtonLabel="Save topic"
          />
        }
        redirect={false}
        submitOnEnter={false}
        validate={validateTopicFormValues}
        keepDirtyOnReinitialize={false}
      >
        <TopicFormContent mode={mode} record={record} />
      </SimpleForm>
    </>
  );
};

const TopicFormContent = (props) => {
  const { mode } = props;
  const [dirtyFields, setDirtyFields] = useState({});
  const [formData, setFormData] = useState({});
  const validateText = [
    required('Please fill the text.'),
    regex(RegexNoOnlySpaceOrNewline, 'Just space or newline is forbidden.'),
  ];

  const form = useForm();

  useEffect(() => {
    const unsubscribe = form.subscribe(
      (s) => {
        setDirtyFields(s.dirtyFields);
        setFormData(s.values);
      },
      { dirtyFields: true, values: true },
    );
    return () => {
      unsubscribe();
    };
  }, [form]);

  const isCreating = mode === 'create';

  const onPublishedChange = useCallback(
    (published) => {
      if (published) {
        form.change('publishedAt', moment().toDate());
      } else {
        form.change('publishedAt', null);
      }
    },
    [form],
  );

  return (
    <div style={styles.verticalView}>
      {!isCreating && (
        <>
          <TextInput
            disabled
            label="Topic Id"
            source="id"
            style={styles.textInput}
          />
          <TextInput
            label="Fragments"
            source="fragmentCount"
            style={{ width: 120 }}
            disabled
          />
        </>
      )}
      <BooleanInput
        label="Published"
        source="published"
        onChange={onPublishedChange}
        style={{
          ...styles.switch,
          ...(dirtyFields.published ? styles.updated : null),
        }}
        defaultValue={isCreating ? false : undefined}
      />
      <TextInput
        style={dirtyFields.text ? styles.updated : null}
        multiline
        source="text"
        fullWidth
        validate={validateText}
        parse={(value) => JSUtility.hasValidQuote(value)}
      />
      <TranslationField
        label="Text Translation"
        source="textTranslations"
        record={formData}
        dirtyFields={dirtyFields}
      />
    </div>
  );
};

const CommonPropTypesForTopicFormAndContent = {
  mode: PropTypes.oneOf(['create', 'update']).isRequired,
  record: PropTypes.shape({}),
};

TopicForm.propTypes = {
  ...CommonPropTypesForTopicFormAndContent,
};

TopicFormContent.propTypes = {
  ...CommonPropTypesForTopicFormAndContent,
};

export default TopicForm;
