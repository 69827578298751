import React from 'react';
import PropTypes from 'prop-types';

import JSUtility from '../../utilities/JSUtility';

const DurationField = ({ record }) => {
  const duration = record.durationInMs;

  return (
    <p>{duration != null ? JSUtility.convertSecondsToTime(duration) : ''}</p>
  );
};

// Workaround: To remove eslint warning
DurationField.propTypes = {
  record: PropTypes.shape({
    durationInMs: PropTypes.number,
  }),
};

DurationField.defaultProps = {
  record: {
    durationInMs: 0,
  },
};

export default DurationField;
