import _ from 'lodash';
import ApolloClientHelper from './ApolloClientHelper';
import Queries from './graphqlQueries';
import filterRecord from './filterRecord';

export default {
  getList: async (type, resource, params) => {
    const apolloClient = ApolloClientHelper.getInstance();

    const { filter, sort } = params;

    const { id, startDate, endDate, ...restFilters } = filter;

    const {
      data: {
        user: { playerSessions },
      },
    } = await apolloClient.query({
      query: Queries.GET_PLAYER_SESSIONS,
      variables: {
        inputId: id,
        inputSessions: {
          period: {
            startDate,
            endDate,
          },
        },
      },
    });
    let results = playerSessions.records.map((record) => ({
      id: record.id,
      createdAt: record.createdAt,
      listeningTimeInSec: record.listeningTimeInSec,
      speakingTimeInSec: record.speakingTimeInSec,
    }));

    if (filter != null) {
      results = results.filter((r) => filterRecord(r, restFilters, [], []));
    }

    if (sort != null) {
      results = _.orderBy(results, [sort.field], [sort.order.toLowerCase()]);
    }

    return {
      data: results,
      total: results.length,
    };
  },
};
