import React from 'react';
import { Edit } from 'react-admin';

import SeriesForm from './SeriesForm';
import SeriesActions from './SeriesActions';

const SeriesEdit = (props) => (
  <Edit {...props} actions={<SeriesActions basePath="/series" />}>
    <SeriesForm mode="update" />
  </Edit>
);

export default SeriesEdit;
