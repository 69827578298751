// This file was copied from 'redkiwi/packages/firebase-hosting/cli/constants.js'

export const AccentTags = {
  AMERICAN: 'American',
  BRITISH: 'British',
  AUSTRALIAN: 'Australian',
};

export const CategoryTags = {
  TV_SERIES: 'TVSeries',
  MOVIE: 'Movie',
  ANIMATION: 'Animation',
  INTERVIEW: 'Interview',
  TALKSHOW: 'TalkShow',
  NEWS: 'News',
  SONG: 'Song',
  ENGLISH: 'English', // ToDo: Change tag name to 'English Lesson' or something!
  COMEDY: 'Comedy',
  MONOLOGUE: 'Monologue',
  TRAILER: 'Trailer',
  DOCUMENTARY: 'Documentary',
  SPEECH: 'Speech',
  SITCOM: 'Sitcom', // Friends, The Big Bang Theory
  DRAMA: 'Drama', // Grey's Anatomy
  REALITY: 'Reality', // Project Runway, Keeping up with Kardashians
};

export const ComedySeriesTags = {
  THE_BIG_BANG_THEORY: 'TheBigBangTheory',
  FRIENDS: 'Friends',
  THE_OFFICE_US: 'TheOfficeUS',
  GOOD_LUCK_CHARLIE: 'GoodLuckCharlie',
  LIV_AND_MADDIE: 'LivAndMaddie',
  SCHOOL_OF_ROCK: 'SchoolOfRock',
  MIRANDA: 'Miranda',
};
export const SoapOperaSeriesTags = {
  THE_GOOD_DOCTOR: 'TheGoodDoctor',
  FRESH_OFF_THE_BOAT: 'FreshOffTheBoat',
  GREYS_ANATOMY: 'GreysAnatomy',
  MY_MAD_FAT_DIARY: 'MyMadFatDiary',
  KIMS_CONVENIENCE: 'KimsConvenience',
  THE_GOOD_WIFE: 'TheGoodWife',
  MODERN_FAMILY: 'ModernFamily',
};
export const AnimationSeriesTags = {
  WE_BARE_BEARS: 'WeBareBears',
  PEPPA_PIG: 'PeppaPig',
  ADVENTURE_TIME: 'AdventureTime',
  PEANUTS: 'Peanuts',
  SPONGE_BOB: 'SpongeBob',
  THE_SIMPSONS: 'TheSimpsons',
  THE_POWERPUFF_GIRLS: 'ThePowerpuffGirls',
  WALT_DISNEY: 'WaltDisney',
};
export const InterviewSeriesTags = {
  CONAN: 'Conan',
  THE_ELLEN_SHOW: 'TheEllenShow',
  THE_GRAHAM_NORTON_SHOW: 'TheGrahamNortonShow',
  JIMMY_KIMMEL_LIVE: 'JimmyKimmelLive',
  // Not supported because of the country restriction
  // THE_TONIGHT_SHOW_STARRING_JIMMY_SHOW: 'TheTonightShowStarringJimmyFallon',
};
export const MovieSeriesTags = {
  HARRY_POTTER: 'HarryPotter',
  MARVEL_CINEMATIC_UNIVERSE: 'MarvelSeries',
  THE_LORD_OF_THE_RINGS: 'TheLordOfTheRings',
  TWILIGHT: 'Twilight',
};
export const RealitySeriesTags = {
  PROJECT_RUNWAY: 'ProjectRunway',
  KEEPING_UP_WITH_THE_KARDASHIANS: 'KeepingUpWithTheKardashians',
};
export const SpeechSeriesTags = {
  TED: 'TED',
};
export const LectureSeriesTags = {
  ENGLISH_WITH_LUCY: 'EnglishWithLucy',
  BILINGIRL_CHIKA: 'BilingirlChika',
  BBC_ENGLISH: 'BBCEnglish',
};
export const CelebrityTags = {
  ARIANA_GRANDE: 'ArianaGrande',
  EMMA_WATSON: 'EmmaWatson',
};

export const SeriesTags = {
  ...ComedySeriesTags,
  ...SoapOperaSeriesTags,
  ...AnimationSeriesTags,
  ...InterviewSeriesTags,
  ...MovieSeriesTags,
  ...RealitySeriesTags,
  ...SpeechSeriesTags,
  ...LectureSeriesTags,
  ...CelebrityTags,
};

export const GenderTags = {
  FEMALE: 'female',
  MALE: 'male',
};

export const ContentTags = {
  ACTION: 'Action',
  ADVENTURE: 'Adventure',
  COOKING: 'Cooking',
  TECHNOLOGY: 'Technology',
  FANTASY: 'Fantasy',
  HORROR: 'Horror',
  ROMANCE: 'Romance',
  SCIENCE_FICTION: 'ScienceFiction',
  SUPERHERO: 'Superhero',
  THRILLER: 'Thriller',
  FOOD: 'Food',
  REVIEW: 'Review',
};

export const Tags = {
  ...AccentTags,
  ...CategoryTags,
  ...SeriesTags,
  ...GenderTags,
  ...ContentTags,
};

export const TagGroup = {
  SERIES: 'series',
  ACCENT: 'accent',
  GENDER: 'gender',
  CATEGORY: 'category',
  CONTENT: 'content',
};
