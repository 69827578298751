import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import JSUtility from '../../utilities/JSUtility';

const UnixTimestampField = ({ record = {}, source = null }) => {
  const unixTimestampSecond = _.get(record, source, null);

  return (
    <div>
      {unixTimestampSecond != null
        ? JSUtility.convertUnixSecondToString(unixTimestampSecond)
        : ''}
    </div>
  );
};

UnixTimestampField.propTypes = {
  // eslint-disable-next-line react/require-default-props
  record: PropTypes.shape({}),
  // eslint-disable-next-line react/require-default-props
  source: PropTypes.string,
};

// eslint-disable-next-line react/default-props-match-prop-types
UnixTimestampField.defaultProps = { addLabel: true };

export default UnixTimestampField;
