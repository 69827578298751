import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Edit } from 'react-admin';

import SurveyQuestionActions from './SurveyQuestionActions';
import SurveyQuestionForm from './SurveyQuestionForm';

const SurveyQuestionTitle = ({ record = null }) => (
  <>SurveyQuestion: {_.get(record, 'question', '')}</>
);

SurveyQuestionTitle.propTypes = {
  // eslint-disable-next-line react/require-default-props
  record: PropTypes.shape({
    question: PropTypes.string.isRequired,
  }),
};

const SurveyQuestionEdit = (props) => (
  <Edit
    title={<SurveyQuestionTitle />}
    actions={<SurveyQuestionActions basePath="/surveyQuestions" />}
    {...props}
  >
    <SurveyQuestionForm mode="update" />
  </Edit>
);

export default SurveyQuestionEdit;
