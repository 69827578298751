import firestoreDataProvider from './firestoreDataProvider';
import graphqlDataProvider from './graphqlDataProvider';

const dataProvider = async (type, resource, params) => {
  // eslint-disable-next-line no-console
  console.log(type, resource, params);

  if (
    resource === 'questions' ||
    resource === 'lemmas' ||
    resource === 'reviews'
  ) {
    return graphqlDataProvider(type, resource, params);
  }

  return firestoreDataProvider(type, resource, params);
};

export default dataProvider;
