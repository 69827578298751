import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  SimpleForm,
  SelectInput,
  NumberInput,
  TextInput,
  BooleanInput,
  SelectArrayInput,
  ReferenceArrayInput,
  DateInput,
  regex,
  required,
  CloneButton,
  // REDUX_FORM_NAME,
  GET_LIST,
  GET_MANY_REFERENCE,
  UPDATE,
  useGetManyReference,
  useGetList,
  Loading,
} from 'react-admin';
import shallowequal from 'shallowequal';
import moment from 'moment';

import { useForm } from 'react-final-form'; // eslint-disable-line import/no-extraneous-dependencies
import { useLocalStorage } from 'react-use';
import {
  FileCopy as CopyIcon,
  AddBox as CreateIcon,
  Cancel as CancelIcon,
  GetApp as DownloadIcon,
} from '@material-ui/icons';
import {
  Button,
  Typography,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import _, { uniqueId } from 'lodash';
import { firestore } from 'firebase';
import myDataProvider from '../../dataProvider/firestoreDataProvider';
import JSUtility from '../../utilities/JSUtility';
import {
  LINK_TO_TMS,
  RegexYouTubeVideoId,
  ContentLanguages,
  EnglishContentMustHaveLanguages,
  NonEnglishContentMustHaveLanguages,
  RegexNoOnlySpaceOrNewline,
  KST_OFFSET_IN_MIN,
} from '../../constants';
import YouTubeVideoPlayer from './component/YouTubeVideoPlayer';
import FragmentForm, { FragmentFormContainer } from '../fragments/FragmentForm';
import {
  TimestampInput,
  CustomEditToolbar,
  TranslationField,
  ScrollToTopButton,
  TextInputWithButton,
} from '../../common';
import {
  AgeRestrictionCheckField,
  RegionRestrictionCheckField,
  CustomTagSelect,
} from './component';
import { Tags as LegacyTags } from './data/TagsLegacy';
import MissionWordSelect from './component/MissionWordSelect';
import SeriesInputWithSearch from './component/series/SeriesInputWithSearch';
import AutoTranscriptionHelper from './component/AutoTranscriptionHelper';
import FragmentsGenerator from '../fragments/component/FragmentsGenerator';
import { AutomationContextProvider } from '../../common/AutomationContextProvider';

const useStyles = makeStyles({
  helperText: {
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
  },
});

const styles = {
  flex: {
    display: 'flex',
    float: 'left',
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  columnContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
  },
  float: {
    float: 'left',
  },
  updated: {
    backgroundColor: '#fad0c3',
  },
  leftMargin: {
    marginLeft: 20,
  },
  icon: {
    paddingRight: 8,
  },
  button: {
    color: '#ffffff',
    margin: '10px 0px 10px 30px',
    fontSize: 'medium',
    textAlign: 'center',
    paddingRight: '13px',
    backgroundColor: '#ec8a88',
  },
  smallListener: {
    width: 120,
    paddingLeft: 30,
  },
  largeListener: {
    width: 280,
    paddingLeft: 30,
  },
  warningText: {
    color: 'red',
  },
  duplicateContentsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  sizeIcon: {
    fontSize: 43,
  },
  seriesErrorText: {
    color: 'red',
    fontSize: 12,
    paddingBottom: 10,
  },
  errorBackground: {
    backgroundColor: '#fad0c3',
  },
};

const validateLanguageCode = required(
  'Please select one of the language code.',
);
const validateYouTubeVideoId = [
  required('Please fill the YouTube Video ID.'),
  regex(
    RegexYouTubeVideoId,
    "Only 'A-Za-z0-9_-' is allowed. It should be 11 letters.",
  ),
];
const validateTitle = [
  required('Please fill the title of the content.'),
  regex(RegexNoOnlySpaceOrNewline, 'Just space or newline is forbidden.'),
];

const validateSubtitle = [
  required('Please fill the subtitle of the content.'),
  regex(RegexNoOnlySpaceOrNewline, 'Just space or newline is forbidden.'),
];

// Check age and region restriction through youTubeVideoID
const checkAgeAndRegionRestriction = async (youTubeVideoId) => {
  const response = await fetch(
    `https://www.googleapis.com/youtube/v3/videos?part=contentDetails&id=${youTubeVideoId}&key=AIzaSyAA35Wfce-B_ma9T0qPpwKiIWOtVAluEn8`,
  );
  const object = await response.json();

  const result = {};
  const resultForValidYouTubeIdFromApi = _.get(
    object,
    'pageInfo.resultsPerPage',
    '',
  );
  if (resultForValidYouTubeIdFromApi === 1) {
    // Check age restriction
    const ageRestriction = _.get(
      object,
      'items[0].contentDetails.contentRating.ytRating',
      '',
    ); // '' || string
    if (ageRestriction === 'ytAgeRestricted') {
      result.isAgeRestricted = true;
    } else {
      result.isAgeRestricted = false;
    }

    // Check region restriction
    const listOfRestrictedRegion = _.get(
      object,
      'items[0].contentDetails.regionRestriction.blocked',
      [],
    );
    const listOfAllowedRegion = _.get(
      object,
      'items[0].contentDetails.regionRestriction.allowed',
      [],
    );

    const regionRestriction = {
      blocked: listOfRestrictedRegion,
      allowed: listOfAllowedRegion,
    };
    result.regionRestriction = regionRestriction;
    return result;
  }

  // Invalid YouTube ID
  return null;
};

// const FRAGMENT_FORM_NAME = 'FRAGMENT_FORM_NAME';

const YouTubeApiHelper = ({
  youTubeVideoId,
  startTime,
  endTime,
  onChangeDurationInMs,
  onChangeAgeRestricted,
  onChangeBlockedRegions,
  onChangeAllowedRegions,
}) => {
  // TODO: Debouncing should be required to enhance the performance
  useEffect(() => {
    (async () => {
      const durationInMs =
        youTubeVideoId == null
          ? undefined
          : await JSUtility.calculateDurationInMs(
              youTubeVideoId,
              startTime,
              endTime,
            );
      console.log(`durationInMs is calculated: ${durationInMs}`); // eslint-disable-line no-console

      // event.durationInMs = durationInMs; // https://stackoverflow.com/a/51421999/1609620
      if (durationInMs != null) {
        onChangeDurationInMs(durationInMs);
      }

      const current = youTubeVideoId;
      const result = await checkAgeAndRegionRestriction(current);
      if (result != null) {
        const { isAgeRestricted, regionRestriction } = result;
        onChangeAgeRestricted(isAgeRestricted);
        onChangeBlockedRegions(regionRestriction.blocked);
        onChangeAllowedRegions(regionRestriction.allowed);
      } else {
        onChangeAgeRestricted(null);
        onChangeBlockedRegions(null);
        onChangeAllowedRegions(null);
      }
    })();
  }, [
    youTubeVideoId,
    startTime,
    endTime,
    onChangeDurationInMs,
    onChangeAgeRestricted,
    onChangeAllowedRegions,
    onChangeBlockedRegions,
  ]);

  return null;
};

const YouTubeApiHelperWrapper = (otherProps) => {
  const form = useForm();

  const onChangeDurationInMs = useCallback(
    (durationInMs) => {
      form.change('durationInMs', durationInMs);
    },
    [form],
  );

  return (
    <YouTubeApiHelper
      onChangeDurationInMs={onChangeDurationInMs}
      {...otherProps}
    />
  );
};

const DuplicateContentsChecker = ({ contentId = '', videoId }) => {
  const { data } = useGetList(
    'contents',
    null,
    { field: 'startTime', order: 'ASC' },
    { youTubeVideoId: videoId },
  );
  delete data[contentId];
  const duplicateContents = Object.values(data);

  return _.isEqual(duplicateContents, []) ? null : (
    <>
      <Typography variant="h6">
        Same YouTube video is also used in...
      </Typography>
      {duplicateContents.map((c) => (
        <div
          key={`Duplicate-${c.id}`}
          style={styles.duplicateContentsContainer}
        >
          ●&nbsp;
          <a
            href={`${LINK_TO_TMS}/contents/${c.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {c.title}
          </a>
          &nbsp;- {c.startTime || '00:00:00,000'}~{' '}
          {c.endTime || JSUtility.convertMillisecondToTimestamp(c.durationInMs)}
        </div>
      ))}
    </>
  );
};

DuplicateContentsChecker.propTypes = {
  // eslint-disable-next-line react/require-default-props
  contentId: PropTypes.string,
  videoId: PropTypes.string.isRequired,
};

const validateContentFormValues = (values) => {
  // It validates code below every time there is any change.
  const errors = {};
  const isPublishedContent =
    values._dev === false || values.releaseDate != null; // eslint-disable-line no-underscore-dangle
  const isEnglishContent = values.languageCode === 'en';

  if (
    values.startTime &&
    values.endTime &&
    values.startTime >= values.endTime
  ) {
    errors.startTime = 'Start time should be before End time.';
    errors.endTime = 'End time should be after Start time.';
  }

  if (!isPublishedContent) {
    return errors;
  }

  // Content to be published
  if (values.id != null && isPublishedContent) {
    // Validate 1. Empty subtitle
    if (
      values.subtitle == null ||
      values.subtitle === '' ||
      values.subtitle === '.'
    ) {
      errors.subtitle = 'Valid subtitle is needed to publish the content.';
    }

    const mustHaveLanguages = isEnglishContent
      ? EnglishContentMustHaveLanguages
      : NonEnglishContentMustHaveLanguages;

    // Validate 2. Missing Subtitles' translation
    let missingSubtitleTranslation = mustHaveLanguages.reduce((acc, lang) => {
      const subtitleTranslation = _.get(
        values.subtitleTranslations,
        lang.id,
        null,
      );

      if (
        subtitleTranslation == null ||
        subtitleTranslation === '' ||
        subtitleTranslation === '.'
      ) {
        return [...acc, lang];
      }
      return acc;
    }, []);

    if (_.get(values, '_subtitleTranslationsByGengo.ja') != null) {
      missingSubtitleTranslation = missingSubtitleTranslation.filter(
        (lang) => lang.id !== 'ja',
      );
    }

    missingSubtitleTranslation.forEach((lang) => {
      _.set(
        errors,
        `subtitleTranslations.${lang.id}`,
        `${lang.id} subtitle translation is needed to publish content.`,
      );
    });

    const seriesMarkInTitle = /\s?\(\d\/\d\)\s?/;
    if (
      values.title.search(seriesMarkInTitle) !== -1 &&
      _.isEmpty(values.seriesId)
    ) {
      const seriesErrorText = 'Please select series for the content';
      errors.seriesId = seriesErrorText;
    }
  }

  return errors;
};

// eslint-disable-next-line no-unused-vars
const saveContentForm = (values) => {
  // ToDo #5987: Enable publish validation after checking that custom save is okay
  const updateContent = () => {
    myDataProvider(UPDATE, 'contents', {
      data: values,
      // previousData: record,
    });
  };

  const isPublishedContent = values._dev === false; // eslint-disable-line no-underscore-dangle
  const isEnglishContent = values.languageCode === 'en';
  const isChineseContent = values.languageCode === 'zh';

  if (isPublishedContent) {
    // eslint-disable-line no-underscore-dangle
    // Validate 3. Missing accent tag
    const promise1 = !isEnglishContent
      ? Promise.resolve()
      : myDataProvider(GET_LIST, 'tags')
          .then((response) => {
            const englishAccentTagKeys = response.data.reduce((acc, val) => {
              if (
                val.category === 'accent' &&
                val.learningLanguageCodes.includes('en')
              ) {
                return [...acc, val.id];
              }
              return acc;
            }, []);

            const intersectionWithAccentTagKeys = _.intersectionBy(
              values.tagKeys,
              englishAccentTagKeys,
            );

            if (_.isEqual(intersectionWithAccentTagKeys, [])) {
              // eslint-disable-next-line no-alert
              alert(
                'At least one accent tag should be included to publish the content.',
              );
              throw new Error(
                'At least one accent tag should be included to publish the content.',
              );
            }

            return Promise.resolve();
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.log('Accent tag validation failed.', error);
            return Promise.reject(error);
          });

    // Validate 4. Missing Fragments' translation
    const promise2 = myDataProvider(GET_MANY_REFERENCE, 'fragments', {
      sort: { field: 'startTime', order: 'ASC' },
      target: 'contentKey',
      id: values.id,
    })
      .then((response) => {
        const translationMissingFragment = [];
        const mustHaveLanguages = isEnglishContent
          ? EnglishContentMustHaveLanguages
          : NonEnglishContentMustHaveLanguages;

        if (
          response.data.some((fragment, index) =>
            mustHaveLanguages.some((lang) => {
              const {
                textTranslations: trans,
                _textTranslationsByGengo: gengoTrans,
              } = fragment;

              const isTranslationEmpty =
                (_.get(gengoTrans, [lang.id]) == null &&
                  _.get(trans, [lang.id]) == null) ||
                _.get(trans, [lang.id]) === '';

              if (isTranslationEmpty) {
                translationMissingFragment.push({ index, language: lang });
              }

              return isTranslationEmpty;
            }),
          )
        ) {
          // eslint-disable-next-line no-alert
          alert(
            `Check No.${translationMissingFragment[0].index + 1} fragment's ${
              translationMissingFragment[0].language.id
            } translation.`,
          );
          throw new Error(
            `Check No.${translationMissingFragment[0].index + 1} fragment's ${
              translationMissingFragment[0].language.id
            } translation.`,
          );
        }

        return Promise.resolve();
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Fragment translation validation failed.', error);
        return Promise.reject(error);
      });

    // Validate 5. Missing pronunciation
    const promise3 = isEnglishContent
      ? Promise.resolve()
      : myDataProvider(GET_MANY_REFERENCE, 'fragments', {
          sort: { field: 'startTime', order: 'ASC' },
          target: 'contentKey',
          id: values.id,
        })
          .then((response) => {
            const pronunciationMissingFragment = [];

            if (
              response.data.some((fragment, index) => {
                const pronunciation = isChineseContent // eslint-disable-line no-nested-ternary
                  ? fragment.pinyin
                  : fragment.pronunciations.en;
                // English contents are already filtered on the beginning of Validation 5.

                const isPronunciationEmpty =
                  pronunciation == null || pronunciation === '';

                if (isPronunciationEmpty) {
                  pronunciationMissingFragment.push({ index });
                }

                return isPronunciationEmpty;
              })
            ) {
              // eslint-disable-next-line no-alert
              alert(
                `Check No.${
                  pronunciationMissingFragment[0].index + 1
                } fragment's pronunciation(pinyin).`,
              );
              throw new Error(
                `Check No.${
                  pronunciationMissingFragment[0].index + 1
                } fragment's pronunciation(pinyin).`,
              );
            }

            return Promise.resolve();
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.log('Fragment pronunciation validation failed.', error);
            return Promise.reject(error);
          });

    return Promise.all([promise1, promise2, promise3]).then(() => {
      updateContent();

      return Promise.resolve();
    });
  }

  return updateContent();
};

const useTranscriptFragmentFormContainerStyles = makeStyles(() => ({
  root: {
    borderBottom: '3px solid #e0e0e0',
  },
}));

const useUnsavedFragmentsHeaderStyles = makeStyles(() => ({
  root: {
    padding: '2rem',
  },
}));

const FragmentFormsInContentForm = ({ record = {} }) => {
  const transcriptFragmentContainerClasses =
    useTranscriptFragmentFormContainerStyles();

  const unsavedFragmentsHeaderClasses = useUnsavedFragmentsHeaderStyles();

  const contentId = record.id;
  const { youTubeVideoId, _dev, languageCode } = record;
  const isPublishedContent = _dev === false;

  const fragmentRecord = {
    contentKey: contentId,
    languageCode,
  };

  const CREATION_STATE = {
    NO: 0,
    YES: 1,
    MULTIPLE: 2,
  };

  const FRAGMENT_DISPLAY_ENUM = {
    SINGLE_FRAGMENT: 0,
    MULTIPLE_FRAGMENTS: 1,
    NONE: 2,
  };

  const [isCreateButtonClicked, setIsCreateButtonClicked] = useState(
    CREATION_STATE.NO,
  );

  const [value, updateLocalStorage, deleteLocalStorage] = useLocalStorage(
    `rk-fragments-unsaved-${contentId}`,
    [],
    {
      serializer: (fragments) => {
        if (fragments.length === 0) {
          return JSON.stringify([]);
        }
        // eslint-disable-next-line no-shadow
        const { contentKey, languageCode } = fragments[0]; // Assuming all fragments share the same contentKey and languageCode
        const minimizedJSON = {
          contentKey,
          languageCode,
          fragments: fragments.map(
            // eslint-disable-next-line no-shadow
            ({ contentKey, languageCode, ...rest }) => rest,
          ),
        };
        return JSON.stringify(minimizedJSON);
      },
      deserializer: (fragmentsSerialized) => {
        const parsed = JSON.parse(fragmentsSerialized);
        if (!parsed.contentKey || !parsed.languageCode) {
          return [];
        }
        // eslint-disable-next-line no-shadow
        const { contentKey, languageCode, fragments } = parsed;
        const restoredFragments = fragments.map((fragment) => ({
          ...fragment,
          contentKey,
          languageCode,
        }));
        return restoredFragments;
      },
    },
  );

  const [fragmentsFromTranscripts, setFragmentsFromTranscripts] =
    useState(value);

  useEffect(() => {
    if (fragmentsFromTranscripts?.length > 0) {
      updateLocalStorage(fragmentsFromTranscripts);
    } else {
      deleteLocalStorage();
    }
  }, [fragmentsFromTranscripts, updateLocalStorage, deleteLocalStorage]);

  const FragmentCreateInContentForm = () => {
    const CreateFragmentButton = () => (
      <Button
        onClick={() => setIsCreateButtonClicked(CREATION_STATE.YES)}
        style={styles.button}
      >
        <CreateIcon style={styles.icon} />
        Make New Fragment
      </Button>
    );

    // Delete New Fragment Button for Single Fragment Creation
    const CreateCancelButton = () => (
      <Button
        onClick={() => setIsCreateButtonClicked(CREATION_STATE.NO)}
        style={styles.button}
      >
        <CancelIcon style={styles.icon} />
        Delete New Fragment
      </Button>
    );

    // Add New Fragment Button to insert new fragments between existing unsaved fragments
    // Required when the user is not satisfied with the auto generated fragments
    const AddFragmentHereButton = ({ nextFragmentKey }) => (
      <Button
        onClick={() => {
          setFragmentsFromTranscripts((prev) => {
            const newFragment = {
              contentKey: contentId,
              languageCode,
              fragmentKey: uniqueId(),
            };
            if (nextFragmentKey === null) {
              return [...prev, newFragment];
            }
            const nextFragmentIndex = prev.findIndex(
              (fragment) => fragment.fragmentKey === nextFragmentKey,
            );
            return [
              ...prev.slice(0, nextFragmentIndex),
              newFragment,
              ...prev.slice(nextFragmentIndex),
            ];
          });
        }}
        style={styles.button}
      >
        <CreateIcon style={styles.icon} />
        Add Fragment Here
      </Button>
    );

    // Delete New Fragment Button for Multiple Fragment Creation
    // deletes the fragment from the list of fragments specified with fragmentKey
    const DeleteFragmentButton = ({ fragmentKey }) => (
      <Button
        onClick={() => {
          setFragmentsFromTranscripts((prev) =>
            prev.filter((fragment) => fragment.fragmentKey !== fragmentKey),
          );
        }}
        style={styles.button}
      >
        <CancelIcon style={styles.icon} />
        Delete This Fragment
      </Button>
    );

    const resolveFragmentFormDisplay = () => {
      if (fragmentsFromTranscripts?.length > 0)
        return FRAGMENT_DISPLAY_ENUM.MULTIPLE_FRAGMENTS;

      if (isCreateButtonClicked === CREATION_STATE.YES)
        return FRAGMENT_DISPLAY_ENUM.SINGLE_FRAGMENT;

      if (isCreateButtonClicked === CREATION_STATE.MULTIPLE)
        return FRAGMENT_DISPLAY_ENUM.MULTIPLE_FRAGMENTS;

      return FRAGMENT_DISPLAY_ENUM.NONE;
    };

    const fragmentFormOnClickHandler = (fragmentKey) => {
      // This function is called when a fragment is saved.
      // The fragment that was just saved needs to be removed
      // from the list of fragments to be saved.
      setFragmentsFromTranscripts((prev) =>
        prev.filter((fragment) => fragment.fragmentKey !== fragmentKey),
      );
    };

    AddFragmentHereButton.propTypes = {
      nextFragmentKey: PropTypes.string.isRequired,
    };

    DeleteFragmentButton.propTypes = {
      fragmentKey: PropTypes.string.isRequired,
    };

    return (
      <>
        {resolveFragmentFormDisplay() ===
        FRAGMENT_DISPLAY_ENUM.SINGLE_FRAGMENT ? (
          // Original Create Fragment Button is clicked
          <>
            <FragmentForm mode="create" record={fragmentRecord} />
            <CreateCancelButton />
          </>
        ) : resolveFragmentFormDisplay() ===
          FRAGMENT_DISPLAY_ENUM.MULTIPLE_FRAGMENTS ? (
          // Fragment Auto Generation Button is clicked
          <>
            <div className={`${unsavedFragmentsHeaderClasses.root}`}>
              <h2>Unsaved Fragments</h2>
            </div>
            {fragmentsFromTranscripts.map((fragment) => (
              <div className={`${transcriptFragmentContainerClasses.root}`}>
                <AddFragmentHereButton nextFragmentKey={fragment.fragmentKey} />
                <FragmentForm
                  key={`${fragment.contentKey}-${fragment.fragmentKey}`}
                  mode="create"
                  record={(() => {
                    const { fragmentKey, ...rest } = fragment;
                    return rest;
                  })()}
                  created={() =>
                    fragmentFormOnClickHandler(fragment.fragmentKey)
                  }
                />
                <DeleteFragmentButton fragmentKey={fragment.fragmentKey} />
              </div>
            ))}
            <AddFragmentHereButton nextFragmentKey={null} />
          </>
        ) : (
          // No button clicked yet
          <>
            <FragmentsGenerator
              baseRecord={fragmentRecord}
              callback={() => {
                setIsCreateButtonClicked(CREATION_STATE.MULTIPLE);
              }}
              setFragments={setFragmentsFromTranscripts}
            />
            <CreateFragmentButton />
          </>
        )}
      </>
    );
  };

  const { data, loading, error } = useGetManyReference(
    'fragments',
    'contentKey',
    contentId,
    null,
    { field: 'startTime', order: 'ASC' },
    {},
    'contents',
  );

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <p>ERROR</p>;
  }

  const fragmentIds = Object.keys(data);

  return (
    <>
      {fragmentIds.map((id, index) => (
        <FragmentFormContainer
          key={`${contentId}-${id}`}
          redirect={false}
          mode="update"
          indexFromFragmentList={index}
          recordId={id}
          youTubeVideoId={youTubeVideoId}
        />
      ))}
      {isPublishedContent ? null : (
        <FragmentCreateInContentForm
          contentKey={contentId}
          languageCode={languageCode}
        />
      )}
    </>
  );
};

FragmentFormsInContentForm.propTypes = {
  // eslint-disable-next-line react/require-default-props
  record: PropTypes.shape({
    id: PropTypes.string.isRequired,
    youTubeVideoId: PropTypes.string.isRequired,
    languageCode: PropTypes.string.isRequired,
  }),
};

const ContentForm = (props) => {
  const { mode, record = {}, resource = 'contents' } = props;

  const isCreating = mode === 'create';

  const jumpToSpecificFragment = useCallback(() => {
    const elementName = window.location.hash.slice(1); // To remove # at the beginning
    const elements = document.getElementsByName(elementName);

    if (elements[0] != null) {
      elements[0].scrollIntoView(true);
    }
  }, []);

  return (
    <AutomationContextProvider>
      <SimpleForm
        {...props}
        redirect={false} // "list"
        toolbar={<CustomEditToolbar {...props} />}
        submitOnEnter={false}
        validate={validateContentFormValues}
        keepDirtyOnReinitialize={false} // Remember set this property as 'false'.
        // save={saveContentForm} // ToDo #5987
      >
        {isCreating ? null : (
          <CloneButton
            label="Copy"
            icon={<CopyIcon />}
            variant="outlined"
            style={{ marginBottom: 10 }}
          />
        )}
        {window.location.hash.includes('/fragments/') ? (
          <Button
            variant="outlined"
            onClick={jumpToSpecificFragment}
            style={{ borderColor: '#EE4E48', color: '#EE4E48' }}
          >
            Move To Fragment Directly
          </Button>
        ) : null}
        {/* The wrapper below exists to support `useForm` in the `ContentFormContent` component */}
        <ContentFormContent mode={mode} record={record} resource={resource} />
      </SimpleForm>
      {isCreating ? null : <FragmentFormsInContentForm record={record} />}
      <ScrollToTopButton category={1} />
    </AutomationContextProvider>
  );
};

/* eslint-disable max-len */
/**
 * Whenever `ReferenceArrayInput` re-renders, the injected prop(choices) considered as a newly created object.
 * To prevent unnecessary re-rendering, we made a patch for this component.
 * If we have a plan to use this component in another place, one might need the same patch.
 */
/* eslint-enable max-len */
const PatchedReferenceArrayInput = React.memo(
  (props) => <ReferenceArrayInput {...props} />,
  (prevProps, nextProps) => {
    // arePropsEqual
    const keysToOmit = ['children'];
    return shallowequal(
      _.omit(prevProps, keysToOmit),
      _.omit(nextProps, keysToOmit),
    );
  },
);

const formatReleaseDate = (date) => {
  if (!date) {
    return null;
  }
  const transformedDate =
    date instanceof firestore.Timestamp ? date.toDate() : date;
  const kstNormalizedDate =
    moment(transformedDate).utcOffset(KST_OFFSET_IN_MIN);
  return kstNormalizedDate.format('YYYY-MM-DD');
};
const parseReleaseDate = (date) => {
  if (!date) {
    return null;
  }
  const kstNormalizedDate = moment(date).utcOffset(KST_OFFSET_IN_MIN, true);
  return kstNormalizedDate.toDate();
};

const ContentFormContent = (props) => {
  const classes = useStyles();
  const [isAgeRestricted, setIsAgeRestricted] = useState(null);
  const [blockedRegions, setBlockedRegions] = useState(null);
  const [allowedRegions, setAllowedRegions] = useState(null);
  const [isSeriesContent, setIsSeriesContent] = useState(false);
  const [dirtyFields, setDirtyFields] = useState({});
  const [formData, setFormData] = useState({});
  const { mode, record, resource } = props;

  const { data } = useGetManyReference(
    'fragments',
    'contentKey',
    record?.id,
    null,
    { field: 'startTime', order: 'ASC' },
    {},
    'contents',
  );

  const fragmentsArray = useMemo(
    () => (data ? Object.values(data) : []),
    [data],
  );
  const invalidLemmaIdExists = useMemo(
    () =>
      fragmentsArray
        .map((el) => el.words?.map((e) => e.lemmaId))
        .flat()
        .some((el) => el === undefined || el.trim().length === 0),
    [fragmentsArray],
  );

  useEffect(() => {
    if (!_.isEmpty(formData.seriesId)) {
      setIsSeriesContent(true);
    }
  }, [formData]);

  const onChangeAgeRestricted = useCallback((isRestricted) => {
    setIsAgeRestricted(isRestricted);
  }, []);

  const onChangeAllowedRegions = useCallback((allowedRegionResponse) => {
    setAllowedRegions(allowedRegionResponse);
  }, []);

  const onChangeBlockedRegions = useCallback((blockedRegionResponse) => {
    setBlockedRegions(blockedRegionResponse);
  }, []);

  const form = useForm();
  const { errors } = form.getState();
  const onClickSeriesContentCheckbox = useCallback(() => {
    setIsSeriesContent((prevIsSeries) => {
      const nextIsSeriesValue = !prevIsSeries;
      if (!nextIsSeriesValue) {
        form.change('seriesId', null);
      }
      return nextIsSeriesValue;
    });
  }, [form]);

  useEffect(() => {
    const unsubscribe = form.subscribe(
      (s) => {
        setDirtyFields(s.dirtyFields);
        setFormData(s.values);
      },
      { dirtyFields: true, values: true },
    );
    return () => {
      unsubscribe();
    };
  }, [form]);

  const {
    id,
    isOnboarding,
    youTubeVideoId,
    startTime,
    endTime,
    languageCode,
    title = '',
  } = formData;

  const isCreating = mode === 'create';
  const optionRenderer = useCallback(
    (choice) =>
      choice == null
        ? 'No Object'
        : _.get(
            choice,
            'languageCodeToName.en',
            `{ tagKey: ${choice.id} } does not have languageCodeToName.en prop`,
          ),
    [],
  );

  const onClickCopyIdButton = useCallback(() => {
    JSUtility.copyToClipboard(id);
  }, [id]);

  const onClickDownloadMp3Icon = useCallback(() => {
    JSUtility.copyToClipboard(
      `https://www.youtube.com/watch?v=${youTubeVideoId}`,
    );
  }, [youTubeVideoId]);

  const legacyTagChoices = useMemo(
    () => Object.values(LegacyTags).map((tag) => ({ id: tag, name: tag })),
    [],
  );

  const referenceSortProp = useMemo(
    () => ({ field: 'languageCodeToName.en', order: 'ASC' }),
    [],
  );

  const characterCount = title.length;
  const maxCharacterCount = 50; // English subtitle in search result
  const charCountStyle =
    characterCount > maxCharacterCount ? styles.warningText : null;

  return (
    <>
      <div style={styles.rowContainer}>
        {isCreating || (
          <TextInputWithButton
            source="id"
            label="Content Id"
            onClickHandler={onClickCopyIdButton}
            icon={<CopyIcon fontSize="small" />}
            width={120}
            disabled
          />
        )}
        <SelectInput
          label="Language Code"
          choices={ContentLanguages}
          source="languageCode"
          defaultValue="en"
          validate={validateLanguageCode}
          style={dirtyFields.languageCode ? styles.updated : null}
          disabled
        />
        {/* Onboarding related fields(Priority, Onboarding Content) is not editable in TMS */}
        <TextInput
          label="Priority (Legacy)"
          source="priority"
          defaultValue={0}
          style={
            isCreating ? { width: 120 } : { ...styles.leftMargin, width: 120 }
          }
          disabled
        />
        <TextInput
          label="Onboarding? (Legacy)"
          source="isOnboarding"
          defaultValue={isOnboarding == null ? false : undefined}
          style={{ ...styles.leftMargin, width: 170 }}
          disabled
        />
        <TextInput
          label="Commentaries"
          source="publicCommentaryCount"
          style={{ ...styles.leftMargin, width: 120 }}
          disabled
        />
        <TextInput
          label="Supported Langs"
          source="supportedTranslationLanguages"
          style={{ ...styles.leftMargin, width: 170 }}
          disabled
        />
      </div>
      <div style={styles.rowContainer}>
        <TextInputWithButton
          source="youTubeVideoId"
          label="YouTube Video ID"
          onClickHandler={onClickDownloadMp3Icon}
          icon={<DownloadIcon fontSize="small" />}
          width={150}
          href="https://yt2mp3.info/ytmp3.cc/en24/"
        />
        <DateInput
          label="Release date in KST"
          source="releaseDate"
          defaultValue={record.releaseDate ?? null}
          style={dirtyFields.releaseDate ? styles.updated : null}
          format={formatReleaseDate}
          parse={parseReleaseDate}
          disabled={invalidLemmaIdExists}
          helperText={invalidLemmaIdExists ? 'The invalid lemma id exists' : ''}
          FormHelperTextProps={{ style: styles.warningText }}
        />
        <TextInput
          label="level"
          source="level"
          style={{ ...styles.leftMargin, width: 170 }}
          disabled
        />
        <BooleanInput
          label="Hidden?"
          source="_dev"
          defaultValue={isCreating ? true : undefined}
          disabled={isCreating || invalidLemmaIdExists}
          style={{
            ...styles.smallListener,
            ...(dirtyFields._dev ? styles.updated : {}),
          }}
          helperText={invalidLemmaIdExists ? 'The invalid lemma id exists' : ''}
          className={classes.helperText}
        />
        <BooleanInput
          label="Premium Content?"
          source="isPremiumContent"
          defaultValue={isCreating ? false : undefined}
          style={{
            ...styles.largeListener,
            ...(dirtyFields.isPremiumContent ? styles.updated : {}),
          }}
        />
      </div>
      <br />
      <div>
        <div
          style={errors.seriesId != null ? styles.errorBackground : undefined}
        >
          <FormControlLabel
            control={
              <Switch
                checked={isSeriesContent}
                onClick={onClickSeriesContentCheckbox}
                color="primary"
              />
            }
            label="Is Series Content?"
          />
          {errors.seriesId != null && (
            <div style={styles.seriesErrorText}>{errors.seriesId}</div>
          )}
        </div>
        {isSeriesContent && (
          <SeriesInputWithSearch source="seriesId" record={formData} />
        )}
      </div>
      <br />
      <div style={styles.rowContainer}>
        <YouTubeVideoPlayer
          label="YouTube Video Player"
          record={formData}
          source="youTubeVideoId"
          startTime={record.startTime}
          endTime={record.endTime}
          validate={validateYouTubeVideoId}
        />
        <div style={styles.columnContainer}>
          <AgeRestrictionCheckField
            label="Age Restriction check"
            isAgeRestricted={isAgeRestricted}
            youTubeVideoId={youTubeVideoId}
          />
          <RegionRestrictionCheckField
            label="Region Restriction check"
            blockedRegions={blockedRegions}
            allowedRegions={allowedRegions}
            youTubeVideoId={youTubeVideoId}
          />
        </div>
      </div>
      <br />
      {youTubeVideoId == null ? null : (
        <DuplicateContentsChecker
          contentId={record.id}
          videoId={youTubeVideoId}
        />
      )}
      <div style={styles.flex}>
        <TimestampInput
          source="startTime"
          style={dirtyFields.startTime ? styles.updated : null}
        />
        <TimestampInput
          source="endTime"
          style={dirtyFields.endTime ? styles.updated : null}
        />
      </div>
      <YouTubeApiHelperWrapper
        // dependencies
        youTubeVideoId={youTubeVideoId}
        startTime={startTime}
        endTime={endTime}
        // outputs
        onChangeAgeRestricted={onChangeAgeRestricted}
        onChangeAllowedRegions={onChangeAllowedRegions}
        onChangeBlockedRegions={onChangeBlockedRegions}
      />
      <NumberInput
        disabled
        source="durationInMs"
        placeholder="1500"
        style={{
          ...styles.leftMargin,
          ...(dirtyFields.durationInMs ? styles.updated : null),
        }}
      />
      <br />
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <TextInput
          style={dirtyFields.title ? styles.updated : null}
          source="title"
          validate={validateTitle}
          fullWidth
          parse={(value) => JSUtility.hasValidQuote(value)}
        />
        <p style={charCountStyle}>
          ({characterCount}/{maxCharacterCount})
        </p>
      </div>
      <TranslationField
        source="subtitleTranslations"
        label="Title Translation"
        languageCodeIdOfCurrentContent={languageCode}
        record={formData}
        dirtyFields={dirtyFields}
      />
      {
        // eslint-disable-next-line no-underscore-dangle
        record._subtitleTranslationsByGengo == null ? null : (
          <TranslationField
            label="Title Translation By Gengo"
            source="_subtitleTranslationsByGengo"
            languageCodeIdOfCurrentContent={languageCode}
            record={formData}
            dirtyFields={dirtyFields}
          />
        )
      }
      {/* <TextInput
        style={dirtyFields.preferredSubtitle ? styles.updated : null}
        source="preferredSubtitle"
        label="Key Expression"
        multiline
        fullWidth
      />
      <TranslationField
        source="preferredSubtitleTranslations"
        label="Key Expression Translation"
        languageCodeIdOfCurrentContent={languageCode}
        record={formData}
        dirtyFields={dirtyFields}
      /> */}
      <TextInput
        style={dirtyFields.subtitle ? styles.updated : null}
        source="subtitle"
        label="English 2nd title"
        validate={validateSubtitle}
        multiline
        fullWidth
        parse={(value) => JSUtility.hasValidQuote(value)}
      />
      <br />
      <SelectArrayInput
        disabled
        label="Tags (Legacy)"
        source="tags"
        choices={legacyTagChoices}
        style={dirtyFields.tags ? styles.updated : null}
      />
      <PatchedReferenceArrayInput
        label="Tags (New)"
        source="tagKeys"
        reference="tags"
        sort={referenceSortProp}
        perPage={Infinity}
        resource={resource}
      >
        <CustomTagSelect
          options={optionRenderer}
          legacyTagChoices={legacyTagChoices}
        />
      </PatchedReferenceArrayInput>
      <MissionWordSelect data={data} />
      <AutoTranscriptionHelper
        youTubeVideoId={youTubeVideoId}
        startTime={startTime}
        endTime={endTime}
      />
    </>
  );
};

ContentForm.propTypes = {
  mode: PropTypes.oneOf(['create', 'update']).isRequired,
  // eslint-disable-next-line react/require-default-props
  record: PropTypes.shape({
    _dev: PropTypes.bool,
  }),
  // eslint-disable-next-line react/require-default-props
  resource: PropTypes.string,
};

ContentFormContent.propTypes = {
  mode: PropTypes.oneOf(['create', 'update']).isRequired,
  record: PropTypes.shape({
    id: PropTypes.string,
    youTubeVideoId: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    subtitleTranslations: PropTypes.shape({}),
    languageCode: PropTypes.string,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    _subtitleTranslationsByGengo: PropTypes.shape({}),
    releaseDate: PropTypes.shape({}),
    _dev: PropTypes.bool,
  }),
  resource: PropTypes.string.isRequired,
};

ContentFormContent.defaultProps = {
  record: PropTypes.shape({
    id: null,
    youTubeVideoId: null,
    title: null,
    subtitle: null,
    subtitleTranslations: null,
    languageCode: null,
    startTime: null,
    endTime: null,
  }),
};

export default ContentForm;
