import React, { useEffect } from 'react';
import {
  List,
  Filter,
  Datagrid,
  TextField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  EditButton,
  Pagination,
} from 'react-admin';
import { CustomBooleanField, ScrollToTopButton } from '../../common';

const SktFragmentFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput
      label="Content title"
      source="contentKey"
      reference="sktContents"
      alwaysOn
      allowEmpty
    >
      <SelectInput optionText="title" />
    </ReferenceInput>
  </Filter>
);

const SktFragmentList = (props) => {
  useEffect(() => {
    document.title = 'TMS: Skt Fragment';
  }, []);

  return (
    <>
      <List
        filters={<SktFragmentFilter />}
        pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
        perPage={25}
        bulkActionButtons={false}
        exporter={false}
        {...props}
      >
        <Datagrid rowClick="expand">
          <TextField source="text" />
          <ReferenceField source="contentKey" reference="sktContents">
            <TextField source="title" />
          </ReferenceField>
          <CustomBooleanField label="For Study" source="keepable" />
          <TextField source="startTime" />
          <TextField source="endTime" />
          <EditButton />
        </Datagrid>
      </List>
      <ScrollToTopButton category={0} />
    </>
  );
};

export default SktFragmentList;
