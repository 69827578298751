import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  TabbedForm,
  FormTab,
  SelectInput,
  TextInput,
  BooleanInput,
  SelectArrayInput,
  ImageInput,
  ImageField,
  DateInput,
  // REDUX_FORM_NAME,
} from 'react-admin';
import { useForm } from 'react-final-form';
import { makeStyles } from '@material-ui/core';
import _ from 'lodash';
import firebase, { firestore } from 'firebase';
import moment from 'moment';

import { CustomEditToolbar, TranslationField } from '../../common';
import { ContentLanguages, KST_OFFSET_IN_MIN } from '../../constants';
import { TagCategories } from './tagsConstants';
import JSUtility from '../../utilities/JSUtility';

const styles = {
  updated: {
    backgroundColor: '#fad0c3',
  },
  verticalView: {
    display: 'flex',
    flexDirection: 'column',
  },
  flexRowContainer: {
    marginTop: 30,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 50,
    width: '100%',
  },
  smallListener: {
    width: 150,
    marginLeft: 10,
  },
  arrayInput: {
    width: 350,
  },
  selectInput: {
    width: 100,
    marginLeft: 10,
  },
};

const useStyles = makeStyles({
  image: {
    margin: '0.5rem',
    maxHeight: '10rem',
  },
});

const TagForm = (props) => {
  const { mode, record, ...otherProps } = props;
  return (
    <TabbedForm
      {...props}
      toolbar={
        <CustomEditToolbar {...otherProps} mode={mode} record={record} />
      }
      redirect={false}
      submitOnEnter={false}
      keepDirtyOnReinitialize={false}
    >
      <ContentTagFormTab mode={mode} record={record} />
      <WordMapTagFormTab mode={mode} record={record} />
    </TabbedForm>
  );
};

const ImageFieldByPath = ({ imagePath, imageUrl, setImageUrl, altText }) => {
  const classes = useStyles();

  if (imageUrl === '') {
    const imageRef = firebase.storage().ref().child(imagePath);
    imageRef.getDownloadURL().then((url) => setImageUrl(url));
  }
  return <img src={imageUrl} alt={altText} className={classes.image} />;
};

ImageFieldByPath.propTypes = {
  // eslint-disable-next-line react/require-default-props
  imagePath: PropTypes.string,
  imageUrl: PropTypes.string,
  setImageUrl: PropTypes.func.isRequired,
  altText: PropTypes.string,
};

const ContentTagFormTab = (props) => {
  const { mode, ...formTabProps } = props;
  const [dirtyFields, setDirtyFields] = useState({});
  const [formData, setFormData] = useState({});
  const [imageUrl, setImageUrl] = useState('');
  const form = useForm();

  useEffect(() => {
    const unsubscribe = form.subscribe(
      (s) => {
        setDirtyFields(s.dirtyFields);
        setFormData(s.values);
      },
      { dirtyFields: true, values: true },
    );
    return () => {
      unsubscribe();
    };
  }, [form]);

  const isCreating = mode === 'create';

  const formatReleaseDate = (date) => {
    if (!date) {
      return null;
    }
    const transformedDate =
      date instanceof firestore.Timestamp ? date.toDate() : date;
    const kstNormalizedDate =
      moment(transformedDate).utcOffset(KST_OFFSET_IN_MIN);
    return kstNormalizedDate.format('YYYY-MM-DD');
  };
  const parseReleaseDate = (date) => {
    if (!date) {
      return null;
    }
    const kstNormalizedDate = moment(date).utcOffset(KST_OFFSET_IN_MIN, true);
    return kstNormalizedDate.toDate();
  };

  return (
    <>
      <FormTab label="For content" {...formTabProps}>
        <div style={styles.flexRowContainer}>
          {isCreating || <TextInput disabled label="Tag Id" source="id" />}
          <SelectInput
            label="Tag Type"
            source="category"
            choices={TagCategories}
            style={{
              ...styles.selectInput,
              ...(dirtyFields.category ? styles.updated : null),
            }}
          />

          <BooleanInput
            label="Hidden?"
            source="_dev"
            defaultValue={isCreating ? true : undefined}
            style={{
              ...styles.smallListener,
              // eslint-disable-next-line no-underscore-dangle
              ...(dirtyFields._dev ? styles.updated : null),
            }}
          />

          <BooleanInput
            label="Released?"
            source="isReleased"
            style={{
              ...(dirtyFields.isReleased ? styles.updated : null),
            }}
          />
        </div>
        <div style={styles.verticalView}>
          <TextInput disabled label="TagName" source="tagName" />
          <DateInput
            label="Release date in KST"
            source="releaseDate"
            style={dirtyFields.releaseDate ? styles.updated : null}
            format={formatReleaseDate}
            parse={parseReleaseDate}
          />
          <SelectArrayInput
            label="Supported Language Code"
            source="learningLanguageCodes"
            choices={ContentLanguages}
            style={{
              ...styles.arrayInput,
              ...(dirtyFields.learningLanguageCodes ? styles.updated : null),
            }}
          />
        </div>
        <ImageInput
          label="Image"
          source="image"
          placeholder={<p>Drop or Select image for Tag page</p>}
        >
          <ImageField source="src" />
        </ImageInput>
        {_.get(formData, 'imagePath', null) &&
        !_.get(formData, 'image', null) ? (
          <ImageFieldByPath
            imagePath={formData.imagePath}
            imageUrl={imageUrl}
            altText="Previous_Tag_Image"
            setImageUrl={setImageUrl}
          />
        ) : null}
        <h3>Name</h3>
        <TranslationField
          source="languageCodeToName"
          label="Name"
          record={formData}
          dirtyFields={dirtyFields}
          parse={(value) => JSUtility.hasValidQuote(value)}
        />
        <h3>Description</h3>
        <TranslationField
          source="languageCodeToDescription"
          label="Description"
          record={formData}
          dirtyFields={dirtyFields}
        />
        <>
          <h3>Category Sort by country</h3>
          <TranslationField
            source="order"
            label="order"
            record={formData}
            dirtyFields={dirtyFields}
          />
        </>
      </FormTab>
    </>
  );
};

const WordMapTagFormTab = (props) => {
  const { mode, ...formTabProps } = props;
  const [dirtyFields, setDirtyFields] = useState({});
  const [formData, setFormData] = useState({});
  const [imageUrl, setImageUrl] = useState('');
  const [headerImageUrl, setHeaderImageUrl] = useState('');
  const form = useForm();

  useEffect(() => {
    const unsubscribe = form.subscribe(
      (s) => {
        setDirtyFields(s.dirtyFields);
        setFormData(s.values);
      },
      { dirtyFields: true, values: true },
    );
    return () => {
      unsubscribe();
    };
  }, [form]);

  const isCreating = mode === 'create';

  return (
    <>
      <FormTab label="For word map" {...formTabProps}>
        <div style={styles.flexRowContainer}>
          <BooleanInput
            label="Is word map category?"
            source="isWordMapCategory"
            defaultValue={isCreating ? false : undefined}
            style={dirtyFields.isWordMapCategory ? styles.updated : {}}
          />
        </div>
        <ImageInput
          label="Image"
          source="wordMapCategoryImage"
          placeholder={<p>Drop or Select image for word map page</p>}
        >
          <ImageField source="src" />
        </ImageInput>
        {_.get(formData, 'wordMapCategoryImagePath', null) &&
        !_.get(formData, 'wordMapCategoryImage', null) ? (
          <ImageFieldByPath
            imagePath={formData.wordMapCategoryImagePath}
            imageUrl={imageUrl}
            setImageUrl={setImageUrl}
            altText="Previous_Word_Map_Image"
          />
        ) : null}
        <ImageInput
          label="Header image"
          source="wordMapCategoryHeaderImage"
          placeholder={<p>Drop or Select image for word map page header</p>}
        >
          <ImageField source="src" />
        </ImageInput>
        {_.get(formData, 'wordMapCategoryHeaderImagePath', null) &&
        !_.get(formData, 'wordMapCategoryHeaderImage', null) ? (
          <ImageFieldByPath
            imagePath={formData.wordMapCategoryImagePath}
            imageUrl={headerImageUrl}
            setImageUrl={setHeaderImageUrl}
            altText="Previous_Word_Map_Category_Header_Image"
          />
        ) : null}
        <h3>Description</h3>
        <TranslationField
          source="languageCodeToWordMapDescription"
          label="Description"
          record={formData}
          dirtyFields={dirtyFields}
        />
      </FormTab>
    </>
  );
};

const CommonPropTypesForTagFormAndContent = {
  mode: PropTypes.oneOf(['create', 'update']).isRequired,
  record: PropTypes.PropTypes.shape({}),
};

TagForm.propTypes = {
  ...CommonPropTypesForTagFormAndContent,
};

ContentTagFormTab.propTypes = {
  ...CommonPropTypesForTagFormAndContent,
};

WordMapTagFormTab.propTypes = {
  ...CommonPropTypesForTagFormAndContent,
};

export default TagForm;
