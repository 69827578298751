import React from 'react';
import { Layout } from 'react-admin';
import { CustomAppBar } from '..';
import CustomMenu from './CustomMenu';

const CustomLayout = (props) => (
  <Layout {...props} appBar={CustomAppBar} menu={CustomMenu} />
);

export default CustomLayout;
