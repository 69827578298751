import React from 'react';

export interface LemmaResult {
  id: string;
  lemma: string;
  difficultyLevel: number;
  hidden: boolean;
  relatedWords: string[];
  href: string;
}

interface LemmaResultItemProps {
  result: LemmaResult;
}

const QuestiontResultItem = (props: LemmaResultItemProps): JSX.Element => {
  const {
    result: { lemma, difficultyLevel, hidden, relatedWords },
  } = props;

  return (
    <div>
      <div className="hit-lemma">
        <span dangerouslySetInnerHTML={{ __html: lemma }} />
      </div>
      <div className="hit-relatedWords">
        Related words:{' '}
        <span
          dangerouslySetInnerHTML={{ __html: relatedWords?.sort().join(', ') }}
        />
      </div>
      <div className="hit-difficulty">
        Difficulty level: {difficultyLevel}
        &nbsp;
      </div>
      <div className="hit-hidden">{hidden ? 'Hidden' : 'Not hidden'}</div>
    </div>
  );
};

export default QuestiontResultItem;
