import React from 'react';
import { Edit } from 'react-admin';

import L2eRedemptionHistoryActions from './L2eRedemptionHistoryActions';
import L2eRedemptionHistoryForm from './L2eRedemptionHistoryForm';

const L2eRedemptionHistoryEdit = (props) => (
  <Edit
    actions={<L2eRedemptionHistoryActions basePath="/l2eRedemptionHistory" />}
    {...props}
  >
    <L2eRedemptionHistoryForm mode="update" />
  </Edit>
);

export default L2eRedemptionHistoryEdit;
