import React, { useEffect } from 'react';

import ResultsList from './ResultsList';

import { ENGLISH_CODE, ContentLanguageCodeToName } from '../../../constants';
import '../../search.css';
import TagResultItem, { TagResult } from './items/TagResultItem';
import withSearchEngine, { SearchProps } from '../../modules/withSearchEngine';

import * as ElasticAppSearch from '../../modules/ElasticAppSearch';

const connector = ElasticAppSearch.createConnector('tags-en');
const searchEngineConfig = {
  apiConnector: connector,
  searchQuery: {
    precision: 8,
    search_fields: {
      tags: { weight: 10 },
      language_code_to_name: { weight: 5 },
    },
    result_fields: {
      tags: {
        raw: {},
        snippet: {},
      },
      category: { raw: {} },
      hidden: { raw: {} },
      learning_language_codes: { raw: {} },
      language_code_to_name: { raw: {}, snippet: {} },
    },
  },
};

const transformResult = (result): TagResult => ({
  id: result.id,
  tags: result.tags ?? [],
  category: result.category,
  hidden: result.hidden,
  learningLanguageCodes: result.learning_language_codes ?? [],
  tagTranslations: Object.values(result.language_code_to_name ?? {}),
  href: `#/tags/${result.id}`,
});

interface TagSearchProps {
  onChangeResultQuery: (query: string) => void;
  onChangeSearch: (search: (query: string) => void) => void;
  onChangeResults: (results: TagResult[]) => void;
}

type SearchResultsWithEngineProps = TagSearchProps & SearchProps<TagResult>;

const FragmentSearch = ({
  onChangeSearch,
  onChangeResultQuery,
  onChangeResults,
  search,
  resultQuery,
  results,
  setResultsPerPage,
}: SearchResultsWithEngineProps) => {
  useEffect(() => {
    setResultsPerPage(20);
  }, [setResultsPerPage]);

  useEffect(() => {
    onChangeSearch(search);
  }, [onChangeSearch, search]);

  useEffect(() => {
    onChangeResultQuery(resultQuery);
  }, [onChangeResultQuery, resultQuery]);

  useEffect(() => {
    onChangeResults(results);
  }, [onChangeResults, results]);

  return (
    <ResultsList
      title={ContentLanguageCodeToName[ENGLISH_CODE]}
      results={results}
      renderItem={(result) => <TagResultItem result={result} />}
    />
  );
};

export default withSearchEngine<unknown, TagResult, TagSearchProps>(
  searchEngineConfig,
  transformResult,
  FragmentSearch,
);
