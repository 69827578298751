import React, { useEffect, useState } from 'react';
import { Admin, Resource } from 'react-admin';
// import jsonServerProvider from 'ra-data-json-server';
import firebase from 'firebase';
import PropTypes from 'prop-types';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';
import { getFirebaseConfig, GraphQL } from '@redkiwi/shared';

import contents from './collections/contents/index';
import series from './collections/series/index';
import fragments from './collections/fragments/index';
import tags from './collections/tags/index';
import lemmas from './collections/lemmas/index';
import surveyAnswerOptions from './collections/surveyAnswerOptions/index';
import surveyQuestions from './collections/surveyQuestions/index';
import surveys from './collections/surveys/index';
import users from './collections/users/index';
import questions from './collections/questions';
import subscriptions from './collections/subscriptions';
import reviews from './collections/reviews';
import grammars from './collections/grammars';
import l2eRedemptionHistory from './collections/l2eRedemptionHistory';
import l2eMonthlyConfig from './collections/l2eMonthlyConfig';
import l2eMonthlyPointLogs from './collections/l2eMonthlyPointLogs';
import Dashboard from './Dashboard';
import authProvider from './authProvider';
import dataProvider from './dataProvider';
import CustomLayout from './common/layout/CustomLayout';
import LoginPage from './login/LoginPage';
import LogoutButton from './login/LogoutButton';
import Route from './Routes';
import topics from './collections/topics';
import events from './collections/events';
import pointLogs from './collections/pointLogs';
import contentRewards from './collections/contentRewards';
import sktContents from './collections/sktContents';
import sktFragments from './collections/sktFragments';

const useStyles = makeStyles({
  toolbar: null, // theme.mixins.toolbar,
});

const theme = createMuiTheme({
  sidebar: {
    width: 180,
  },
});

// Line below is needed to disable warnings. https://github.com/hayanmind/redkiwi/pull/6899/files#r380010218
const i18nProvider = polyglotI18nProvider(() => englishMessages, 'en', {
  allowMissing: true,
  onMissingKey: (key) => key || null,
});

// const dataProvider = jsonServerProvider('http://localhost:3001');
const App = () => {
  const isProduction = window.location.host === 'tms.redkiwiapp.com';
  if (firebase.apps.length === 0) {
    // Ref: https://github.com/zeit/next.js/issues/1999#issuecomment-326805233
    firebase.initializeApp(getFirebaseConfig(isProduction));
    if (!isProduction) {
      // eslint-disable-next-line no-console
      console.log('Firebase is initialized with dev environment');
    }

    const tokenExtractor = async () => {
      const getCurrentUser = () =>
        new Promise((resolve) => {
          const unsubsribe = firebase.auth().onAuthStateChanged((user) => {
            resolve(user);
            unsubsribe();
          });
        });
      const currentUser = await getCurrentUser();
      return currentUser == null ? null : currentUser.getIdToken();
    };

    GraphQL.initialize(isProduction, tokenExtractor);
  }

  const classes = useStyles();

  const [authInitialized, setAuthInitialized] = useState(false);
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(() => {
      setAuthInitialized(true);
      unsubscribe(); // Unscribe it after initial event was handled
    });
  }, []);

  if (!authInitialized) {
    /**
     * This is to prevent AuthProvider from calling `checkAuth()` before Firebase is iniitialized.
     * If `checkAuth()` fails, `logout()` is called automatically (in RA 3.0.2).
     */
    return null;
  }

  return (
    <Admin
      theme={theme}
      loginPage={LoginPage}
      logoutButton={LogoutButton}
      customRoutes={Route}
      layout={CustomLayout} // Without, default AppBar will be used
      dataProvider={dataProvider}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      dashboard={Dashboard}
    >
      <div className={classes.toolbar} />
      <Resource name="contents" {...contents} />
      <Resource name="fragments" {...fragments} />
      <Resource name="questions" {...questions} />
      <Resource name="tags" {...tags} />
      <Resource name="series" {...series} />
      <Resource name="lemmas" {...lemmas} />
      <Resource name="grammars" {...grammars} options={{ label: 'Grammars' }} />
      <Resource name="topics" {...topics} options={{ label: 'Topics' }} />
      <Resource
        name="surveyAnswerOptions"
        {...surveyAnswerOptions}
        options={{ label: 'Surv. Options' }}
      />
      <Resource
        name="surveyQuestions"
        {...surveyQuestions}
        options={{ label: 'Surv. Questions' }}
      />
      <Resource name="surveys" {...surveys} options={{ label: 'Surveys' }} />
      <Resource name="users" {...users} />
      <Resource name="subscriptions" {...subscriptions} />
      <Resource name="reviews" {...reviews} />
      <Resource name="events" {...events} />
      <Resource name="pointLogs" {...pointLogs} />
      <Resource name="contentRewards" {...contentRewards} />
      <Resource
        name="l2eRedemptionHistory"
        {...l2eRedemptionHistory}
        options={{ label: 'L2eRedemptionHistory' }}
      />
      <Resource
        name="l2eMonthlyConfig"
        {...l2eMonthlyConfig}
        options={{ label: 'L2eMonthlyConfig' }}
      />
      <Resource
        name="l2eMonthlyPointLogs"
        {...l2eMonthlyPointLogs}
        options={{ label: 'L2eMonthlyPointLogs' }}
      />
      <Resource
        name="sktContents"
        {...sktContents}
        options={{ label: 'SKT Contents' }}
      />
      <Resource
        name="sktFragments"
        {...sktFragments}
        options={{ label: 'SKT Fragments' }}
      />
    </Admin>
  );
};

App.propTypes = {
  classes: PropTypes.string, // eslint-disable-line react/require-default-props
};

export default App;
