import React from 'react';
import PropTypes from 'prop-types';
import { Edit } from 'react-admin';

import ContentActions from './ContentActions';
import ContentFormWithFragmentForms from './ContentFormWithFragmentForms';

const ContentTitle = ({ record }) => (
  <span>{record ? `"${record.title}"` : ''}</span>
);

const ContentEdit = (props) => (
  <Edit
    title={<ContentTitle />}
    actions={<ContentActions basePath="/contents" />}
    {...props}
  >
    <ContentFormWithFragmentForms mode="update" />
  </Edit>
);

ContentTitle.propTypes = {
  record: PropTypes.shape({
    title: PropTypes.string,
  }),
};

ContentTitle.defaultProps = {
  record: {
    title: null,
  },
};

export default ContentEdit;
