import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Edit } from 'react-admin';

import SurveyAnswerOptionActions from './SurveyAnswerOptionActions';
import SurveyAnswerOptionForm from './SurveyAnswerOptionForm';

const SurveyAnswerOptionTitle = ({ record = null }) => (
  <>SurveyAnswerOption: {_.get(record, 'answer', '')}</>
);

SurveyAnswerOptionTitle.propTypes = {
  // eslint-disable-next-line react/require-default-props
  record: PropTypes.shape({
    answer: PropTypes.string.isRequired,
  }),
};

const SurveyAnswerOptionEdit = (props) => (
  <Edit
    title={<SurveyAnswerOptionTitle />}
    actions={<SurveyAnswerOptionActions basePath="/surveyAnswerOptions" />}
    {...props}
  >
    <SurveyAnswerOptionForm mode="update" />
  </Edit>
);

export default SurveyAnswerOptionEdit;
