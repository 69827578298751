import React from 'react';
import PropTypes from 'prop-types';
import { TextField, SimpleShowLayout, Show } from 'react-admin';

import { ContentLanguages } from '../../constants';

const ltcfc = 'langToCompletedFragmentCount';

const UserShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      {ContentLanguages.map((lang) =>
        props.record[ltcfc] != null ? (
          <TextField
            key={lang.id}
            source={`${ltcfc}.${lang.id}`}
            label={`Completed ${lang.name.slice(0, 3)} fragments`}
          />
        ) : null,
      )}
    </SimpleShowLayout>
  </Show>
);

UserShow.propTypes = {
  // eslint-disable-next-line react/require-default-props
  record: PropTypes.shape({
    id: PropTypes.string.isRequired,
    isAnonymous: PropTypes.bool,
    langToCompletedFragmentCount: PropTypes.shape({}),
    subscription: PropTypes.shape({}),
  }),
};

UserShow.defaultProps = {
  record: null,
};

export default UserShow;
