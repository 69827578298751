import { GraphQL } from '@redkiwi/shared';

const { Question, Lemma, Review } = GraphQL;

const graphqlDataProvider = async (type, resource, params) => {
  if (type === 'GET_LIST') {
    if (resource === 'questions') {
      return Question.getList(type, resource, params);
    }
    if (resource === 'lemmas') {
      return Lemma.getList(type, resource, params);
    }
    if (resource === 'reviews') {
      return Review.getList(type, resource, params);
    }
  }

  if (type === 'GET_ONE') {
    if (resource === 'questions') {
      return Question.getOne(type, resource, params);
    }
    if (resource === 'lemmas') {
      return Lemma.getOne(type, resource, params);
    }
    if (resource === 'reviews') {
      return Review.getOne(type, resource, params);
    }
  }

  if (type === 'GET_MANY') {
    if (resource === 'lemmas') {
      return Lemma.getMany(type, resource, params);
    }
  }
  if (type === 'CREATE') {
    if (resource === 'questions') {
      return Question.create(type, resource, params);
    }
    if (resource === 'lemmas') {
      return Lemma.create(type, resource, params);
    }
  }

  if (type === 'UPDATE') {
    if (resource === 'questions') {
      return Question.update(type, resource, params);
    }
    if (resource === 'lemmas') {
      return Lemma.update(type, resource, params);
    }
    if (resource === 'reviews') {
      return Review.update(type, resource, params);
    }
  }

  if (type === 'DELETE') {
    if (resource === 'questions') {
      return Question.delete(type, resource, params);
    }
    if (resource === 'lemmas') {
      return Lemma.delete(type, resource, params);
    }
  }

  if (type === 'DELETE_MANY') {
    if (resource === 'questions') {
      return Question.deleteMany(type, resource, params);
    }
    if (resource === 'lemmas') {
      return Lemma.deleteMany(type, resource, params);
    }
  }

  if (type === 'QNA_BOT_POSTING') {
    if (resource === 'questions') {
      return Question.qnaBotPost(params);
    }
  }

  console.error('Not supported type or resource on graphql provider'); // eslint-disable-line no-console
  return { data: [] };
};

export default graphqlDataProvider;
