import React from 'react';
import { Create } from 'react-admin';

import SktContentForm from './SktContentFormWithFragmentForms';

const SktContentCreate = (props) => (
  <Create {...props}>
    <SktContentForm mode="create" />
  </Create>
);

export default SktContentCreate;
