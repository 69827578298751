import React from 'react';
import PropTypes from 'prop-types';
import { TopToolbar, ListButton } from 'react-admin';

const PointLogActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
  </TopToolbar>
);

PointLogActions.propTypes = {
  basePath: PropTypes.string,
  data: PropTypes.shape({}),
};

PointLogActions.defaultProps = {
  basePath: '',
  data: {},
};
export default PointLogActions;
