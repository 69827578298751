import _ from 'lodash';

import ApolloClientHelper from './ApolloClientHelper';
import Queries from './graphqlQueries';
import filterRecord from './filterRecord';

export default {
  getList: async (type, resource, params) => {
    const apolloClient = ApolloClientHelper.getInstance();

    const {
      pagination: { perPage: limit, page },
      filter,
    } = params;

    const { languageCode, published, rating, ...restFilters } = filter;

    const offset = limit * (page - 1);

    const {
      data: {
        reviewsV2: { records, nextStartAt },
      },
    } = await apolloClient.query({
      query: Queries.GET_REVIEWS,
      variables: {
        input: {
          languageCode,
          published,
          limit,
          offset,
          rating,
        },
      },
    });

    let results = records;

    if (filter != null) {
      results = results.filter((r) => filterRecord(r, restFilters, [], []));
    }

    const total =
      nextStartAt != null
        ? offset + results.length + 1 // Not support the correct total
        : offset + results.length;

    return {
      data: results,
      total,
    };
  },
  getOne: async (type, resource, params) => {
    const apolloClient = ApolloClientHelper.getInstance();
    const {
      data: { review },
    } = await apolloClient.query({
      query: Queries.GET_REVIEW,
      variables: { input: { id: params.id } },
    });

    return { data: review };
  },
  update: async (type, resource, params) => {
    const apolloClient = ApolloClientHelper.getInstance();

    const {
      data: { id, published },
    } = params;

    const {
      data: {
        updateReview: { review: updatedReview },
      },
    } = await apolloClient.mutate({
      mutation: Queries.UPDATE_REVIEW,
      variables: {
        input: {
          id,
          published,
        },
      },
    });

    return { data: updatedReview };
  },
};
