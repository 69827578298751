import React from 'react';
import PropTypes from 'prop-types';
import { TopToolbar, ListButton } from 'react-admin';

const GrammarActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
  </TopToolbar>
);

GrammarActions.propTypes = {
  basePath: PropTypes.string,
  data: PropTypes.shape({}),
};

GrammarActions.defaultProps = {
  basePath: '',
  data: {},
};

export default GrammarActions;
