import React, { useEffect } from 'react';

import ResultsList from './ResultsList';

import { ENGLISH_CODE, ContentLanguageCodeToName } from '../../../constants';
import '../../search.css';
import FragmentResultItem, { FragmentResult } from './items/FragmentResultItem';
import withSearchEngine, { SearchProps } from '../../modules/withSearchEngine';

import * as ElasticAppSearch from '../../modules/ElasticAppSearch';

const connector = ElasticAppSearch.createConnector('fragments-en');
const searchEngineConfig = {
  apiConnector: connector,
  searchQuery: {
    precision: 8,
    search_fields: {
      text: { weight: 10 },
      lemma_ids: { weight: 5 },
      text_translations: { weight: 5 },
      text_translations_by_gengo: { weight: 5 },
    },
    result_fields: {
      text: {
        raw: {},
        snippet: {},
      },
      content_title: {
        raw: {},
      },
      keepable: { raw: {} },
      text_translations: { raw: {}, snippet: {} },
      text_translations_by_gengo: { raw: {}, snippet: {} },
      lemma_ids: {
        raw: {},
        snippet: {},
      },
    },
  },
};

const transformResult = (result): FragmentResult => ({
  id: result.id,
  text: result.text,
  contentTitle: result.content_title,
  keepable: result.keepable,
  textTranslations: Object.values(result.text_translations ?? {}),
  textTranslationsByGengo: Object.values(
    result.text_translations_by_gengo ?? {},
  ),
  lemmaIds: result.lemma_ids ?? [],
  href: `#/fragments/${result.id}`,
});

interface FragmentSearchProps {
  onChangeResultQuery: (query: string) => void;
  onChangeSearch: (search: (query: string) => void) => void;
  onChangeResults: (results: FragmentResult[]) => void;
}

type SearchResultsWithEngineProps = FragmentSearchProps &
  SearchProps<FragmentResult>;

const FragmentSearch = ({
  onChangeSearch,
  onChangeResultQuery,
  onChangeResults,
  search,
  resultQuery,
  results,
  setResultsPerPage,
}: SearchResultsWithEngineProps) => {
  useEffect(() => {
    setResultsPerPage(40);
  }, [setResultsPerPage]);

  useEffect(() => {
    onChangeSearch(search);
  }, [onChangeSearch, search]);

  useEffect(() => {
    onChangeResultQuery(resultQuery);
  }, [onChangeResultQuery, resultQuery]);

  useEffect(() => {
    onChangeResults(results);
  }, [onChangeResults, results]);

  return (
    <ResultsList
      title={ContentLanguageCodeToName[ENGLISH_CODE]}
      results={results}
      renderItem={(result) => <FragmentResultItem result={result} />}
    />
  );
};

export default withSearchEngine<unknown, FragmentResult, FragmentSearchProps>(
  searchEngineConfig,
  transformResult,
  FragmentSearch,
);
