import React from 'react';
import { Create } from 'react-admin';

import L2eMonthlyConfigActions from './L2eMonthlyConfigActions';
import L2eMonthlyConfigForm from './L2eMonthlyConfigForm';

const L2eMonthlyConfigCreate = (props) => (
  <Create
    actions={<L2eMonthlyConfigActions basePath="/l2eMonthlyConfig" />}
    {...props}
  >
    <L2eMonthlyConfigForm mode="create" />
  </Create>
);

export default L2eMonthlyConfigCreate;
