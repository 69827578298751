import React from 'react';
import { Create } from 'react-admin';

import TopicActions from './TopicActions';
import TopicForm from './TopicForm';

const TopicCreate = (props) => (
  <Create actions={<TopicActions basePath="/topics" />} {...props}>
    <TopicForm mode="create" />
  </Create>
);

export default TopicCreate;
