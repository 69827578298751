import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Edit } from 'react-admin';

import SubscriptionForm from './SubscriptionForm';

const SubscriptionTitle = ({ record = null }) => <>{_.get(record, 'id', '')}</>;

SubscriptionTitle.propTypes = {
  // eslint-disable-next-line react/require-default-props
  record: PropTypes.shape({}),
};

const SubscriptionEdit = (props) => (
  <Edit title={<SubscriptionTitle />} {...props}>
    <SubscriptionForm mode="update" />
  </Edit>
);

export default SubscriptionEdit;
