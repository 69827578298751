import * as firebase from 'firebase';
import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY,
} from 'react-admin';
import _, { groupBy, orderBy } from 'lodash';
import axios from 'axios';
import { FIREBASE_APP_NAME } from '@redkiwi/shared'; // ToDo: Replace it with getFirebaseConfig()

import JSUtility from '../utilities/JSUtility';
import {
  Collections,
  ImageFieldNameToSubFolder,
  ResourceNameToImageField,
} from '../constants';

const baseEndPoint = `https://us-central1-${FIREBASE_APP_NAME}.cloudfunctions.net/api`;
export const URL_GIVE_FREE_SUBSCRIPTION = `${baseEndPoint}/v3/giveFreeSubscription`;
const promiseArray = [];

export const utils = {
  filterRecord: (
    record,
    filter,
    // For array value keys, it filters record that HAS (not equals) the value.
    keysOfArrayValue = ['learningLanguageCodes', 'tagKeys'],
  ) => {
    const filterKeys = Object.keys(filter); // ['langaugeCode', 'keepable'];
    const listOfBooleans = filterKeys.map((filterKey) => {
      const filterValue = filter[filterKey];
      const isSatisfied = keysOfArrayValue.includes(filterKey)
        ? record[filterKey].includes(filterValue)
        : record[filterKey] === filterValue;
      return isSatisfied;
    });

    return listOfBooleans.every((item) => item);
  },
  filterQuery: (
    collection,
    targetResource,
    filter,
    keysOfArrayValue = [
      'learningLanguageCodes',
      'tagKeys',
      'supportedTranslationLanguages',
    ],
  ) => {
    const filterKeys = Object.keys(filter);
    const getQuery = filterKeys.reduce((acc, filterKey) => {
      const filterValue = filter[filterKey];

      // Prevent empty value
      if (
        filterValue == null ||
        filterValue === undefined ||
        filterValue === []
      ) {
        return acc;
      }

      const compoundQuery = keysOfArrayValue.includes(filterKey)
        ? acc.where(filterKey, 'array-contains-any', [filterValue])
        : acc.where(filterKey, '==', filterValue);
      return compoundQuery;
    }, targetResource);

    if (collection === 'fragments' || collection === 'users') {
      return getQuery.limit(10);
    }
    return getQuery;
  },
};

export const subscribeManually = async (userId, howManyDays) => {
  const { currentUser } = firebase.auth();

  const params = {
    howManyDays,
    type: 'premium',
    userId,
  };

  try {
    const idToken = await currentUser.getIdToken();
    const response = await axios.post(URL_GIVE_FREE_SUBSCRIPTION, params, {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    });

    return response.data.data;
  } catch (error) {
    console.error(error); // eslint-disable-line no-console
    throw new Error('subscribeManually() - request Error');
  }
};

export const getMonetaryUserRanking = async (limitPage) => {
  try {
    const pointLogs = firebase.firestore().collection('pointLogs');
    const query = pointLogs.where('status', '==', 'collected');
    const querySnapshot = await query.get();
    const records = [];

    querySnapshot.forEach((doc) => {
      const { points, userId } = doc.data();
      records.push({ points, userId });
    });

    const userPointLogsGrouped = groupBy(records, (q) => q.userId);

    const summarizedPoints = Object.keys(userPointLogsGrouped).map((userId) => {
      const user = `${userId}`;

      const getSum = (acc, cur) => (acc ?? 0) + cur.points;
      const sum = userPointLogsGrouped[userId].reduce(
        (acc, cur) => getSum(acc, cur),
        0,
      );

      return {
        user,
        collectedPoints: sum,
      };
    });
    return orderBy(summarizedPoints, ['collectedPoints'], ['desc']).slice(
      0,
      limitPage,
    );
  } catch (error) {
    console.error(error); // eslint-disable-line no-console
    throw new Error('getMonetaryUserRanking() - request Error');
  }
};

export const getUserDailySummary = async () =>
  firebase.firestore().collection('l2eEvents').orderBy('createdAt', 'desc');

export const getL2eRedeemRequests = async () => {
  try {
    const pointLogs = firebase.firestore().collection('l2eRedeemRequests');
    const querySnapshot = await pointLogs.get();
    const records = [];

    querySnapshot.forEach((doc) => {
      const { accountNumber, bankId, bankName, userName, uid } = doc.data();
      records.push({
        accountNumber,
        bankId,
        bankName,
        userName,
        uid,
      });
    });

    return records;
  } catch (error) {
    console.error(error); // eslint-disable-line no-console
    throw new Error('getMonetaryUserRanking() - request Error');
  }
};

export const getPointLogList = async () => {
  try {
    const pointLogs = firebase
      .firestore()
      .collection('pointLogs')
      .orderBy('createdAt', 'desc');
    const querySnapshot = await pointLogs.get();
    const records = [];

    querySnapshot.forEach((doc) => {
      const { contentId, points, status, userId, createdAt } = doc.data();
      records.push({
        contentId,
        points,
        status,
        userId,
        createdAt: createdAt.toDate(),
      });
    });
    return records;
  } catch (error) {
    console.error(error); // eslint-disable-line no-console
    throw new Error('getMonetaryUserRanking() - request Error');
  }
};

const firestoreDataProvider = async (type, resource, params) => {
  // eslint-disable-next-line no-console
  console.log(type, resource, params);

  const storageRef = firebase.storage().ref();
  const targetResource = firebase.firestore().collection(resource);
  switch (type) {
    case GET_LIST: {
      const query = utils.filterQuery(resource, targetResource, params.filter);
      const querySnapshot = await query.get();
      let records = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        const { id } = doc;
        // TODO: property `id` could be overwritten according the current design
        records.push({ ...data, id });
      });

      if (params != null && params.sort != null) {
        records = _.orderBy(
          records,
          [params.sort.field],
          [params.sort.order.toLowerCase()],
        );
      }

      if (params != null && params.pagination != null) {
        const { page, perPage } = params.pagination;
        const startIndex = (page - 1) * perPage;
        const endIndex = page * perPage;

        return {
          data: records.slice(startIndex, endIndex),
          total: records.length,
        };
      }

      return { data: records, total: records.length };
    }
    case GET_ONE: {
      if (params.id == null) {
        return { data: {} };
      }

      const docRef = targetResource.doc(params.id);
      const docSnapshot = await docRef.get();
      // TODO: property `id` could be overwritten according the current design
      const record = { data: { ...docSnapshot.data(), id: params.id } };
      return record;
    }
    case GET_MANY: {
      if (params.ids == null) {
        return { data: {} };
      }

      const promises = params.ids.map(async (dataId) => {
        const docRef = targetResource.doc(dataId);
        const docSnapshot = await docRef.get();
        // TODO: property `id` could be overwritten according the current design

        const record = { ...docSnapshot.data(), id: dataId };
        return record;
      });

      if (resource === 'lemmas') {
        promiseArray.push(promises);
        const data = await Promise.all(
          promiseArray.map(Promise.all.bind(Promise)),
        );
        promiseArray.length = 0;

        return { data };
      }

      const recordList = await Promise.all(promises);
      return { data: recordList };
    }
    case GET_MANY_REFERENCE: {
      const query = targetResource.where(params.target, '==', params.id);
      const querySnapshot = await query.get();
      let records = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        const { id } = doc;
        // TODO: property `id` could be overwritten according the current design
        records.push({ ...data, id });
      });

      if (params != null && params.filter != null) {
        records = records.filter((r) => utils.filterRecord(r, params.filter));
      }

      if (params != null && params.sort != null) {
        records = _.orderBy(
          records,
          [params.sort.field],
          [params.sort.order.toLowerCase()],
        );
      }

      if (params != null && params.pagination != null) {
        const { page, perPage } = params.pagination;
        const startIndex = (page - 1) * perPage;
        const endIndex = page * perPage;

        return {
          data: records.slice(startIndex, endIndex),
          total: records.length,
        }; // { data: {Record[]}, total: {int} }
      }

      return { data: records, total: records.length };
    }
    case CREATE: {
      const docData = {
        ...params.data,
        createdAt: new Date(),
      };

      if (resource === 'l2eMonthlyConfig') {
        await targetResource.doc(params.data.id).set(_.omit(docData, 'id'));
        return { data: { ...params.data, id: params.data.id } };
      }

      const docRef = await targetResource.add(docData);
      const { id } = docRef;
      return { data: { ...params.data, id } }; // { data: {Record} }
    }
    case UPDATE: {
      const objWithOnlyUnchanged = JSUtility.removeUnchangedProperties(
        params.data,
        params.previousData,
      );
      const objWithEmptyObjRemoved =
        JSUtility.removeEmptyObject(objWithOnlyUnchanged);

      const updatedAt = new Date();
      const docDataForUpdate = {
        updatedAt,
        ...objWithEmptyObjRemoved,
      };

      const hasImageField =
        resource === Collections.TAGS ||
        resource === Collections.SURVEY_ANSWER_OPTIONS;

      const isL2eConfigUpdate =
        resource === 'l2eMonthlyConfig' || resource === 'l2eMonthlyPointLogs';

      if (!hasImageField) {
        await targetResource
          .doc(params.data.id)
          .set(
            isL2eConfigUpdate
              ? { updatedAt, ...params.data }
              : docDataForUpdate,
            {
              merge: true,
            },
          );
      } else {
        const imageFieldNames = ResourceNameToImageField[resource];
        const imageDataForUpdate = imageFieldNames.filter(
          (fieldName) => params.data[fieldName] != null,
        );
        if (imageDataForUpdate.length === 0) {
          // if image was removed need to update image path fields
          imageFieldNames.forEach((fieldName) => {
            if (fieldName in docDataForUpdate) {
              docDataForUpdate[`${fieldName}Path`] =
                docDataForUpdate[fieldName];
              delete docDataForUpdate[fieldName];
            }
            delete docDataForUpdate[fieldName];
          });
          await targetResource
            .doc(params.data.id)
            .set(docDataForUpdate, { merge: true });
        } else {
          const imagePathData = {};
          await Promise.all(
            imageDataForUpdate.map(async (imageFieldName) => {
              const file = params.data[imageFieldName].rawFile;
              const imageName = _.get(
                params.data,
                'languageCodeToName.en',
                file.name,
              );
              const subFolderName = ImageFieldNameToSubFolder[imageFieldName];
              const imagePath =
                subFolderName != null
                  ? `images/${resource}/${subFolderName}/${imageName}`
                  : `images/${resource}/${imageName}`;
              const imageRef = storageRef.child(imagePath);
              try {
                await imageRef.put(file);
                // eslint-disable-next-line no-console
                console.log('Image upload finished.');
                imagePathData[`${imageFieldName}Path`] = imagePath;
                delete docDataForUpdate[imageFieldName];
              } catch (error) {
                // eslint-disable-next-line no-console
                console.log(error);
              }
            }),
          );
          const docDataWithImagePath = {
            ...docDataForUpdate,
            ...imagePathData,
          };
          await targetResource
            .doc(params.data.id)
            .set(docDataWithImagePath, { merge: true });
        }
      }
      const docDataAfterUpdate = {
        updatedAt,
        ...params.data, // it includes removed properties
      };

      // The returned object will be used for filling forms locally
      return { data: { ...docDataAfterUpdate, id: params.data.id } }; // { data: {Record} }
    }
    case UPDATE_MANY: {
      // eslint-disable-next-line no-console
      console.log('UPDATE_MANY is not implemented yet');
      return {}; // { data: {mixed[]} } The ids which have been updated
    }
    case DELETE: {
      const docRef = targetResource.doc(params.id);
      await docRef.delete();
      return { data: null }; // returning Record object is optional
    }
    case DELETE_MANY: {
      const promises = params.ids.map((id) => targetResource.doc(id).delete());
      await Promise.all(promises);
      return { data: null }; // returning Record object is optional
    }
    default:
      break;
  }

  return { data: [] };
};

export default firestoreDataProvider;
