import { Edit } from 'react-admin';
import React from 'react';
import PointLogActions from './PointLogActions';
import PointLogForm from './PointLogForm';

const PointLogEdit = (props) => (
  <Edit actions={<PointLogActions basePath="/pointLogs" />} {...props}>
    <PointLogForm mode="update" />
  </Edit>
);

export default PointLogEdit;
