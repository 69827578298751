import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { SimpleForm, TextInput, ReferenceField } from 'react-admin';
import { CustomEditToolbar } from '../../common';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  margin: {
    marginLeft: 20,
  },
  textInput: {
    width: 'fit-content',
  },
});

const PointLogFormContent = () => {
  const classes = useStyles();
  const timestampFormatter = (v) =>
    v != null ? moment(v.toDate()).format('YYYY-MM-DD, HH:mm:ss') : null;

  return (
    <div className={classes.wrapper}>
      <span>
        <TextInput
          disabled
          label="ID"
          source="id"
          className={classes.textInput}
        />
        <TextInput
          disabled
          label="Content Reward Id"
          source="contentId"
          className={classes.textInput}
        />
      </span>
      <TextInput
        disabled
        label="UID"
        source="uid"
        className={classes.textInput}
      />
      <span>
        <TextInput
          disabled
          label="Get Point"
          source="points"
          className={classes.textInput}
        />
        <TextInput disabled source="status" className={classes.margin} />
      </span>
      <TextInput
        disabled
        label="Created At"
        source="createdAt"
        className={classes.textInput}
        format={timestampFormatter}
      />
    </div>
  );
};

const ContentReward = (props) => {
  const { id, contentId, freePoints, memberPoints, paidPoints, totalPoints } =
    props;

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Id</TableCell>
          <TableCell>Content Id</TableCell>
          <TableCell>Free Points</TableCell>
          <TableCell>Member Points</TableCell>
          <TableCell>Paid Points</TableCell>
          <TableCell>Total Points</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>{id}</TableCell>
          <TableCell>{contentId}</TableCell>
          <TableCell>{freePoints}</TableCell>
          <TableCell>{memberPoints}</TableCell>
          <TableCell>{paidPoints}</TableCell>
          <TableCell>{totalPoints}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

const PointLogForm = (props) => {
  const { mode, record, ...otherProps } = props;

  return (
    <>
      <SimpleForm
        {...props}
        toolbar={
          <CustomEditToolbar {...otherProps} mode={mode} record={record} />
        }
        redirect={false}
        submitOnEnter={false}
        keepDirtyOnReinitialize={false}
      >
        <PointLogFormContent mode={mode} record={record} />
        <ReferenceField
          lable="Content Rewards"
          source="contentId"
          reference="contentRewards"
        >
          <ContentReward {...props} />
        </ReferenceField>
      </SimpleForm>
    </>
  );
};

const CommonPropTypesForPointLogForm = {
  mode: PropTypes.oneOf(['create', 'update']).isRequired,
  record: PropTypes.shape({}),
};

PointLogForm.propTypes = {
  ...CommonPropTypesForPointLogForm,
};

PointLogFormContent.propTypes = {
  ...CommonPropTypesForPointLogForm,
};

ContentReward.propTypes = {
  ...CommonPropTypesForPointLogForm,
};
export default PointLogForm;
