import React from 'react';
import { Create } from 'react-admin';

import TagActions from './TagActions';
import TagForm from './TagForm';

const TagCreate = (props) => (
  <Create actions={<TagActions basePath="/tags" />} {...props}>
    <TagForm mode="create" />
  </Create>
);

export default TagCreate;
