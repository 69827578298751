import React from 'react';
import {
  TextField,
  ShowController,
  ShowView,
  SimpleShowLayout,
} from 'react-admin';
import { ShortenedDateField } from '../../common';
import { KST_OFFSET_IN_MIN } from '../../constants';

const EventShow = (props) => (
  <>
    <ShowController {...props}>
      {(controllerProps) => (
        <ShowView {...props} {...controllerProps}>
          <SimpleShowLayout>
            <TextField source="eventName" />
            <ShortenedDateField
              label="Start Date"
              source="start.seconds"
              type="timestamp"
              utcOffset={KST_OFFSET_IN_MIN}
            />
            <ShortenedDateField
              label="End Date"
              source="end.seconds"
              type="timestamp"
              utcOffset={KST_OFFSET_IN_MIN}
            />
          </SimpleShowLayout>
        </ShowView>
      )}
    </ShowController>
  </>
);

export default EventShow;
