import React, { useEffect } from 'react';
import { List, Datagrid, TextField, Pagination, EditButton } from 'react-admin';
import { ShortenedDateField } from '../../common';

import SeriesShow from './SeriesShow';

const SeriesList = (props) => {
  useEffect(() => {
    document.title = 'TMS: Seriess';
  }, []);

  return (
    <>
      <List
        pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
        perPage={25}
        sort={{ field: 'date', order: 'DESC' }}
        bulkActionButtons={false}
        exporter={false}
        {...props}
      >
        <Datagrid rowClick="expand" expand={<SeriesShow />}>
          <TextField label="Series" source="title" />
          <ShortenedDateField
            label="Created"
            source="createdAt.seconds"
            type="timestamp"
          />
          <ShortenedDateField
            label="Last modified"
            source="updatedAt.seconds"
            type="timestamp"
          />
          <TextField label="Content count" source="contentCount" />
          <EditButton />
        </Datagrid>
      </List>
    </>
  );
};

export default SeriesList;
