import React from 'react';
import {
  TextField,
  BooleanField,
  Show,
  SimpleShowLayout,
  EditButton,
} from 'react-admin';
import { ShortenedDateField } from '../../common';

const SurveyShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <EditButton {...props} />
      <TextField label="Name" source="name" />
      <BooleanField label="Published" source="published" />
      <ShortenedDateField
        label="Published At"
        source="publishedAt.seconds"
        type="timestamp"
      />
    </SimpleShowLayout>
  </Show>
);

export default SurveyShow;
