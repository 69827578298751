import React, { useCallback, useState } from 'react';
import { ArrayInput } from 'react-admin';
import { Collapse, IconButton } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import _ from 'lodash';
import { useForm } from 'react-final-form';

import { CustomSimpleFormIterator } from '../../../common';
import ArrayInputEmptyComponent from '../../../common/ArrayInputEmptyComponent';
import GrammarInputWithSearch from './GrammarInputWithSearch';

const styles = {
  expandedArrow: {
    transform: 'rotate(180deg)',
  },
  updated: {
    backgroundColor: '#fad0c3',
  },
};

interface Record {
  grammarIds: string[];
}

interface GrammarsFieldProps {
  record?: Record;
  isDirty: boolean;
}

export const defaultIdValue = 'Grammar ID';

const GrammarsField = ({ record, isDirty }: GrammarsFieldProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { grammarIds } = record ?? {};
  const form = useForm();
  const onClickExpand = useCallback(
    () => setIsExpanded((prevExpanded) => !prevExpanded),
    [],
  );

  const onPressCreateFirstGrammar = useCallback(() => {
    form.change('grammarIds', [defaultIdValue]);
  }, [form]);

  const renderJoinedGrammars = useCallback(
    () => (!_.isEmpty(grammarIds) ? _.join(grammarIds, ', ') : 'No grammars'),
    [grammarIds],
  );

  return (
    <>
      <span>
        <IconButton
          style={isExpanded ? styles.expandedArrow : undefined}
          onClick={onClickExpand}
        >
          <ExpandMoreIcon />
        </IconButton>
        {isExpanded ? 'Hide grammars: ' : 'Show grammars: '}
        <span style={isDirty ? styles.updated : undefined}>
          {renderJoinedGrammars()}
        </span>
      </span>
      <Collapse in={isExpanded} timeout="auto">
        <ArrayInput source="grammarIds" record={record}>
          <CustomSimpleFormIterator defaultAddValue={defaultIdValue}>
            <GrammarInputWithSearch />
          </CustomSimpleFormIterator>
        </ArrayInput>
        {_.isEmpty(record?.grammarIds) && (
          <ArrayInputEmptyComponent
            label="grammars"
            onPressCreateButton={onPressCreateFirstGrammar}
          />
        )}
      </Collapse>
    </>
  );
};

export default GrammarsField;
