import React, { useState, useCallback, useEffect } from 'react';

import { useAuthenticated } from 'react-admin';

import { CollapsableCard, LinkCard } from './common';

const styles = {
  textContainer: {
    marginRight: 30,
    marginLeft: 30,
  },
  cardRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 10,
  },
};

export default function DashboardCard() {
  useAuthenticated();
  const [contentMemberExpanded, setContentMemberExpanded] = useState(false);
  const [developerExpanded, setDeveloperExpanded] = useState(false);

  const handleContentMemberExpandClick = useCallback(() => {
    setContentMemberExpanded(!contentMemberExpanded);
  }, [contentMemberExpanded]);

  const handleDeveloperExpandClick = useCallback(() => {
    setDeveloperExpanded(!developerExpanded);
  }, [developerExpanded]);

  useEffect(() => {
    document.title = 'TMS: Dashboard';
  }, []);
  return (
    <>
      <div style={styles.textContainer}>
        <h1>Welcome to the RedKiwi TMS</h1>
        <p>This website is for managing the data related to RedKiwi app.</p>
      </div>
      <div style={styles.cardRowContainer}>
        <LinkCard
          text="Content Workflow Table"
          link="https://docs.google.com/spreadsheets/d/16Qea64fKr6IMeq2MNY4znMQzZexxa3J8cPhA1ZK1m4Q/edit#gid=0"
        />
        {/* <LinkCard
          text="Content QA Feedback Table"
          link="https://docs.google.com/spreadsheets/d/16Qea64fKr6IMeq2MNY4znMQzZexxa3J8cPhA1ZK1m4Q/edit#gid=1116393808"
        /> */}
        <LinkCard
          text="Commentary Workflow Table"
          link="https://docs.google.com/spreadsheets/d/1oV_F4u7PuhotBse8KXVmruiUDUh0IlBcudTSYoxHYO8/edit#gid=1350434213"
        />
        <LinkCard
          text="How to Add a Content"
          link="https://www.notion.so/hayanmind/Content-Creator-How-to-add-content-in-CMS-ac5004fe24b54c07b5566f55d90c9829"
        />
      </div>
      <div style={styles.cardRowContainer}>
        <div>
          <CollapsableCard
            title="Content Creators/Translators"
            content={
              <>
                {/* Issue #5809 */}
                Eunice (박은비) - Content team leader
                <br />
                Mico - Japanese translator
                <br />
                Sam (Bich Do) - Vietnamese translator
                <br />
                Sara - Spanish translator
                <br />
                Kayo - Japanese translator
                <br />
                Kainan - English manager
                <br />
                Manami - Japanese translator
                <br />
                Atsumi - Japanese translator
                <br />
                Damian (이준성) - Korean translator
                <br />
                Brynie - English manager
                <br />
                James (남동건) - Korean translator
                <br />
                Odelia - Taiwanese translator
                <br />
                Alice - Taiwanese translator
                <br />
                (Alumni) Yumi (오유미) - Consultant
                <br />
                (Alumni) Jade (김희) - Korean translator
              </>
            }
            onClick={handleContentMemberExpandClick}
            isExpanded={contentMemberExpanded}
          />
        </div>
        <div>
          <CollapsableCard
            title="TMS Developers"
            content={
              <>
                Yumi (Umi Oh)
                <br />
                Lucy (Younyi Kim) (22.03. ~)
                <br />
                Ella (Soyoung Jeong) (inactive currently)
                <br />
                Jay (Jeungmin Oh) (inactive currently)
                <br />
                Seungduk Seo (21.03. ~ 21.06.)
                <br />
                Yungi Min (19.12. ~ 20.03. / 20.07. ~ 20.08.)
                <br />
                Eunjin Oh (19.07. ~ 19.08.)
                <br />
                Jinhyeok Kim (19.07. ~ 19.09.)
                <br />
                Hyojin Sim (19.12. ~ 20.03.)
              </>
            }
            onClick={handleDeveloperExpandClick}
            isExpanded={developerExpanded}
          />
        </div>
      </div>
    </>
  );
}
