import React, { useEffect } from 'react';
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  EditButton,
  Pagination,
  BooleanField,
  DateField,
  Filter,
  SelectInput,
} from 'react-admin';

import { ScrollToTopButton, ArrayTypeField } from '../../common';
import LemmaShow from './LemmaShow';
import { BooleanChoices, LemmaDifficultyLevels } from '../../constants';

const LemmaFilter = (props) => (
  <Filter {...props}>
    <SelectInput
      label="Difficulty Level"
      source="difficultyLevel"
      choices={LemmaDifficultyLevels}
      allowEmpty={false}
    />
    <SelectInput
      label="Hidden"
      source="hidden"
      choices={BooleanChoices}
      alwayson="false"
      allowEmpty={false}
    />
  </Filter>
);

const LemmaList = (props) => {
  useEffect(() => {
    document.title = 'TMS: Lemmas';
  }, []);

  return (
    <>
      <List
        filters={<LemmaFilter />}
        filterDefaultValues={{ hidden: false }}
        pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
        perPage={25}
        sort={{ field: 'createdAt', order: 'DESC' }}
        bulkActionButtons={false}
        exporter={false}
        {...props}
      >
        <Datagrid rowClick="expand" expand={<LemmaShow />}>
          <TextField label="Lemma" source="lemma" />
          <NumberField label="Frequency" source="frequency" />
          <NumberField label="Difficulty Lv." source="difficultyLevel" />
          <BooleanField label="Hidden" source="hidden" />
          <DateField label="Created" source="createdAt" />
          <DateField label="Last modified" source="updatedAt" />
          <ArrayTypeField label="Related Words" source="relatedWords" />
          <EditButton />
        </Datagrid>
      </List>
      <ScrollToTopButton category={0} />
    </>
  );
};

export default LemmaList;
