import React, { useEffect } from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Pagination,
  BooleanField,
  Filter,
  SelectInput,
} from 'react-admin';

import { ScrollToTopButton, ShortenedDateField } from '../../common';
import { BooleanChoices } from '../../constants';
import SurveyAnswerOptionShow from './SurveyAnswerOptionShow';

const SurveyAnswerQuestionFilter = (props) => (
  <Filter {...props}>
    <SelectInput
      label="Published"
      source="published"
      choices={BooleanChoices}
      alwayson="true"
      allowEmpty={false}
    />
  </Filter>
);

const SurveyAnswerOptionList = (props) => {
  useEffect(() => {
    document.title = 'TMS: Surv Options';
  }, []);

  return (
    <>
      <List
        filters={<SurveyAnswerQuestionFilter />}
        filterDefaultValues={{ published: true }}
        pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
        perPage={25}
        bulkActionButtons={false}
        exporter={false}
        title="Survey Answer Options"
        {...props}
      >
        <Datagrid rowClick="expand" expand={<SurveyAnswerOptionShow />}>
          <TextField label="Answer" source="answer" />
          <TextField label="Description" source="description" />
          <BooleanField label="Published" source="published" />
          <ShortenedDateField
            label="Created"
            source="createdAt.seconds"
            type="timestamp"
          />
          <ShortenedDateField
            label="Last modified"
            source="updatedAt.seconds"
            type="timestamp"
          />
          <EditButton />
        </Datagrid>
      </List>
      <ScrollToTopButton category={0} />
    </>
  );
};

export default SurveyAnswerOptionList;
