import React from 'react';
import { Create } from 'react-admin';

import ContentForm from './ContentFormWithFragmentForms';

const ContentCreate = (props) => (
  <Create {...props}>
    <ContentForm mode="create" />
  </Create>
);

export default ContentCreate;
