import React, { useCallback } from 'react';
import { Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  hitVariety: {
    width: '100%',
    borderBottom: '1px solid #ccc',
    marginBottom: '0.5rem',
    paddingBottom: '0.5rem',
    paddingTop: '0.5rem',
    fontSize: '1.05rem',
    color: '#33363d',
  },
});

interface ResultList {
  id: string;
  href: string;
}

interface ResultListProps<T> {
  title: string;
  results: T[];
  renderItem: (result: T) => JSX.Element;
}

const ResultsList = <T extends ResultList>(
  props: ResultListProps<T>,
): JSX.Element => {
  const classes = useStyles();
  const { title, results = [], renderItem } = props;

  const handleMouseEnter = useCallback((e) => {
    const resultList = document.getElementsByClassName('ais-Hits-item');
    const selectedItem = document.getElementsByClassName('selected');
    if (resultList.length !== 0 && selectedItem.length !== 0) {
      selectedItem[0].classList.remove('selected');
    }
    let find = e.target;
    while (!find.className.includes('ais-Hits-item')) {
      if (find != null) {
        find = find.parentNode;
      }
    }
    return find.classList.add('selected');
  }, []);

  const handleMouseLeave = useCallback((e) => {
    let find = e.target;
    while (!find.className.includes('ais-Hits-item')) {
      if (find != null) {
        find = find.parentNode;
      }
    }
    return find.classList.remove('selected');
  }, []);

  return (
    <>
      {results == null || results.length === 0 ? (
        <></>
      ) : (
        <div className={classes.hitVariety}>{title}</div>
      )}
      {results.map((result, index) => (
        <div
          key={result.id}
          className={index === 0 ? 'ais-Hits-item selected' : 'ais-Hits-item'}
          id={result.id}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Link
            underline="none"
            variant="inherit"
            color="inherit"
            target="_blank"
            href={result.href}
          >
            {renderItem(result)}
          </Link>
        </div>
      ))}
    </>
  );
};

export default ResultsList;
