import ApolloClientHelper from './ApolloClientHelper';
import Queries from './graphqlQueries';

export default {
  getUserByEmail: async (email) => {
    const apolloClient = ApolloClientHelper.getInstance();

    const { data } = await apolloClient.query({
      query: Queries.GET_USER_BY_EMAIL,
      variables: { input: email },
    });
    return data;
  },
};
