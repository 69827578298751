import React, { useReducer, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  List,
  Filter,
  Datagrid,
  TextField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  EditButton,
  Pagination,
} from 'react-admin';
import { Divider, Tabs, Tab } from '@material-ui/core';
import _ from 'lodash';

import { ContentLanguages } from '../../constants';
import { CustomBooleanField, ScrollToTopButton } from '../../common';
import FragmentShow from './FragmentShow';

const FragmentFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput
      label="Content title"
      source="contentKey"
      reference="contents"
      alwaysOn
      allowEmpty
    >
      <SelectInput optionText="title" />
    </ReferenceInput>
  </Filter>
);

function reducer(state, action) {
  return {
    ...state,
    [action.languageCode]: action.languageIds,
  };
}

const TabbedDatagrid = (props) => {
  const [state, dispatch] = useReducer(reducer, {
    en: [],
    ko: [],
    ja: [],
    zh: [],
    vi: [],
  });

  const {
    filterValues = { languageCode: 'en' },
    ids = [],
    setFilters = () => {},
  } = props;
  const { languageCode } = filterValues;
  const currentIds = state[filterValues.languageCode];

  useEffect(() => {
    if (!_.isEqual(ids, currentIds)) {
      dispatch({ languageCode, languageIds: ids });
    }
  }, [ids, languageCode, currentIds]);

  const onHandleChange = useCallback(
    (event, value) => {
      setFilters({ ...filterValues, languageCode: value });
    },
    [filterValues, setFilters],
  );

  return (
    <>
      <Tabs
        centered
        value={filterValues.languageCode}
        indicatorColor="primary"
        onChange={onHandleChange}
      >
        {ContentLanguages.map((lang) => (
          <Tab
            key={`fragment-tab-${lang.id}`}
            label={lang.name}
            value={lang.id}
          />
        ))}
      </Tabs>
      <Divider />
      <div>
        {ContentLanguages.map(
          (lang) =>
            filterValues.languageCode === lang.id && (
              <Datagrid
                {...props}
                ids={state[lang.id]}
                key={`fragment-datagrid-${filterValues.languageCode}-${lang.id}`}
                rowClick="expand"
                expand={<FragmentShow />}
              >
                <TextField source="text" />
                <ReferenceField source="contentKey" reference="contents">
                  <TextField source="title" />
                </ReferenceField>
                <CustomBooleanField label="For Study" source="keepable" />
                <TextField source="languageCode" />
                <CustomBooleanField
                  label="Notification Enabled"
                  source="notificationEnabled"
                />
                <TextField source="startTime" />
                <TextField source="endTime" />
                <EditButton />
              </Datagrid>
            ),
        )}
      </div>
    </>
  );
};

const FragmentList = (props) => {
  useEffect(() => {
    document.title = 'TMS: Fragment';
  }, []);

  return (
    <>
      <List
        filters={<FragmentFilter />}
        filterDefaultValues={{ languageCode: 'en' }}
        pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
        bulkActionButtons={false}
        exporter={false}
        {...props}
      >
        <TabbedDatagrid />
      </List>
      <ScrollToTopButton category={0} />
    </>
  );
};

TabbedDatagrid.propTypes = {
  // eslint-disable-next-line react/require-default-props
  ids: PropTypes.arrayOf(PropTypes.string),
  // eslint-disable-next-line react/require-default-props
  filterValues: PropTypes.shape({
    languageCode: PropTypes.string,
  }),
  // eslint-disable-next-line react/require-default-props
  setFilters: PropTypes.func,
};

export default FragmentList;
