/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
// This code is from react-admin source code.
// Hyojin made custom component to make ADD button works for each row.
// https://github.com/marmelab/react-admin/blob/master/packages/ra-ui-materialui/src/form/SimpleFormIterator.js

import React, {
  Children,
  cloneElement,
  Component,
  isValidElement,
} from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import get from 'lodash/get';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withStyles, createStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/RemoveCircleOutline';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { translate, ValidationError } from 'ra-core';
import classNames from 'classnames';

import FormInput from './inputs/CustomFormInput';

const styles = (theme) =>
  createStyles({
    root: {
      padding: 0,
      marginBottom: 0,
      '& > li:last-child': {
        borderBottom: 'none',
      },
    },
    line: {
      display: 'flex',
      listStyleType: 'none',
      borderBottom: `solid 1px ${theme.palette.divider}`,
      [theme.breakpoints.down('xs')]: { display: 'block' },
      '&.fade-enter': {
        opacity: 0.01,
        transform: 'translateX(100vw)',
      },
      '&.fade-enter-active': {
        opacity: 1,
        transform: 'translateX(0)',
        transition: 'all 500ms ease-in',
      },
      '&.fade-exit': {
        opacity: 1,
        transform: 'translateX(0)',
      },
      '&.fade-exit-active': {
        opacity: 0.01,
        transform: 'translateX(100vw)',
        transition: 'all 500ms ease-in',
      },
    },
    index: {
      width: '3em',
      paddingTop: '1em',
      [theme.breakpoints.down('sm')]: { display: 'none' },
    },
    form: { flex: 2 },
    action: {
      paddingTop: '0.5em',
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
    grid: {
      display: 'grid',
      marginRight: '20px',
      gridTemplateColumns: 'repeat(auto-fill,minmax(300px, 500px))',
    },
  });

export class CustomSimpleFormIterator extends Component {
  constructor(props) {
    super(props);
    // we need a unique id for each field for a proper enter/exit animation
    // but redux-form doesn't provide one (cf https://github.com/erikras/redux-form/issues/2735)
    // so we keep an internal map between the field position and an auto-increment id
    // eslint-disable-next-line no-nested-ternary
    this.nextId = props.fields.length
      ? props.fields.length
      : props.defaultValue
      ? props.defaultValue.length
      : 0;

    // We check whether we have a defaultValue (which must be an array) before checking
    // the fields prop which will always be empty for a new record.
    // Without it, our ids wouldn't match the default value and we would get key warnings
    // on the CssTransition element inside our render method
    this.ids = this.nextId > 0 ? Array.from(Array(this.nextId).keys()) : [];
  }

  removeField = (index) => () => {
    const { fields } = this.props;
    this.ids.splice(index, 1);
    fields.remove(index);
  };

  // Returns a boolean to indicate whether to disable the remove button for certain fields.
  // If disableRemove is a function, then call the function with the current record to
  // determining if the button should be disabled. Otherwise, use a boolean property that
  // enables or disables the button for all of the fields.
  disableRemoveField = (record, disableRemove) => {
    if (typeof disableRemove === 'boolean') {
      return disableRemove;
    }
    return disableRemove && disableRemove(record);
  };

  addField = (index, defaultAddValue) => () => {
    const { fields } = this.props;
    this.ids.splice(index + 1, 0, this.nextId);
    this.nextId += 1;
    fields.insert(index + 1, defaultAddValue);
  };

  render() {
    const {
      basePath,
      classes = {},
      children,
      defaultAddValue,
      fields,
      meta: { error, submitFailed },
      record,
      resource,
      source,
      // translate,
      disableAdd,
      disableRemove,
      variant,
      margin,
      TransitionProps,
    } = this.props;

    const records = get(record, source);

    return fields ? (
      <ul className={classes.root}>
        {submitFailed && typeof error !== 'object' && error && (
          <FormHelperText error>
            <ValidationError error={error} />
          </FormHelperText>
        )}
        <div className={classes.grid}>
          <TransitionGroup component={null}>
            {fields.map((member, index) => (
              <CSSTransition
                key={this.ids[index]}
                timeout={500}
                classNames="fade"
                {...TransitionProps}
              >
                <li className={classes.line}>
                  <Typography variant="body1" className={classes.index}>
                    {index + 1}
                  </Typography>
                  <section className={classes.form}>
                    {Children.map(children, (input, index2) =>
                      isValidElement(input) ? (
                        <FormInput
                          basePath={input.props.basePath || basePath}
                          input={cloneElement(input, {
                            source: input.props.source
                              ? `${member}.${input.props.source}`
                              : member,
                            index: input.props.source ? undefined : index2,
                            label:
                              typeof input.props.label === 'undefined'
                                ? input.props.source
                                  ? `resources.${resource}.fields.${input.props.source}`
                                  : undefined
                                : input.props.label,
                          })}
                          record={(records && records[index]) || {}}
                          resource={resource}
                          variant={variant}
                          margin={margin}
                        />
                      ) : null,
                    )}
                  </section>
                  {!this.disableRemoveField(
                    (records && records[index]) || {},
                    disableRemove,
                  ) && (
                    <span className={classes.action}>
                      <Button
                        className={classNames(
                          'button-remove',
                          `button-remove-${source}-${index}`,
                        )}
                        size="small"
                        onClick={this.removeField(index)}
                      >
                        <CloseIcon className={classes.leftIcon} />
                        Remove
                      </Button>
                    </span>
                  )}
                  {!disableAdd && (
                    <span className={classes.action}>
                      <Button
                        className={classNames(
                          'button-add',
                          `button-add-${source}`,
                        )}
                        size="small"
                        onClick={this.addField(index, defaultAddValue)}
                      >
                        <AddIcon className={classes.leftIcon} />
                        Add
                      </Button>
                    </span>
                  )}
                </li>
              </CSSTransition>
            ))}
          </TransitionGroup>
        </div>
      </ul>
    ) : null;
  }
}

CustomSimpleFormIterator.defaultProps = {
  disableAdd: false,
  disableRemove: false,
};

CustomSimpleFormIterator.propTypes = {
  defaultValue: PropTypes.shape(),
  defaultAddValue: PropTypes.shape({}),
  basePath: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.shape({}),
  className: PropTypes.string,
  fields: PropTypes.shape([]),
  meta: PropTypes.shape({
    error: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    submitFailed: PropTypes.bool,
  }),
  record: PropTypes.shape({}),
  source: PropTypes.string,
  resource: PropTypes.string,
  // translate: PropTypes.func,
  disableAdd: PropTypes.bool,
  disableRemove: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  TransitionProps: PropTypes.shape({}),
  variant: PropTypes.string,
  margin: PropTypes.string,
};

export default compose(translate, withStyles(styles))(CustomSimpleFormIterator);
