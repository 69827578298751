// import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

const ShortenedTextField = ({ record = {}, source, placeholder = '-' }) => {
  // To prevent slice error, I put default value to ''
  const originalValue = _.get(record, source, null);

  if (originalValue == null) {
    return placeholder;
  }

  return originalValue != null ? originalValue.slice(0, 5) : null;
};

// Workaround: To remove eslint warning
ShortenedTextField.propTypes = {
  // eslint-disable-next-line react/require-default-props
  record: PropTypes.shape({}),
  source: PropTypes.string.isRequired,
  // eslint-disable-next-line react/require-default-props
  placeholder: PropTypes.string,
};

export default ShortenedTextField;
