import QuestionIcon from '@material-ui/icons/Help';

import QuestionList from './QuestionList';
import QuestionCreate from './QuestionCreate';
import QuestionEdit from './QuestionEdit';

export default {
  list: QuestionList,
  create: QuestionCreate,
  edit: QuestionEdit,
  icon: QuestionIcon,
};
