import _ from 'lodash';
import moment from 'moment';
import {
  JapaneseBigNumberToNormalNumber,
  RegexOnlyNormalNumbers,
  RegexOnlyBigNumbers,
} from '../constants';

const JSUtility = {
  convertUnixSecondToString(
    unixTimestampSecond,
    { utcOffset, format = 'YYYY-MM-DDTHH:MM:SS' } = {},
  ) {
    if (unixTimestampSecond == null) {
      return null;
    }
    const dateTimeMoment = moment.unix(unixTimestampSecond);
    return utcOffset != null
      ? dateTimeMoment.utcOffset(utcOffset).format(format)
      : dateTimeMoment.format(format);
  },
  convertUnixMillisecondToString(unixTimestampMillisecond) {
    return moment(unixTimestampMillisecond).format('YYYY-MM-DDTHH:MM:SS');
  },
  convertSecondsToTime(stringInput) {
    return Math.floor(stringInput / 1000 / 60) === 0
      ? `${(stringInput / 1000) % 60}s`
      : `${Math.floor(stringInput / 1000 / 60)}m ${(stringInput / 1000) % 60}s`; // 00m 00s
  },
  fragmentCountWithSize(fragmentCount) {
    let fragmentCountRangeText = null;
    if (fragmentCount != null) {
      if (fragmentCount > 0 && fragmentCount <= 16) {
        fragmentCountRangeText = 'S';
      } else if (fragmentCount > 16 && fragmentCount <= 26) {
        fragmentCountRangeText = 'M';
      } else if (fragmentCount > 26) {
        fragmentCountRangeText = 'L';
      }
    }
    return `${fragmentCount}(${fragmentCountRangeText})`; // 0(S)
  },
  convertTimestampToSeconds(timestamp) {
    // 00:01:08,316
    if (timestamp == null) {
      return null;
    }

    const regExp = /^([0-9]{2}:){2}[0-9]{2},[0-9]{3}$/;
    if (!regExp.test(timestamp)) {
      // eslint-disable-next-line no-console
      console.log("Timestamp should be in a form of '00:00:00,000'");
      return null; // ToDo: Change this to report error (not returning null)
    }

    const stringWithoutMark = timestamp.replace(/:/gi, '').replace(/,/gi, ''); // 000108316
    const stringWithoutMs = stringWithoutMark.slice(0, 6); // 000108

    const hoursInSeconds = parseInt(stringWithoutMs.slice(0, 2), 10) * 60 * 60; //  00 * 60 * 60
    const minutesInSeconds = parseInt(stringWithoutMs.slice(2, 4), 10) * 60; // 01 * 60
    const seconds = parseInt(stringWithoutMs.slice(4, 6), 10); // 08

    // (00 * 60 * 60) + (01 * 60) + (08)
    const secondsOfTimestamp = hoursInSeconds + minutesInSeconds + seconds;

    return secondsOfTimestamp; // 68
  },
  convertTimestampToDurationInMs(startTime, endTime, originalDurationInSec) {
    // Migration to TMS was done
    if (originalDurationInSec == null) {
      return null;
    }

    const convertedStartTimeInSec =
      JSUtility.convertTimestampToSeconds(startTime);
    const convertedEndTimeInSec = JSUtility.convertTimestampToSeconds(endTime);

    const isDefaultStartTime =
      convertedStartTimeInSec == null || convertedStartTimeInSec === 0;
    const isDefaultEndTime =
      convertedEndTimeInSec == null || convertedEndTimeInSec === 0;

    if (isDefaultStartTime && isDefaultEndTime) {
      return originalDurationInSec * 1000;
    } else if (!isDefaultStartTime && isDefaultEndTime) {
      return (originalDurationInSec - convertedStartTimeInSec) * 1000;
    } else if (isDefaultStartTime && !isDefaultEndTime) {
      return convertedEndTimeInSec * 1000;
    } else if (!isDefaultStartTime && !isDefaultEndTime) {
      return (convertedEndTimeInSec - convertedStartTimeInSec) * 1000;
    }

    return null;
  },
  convertMillisecondToTimestamp(millisecond) {
    const hr = (Math.floor(millisecond / (1000 * 60 * 60)) % 24).toString();
    const min = (Math.floor(millisecond / (1000 * 60)) % 60).toString();
    const sec = (Math.floor(millisecond / 1000) % 60).toString();
    return `${hr.padStart(2, '0')}:${min.padStart(2, '0')}:${sec.padStart(
      2,
      '0',
    )},000`;
  },
  async calculateDurationInMs(youTubeVideoId, startTime, endTime) {
    const youTubeApiUrl = `https://www.googleapis.com/youtube/v3/videos?part=contentDetails&id=${youTubeVideoId}&key=AIzaSyAA35Wfce-B_ma9T0qPpwKiIWOtVAluEn8`;
    try {
      const response = await fetch(youTubeApiUrl);
      const responseJson = await response.json();
      // PT1H40M20S: example value for durationFromYouTubeApi
      const durationFromYouTubeApi = _.get(
        responseJson,
        'items[0].contentDetails.duration',
        '',
      );

      if (durationFromYouTubeApi === '') {
        throw new Error('There is no duration data');
      }

      const regExpHour = /([0-9]*)H/g;
      const arrHour = regExpHour.exec(durationFromYouTubeApi) || [];
      const [, hour = '0'] = arrHour; // destructurting

      const regExpMin = /([0-9]*)M/g;
      const arrMin = regExpMin.exec(durationFromYouTubeApi) || [];
      const [, min = '0'] = arrMin;

      const regExpSec = /([0-9]*)S/g;
      const arrSec = regExpSec.exec(durationFromYouTubeApi) || [];
      const [, sec = '0'] = arrSec;

      // hour, min, sec are string. By multiplying the number, these value can become number.
      const convertedDurationFromYouTubeApiInSec =
        hour * 3600 + min * 60 + sec * 1;

      const calculatedDurationInMs = JSUtility.convertTimestampToDurationInMs(
        startTime,
        endTime,
        convertedDurationFromYouTubeApiInSec,
      );

      const durationInMs = calculatedDurationInMs;
      return durationInMs;
    } catch (error) {
      console.log(error); // eslint-disable-line no-console
      return 0;
    }
  },
  formatTimestamp(stringInput) {
    const onlyNumbers = stringInput.replace(/[^0-9]/g, '');

    const ms = (onlyNumbers % 1000).toString();
    const sec = (Math.floor(onlyNumbers / 1000) % 100).toString();
    const min = (Math.floor(onlyNumbers / 100000) % 100).toString();
    const hr = Math.floor(onlyNumbers / 10000000).toString();

    return `${hr.padStart(2, '0')}:${min.padStart(2, '0')}:${sec.padStart(
      2,
      '0',
    )},${ms.padStart(3, '0')}`; // 00:00:00,000
  },
  convertStringToOnlyNumberString(value) {
    if (typeof value !== 'string') {
      return '';
    }

    const onlyNumberArray = [];
    value.split('').forEach((character) => {
      if (RegexOnlyNormalNumbers.test(character)) {
        onlyNumberArray.push(character);
      } else if (RegexOnlyBigNumbers.test(character)) {
        onlyNumberArray.push(JapaneseBigNumberToNormalNumber[character]);
      }
    });
    return onlyNumberArray.join('');
  },
  extractLeafNodePathList(obj) {
    // object = { a: { b: 123, c: { d: 45, e: 67 } } }
    if (!_.isPlainObject(obj)) {
      return null;
    }

    const keys = Object.keys(obj);
    const pathList = keys.map((key) => {
      const value = obj[key];
      if (_.isPlainObject(value)) {
        return this.extractLeafNodePathList(value).map(
          (path) => `${key}.${path}`,
        );
      }
      return [key];
    });
    return _.flatten(pathList);
  },
  removeEmptyObject(obj) {
    // { cms: {}, _system: { diff: {} }, subtitle: '' }
    const keys = Object.keys(obj); // cms, _system, subtitle
    const removedObj = keys.reduce((acc, key) => {
      const childProp = acc[key];
      if (_.isPlainObject(childProp)) {
        const result = this.removeEmptyObject(childProp); // {}, { a: 1 }
        if (_.isEqual(result, {})) {
          _.unset(acc, key);
        }
      }
      return acc;
    }, _.cloneDeep(obj));
    return removedObj;
  },
  removeUnchangedProperties(newObj, oldObj) {
    const pathListOfOldObj = this.extractLeafNodePathList(oldObj);
    const newObjWithoutUnchangedProperties = pathListOfOldObj.reduce(
      (acc, path) => {
        if (_.isEqual(_.get(oldObj, path), _.get(newObj, path))) {
          _.unset(acc, path);
        }
        return acc;
      },
      _.cloneDeep(newObj),
    ); // Because _.unset() mutates the object, use _.cloneDeep()

    return newObjWithoutUnchangedProperties;
  },
  copyToClipboard(str) {
    // https://www.w3resource.com/javascript-exercises/fundamental/javascript-fundamental-exercise-2.php
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);

    const selected =
      document.getSelection().rangeCount > 0
        ? document.getSelection().getRangeAt(0)
        : false;
    el.select();

    document.execCommand('copy');
    document.body.removeChild(el);

    if (selected) {
      document.getSelection().removeAllRanges();
    }
  },
  removePunctuation(text = '') {
    const regexToMatchPunctuation =
      /[-.,（）<>()&$%#![\]{}"“'?，？！。「」『』、《》~:;\s]/g; // eslint-disable-line no-useless-escape
    const punctuationFreeText = text.replace(regexToMatchPunctuation, '');
    return punctuationFreeText.toLowerCase();
  },
  removeDuplicateSymbol(text = '', symbol) {
    const linesWithoutSymbol = text.split(symbol);
    const linesWithSymbol = [
      linesWithoutSymbol[0],
      symbol,
      ...linesWithoutSymbol.slice(1),
    ];
    const modifiedText = linesWithSymbol.join('');
    return modifiedText;
  },
  removeDuplicateSpace(text = '') {
    return text.replace(/ {2,}/g, ' ');
  },
  sort(list) {
    // Default sort function given by javascript is string sort.
    // This sort function is comparison sort.
    const sortedList = list.sort((a, b) => {
      if (a > b) {
        return 1;
      } else if (a < b) {
        return -1;
      }
      return 0;
    });

    return sortedList;
  },
  createData(columnArray, dataArray) {
    return dataArray.reduce((acc, value, index) => {
      acc[columnArray[index]] = value;
      return acc;
    }, {});
  },
  // normalizeWord and nlp functions are from redkiwi-server/src/JSUtility.js.
  normalizeWord(text) {
    const punctuationRegex =
      /[-.,（）<>()&$%#![\]{}"“'?，？！。「」『』、《》~:;\s]/;
    const regexToMatchPuctuationAtTheBoundaryOfText = new RegExp(
      `^${punctuationRegex.source}+|${punctuationRegex.source}+$`,
      'g',
    );
    const punctuationFreeText = text.replace(
      regexToMatchPuctuationAtTheBoundaryOfText,
      '',
    );
    return punctuationFreeText.toLowerCase();
  },
  nlp(fragmentText, langCode = 'en') {
    const fragmentTextSplitWithNewLine = fragmentText.split('\n');
    const words = fragmentTextSplitWithNewLine.reduce(
      (accFragment, fragmentSplit, lineIndex) => {
        const isTheLastLine =
          lineIndex + 1 === fragmentTextSplitWithNewLine.length;

        // It matches all whitespaces(' ', '\n')
        const regexToMatchWhiteSpaceWithoutDashSymbol = /\s+/g;
        const wordTextSplitWithWhiteSpace = fragmentSplit.split(
          regexToMatchWhiteSpaceWithoutDashSymbol,
        );

        const singleAllowedSymbols = ['-']; // symbols could be added if needed

        const newWordTextSplit = wordTextSplitWithWhiteSpace.reduce(
          (accWordText, wordText, wordIndex) => {
            if (singleAllowedSymbols.includes(wordText)) {
              return accWordText;
            }

            const prevWordText = wordTextSplitWithWhiteSpace[wordIndex - 1];
            const shouldIncludePrevWordText =
              singleAllowedSymbols.includes(prevWordText);
            const isTheLastWordOfLine =
              wordIndex + 1 === wordTextSplitWithWhiteSpace.length;
            return [
              ...accWordText,
              {
                textBefore: shouldIncludePrevWordText ? `${prevWordText} ` : '',
                text: wordText,
                textAfter: isTheLastWordOfLine && !isTheLastLine ? '\n' : '',
                normal: this.normalizeWord(wordText),
              },
            ];
          },
          [],
        );

        return [...accFragment, ...newWordTextSplit];
      },
      [
        /* empty */
      ],
    );

    // Compromise can't normalize for Japanese or Chinese punctuation(e.g., "，", "。")
    const normalizedWordsForOtherLanguage = words.reduce(
      (acc, word) => {
        const { text, normal } = word;

        if (langCode === 'ja') {
          const textWithFuriganaSymbol = text
            .replace(/[＜]/g, '（')
            .replace(/[＞]/g, '）');
          const normalWithFuriganaSymbol = normal
            .replace(/[＜]/g, '（')
            .replace(/[＞]/g, '）');

          return [
            ...acc,
            {
              ...word,
              text: textWithFuriganaSymbol,
              normal: normalWithFuriganaSymbol,
            },
          ];
        }

        return [...acc, word];
      },
      [
        /* empty */
      ],
    );

    return normalizedWordsForOtherLanguage;
  },
  hasValidTimestamp(
    comparingDataArray,
    targetData,
    currentIndex,
    { isAllowOverlap = false } = {},
  ) {
    const INFINITY_STRING = '99:99:99,999';
    const comparableDataArray =
      currentIndex == null
        ? comparingDataArray
        : comparingDataArray
            .slice(0, currentIndex)
            .concat(comparingDataArray.slice(currentIndex + 1));

    const emptyTimestampArray = comparableDataArray.reduce(
      (acc, data) => {
        const {
          startTime: startTimeOfData = '00:00:00,000',
          endTime: endTimeOfData = JSUtility.convertMillisecondToTimestamp(
            data.durationInMs,
          ),
        } = data;
        const lastElementOfAcc = acc[acc.length - 1];
        lastElementOfAcc.endTime = startTimeOfData;

        return [...acc, { startTime: endTimeOfData, endTime: INFINITY_STRING }];
      },
      [{ startTime: '00:00:00,000', endTime: INFINITY_STRING }],
    );

    const isValidTimestamp = emptyTimestampArray.some((timestamp) => {
      const targetStartTime = isAllowOverlap
        ? // Allow 3s overlap
          JSUtility.convertMillisecondToTimestamp(
            JSUtility.convertTimestampToSeconds(targetData.startTime) * 1000 +
              3000,
          )
        : targetData.startTime;

      const targetEndTime = isAllowOverlap
        ? // Allow 3s overlap
          JSUtility.convertMillisecondToTimestamp(
            JSUtility.convertTimestampToSeconds(targetData.endTime) * 1000 -
              3000,
          )
        : targetData.endTime;

      const isValidStartTime = timestamp.startTime <= targetStartTime;
      const isValidEndTime = targetEndTime <= timestamp.endTime;

      return isValidStartTime && isValidEndTime;
    });

    return isValidTimestamp;
  },
  // ToDo: #7018
  // textInputParser(value) {
  //   const removeDuplicateWhiteSpace = _.replace(value, '  ', ' ');
  //   return removeDuplicateWhiteSpace;
  // },
  getRecentStringDate(stringDateA, stringDateB) {
    const recentStringDate =
      stringDateA != null && stringDateB != null
        ? _.maxBy([stringDateA, stringDateB], (o) => new Date(o))
        : // returns parameter that is not null
          // or returns null if both parameters are null
          stringDateA || stringDateB;

    return recentStringDate;
  },
  getDifficultyLevelBasedOnFrequency(frequency) {
    if (frequency < 115) {
      return 6;
    }
    if (frequency < 725) {
      return 5;
    }
    if (frequency < 6938) {
      return 4;
    }
    if (frequency < 21273) {
      return 3;
    }
    if (frequency < 176515) {
      return 2;
    }
    return 1;
  },
  hasValidQuote(value) {
    return value
      .replace(/[\u2018\u2019\u201A\u201B\u2032\u2035]/g, "'")
      .replace(/[\u201C\u201D\u201E\u201F\u2033\u2036]/g, '"');
  },
};

export default JSUtility;
