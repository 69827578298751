import React, { useEffect } from 'react';
import {
  List,
  Filter,
  Datagrid,
  TextField,
  SelectInput,
  EditButton,
  Pagination,
} from 'react-admin';

import { ContentLanguages, BooleanChoices } from '../../constants';
import {
  ArrayTypeField,
  ObjectKeyTypeField,
  ScrollToTopButton,
  FirebaseImageField,
} from '../../common';
import { TagCategories } from './tagsConstants';
import TagShow from './TagShow';

const styles = {
  selectInput: {
    width: 230,
  },
};

const TagFilter = (props) => (
  <Filter {...props}>
    <SelectInput
      // `learningLanguageCodes` is array, so it searches record that contains selected value.
      label="Supported Language Code"
      source="learningLanguageCodes"
      choices={ContentLanguages}
      alwaysOn
      allowEmpty={false}
      style={styles.selectInput}
    />
    <SelectInput
      label="Hidden as Content Tag"
      source="_dev"
      choices={BooleanChoices}
      alwaysOn
      emptyText="All"
      initialValue="All"
      style={styles.selectInput}
    />
    <SelectInput
      style={styles.selectInput}
      label="Is Word Map Category"
      source="isWordMapCategory"
      choices={BooleanChoices}
      emptyText="All"
      alwaysOn
      initialValue
    />
    <SelectInput label="Category" source="category" choices={TagCategories} />
  </Filter>
);

const TagList = (props) => {
  useEffect(() => {
    document.title = 'TMS: Tags';
  }, []);

  return (
    <>
      <List
        filters={<TagFilter />}
        filterDefaultValues={{
          learningLanguageCodes: 'en',
          isWordMapCategory: true,
        }}
        pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
        perPage={25}
        sort={{ field: 'languageCodeToName.en', order: 'ASC' }}
        bulkActionButtons={false}
        exporter={false}
        {...props}
      >
        <Datagrid rowClick="expand" expand={<TagShow />}>
          <TextField source="category" />
          <FirebaseImageField label="Image" />
          <TextField label="Eng. name" source="languageCodeToName.en" />
          <ArrayTypeField
            label="Supported lang."
            source="learningLanguageCodes"
          />
          <ObjectKeyTypeField
            label="Name Transl."
            source="languageCodeToName"
          />
          <ObjectKeyTypeField
            label="Desc. Transl."
            source="languageCodeToDescription"
          />
          <EditButton />
        </Datagrid>
      </List>
      <ScrollToTopButton category={0} />
    </>
  );
};

export default TagList;
