import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SimpleForm, TextInput, SelectInput } from 'react-admin';
import { useForm } from 'react-final-form';
import { CustomEditToolbar } from '../../common';
import { L2eRedemptionStatus } from '../../constants';

const styles = {
  verticalView: {
    display: 'flex',
    flexDirection: 'column',
  },
  textInput: {
    width: 350,
  },
  selectInput: {
    width: 350,
  },
  updated: {
    backgroundColor: '#fad0c3',
  },
};

const L2eRedemptionHistoryForm = (props) => {
  const { mode, record, ...otherProps } = props;

  return (
    <>
      <SimpleForm
        {...props}
        toolbar={
          <CustomEditToolbar
            {...otherProps}
            mode={mode}
            record={record}
            saveButtonLabel="Save redemption request"
          />
        }
        redirect={false}
        submitOnEnter={false}
        keepDirtyOnReinitialize={false}
      >
        <L2eRedemptionHistoryFormContent mode={mode} record={record} />
      </SimpleForm>
    </>
  );
};

const L2eRedemptionHistoryFormContent = () => {
  const [dirtyFields, setDirtyFields] = useState({});
  const [formData, setFormData] = useState({});

  const form = useForm();

  useEffect(() => {
    const unsubscribe = form.subscribe(
      (s) => {
        setDirtyFields(s.dirtyFields);
        setFormData(s.values);
      },
      { dirtyFields: true, values: true },
    );
    return () => {
      unsubscribe();
    };
  }, [form]);

  return (
    <div style={styles.verticalView}>
      <TextInput
        disabled
        label="Request Id"
        source="id"
        style={styles.textInput}
        record={formData}
      />
      <TextInput
        label="User Name"
        source="userName"
        style={{ width: 120 }}
        record={formData}
        disabled
      />
      <TextInput
        label="User Id"
        source="userId"
        style={{ width: 360 }}
        record={formData}
        disabled
      />
      <TextInput
        label="Bank Name"
        source="bankName"
        style={{ width: 120 }}
        record={formData}
        disabled
      />
      <TextInput
        label="Account Number"
        source="accountNumber"
        style={{ width: 360 }}
        record={formData}
        disabled
      />
      <TextInput
        label="Redeemed Points"
        source="redeemedPoints"
        style={{ width: 360 }}
        record={formData}
        disabled
      />
      <TextInput
        label="Phone Number"
        source="phoneNumber"
        style={{ width: 360 }}
        record={formData}
        disabled
      />
      <SelectInput
        label="Request Status"
        source="status"
        choices={L2eRedemptionStatus}
        allowEmpty={false}
        defaultValue="requested"
        record={formData}
        style={{
          ...styles.selectInput,
          ...(dirtyFields.status ? styles.updated : null),
        }}
      />
    </div>
  );
};

const CommonPropTypesForL2eRedemptionHistoryFormAndContent = {
  mode: PropTypes.oneOf(['create', 'update']).isRequired,
  record: PropTypes.shape({}),
};

L2eRedemptionHistoryForm.propTypes = {
  ...CommonPropTypesForL2eRedemptionHistoryFormAndContent,
};

L2eRedemptionHistoryFormContent.propTypes = {
  ...CommonPropTypesForL2eRedemptionHistoryFormAndContent,
};

export default L2eRedemptionHistoryForm;
