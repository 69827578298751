import React, { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { Button, Tooltip, TextField } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';

const styles = {
  searchButton: {
    marginTop: 5,
    marginLeft: 5,
  },
};

interface SearchableTextInputProps {
  refine: (query: string) => void;
  label: string;
  initialValue?: string;
  onChange?: ({ value }) => void;
}

const SearchableTextInput = ({
  refine,
  initialValue = '',
  label,
  onChange,
}: SearchableTextInputProps): JSX.Element => {
  const [wordQuery, setWordQuery] = useState(initialValue ?? '');
  const updateWordQuery = useMemo(
    () =>
      _.debounce((rawWord) => {
        setWordQuery(rawWord);
      }, 500),
    [],
  );

  const handleChange = useCallback(
    ({ target: { value } }) => {
      updateWordQuery(value);
      if (onChange != null) {
        onChange({ value });
      }
    },
    [updateWordQuery, onChange],
  );

  useEffect(() => {
    if (wordQuery !== '') {
      refine(wordQuery);
    }
  }, [refine, wordQuery]);

  const handleSearchButtonClick = useCallback(() => {
    refine(wordQuery);
  }, [refine, wordQuery]);

  return (
    <div>
      <TextField
        defaultValue={initialValue}
        label={label}
        onChange={handleChange}
        variant="filled"
        size="small"
      />
      <Tooltip title="Press here to refresh list of matching word series" arrow>
        <Button
          onClick={handleSearchButtonClick}
          style={styles.searchButton}
          variant="outlined"
        >
          <SearchIcon />
        </Button>
      </Tooltip>
    </div>
  );
};

export default SearchableTextInput;
