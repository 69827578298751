import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { AddBox as CreateIcon } from '@material-ui/icons';

const styles = {
  button: {
    color: '#ffffff',
    margin: '10px 0px 0px 0px',
    fontSize: 'medium',
    textAlign: 'center',
    paddingRight: '13px',
    backgroundColor: '#ec8a88',
  },
  container: {
    backgroundColor: '#f0f0f0',
    width: 350,
    padding: 15,
    alignContent: 'center',
  },
  emptyComponentText: {
    color: '#495057',
  },
  icon: {
    paddingRight: 8,
  },
};

const ArrayInputEmptyComponent = ({ label, onPressCreateButton }) => (
  <div style={styles.container}>
    <div style={styles.emptyComponentText}>
      {`No ${label} yet. Do you want to add one?`}
    </div>
    <Button onClick={onPressCreateButton} style={styles.button}>
      <CreateIcon style={styles.icon} />
      Create
    </Button>
  </div>
);

ArrayInputEmptyComponent.propTypes = {
  label: PropTypes.string.isRequired,
  onPressCreateButton: PropTypes.func.isRequired,
};

export default ArrayInputEmptyComponent;
