import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { useForm } from 'react-final-form';
import { FileCopy as CopyIcon } from '@material-ui/icons';

const styles = {
  copyButton: { marginRight: 10 },
  iconInsideButton: { marginRight: 5 },
};

const OriginalTextCopyButton = ({
  propertyName,
  originalText,
  originalTextTranslations = [],
}) => {
  const form = useForm();

  const onClickCopyIcon = useCallback(() => {
    form.change(propertyName, [
      ...originalTextTranslations,
      { languageCode: null, text: originalText },
    ]);
  }, [form, originalText, originalTextTranslations, propertyName]);

  return (
    <Button
      variant="outlined"
      size="small"
      style={styles.copyButton}
      onClick={onClickCopyIcon}
    >
      <CopyIcon style={styles.copyIcon} />
      Copy Original Text To Translation
    </Button>
  );
};

OriginalTextCopyButton.propTypes = {
  originalText: PropTypes.string.isRequired,
  propertyName: PropTypes.string.isRequired,
  // eslint-disable-next-line react/require-default-props
  originalTextTranslations: PropTypes.arrayOf(PropTypes.shape({})),
};

export default OriginalTextCopyButton;
