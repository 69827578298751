import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Edit } from 'react-admin';

import TopicActions from './TopicActions';
import TopicForm from './TopicForm';

const TopicTitle = ({ record = null }) => (
  <>Topic: {_.get(record, 'text', '')}</>
);

TopicTitle.propTypes = {
  // eslint-disable-next-line react/require-default-props
  record: PropTypes.shape({}),
};

const TopicEdit = (props) => (
  <Edit
    title={<TopicTitle />}
    actions={<TopicActions basePath="/topics" />}
    {...props}
  >
    <TopicForm mode="update" />
  </Edit>
);

export default TopicEdit;
