import ApolloClientHelper from './ApolloClientHelper';
import Queries from './graphqlQueries';

export default {
  create: async (type, resource, params) => {
    const apolloClient = ApolloClientHelper.getInstance();
    const {
      data: { managerId, howManyDays },
    } = params;

    const {
      data: {
        giveSubscriptionCreditToManager: { user: byUser },
      },
    } = await apolloClient.mutate({
      mutation: Queries.CREATE_SUBSCRIPTION_CREDIT,
      variables: {
        input: {
          managerId,
          howManyDays,
        },
      },
    });

    return { data: byUser };
  },
};
