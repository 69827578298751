import React from 'react';
import _ from 'lodash';

import JSUtility from '../../utilities/JSUtility';
/*
The role of ArrayTypeField
i) ['a', 'b'] => ArrayTypeField => 'a, b'
ii) [{code: 'a'}, {code: 'b'}] => ArrayTypeField => 'a, b'
*/
const ArrayTypeField = ({ record = {}, source = null, objKey = null }) => {
  // TODO: Some existing contents don't seem to have `tags`, we need to fix the record
  const arrayTypeFieldValue = _.get(record, source, []);
  const lastIndex = arrayTypeFieldValue.length - 1;

  if (arrayTypeFieldValue == null || _.isEqual(arrayTypeFieldValue, [])) {
    return '-';
  }

  const valueListToRender = arrayTypeFieldValue.map((obj) =>
    objKey != null ? obj[objKey] : obj,
  );
  const sortedValueListToRender = JSUtility.sort(valueListToRender);

  return sortedValueListToRender.map((value, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <React.Fragment key={`${source}-${index}`}>
      {value}
      {index === lastIndex ? '' : ', '}
    </React.Fragment>
  ));
};

ArrayTypeField.defaultProps = { addLabel: true };

export default ArrayTypeField;
