import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Tabs, Tab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import CustomSearchBox from './components/SearchInput';
import TabPanel from './components/TabPanel';

import './search.css';
import ContentSearch from './components/results/ContentSearch';
import FragmentSearch from './components/results/FragmentSearch';
import QuestionSearch from './components/results/QuestionSearch';
import TagSearch from './components/results/TagSearch';
import LemmaSearch from './components/results/LemmaSearch';

const collections = [
  {
    name: 'Contents',
    engine: 'contents-en',
    // From 2020-04, we only manage English learning language.
  },
  {
    name: 'Fragments',
    engine: 'fragments-en',
    // From 2020-04, we only manage English learning language.
  },
  {
    name: 'Questions',
    engine: 'questions-en',
    // From 2020-04, we only manage English learning language.
  },
  {
    name: 'Tags',
    engine: 'tags-en',
    // From 2020-04, we only manage English learning language.
  },
  {
    name: 'Lemmas',
    engine: 'lemmas-en',
    // Lemma collection only supports English learning language.
  },
];

const useStyles = makeStyles({
  content: {
    position: 'absolute',
    top: '100%',
    right: '250px',
    width: '30%',
    height: 'auto',
    maxHeight: 'calc(100vh - 100px)',
    minWidth: '500px',
    maxWidth: '600px',
    margin: '8px 0 0',
    backgroundColor: '#ffffff',
    borderRadius: 8,
    border: '1px solid #eeeeee',
    color: 'black',
    overflow: 'auto',
    display: 'block',
  },
  hitVariety: {
    width: '100%',
    borderBottom: '1px solid #ccc',
    marginBottom: '0.5rem',
    paddingBottom: '0.5rem',
    paddingTop: '0.5rem',
    fontSize: '1.05rem',
    color: '#33363d',
  },
  tab: {
    minWidth: 100,
  },
  tabIndicator: {
    backgroundColor: '#ee4e48',
  },
});

const Search = (): JSX.Element => {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);
  const searchContentDivRef = useRef<HTMLDivElement>(null);

  const handleTabChange = useCallback((event, newValue) => {
    setTabValue(newValue);
  }, []);

  const handleDisplayState = useCallback((show) => {
    const element = document.getElementById('search-content');
    if (element == null) {
      return;
    }

    element.style.display = show ? 'block' : 'none';
  }, []);

  useEffect(() => {
    const searchInput = document.getElementById('search-input') as
      | HTMLInputElement
      | undefined;
    const searchResultWindow = document.getElementById('search-content');

    const handleClickOutside = (event: MouseEvent) => {
      if (searchResultWindow == null) {
        return;
      }

      const isTouchedSearchInput = event.target === searchInput;
      const isTouchedSearchContent =
        event.target instanceof Node &&
        searchContentDivRef.current?.contains(event.target);

      const isTouchedOutside = !isTouchedSearchInput && !isTouchedSearchContent;

      searchResultWindow.style.display =
        isTouchedOutside || searchInput?.value === '' ? 'none' : 'block';
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const [resultQuery, setResultQuery] = useState('');
  // (How to save function no state) https://stackoverflow.com/a/55621325
  const [search, setSearch] = useState<(query: string) => void>(() => () => {});
  const [hasResult, setHasResult] = useState(false);

  const onChangeResultQuery = useCallback((query: string) => {
    setResultQuery(query);
  }, []);
  const onChangeSearch = useCallback((s: (query: string) => void) => {
    setSearch(() => s);
  }, []);
  const onChangeResults = useCallback((results: unknown[]) => {
    setHasResult(results.length > 0);
  }, []);

  return (
    <div>
      <CustomSearchBox
        currentRefinement={resultQuery}
        refine={search}
        collection={collections[tabValue].name.toLowerCase()}
        onInputChange={handleDisplayState}
      />
      <span>
        <div
          id="search-content"
          className={classes.content}
          ref={searchContentDivRef}
        >
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            variant="fullWidth"
            textColor="inherit"
            classes={{ indicator: classes.tabIndicator }}
          >
            {collections.map((collection) => (
              <Tab
                key={`${collection.name}tab`}
                className={classes.tab}
                label={collection.name}
              />
            ))}
          </Tabs>
          {collections.map(({ name }, index) => (
            <TabPanel key={name} value={tabValue} index={index}>
              <div id="result">
                {name === 'Contents' ? (
                  <ContentSearch
                    onChangeResultQuery={onChangeResultQuery}
                    onChangeSearch={onChangeSearch}
                    onChangeResults={onChangeResults}
                  />
                ) : null}
                {name === 'Fragments' ? (
                  <FragmentSearch
                    onChangeResultQuery={onChangeResultQuery}
                    onChangeSearch={onChangeSearch}
                    onChangeResults={onChangeResults}
                  />
                ) : null}
                {name === 'Questions' ? (
                  <QuestionSearch
                    onChangeResultQuery={onChangeResultQuery}
                    onChangeSearch={onChangeSearch}
                    onChangeResults={onChangeResults}
                  />
                ) : null}
                {name === 'Tags' ? (
                  <TagSearch
                    onChangeResultQuery={onChangeResultQuery}
                    onChangeSearch={onChangeSearch}
                    onChangeResults={onChangeResults}
                  />
                ) : null}
                {name === 'Lemmas' ? (
                  <LemmaSearch
                    onChangeResultQuery={onChangeResultQuery}
                    onChangeSearch={onChangeSearch}
                    onChangeResults={onChangeResults}
                  />
                ) : null}
                <div hidden={hasResult}>
                  No results found for query <b>{`"${resultQuery}"`}</b>
                </div>
              </div>
            </TabPanel>
          ))}
        </div>
      </span>
    </div>
  );
};

export default Search;
