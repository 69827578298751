import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  List,
  Datagrid,
  TextField,
  Pagination,
  Filter,
  SelectInput,
  ReferenceField,
  SearchInput,
} from 'react-admin';
import { CSVLink } from 'react-csv';
import { ScrollToTopButton, UnixTimestampField } from '../../common';
import { StatusChoices } from '../../constants';
import {
  getL2eRedeemRequests,
  getPointLogList,
} from '../../dataProvider/firestoreDataProvider';
import UserBoardShow from './PointLogShow';

const PointFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="userId" alwaysOn />
    <SelectInput label="Status" source="status" choices={StatusChoices} />
  </Filter>
);

const PointLogList = (props) => {
  const [records, setRecords] = useState([]);
  const [accountRecords, setAccountRecords] = useState([]);

  useEffect(() => {
    const getL2eRedeemRequestsList = async () => {
      const data = await getL2eRedeemRequests();
      setAccountRecords(data);
    };
    getL2eRedeemRequestsList();
  }, []);

  useEffect(() => {
    const getPointLogListDownLoad = async () => {
      const data = await getPointLogList();
      setRecords(data);
    };
    getPointLogListDownLoad();
  }, []);

  return (
    <>
      <List
        filters={<PointFilter />}
        pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
        bulkActionButtons={false}
        exporter={false}
        sort={{ field: 'createdAt', order: 'DESC' }}
        {...props}
      >
        <Datagrid rowClick="expand" expand={<UserBoardShow />}>
          <ReferenceField
            label="Content"
            source="contentId"
            reference="contents"
          >
            <TextField source="title" />
          </ReferenceField>
          <TextField label="User Id" source="userId" />
          <TextField source="points" />
          <TextField source="status" />
          <UnixTimestampField label="Created At" source="createdAt.seconds" />
        </Datagrid>
      </List>
      <CSVLink
        data={records}
        filename={`UserBoard_${moment().format('YYYYMMDD_HHmmss')}`}
      >
        사용자 포인트로그 다운로드
      </CSVLink>
      <CSVLink
        data={accountRecords}
        filename={`UserAccount_${moment().format('YYYYMMDD_HHmmss')}`}
      >
        계좌정보 다운로드
      </CSVLink>
      <ScrollToTopButton category={0} />
    </>
  );
};
export default PointLogList;
