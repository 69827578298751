import React from 'react';
import { Create } from 'react-admin';

import FragmentForm from './FragmentForm';

const FragmentCreate = (props) => (
  <Create {...props}>
    <FragmentForm mode="create" />
  </Create>
);

export default FragmentCreate;
