import React, { useEffect } from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Pagination,
  Filter,
  SelectInput,
  BooleanField,
} from 'react-admin';

import { ScrollToTopButton, ShortenedDateField } from '../../common';
import TopicShow from './TopicShow';
import { BooleanChoices } from '../../constants';

const TopicFilter = (props) => (
  <Filter {...props}>
    <SelectInput
      label="Published"
      source="published"
      choices={BooleanChoices}
      alwayson="true"
      allowEmpty={false}
    />
  </Filter>
);

const TopicList = (props) => {
  useEffect(() => {
    document.title = 'TMS: Topics';
  }, []);

  return (
    <>
      <List
        filters={<TopicFilter />}
        filterDefaultValues={{ published: false }}
        pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
        perPage={25}
        bulkActionButtons={false}
        exporter={false}
        title="Topics"
        {...props}
      >
        <Datagrid rowClick="expand" expand={<TopicShow />}>
          <TextField label="Topic" source="text" />
          <BooleanField label="Published" source="published" />
          <TextField label="Fragment Count" source="fragmentCount" />
          <ShortenedDateField
            label="Created"
            source="createdAt.seconds"
            type="timestamp"
          />
          <ShortenedDateField
            label="Last modified"
            source="updatedAt.seconds"
            type="timestamp"
          />
          <EditButton />
        </Datagrid>
      </List>
      <ScrollToTopButton category={0} />
    </>
  );
};

export default TopicList;
