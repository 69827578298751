import React from 'react';
import PropTypes from 'prop-types';
import ScrollToTop from 'react-scroll-up';
import { KeyboardArrowUp as ArrowUpIcon } from '@material-ui/icons';
import { Fab } from '@material-ui/core';

const styles = {
  fab: {
    backgroundColor: '#ee4e48',
  },
  scrollDefault: {
    position: 'fixed',
    right: '85px',
    bottom: '60px',
    zIndex: '10',
  },
  scrollInContentsForm: {
    position: 'fixed',
    right: '5%',
    bottom: '10%',
    zIndex: '10',
  },
};

const ScrollToTopButton = (props) => {
  const { category } = props;

  const scrollLocationStyle = (() => {
    switch (category) {
      case 0:
        return styles.scrollDefault;
      case 1:
        return styles.scrollInContentsForm;
      default:
        return {};
    }
  })();

  return (
    <ScrollToTop showUnder={160} style={scrollLocationStyle}>
      <Fab
        size="large"
        color="secondary"
        aria-label="scroll-to-top"
        style={styles.fab}
      >
        <ArrowUpIcon fontSize="large" />
      </Fab>
    </ScrollToTop>
  );
};

ScrollToTopButton.propTypes = {
  category: PropTypes.number.isRequired,
};

export default ScrollToTopButton;
