import React, { useEffect } from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Pagination,
  BooleanField,
  Filter,
  SelectInput,
} from 'react-admin';

import { ScrollToTopButton, ShortenedDateField } from '../../common';
import GrammarShow from './GrammarShow';
import { BooleanChoices } from '../../constants';

const GrammarFilter = (props) => (
  <Filter {...props}>
    <SelectInput
      label="Published"
      source="published"
      choices={BooleanChoices}
      alwayson="true"
      allowEmpty={false}
    />
  </Filter>
);

const GrammarList = (props) => {
  useEffect(() => {
    document.title = 'TMS: Grammar';
  }, []);

  return (
    <>
      <List
        filters={<GrammarFilter />}
        filterDefaultValues={{ published: false }}
        pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
        perPage={25}
        bulkActionButtons={false}
        exporter={false}
        title="Grammar"
        {...props}
      >
        <Datagrid rowClick="expand" expand={<GrammarShow />}>
          <TextField label="Text" source="text" />
          <TextField label="Type" source="type" />
          <BooleanField label="Published" source="published" />
          <TextField label="Fragment Count" source="fragmentCount" />
          <TextField label="Definition" source="definition" />
          <ShortenedDateField
            label="Created"
            source="createdAt.seconds"
            type="timestamp"
          />
          <ShortenedDateField
            label="Last modified"
            source="updatedAt.seconds"
            type="timestamp"
          />
          <EditButton />
        </Datagrid>
      </List>
      <ScrollToTopButton category={0} />
    </>
  );
};

export default GrammarList;
