import BookIcon from '@material-ui/icons/MenuBook';

import GrammarList from './GrammarList';
import GrammarEdit from './GrammarEdit';
import GrammarCreate from './GrammarCreate';

export default {
  list: GrammarList,
  create: GrammarCreate,
  edit: GrammarEdit,
  icon: BookIcon,
};
