import PollIcon from '@material-ui/icons/Poll';

import SurveyList from './SurveyList';
import SurveyEdit from './SurveyEdit';
import SurveyCreate from './SurveyCreate';

export default {
  list: SurveyList,
  create: SurveyCreate,
  edit: SurveyEdit,
  icon: PollIcon,
};
