import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Edit } from 'react-admin';

import LemmaActions from './LemmaActions';
import LemmaForm from './LemmaForm';

const LemmaTitle = ({ record = null }) => (
  <>Lemma: {_.get(record, 'lemma', '')}</>
);

LemmaTitle.propTypes = {
  // eslint-disable-next-line react/require-default-props
  record: PropTypes.shape({
    lemma: PropTypes.string.isRequired,
    difficultyLevel: PropTypes.number.isRequired,
  }),
};

const LemmaEdit = (props) => (
  <Edit
    title={<LemmaTitle />}
    actions={<LemmaActions basePath="/lemmas" />}
    {...props}
  >
    <LemmaForm mode="update" />
  </Edit>
);

export default LemmaEdit;
