import React, { useCallback, useRef, useState } from 'react';
import { Button, Paper, Checkbox, ListItem, Tooltip } from '@material-ui/core';
import { useForm } from 'react-final-form'; // eslint-disable-line import/no-extraneous-dependencies
import _ from 'lodash';
import { TextInput, required } from 'react-admin';
import { Link } from 'react-router-dom';
import { Add as AddIcon, Link as LinkIcon } from '@material-ui/icons';
import SearchableTextInput from './SearchableTextInput';

const styles = {
  listContainer: {
    maxHeight: 200,
    maxWidth: 500,
    overflow: 'auto',
    marginBottom: 20,
  },
  listItemTopicContainer: {
    marginTop: 5,
    marginLeft: 5,
  },
  listHiddenItemTopicContainer: {
    marginTop: 5,
    marginLeft: 5,
    color: 'gray',
    textDecoration: 'line-through',
  },
  listItemTopicAttribute: {
    marginTop: 5,
    fontSize: 14,
  },
  listItemTopicAttributeName: {
    marginLeft: 2,
    fontWeight: 600,
  },
  listItemHighlightedAttribute: {
    fontSize: 16,
    fontWeight: 700,
  },
  addButton: {
    width: '100%',
    paddingTop: 10,
    paddingBottom: 10,
  },
  noResultText: {
    marginTop: 20,
    marginBottom: 20,
    marginRight: 10,
    marginLeft: 10,
    fontSize: 16,
    fontWeight: 600,
  },
  divider: {
    height: 1,
    width: '100%',
    backgroundColor: '#f0f0f0',
  },
  selectTopicDescription: {
    marginBottom: 5,
    fontSize: 14,
    color: '#495057',
  },
  refreshButton: {
    marginLeft: 100,
    backgroundColor: '#F8F9FA',
  },
  topicIdInput: {
    width: '190px',
  },
  topicSelectList: {
    marginTop: 10,
  },
  linkButton: {
    marginTop: 15,
    marginLeft: 5,
  },
};

interface TopicResult {
  id;
  text: string;
}

interface TopicListItemProps {
  hit: TopicResult;
  onClick: (result: TopicResult) => void;
  selected: boolean;
}

const TopicListItem = ({
  hit,
  onClick: handleClickProp,
  selected,
}: TopicListItemProps) => {
  const handleClick = useCallback(() => {
    if (handleClickProp != null) {
      handleClickProp(hit);
    }
  }, [hit, handleClickProp]);

  return (
    <ListItem
      selected={selected}
      button
      onClick={handleClick}
      alignItems="flex-start"
      divider
    >
      <Checkbox checked={selected} />
      <div style={styles.listItemTopicContainer}>
        <div style={styles.listItemTopicAttribute}>
          <span style={styles.listItemTopicAttributeName}>Topic:</span>
          &nbsp;
          <span style={styles.listItemHighlightedAttribute}>
            <span dangerouslySetInnerHTML={{ __html: hit.text }} />
          </span>
        </div>
        <div style={styles.listItemTopicAttribute}>
          <span style={styles.listItemTopicAttributeName}>ID:</span>
          &nbsp;
          {hit.id}
        </div>
      </div>
    </ListItem>
  );
};

// eslint-disable-next-line react/prop-types
const NoResultComponent = ({ query }) => (
  <div>
    <div style={styles.noResultText}>
      {query === ''
        ? 'The Topic field is empty. Please type something in the Topic field.'
        : `There are no topics matching "${query}". Please create a new topic.`}
    </div>
    <div style={styles.divider} />
  </div>
);

interface TopicIdSelectInputProps {
  hits: TopicResult[];
  initialTopic?: string;
  source: string;
  topicId?: string;
  search: (query: string) => void;
}

const TopicIdSelectInput = ({
  hits,
  initialTopic,
  source,
  topicId,
  search,
}: TopicIdSelectInputProps): JSX.Element => {
  const form = useForm();
  const [topic, setTopic] = useState(initialTopic);
  const inputRef = useRef<HTMLInputElement>();

  const updateTopicId = useRef((id) => {
    form.change(source, id);
  });

  const handleClickItem = useCallback((hit: TopicResult) => {
    // it focuses text input manually to enable per input validation
    if (inputRef.current != null) {
      inputRef.current.selectionStart = 0;
      inputRef.current.focus();
    }
    updateTopicId.current(hit.id);
  }, []);

  const updateTopic = useCallback(({ value: newTopic }) => {
    setTopic(newTopic);
  }, []);

  const validateTopicId = useCallback(
    (value) => {
      const topicByTopicId = _.find(hits, ['id', value]);
      if (topicByTopicId == null) {
        return 'Please select topic from the list';
      }
      return undefined;
    },
    [hits],
  );

  const topicIdValidate = [required(), validateTopicId];

  return (
    <div>
      <div>
        <TextInput
          label="Topic Id"
          source={source}
          inputRef={inputRef}
          style={styles.topicIdInput}
          validate={topicIdValidate}
        />
        <Tooltip title="Press here to move to topic detail page" arrow>
          <Button
            style={styles.linkButton}
            component={Link}
            variant="outlined"
            target="_blank"
            to={{
              pathname: `/topics/${topicId}`,
            }}
          >
            <LinkIcon />
          </Button>
        </Tooltip>
      </div>
      <SearchableTextInput
        refine={search}
        label="Search Keyword"
        // ToDo #10539: It is better to searchableTextInput replace the apostrophe by itself.
        initialValue={initialTopic?.replace(/’/g, "'")}
        onChange={updateTopic}
      />
      <div style={styles.topicSelectList}>
        <div style={styles.selectTopicDescription}>
          Select topic from the list *
        </div>
        <Paper style={styles.listContainer}>
          {hits.length === 0 ? (
            <NoResultComponent query={topic ?? ''} />
          ) : (
            _.map(hits, (hit) => (
              // eslint-disable-next-line react/no-array-index-key
              <TopicListItem
                key={hit.id}
                hit={hit}
                onClick={handleClickItem}
                selected={topicId === hit.id}
              />
            ))
          )}
          <Button
            style={styles.addButton}
            component={Link}
            to={{
              pathname: '/topics/create',
            }}
            target="_blank"
          >
            <AddIcon />
            Add new topic
          </Button>
        </Paper>
      </div>
    </div>
  );
};

export default TopicIdSelectInput;
