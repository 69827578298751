import FragmentIcon from '@material-ui/icons/LibraryBooks';

import SktFragmentList from './SktFragmentList';
import SktFragmentEdit from './SktFragmentEdit';
import SktFragmentCreate from './SktFragmentCreate';

export default {
  list: SktFragmentList,
  create: SktFragmentCreate,
  edit: SktFragmentEdit,
  icon: FragmentIcon,
};
