import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useForm } from 'react-final-form';
import { CheckboxGroupInput } from 'react-admin';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  Paper,
} from '@material-ui/core';
import { ExpandMore, ChevronRight } from '@material-ui/icons';
import { TreeView, TreeItem } from '@material-ui/lab';
import expandableSelectStyles from './ExpandableSelectStyles';
import ChipWrapper from './ChipWrapper';

const useStyles = expandableSelectStyles;

const CustomTagSelect = (props) => {
  const { choices, legacyTagChoices, options } = props;
  const classes = useStyles();

  const form = useForm();
  const formState = form.getState();
  const formSelectedTagKeys = formState.values.tagKeys;

  // First 2 categories are mandatory to select, so expand by default.
  const [expanded, setExpanded] = useState(['0', '1']);
  const [chipData, setChipData] = useState(formSelectedTagKeys);

  const handleChange = (event, nodes) => {
    setExpanded(nodes);
  };

  const getChipLabelFromTagKeys = useCallback(
    (data) => {
      const findTagKey = (dataToFind) =>
        _.find(choices, (o) => (o.id === dataToFind ? o : null));

      return _.get(findTagKey(data), 'languageCodeToName', null) == null
        ? data.toString()
        : findTagKey(data).languageCodeToName.en;
    },
    [choices],
  );

  const findLegacyMatch = useCallback(
    (tagName) =>
      _.find(legacyTagChoices, (o) => (o.name === tagName ? o : null)),
    [legacyTagChoices],
  );

  const getLabelsInLegacyTag = useCallback(
    (arr) =>
      _.filter(
        arr.map((tagName) =>
          findLegacyMatch(tagName) != null
            ? findLegacyMatch(tagName).name
            : null,
        ),
        undefined,
      ),
    [findLegacyMatch],
  );

  const getSelectedLabel = useCallback(
    (arr) =>
      arr != null ? arr.map((data) => getChipLabelFromTagKeys(data)) : [],
    [getChipLabelFromTagKeys],
  );

  const onChangeTagKeys = useCallback(
    (selectedChoices) => {
      const selectedLabel = getSelectedLabel(selectedChoices);
      setChipData(selectedChoices);
      form.change('tags', getLabelsInLegacyTag(selectedLabel));
    },
    [form, getLabelsInLegacyTag, getSelectedLabel],
  );

  const handleDelete = useCallback(
    (chipToDelete) => {
      const selectedChipAfterDeletion = _.filter(
        formSelectedTagKeys,
        (id) => id !== chipToDelete,
      );
      form.change('tagKeys', selectedChipAfterDeletion);
      onChangeTagKeys(selectedChipAfterDeletion);
    },
    [onChangeTagKeys, formSelectedTagKeys, form],
  );

  const groupedChoices = _.chain(choices)
    .groupBy('category')
    .map((value, key) => ({ category: key, propertiesExceptCategory: value }))
    .value();

  const sortedGroupedChoices = [
    _.find(groupedChoices, (choice) => choice.category === 'accent'),
    _.find(groupedChoices, (choice) => choice.category === 'category'),
    _.find(groupedChoices, (choice) => choice.category === 'theme'),
    _.find(groupedChoices, (choice) => choice.category === 'genre'),
    _.find(groupedChoices, (choice) => choice.category === 'film'),
    _.find(groupedChoices, (choice) => choice.category === 'figure'),
    _.find(groupedChoices, (choice) => choice.category === 'program'),
    _.find(groupedChoices, (choice) => choice.category === 'channel'),
    _.find(groupedChoices, (choice) => choice.category === 'context'),
    _.find(groupedChoices, (choice) => choice.category === 'gender'),
    _.find(groupedChoices, (choice) => choice.category === 'others'),
  ];

  const filteredGroupedChoices = _.filter(
    sortedGroupedChoices,
    (choice) => choice !== undefined,
  );

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {chipData == null
          ? null
          : chipData.map((data) => (
              <ChipWrapper
                key={data.toString()}
                data={data}
                handleDelete={handleDelete}
                className={classes.chip}
                label={getChipLabelFromTagKeys(data)}
              />
            ))}
      </Paper>
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Select Tags Here</Typography>
        </ExpansionPanelSummary>
        <TreeView
          className={classes.tree}
          defaultCollapseIcon={<ExpandMore />}
          defaultExpandIcon={<ChevronRight />}
          expanded={expanded}
          onNodeToggle={handleChange}
        >
          {filteredGroupedChoices.map((choice, index) => (
            <TreeItem
              key={index.toString()}
              nodeId={index.toString()}
              label={
                <div className={classes.labelRoot}>
                  <Typography
                    variant="body2"
                    className={classes.categoryLabelText}
                  >
                    {choice.category.charAt(0).toUpperCase() +
                      choice.category.slice(1)}
                    {index < 2 ? ' (mandatory)' : null}
                    {choice.category === 'theme' ? ' (if applicable)' : null}
                    {choice.category === 'genre'
                      ? ' (mandatory when the category IS movie)'
                      : null}
                  </Typography>
                </div>
              }
            >
              <ExpansionPanelDetails className={classes.detail}>
                <CheckboxGroupInput
                  source="tagKeys"
                  label="" // This line is intended to prevent duplicate label.
                  choices={choice.propertiesExceptCategory}
                  optionText={options}
                  onChange={onChangeTagKeys}
                  row
                />
              </ExpansionPanelDetails>
            </TreeItem>
          ))}
        </TreeView>
      </ExpansionPanel>
    </div>
  );
};

CustomTagSelect.propTypes = {
  choices: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  legacyTagChoices: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  options: PropTypes.func.isRequired,
};

export default React.memo(CustomTagSelect);
