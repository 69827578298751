import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  Typography,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from '@material-ui/core';
import { ChevronRight, ExpandMore } from '@material-ui/icons';
import { GET_MANY, CheckboxGroupInput } from 'react-admin';
import { TreeItem, TreeView } from '@material-ui/lab';
import { useForm } from 'react-final-form';
import _ from 'lodash';
import myDataProvider from '../../../dataProvider/firestoreDataProvider';
import expandableSelectStyles from './ExpandableSelectStyles';
import ChipWrapper from './ChipWrapper';

const useStyles = expandableSelectStyles;

const MissionWordSelect = (props) => {
  const { data } = props;

  const classes = useStyles();

  const form = useForm();
  const formState = form.getState();
  const formSelectedLemmas = formState.values.lemmas;

  const [lemmas, setLemmas] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [chipData, setChipData] = useState(formSelectedLemmas);
  const [loaded, setLoaded] = useState(false);
  const [missionWords, setMissionWords] = useState([]);

  const flatArray = [].concat(...missionWords);

  const getChipLabelFromLemmas = useCallback(
    (item) => flatArray.find((v) => v.id === item)?.lemma,
    [flatArray],
  );

  const onChangeLemma = useCallback(
    (selectedChoices) => {
      setChipData(selectedChoices);
      form.change('lemmas', selectedChoices);
    },
    [form],
  );

  const handleDelete = useCallback(
    (chipToDelete) => {
      const selectedChipAfterDeletion = _.filter(
        formSelectedLemmas,
        (id) => id !== chipToDelete,
      );
      form.change('lemmas', selectedChipAfterDeletion);
      onChangeLemma(selectedChipAfterDeletion);
    },
    [form, formSelectedLemmas, onChangeLemma],
  );

  const handleChange = (event, nodes) => {
    setExpanded(nodes);
  };

  useEffect(() => {
    if (data && !loaded) {
      /*
        This callback filter remove unncessary empty string from lemmaIds array.
        CollectionReference.doc() in GET_MANY dataProvider requires
        its first argument to be of type non-empty string.
      */
      const removeEmptyStringInLemmaIds = (item) =>
        item[1].lemmaIds?.filter((v) => v);
      const lemmaIds = Object.entries(data).map(removeEmptyStringInLemmaIds);

      const getLemmaData = async () => {
        lemmaIds.map(async (lemma) => {
          await myDataProvider(GET_MANY, 'lemmas', { ids: lemma }).then(
            (response) => {
              if (Object.keys(response.data).length === 0) {
                return;
              }

              const filteredData = response.data?.map((array) =>
                array.filter((selectedLemma) => !selectedLemma.hidden),
              );
              setLemmas([...filteredData]);
            },
          );
        });
      };
      getLemmaData();
      setExpanded([...Array(Object.keys(data).length).keys()].map(String));
      setLoaded(true);
    }
  }, [data, loaded, flatArray, lemmas]);

  useEffect(() => {
    if (data && lemmas.length > 0) {
      // This callback filter remove unnecessary empty string from fragment's words.
      const removeEmptyStringInWords = (item) => item?.filter((v) => v.lemmaId);
      const words = Object.entries(data).map((item) => ({
        keepable: item[1].keepable,
        words: removeEmptyStringInWords(item[1].words),
      }));

      const filteredWords = lemmas.map((lemma, idx) =>
        lemma.map((v) => ({
          keepable: words[idx]?.keepable,
          ...words[idx]?.words?.find((word) => word.lemmaId === v.id),
          ...v,
        })),
      );
      setMissionWords(filteredWords);
    }
  }, [data, lemmas]);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {chipData &&
          chipData.map((item) => (
            <ChipWrapper
              key={item}
              data={item}
              label={getChipLabelFromLemmas(item)}
              handleDelete={handleDelete}
              className={classes.chip}
            />
          ))}
      </Paper>
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>
            Select Mission Words Here
          </Typography>
        </ExpansionPanelSummary>
        <TreeView
          defaultCollapseIcon={<ExpandMore />}
          defaultExpandIcon={<ChevronRight />}
          expanded={expanded}
          onNodeToggle={handleChange}
        >
          {missionWords.length > 0 ? (
            missionWords.map((choice, index) => (
              <TreeItem
                key={index.toString()}
                nodeId={index.toString()}
                label={
                  <div className={classes.labelRoot}>
                    <Typography
                      variant="body2"
                      className={classes.categoryLabelText}
                    >
                      {`Fragment No. ${index + 1}`}
                    </Typography>
                  </div>
                }
              >
                <ExpansionPanelDetails className={classes.detail}>
                  <CheckboxGroupInput
                    source="lemmas"
                    label="" // This line is intended to prevent duplicate label.
                    choices={choice.filter(
                      ({ id, lemma, difficultyLevel, studyable, keepable }) =>
                        keepable
                          ? studyable
                            ? { id, lemma, difficultyLevel }
                            : false
                          : false,
                    )}
                    optionText={(option) =>
                      `${option.lemma} (Lv. ${
                        option.difficultyLevel || 'no data'
                      })`
                    }
                    onChange={onChangeLemma}
                    row
                  />
                </ExpansionPanelDetails>
              </TreeItem>
            ))
          ) : (
            <TreeItem
              key="no-object-mission-word"
              nodeId="no-object-mission-word"
              label={
                <div className={classes.labelRoot}>
                  <Typography
                    variant="body2"
                    className={classes.categoryLabelText}
                  >
                    No lemmas
                  </Typography>
                </div>
              }
            />
          )}
        </TreeView>
      </ExpansionPanel>
    </div>
  );
};
MissionWordSelect.propTypes = {
  data: PropTypes.shape({}),
};

export default MissionWordSelect;
