import BookIcon from '@material-ui/icons/MenuBook';

import L2eMonthlyPointLogsList from './L2eMonthlyPointLogsList';
import L2eMonthlyPointLogs from './L2eMonthlyPointLogsEdit';

export default {
  list: L2eMonthlyPointLogsList,
  edit: L2eMonthlyPointLogs,
  icon: BookIcon,
};
