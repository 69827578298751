import React, { useCallback } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes package
import { Chip } from '@material-ui/core'; // Import Chip component from material-ui/core

const ChipWrapper = ({ data, handleDelete, ...otherProps }) => {
  const onDelete = useCallback(() => {
    handleDelete(data);
  }, [data, handleDelete]);

  return <Chip onDelete={onDelete} {...otherProps} />;
};

ChipWrapper.propTypes = {
  data: PropTypes.string.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default ChipWrapper;
