import { Group as UserIcon } from '@material-ui/icons';

import UserEdit from './UserEdit';
import UserForm from './UserForm';
import UserList from './UserList';
import UserShow from './UserShow';

export default {
  edit: UserEdit,
  form: UserForm,
  list: UserList,
  show: UserShow,
  icon: UserIcon,
};
