import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SimpleForm, TextInput, NumberInput } from 'react-admin';
import { useForm } from 'react-final-form';
import _ from 'lodash';
import { CustomEditToolbar } from '../../common';
import { AvailableLanguages } from '../../constants';

const styles = {
  verticalView: {
    display: 'flex',
    flexDirection: 'column',
  },
  textInput: {
    width: 350,
  },
  selectInput: {
    width: 350,
  },
  updated: {
    backgroundColor: '#fad0c3',
  },
};

const validateL2eMonthlyPointLogsForm = (values) => {
  const {
    totalMaxCollectedPoints,
    userMaxCollectedPoints,
    userMaxRedeemedPoints,
    userMinRedeemPoints,
  } = values;

  const errors = {};
  const emptyFieldError = 'Please fill the field';
  const notValidError = 'Must be number and non negative';

  if (
    totalMaxCollectedPoints == null ||
    _.trim(totalMaxCollectedPoints) === ''
  ) {
    errors.totalMaxCollectedPoints = emptyFieldError;
  } else if (totalMaxCollectedPoints < 0) {
    errors.totalMaxCollectedPoints = notValidError;
  }

  if (userMaxCollectedPoints == null || _.trim(userMaxCollectedPoints) === '') {
    errors.userMaxCollectedPoints = emptyFieldError;
  } else if (userMaxCollectedPoints < 0) {
    errors.userMaxCollectedPoints = notValidError;
  }

  if (userMaxRedeemedPoints == null || _.trim(userMaxRedeemedPoints) === '') {
    errors.userMaxRedeemedPoints = emptyFieldError;
  } else if (userMaxRedeemedPoints < 0) {
    errors.userMaxRedeemedPoints = notValidError;
  }

  if (userMinRedeemPoints == null || _.trim(userMinRedeemPoints) === '') {
    errors.userMinRedeemPoints = emptyFieldError;
  } else if (userMinRedeemPoints < 0) {
    errors.userMinRedeemPoints = notValidError;
  }

  return errors;
};

const L2eMonthlyPointLogsForm = (props) => {
  const { mode, record, ...otherProps } = props;

  return (
    <>
      <SimpleForm
        {...props}
        toolbar={
          <CustomEditToolbar
            {...otherProps}
            mode={mode}
            record={record}
            saveButtonLabel="Save log"
          />
        }
        redirect={false}
        submitOnEnter={false}
        keepDirtyOnReinitialize={false}
        validate={validateL2eMonthlyPointLogsForm}
      >
        <L2eMonthlyPointLogsFormContent mode={mode} record={record} />
      </SimpleForm>
    </>
  );
};

const L2eMonthlyPointLogsFormContent = () => {
  const [dirtyFields, setDirtyFields] = useState({});
  const [formData, setFormData] = useState({});

  const form = useForm();

  useEffect(() => {
    const unsubscribe = form.subscribe(
      (s) => {
        setDirtyFields(s.dirtyFields);
        setFormData(s.values);
      },
      { dirtyFields: true, values: true },
    );
    return () => {
      unsubscribe();
    };
  }, [form]);

  return (
    <div style={styles.verticalView}>
      <TextInput
        disabled
        label="Period"
        source="id"
        style={styles.textInput}
        record={formData}
      />
      <NumberInput
        label="Total Collected Points"
        source="totalCollectedPoints"
        disabled
        style={{ width: 240 }}
        record={formData}
      />
      <NumberInput
        label="Total Max Collected Points"
        source="totalMaxCollectedPoints"
        style={{
          ...{ width: 240 },
          ...(dirtyFields.totalMaxCollectedPoints ? styles.updated : null),
        }}
        record={formData}
      />
      <NumberInput
        label="User Max Collected Points"
        source="userMaxCollectedPoints"
        style={{
          ...{ width: 240 },
          ...(dirtyFields.userMaxCollectedPoints ? styles.updated : null),
        }}
        record={formData}
      />
      <NumberInput
        label="Max Redeemed Points"
        source="userMaxRedeemedPoints"
        style={{
          ...{ width: 240 },
          ...(dirtyFields.userMaxRedeemedPoints ? styles.updated : null),
        }}
        record={formData}
      />
      <NumberInput
        label="Min Redeemed Points"
        source="userMinRedeemPoints"
        style={{
          ...{ width: 240 },
          ...(dirtyFields.userMinRedeemPoints ? styles.updated : null),
        }}
        record={formData}
      />
      <span>Points Conversion Rate</span>
      {AvailableLanguages.map((languageCode) => {
        const sourceForLanguageCode = `languageCodeToConversionRate.${languageCode.id}.pointsConversionRate`;

        return (
          <React.Fragment key={sourceForLanguageCode}>
            <NumberInput
              label={`${languageCode.name} - Points per currency`}
              source={sourceForLanguageCode}
              style={{
                ...(dirtyFields.sourceForLanguageCode ? styles.updated : null),
              }}
              record={formData}
            />
          </React.Fragment>
        );
      })}
    </div>
  );
};

const CommonPropTypesForL2eMonthlyPointLogsFormAndContent = {
  mode: PropTypes.oneOf(['create', 'update']).isRequired,
  record: PropTypes.shape({}),
};

L2eMonthlyPointLogsForm.propTypes = {
  ...CommonPropTypesForL2eMonthlyPointLogsFormAndContent,
};

L2eMonthlyPointLogsFormContent.propTypes = {
  ...CommonPropTypesForL2eMonthlyPointLogsFormAndContent,
};

export default L2eMonthlyPointLogsForm;
