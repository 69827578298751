import React, { useCallback, useState } from 'react';
import { ArrayInput } from 'react-admin';
import { Collapse, IconButton } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import _ from 'lodash';
import { useForm } from 'react-final-form';

import { CustomSimpleFormIterator } from '../../../common';
import ArrayInputEmptyComponent from '../../../common/ArrayInputEmptyComponent';
import TopicInputWithSearch from './TopicInputWithSearch';

const styles = {
  expandedArrow: {
    transform: 'rotate(180deg)',
  },
  updated: {
    backgroundColor: '#fad0c3',
  },
};

interface Record {
  topicIds: string[];
}

interface TopicsFieldProps {
  record?: Record;
  isDirty: boolean;
}

export const defaultIdValue = 'Topic ID';

const TopicsField = ({ record, isDirty }: TopicsFieldProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { topicIds } = record ?? {};
  const form = useForm();
  const onClickExpand = useCallback(
    () => setIsExpanded((prevExpanded) => !prevExpanded),
    [],
  );

  const onPressCreateFirstTopic = useCallback(() => {
    form.change('topicIds', [defaultIdValue]);
  }, [form]);

  const renderJoinedTopics = useCallback(
    () => (!_.isEmpty(topicIds) ? _.join(topicIds, ', ') : 'No topics'),
    [topicIds],
  );

  return (
    <>
      <span>
        <IconButton
          style={isExpanded ? styles.expandedArrow : undefined}
          onClick={onClickExpand}
        >
          <ExpandMoreIcon />
        </IconButton>
        {isExpanded ? 'Hide topics: ' : 'Show topics: '}
        <span style={isDirty ? styles.updated : undefined}>
          {renderJoinedTopics()}
        </span>
      </span>
      <Collapse in={isExpanded} timeout="auto">
        <ArrayInput source="topicIds" record={record}>
          <CustomSimpleFormIterator defaultAddValue={defaultIdValue}>
            <TopicInputWithSearch />
          </CustomSimpleFormIterator>
        </ArrayInput>
        {_.isEmpty(record?.topicIds) && (
          <ArrayInputEmptyComponent
            label="topics"
            onPressCreateButton={onPressCreateFirstTopic}
          />
        )}
      </Collapse>
    </>
  );
};

export default TopicsField;
