import React, { useEffect } from 'react';
import moment from 'moment';

import ResultsList from './ResultsList';

import { ENGLISH_CODE, ContentLanguageCodeToName } from '../../../constants';
import '../../search.css';
import ContentResultItem, { ContentResult } from './items/ContentResultItem';
import withSearchEngine, { SearchProps } from '../../modules/withSearchEngine';

import * as ElasticAppSearch from '../../modules/ElasticAppSearch';

const connector = ElasticAppSearch.createConnector('contents-en');
const searchEngineConfig = {
  apiConnector: connector,
  searchQuery: {
    precision: 8,
    search_fields: {
      title: { weight: 10 },
      subtitle: { weight: 8 },
      tags: { weight: 5 },
    },
    result_fields: {
      title: {
        raw: {},
        snippet: {},
      },
      subtitle: {
        raw: {},
        snippet: {},
      },
      created_at: { raw: {} },
      release_date: { raw: {} },
      hidden: { raw: {} },
      supported_translation_languages: { raw: {} },
      tags: {
        raw: {},
        snippet: {},
      },
      tag_translations: { raw: {} },
    },
  },
};

const transformResult = (result): ContentResult => ({
  id: result.id,
  title: result.title,
  subtitle: result.subtitle,
  releaseDate:
    result.release_date != null
      ? moment(result.release_date).toDate()
      : undefined,
  hidden: result.hidden,
  supportedTranslationLanguages: Object.values(
    result.supported_translation_languages ?? {},
  ),
  tags: result.tags ?? [],
  tagTranslations: result.tag_translations ?? [],
  href: `#/contents/${result.id}`,
});

interface ContentSearchProps {
  onChangeResultQuery: (query: string) => void;
  onChangeSearch: (search: (query: string) => void) => void;
  onChangeResults: (results: ContentResult[]) => void;
}

type SearchResultsWithEngineProps = ContentSearchProps &
  SearchProps<ContentResult>;

const ContentSearch = ({
  onChangeSearch,
  onChangeResultQuery,
  onChangeResults,
  search,
  resultQuery,
  results,
  setResultsPerPage,
}: SearchResultsWithEngineProps) => {
  useEffect(() => {
    setResultsPerPage(20);
  }, [setResultsPerPage]);

  useEffect(() => {
    onChangeSearch(search);
  }, [onChangeSearch, search]);

  useEffect(() => {
    onChangeResultQuery(resultQuery);
  }, [onChangeResultQuery, resultQuery]);

  useEffect(() => {
    onChangeResults(results);
  }, [onChangeResults, results]);

  return (
    <ResultsList
      title={ContentLanguageCodeToName[ENGLISH_CODE]}
      results={results}
      renderItem={(result) => <ContentResultItem result={result} />}
    />
  );
};

export default withSearchEngine<unknown, ContentResult, ContentSearchProps>(
  searchEngineConfig,
  transformResult,
  ContentSearch,
);
