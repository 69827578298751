import React from 'react';
import PropTypes from 'prop-types';
import { TextField, NumberField, SimpleShowLayout, Show } from 'react-admin';

const SeriesShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="title" />
      <NumberField source="contentCount" />
    </SimpleShowLayout>
  </Show>
);

SeriesShow.propTypes = {
  // eslint-disable-next-line react/require-default-props
  record: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    contentCount: PropTypes.number.isRequired,
  }),
};

SeriesShow.defaultProps = {
  record: null,
};

export default SeriesShow;
