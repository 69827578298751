import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useCallback, useEffect, useState } from 'react';
import { getUserDailySummary } from '../../dataProvider/firestoreDataProvider';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    border: 'solid 1px',
    width: 300,
  },
  margin: {
    marginLeft: 20,
  },
  textInput: {
    width: 'fit-content',
  },
  listItem: {
    flexDirection: 'column',
    display: 'flex',
  },
  listContainer: {
    maxHeight: 200,
    maxWidth: 500,
    overflow: 'auto',
    marginBottom: 20,
  },
  listItemTopicContainer: {
    marginTop: 5,
    marginLeft: 5,
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },
  item: {
    marginLeft: 20,
  },
});
const Overview = () => {
  const [lastDocument, setLastDocument] = useState();
  const [userData, setUserData] = useState([]);
  const classes = useStyles();

  const MakeUserData = useCallback(
    async (docs) => {
      const templist = [];

      docs.map((doc, i) => {
        const { collectedPoints } = doc.data();
        const temp = (
          <div className={classes.listItemTopicContainer} key={doc.id}>
            <span className={classes.item}>{i + 1}</span>
            <span className={classes.item}>{doc.id}</span>
            <span className={classes.item}>{collectedPoints}</span>
          </div>
        );
        templist.push(temp);
        return temp;
      });
      setUserData(templist);
      return templist;
    },
    [classes.item, classes.listItemTopicContainer],
  );

  const LoadData = useCallback(async () => {
    let query = await getUserDailySummary();
    if (lastDocument !== undefined) {
      query = query.startAfter(lastDocument);
    }

    query
      .limit(30)
      .get()
      .then((querySnapshot) => {
        setLastDocument(querySnapshot.docs[querySnapshot.docs.length - 1]);
        MakeUserData(querySnapshot.docs);
      });
  }, [MakeUserData, lastDocument]);

  useEffect(() => {
    LoadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div>
        <div>{userData}</div>
        <div>
          <Button onClick={LoadData}>더보기</Button>
        </div>
      </div>
    </>
  );
};

export default Overview;
