import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  List,
  Filter,
  Datagrid,
  TextField,
  SelectInput,
  ReferenceInput,
  EditButton,
  Pagination,
  ReferenceArrayField,
} from 'react-admin';
import { Button } from '@material-ui/core';

import JSUtility from '../../utilities/JSUtility';
import ContentShow from './ContentShow';
import {
  DurationField,
  FragmentCountField,
  ScrollToTopButton,
  ShortenedDateField,
  ArrayTypeField,
} from '../../common';

// import { RenderWorkingStatusButton } from './component/UpdateWorkingStatus';
import NestedObjectKeyTypeField from '../../common/fields/NestedObjectKeyTypeField';
import {
  ContentLanguages,
  BooleanChoices,
  KST_OFFSET_IN_MIN,
  LanguageCodes,
  LevelChoices,
} from '../../constants';

const styles = {
  youTubeVideoButton: {
    marginLeft: 5,
  },
  youtubeVideoImage: {
    width: 100,
  },
  titleContainer: {
    width: 200,
  },
  checkBoxContainer: {
    width: 'auto',
  },
  playButton: {
    color: 'red',
  },
};

const ContentFilter = (props) => (
  <Filter {...props}>
    <SelectInput
      label="Language"
      source="languageCode"
      choices={ContentLanguages}
      alwayson="true"
      allowEmpty={false}
    />
    <SelectInput
      label="Hidden"
      source="_dev"
      choices={BooleanChoices}
      alwayson="true"
      allowEmpty={false}
    />
    <ReferenceInput
      // `tagKeys` is array, so it searches record that contains selected value.
      label="Tags"
      source="tagKeys"
      reference="tags"
      allowEmpty
      perPage={500}
      sort={{ field: 'languageCodeToName.en', order: 'ASC' }}
    >
      <SelectInput optionText="languageCodeToName.en" />
    </ReferenceInput>
    <SelectInput
      label="Supported Translations"
      source="supportedTranslationLanguages"
      choices={LanguageCodes}
    />
    <SelectInput label="Level" source="level" choices={LevelChoices} />
    <SelectInput
      label="Premium"
      source="isPremiumContent"
      choices={BooleanChoices}
      allowEmpty={false}
    />
  </Filter>
);

const ThumbnailField = ({ record }) => {
  const convertedTimestampOfStartTime = JSUtility.convertTimestampToSeconds(
    record.startTime,
  );
  const youTubeUrl = `https://www.youtube.com/watch?v=${record.youTubeVideoId}&feature=youtu.be&t=${convertedTimestampOfStartTime}`;
  const imageUrl = `https://img.youtube.com/vi/${record.youTubeVideoId}/default.jpg`;
  return (
    <Button href={youTubeUrl} target="_blank" style={styles.youTubeVideoButton}>
      <img
        src={imageUrl}
        alt={`${record.youTubeVideoId}`}
        style={styles.youtubeVideoImage}
      />
    </Button>
  );
};

const ContentList = ({ ...props }) => {
  useEffect(() => {
    document.title = 'TMS: Content';
  }, []);

  return (
    <>
      <List
        filters={<ContentFilter />}
        filterDefaultValues={{ languageCode: 'en', _dev: true }}
        pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
        perPage={25}
        sort={{ field: 'releaseDate.seconds', order: 'DESC' }}
        bulkActionButtons={false}
        exporter={false}
        {...props}
      >
        <Datagrid rowClick="expand" expand={<ContentShow />}>
          {/* <RenderWorkingStatusButton label="Latest Task/Status" {...props} /> */}
          <ThumbnailField label="Thumbnail" />
          <TextField source="title" style={styles.titleContainer} />
          <ShortenedDateField
            label="Created Date"
            source="createdAt.seconds"
            type="timestamp"
            utcOffset={KST_OFFSET_IN_MIN}
          />
          <ShortenedDateField
            label="Release Date"
            source="releaseDate.seconds"
            type="timestamp"
            utcOffset={KST_OFFSET_IN_MIN}
          />
          <TextField source="level" />
          <DurationField label="Duration" />
          <FragmentCountField label="Fragment Count(size)" />
          <TextField label="Commentary Count" source="publicCommentaryCount" />
          <ArrayTypeField
            label="Supported Translations"
            source="supportedTranslationLanguages"
          />
          <ReferenceArrayField label="Tags" source="tagKeys" reference="tags">
            <NestedObjectKeyTypeField source="languageCodeToName.en" />
          </ReferenceArrayField>
          <EditButton />
        </Datagrid>
      </List>
      <ScrollToTopButton category={0} />
    </>
  );
};

// Workaround: To remove eslint warning
ThumbnailField.propTypes = {
  record: PropTypes.shape({
    youTubeVideoId: PropTypes.string,
    startTime: PropTypes.string,
  }),
};

ThumbnailField.defaultProps = {
  record: {
    youTubeVideoId: null,
    startTime: null,
  },
};

export default ContentList;
