import React from 'react';

export interface TagResult {
  id: string;
  tags: string[];
  category: string;
  hidden: boolean;
  learningLanguageCodes: string[];
  tagTranslations: string[];
  href: string;
}

interface TagResultItemProps {
  result: TagResult;
}

const QuestiontResultItem = (props: TagResultItemProps): JSX.Element => {
  const {
    result: { tags, category, hidden, learningLanguageCodes, tagTranslations },
  } = props;

  return (
    <div>
      <div className="hit-tag">
        <span dangerouslySetInnerHTML={{ __html: tags.sort().join(', ') }} />
      </div>
      <div className="hit-categoryAndPublic">
        Category: {category}
        &nbsp;/&nbsp;
        {hidden ? 'Not Public' : 'Public'}
      </div>
      <div className="hit-learningLanguageCodes">
        Learning language code:&nbsp;
        {learningLanguageCodes.length > 0
          ? learningLanguageCodes.sort().join(', ')
          : ' - '}
      </div>
      <div className="hit-languageCodeToName">
        Translations:&nbsp;
        {tagTranslations.length > 0 ? (
          <span
            dangerouslySetInnerHTML={{
              __html: tagTranslations.sort().join(', '),
            }}
          />
        ) : (
          ' - '
        )}
      </div>
    </div>
  );
};

export default QuestiontResultItem;
