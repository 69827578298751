import FragmentIcon from '@material-ui/icons/LibraryBooks';

import FragmentList from './FragmentList';
import FragmentEdit from './FragmentEdit';
import FragmentCreate from './FragmentCreate';

export default {
  list: FragmentList,
  create: FragmentCreate,
  edit: FragmentEdit,
  icon: FragmentIcon,
};
