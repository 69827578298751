import React, { createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';

const AutomationContext = createContext();

export const useAutomationContext = () => useContext(AutomationContext);

export const AutomationContextProvider = ({ children }) => {
  const [sonixId, setSonixId] = useState('');
  const [startTimeParsed, setStartTimeParsed] = useState(0);
  const [endTimeParsed, setEndTimeParsed] = useState(0);

  return (
    <AutomationContext.Provider
      value={{
        sonixId,
        setSonixId,
        startTimeParsed,
        setStartTimeParsed,
        endTimeParsed,
        setEndTimeParsed,
      }}
    >
      {children}
    </AutomationContext.Provider>
  );
};

AutomationContextProvider.propTypes = {
  children: PropTypes.node,
};
