import React, { useEffect } from 'react';
import { List, Datagrid, TextField, EditButton, Pagination } from 'react-admin';

import { ScrollToTopButton, ShortenedDateField } from '../../common';

const L2eMonthlyPointLogsList = (props) => {
  useEffect(() => {
    document.title = 'TMS: L2eMonthlyPointLogs';
  }, []);

  return (
    <>
      <List
        pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
        perPage={25}
        bulkActionButtons={false}
        exporter={false}
        title="L2e Redemption History"
        {...props}
      >
        <Datagrid rowClick="expand">
          <TextField label="Period" source="id" />
          <TextField
            label="Total Collected Points"
            source="totalCollectedPoints"
          />
          <TextField
            label="Total Max Collected Points"
            source="totalMaxCollectedPoints"
          />
          <TextField
            label="User Max Collected Points"
            source="userMaxCollectedPoints"
          />
          <TextField
            label="Max Redeemed Points"
            source="userMaxRedeemedPoints"
          />
          <TextField label="Min Redeemed Points" source="userMinRedeemPoints" />
          <ShortenedDateField
            label="Created"
            source="createdAt.seconds"
            type="timestamp"
          />
          <ShortenedDateField
            label="Updated"
            source="updatedAt.seconds"
            type="timestamp"
          />
          <EditButton />
        </Datagrid>
      </List>
      <ScrollToTopButton category={0} />
    </>
  );
};

export default L2eMonthlyPointLogsList;
