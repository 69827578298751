import React from 'react';
import PropTypes from 'prop-types';
import { TextInput } from 'react-admin';
import Button from '@material-ui/core/Button';

const styles = {
  button: {
    color: 'white',
    backgroundColor: '#BFBFBF',
    borderRadius: 0,
    height: 48,
    marginTop: 8,
    marginRight: 10,
    marginBottom: 25,
  },
};

const TextInputWithButton = ({
  source,
  style,
  disabled = false,
  onClickHandler,
  icon,
  width = 100,
  href = null,
  ...rest
}) => (
  <>
    <TextInput
      source={source}
      placeholder="id"
      style={{ ...style, width }}
      disabled={disabled}
      {...rest}
    />
    <Button
      onClick={onClickHandler}
      href={href}
      target="_blank"
      style={styles.button}
    >
      {icon}
    </Button>
  </>
);

// Workaround: To remove eslint warning
TextInputWithButton.propTypes = {
  source: PropTypes.string.isRequired,
  // eslint-disable-next-line react/require-default-props
  disabled: PropTypes.bool,
  onClickHandler: PropTypes.func.isRequired,
  icon: PropTypes.shape({}).isRequired,
  // eslint-disable-next-line react/require-default-props
  width: PropTypes.number,
  style: PropTypes.shape({}),
  href: PropTypes.string,
};

export default TextInputWithButton;
