import React, { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';

import ResultsList from './ResultsList';

import { ENGLISH_CODE, ContentLanguageCodeToName } from '../../../constants';
import '../../search.css';
import LemmaResultItem, { LemmaResult } from './items/LemmaResultItem';
import withSearchEngine, { SearchProps } from '../../modules/withSearchEngine';

import * as ElasticAppSearch from '../../modules/ElasticAppSearch';

const connector = ElasticAppSearch.createConnector('lemmas-en');
const searchEngineConfig = {
  apiConnector: connector,
  searchQuery: {
    precision: 8,
    search_fields: {
      lemma: { weight: 10 },
      related_words: { weight: 5 },
    },
    result_fields: {
      lemma: {
        raw: {},
        snippet: {},
      },
      difficulty_level: { raw: {} },
      hidden: { raw: {} },
      related_words: { raw: {}, snippet: {} },
    },
  },
};

const transformResult = (result): LemmaResult => ({
  id: result.id,
  lemma: result.lemma,
  difficultyLevel: result.difficulty_level,
  hidden: result.hidden,
  relatedWords: result.related_words ?? [],
  href: `#/lemmas/${result.id}`,
});

interface LemmaSearchProps {
  onChangeResultQuery: (query: string) => void;
  onChangeSearch: (search: (query: string) => void) => void;
  onChangeResults: (results: LemmaResult[]) => void;
}

type SearchResultsWithEngineProps = LemmaSearchProps & SearchProps<LemmaResult>;

const LemmaSearch = ({
  onChangeSearch,
  onChangeResultQuery,
  onChangeResults,
  search,
  resultQuery,
  results,
  setResultsPerPage,
  addFilter,
  clearFilters,
}: SearchResultsWithEngineProps) => {
  const [query, setQuery] = useState('');

  const searchWithFilter = useCallback(
    (q: string) => {
      /*
    Elastic App Search provides stop words. So, stop words (e.g., `is`) is not searched.
    So, we should use filter not to use stopwords.
    */

      clearFilters();

      search('');
      // `addFilter` applys `all` for root filter.
      // This is a use case to use `any` for root filter.
      // filter: { all: { any: [{ any: { lemma: 1 } }, { any: related_words: 1 }] } }
      addFilter('any', { lemma: q }, 'any');
      addFilter('any', { related_words: q }, 'any'); // addFilter will requests search with empty string

      setQuery(q);
    },
    [search, addFilter, clearFilters],
  );

  useEffect(() => {
    setResultsPerPage(10);
  }, [setResultsPerPage]);

  useEffect(() => {
    onChangeSearch(searchWithFilter);
  }, [onChangeSearch, searchWithFilter]);

  useEffect(() => {
    onChangeResultQuery(resultQuery);
  }, [onChangeResultQuery, resultQuery]);

  useEffect(() => {
    onChangeResults(results);
  }, [onChangeResults, results]);

  const highlightedResults = useMemo(
    () =>
      results.map((result) => {
        const lemma = _.chain(result.lemma)
          .replace(query, `<em>${query}</em>`)
          .value();
        const relatedWords = result.relatedWords.map((w) =>
          _.chain(w).replace(query, `<em>${query}</em>`).value(),
        );
        return {
          ...result,
          lemma,
          relatedWords,
        };
      }),
    [query, results],
  );

  return (
    <ResultsList
      title={ContentLanguageCodeToName[ENGLISH_CODE]}
      results={highlightedResults}
      renderItem={(result) => <LemmaResultItem result={result} />}
    />
  );
};

export default withSearchEngine<unknown, LemmaResult, LemmaSearchProps>(
  searchEngineConfig,
  transformResult,
  LemmaSearch,
);
