import SeriesIcon from '@material-ui/icons/Subscriptions';
import SeriesList from './SeriesList';
import SeriesEdit from './SeriesEdit';
import SeriesCreate from './SeriesCreate';

export default {
  list: SeriesList,
  create: SeriesCreate,
  edit: SeriesEdit,
  icon: SeriesIcon,
};
