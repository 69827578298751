import React from 'react';
import { Create } from 'react-admin';

import GrammarActions from './GrammarActions';
import GrammarForm from './GrammarForm';

const GrammarCreate = (props) => (
  <Create actions={<GrammarActions basePath="/grammars" />} {...props}>
    <GrammarForm mode="create" />
  </Create>
);

export default GrammarCreate;
