import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useGetList, Loading } from 'react-admin';
import { Link as LinkIcon } from '@material-ui/icons';
import { Button } from '@material-ui/core';

import { LINK_TO_TMS } from '../../../constants';

const styles = {
  horizontalWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
  },
};

const QuestionsOnSpecificFragment = ({ questionId = null, fragmentKey }) => {
  const [questionListExpanded, setQuestionListExpanded] = useState(false);

  const handleExpandButtonClick = useCallback(() => {
    setQuestionListExpanded(!questionListExpanded);
  }, [questionListExpanded]);

  const { data, loading, error } = useGetList(
    'questions',
    { perPage: 100, page: 1 },
    null,
    { fragmentKey },
  );

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <p>ERROR</p>;
  }

  if (questionId != null) {
    delete data[questionId];
  }

  const questionValues = Object.values(data);

  /* eslint-disable max-len */
  const publishedQuestionValues = questionValues.filter(
    (v) => v.published === true,
  );
  const willBePublicQuestionValues = questionValues.filter(
    (v) => v.published === false && v.willBePublic === true,
  );
  const answeredQuestionValues = questionValues.filter(
    (v) =>
      v.published === false &&
      v.willBePublic === false &&
      _.get(v, 'answer', null) != null,
  );
  const notAnsweredQuestionValues = questionValues.filter(
    (v) =>
      v.published === false &&
      v.willBePublic === false &&
      _.get(v, 'answer', null) == null,
  );
  /* eslint-enable max-len */

  const QuestionList = [
    ...publishedQuestionValues,
    ...willBePublicQuestionValues,
    ...answeredQuestionValues,
    ...notAnsweredQuestionValues,
  ].map((v) => {
    const quesTrans = _.get(v, 'originalTextTranslations', []);
    const quesEngTransElement =
      quesTrans.find((e) => e.languageCode === 'en') || {};
    const quesEngTrans = quesEngTransElement.text;
    const answer = _.get(v, 'answer', null);
    const answTrans = _.get(v, 'answer.originalTextTranslations', null);
    const answTransLang =
      answTrans == null
        ? null
        : answTrans.reduce((acc, tran) => acc.concat(tran.languageCode), []);

    return (
      <div
        key={`questions-${v.id}`}
        style={{
          ...styles.horizontalWrapper,
          color: answTrans == null ? 'gray' : 'black',
        }}
      >
        {
          // eslint-disable-next-line no-nested-ternary
          v.published ? '★' : v.willBePublic ? '☆' : '-'
        }
        {` [ID: ${v.id}] `}
        {` ${quesEngTrans || v.originalText} `}
        (Q.
        {v.published ? ' Published ' : ' Not published '}/ A.
        {
          // eslint-disable-next-line no-nested-ternary
          answer == null
            ? ' Not answered '
            : _.get(v, 'answer.published')
            ? ' Published '
            : ' Not published '
        }
        {answTransLang == null
          ? null
          : `(Lang: ${
              answTransLang.length === 0
                ? 'Unknown'
                : answTransLang.sort().join(', ')
            })`}
        )
        <a
          href={`${LINK_TO_TMS}/questions/${v.id}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{ justifyContent: 'center' }}
        >
          <LinkIcon />
        </a>
      </div>
    );
  });

  const questionListLength = QuestionList.length;

  return _.isEqual(questionValues, []) ? null : (
    <>
      <h3>{`${questionListLength} Question${
        questionListLength > 1 ? 's' : ''
      } on this fragment`}</h3>
      {QuestionList.slice(0, 5)}
      {questionListLength > 5 ? (
        <Button
          onClick={handleExpandButtonClick}
          style={{ width: 200, backgroundColor: 'lightgray' }}
        >
          {questionListExpanded ? '∧ Hide' : '∨ Show More'}
        </Button>
      ) : null}
      {questionListExpanded ? QuestionList.slice(5) : null}
    </>
  );
};

QuestionsOnSpecificFragment.propTypes = {
  // eslint-disable-next-line react/require-default-props
  questionId: PropTypes.string,
  fragmentKey: PropTypes.string.isRequired,
};

export default QuestionsOnSpecificFragment;
