import React, { useCallback, useState, useMemo } from 'react';
import {
  ArrayInput,
  TextInput,
  BooleanInput,
  regex,
  required,
} from 'react-admin';
import { useForm } from 'react-final-form';
import PropTypes from 'prop-types';
import { Collapse, IconButton } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

import JSUtility from '../../../utilities/JSUtility';
import { CustomSimpleFormIterator } from '../../../common';
import { RegexNoSpace, RegexLastNewline } from '../../../constants';
import LemmaInputWithSearch from './LemmaInputWithSearch';

const styles = {
  expandedArrow: {
    transform: 'rotate(180deg)',
  },
  studyableWord: {
    fontWeight: 'bold',
  },
  notStudyableWord: {
    color: 'gray',
  },
  updated: {
    backgroundColor: '#fad0c3',
  },
  smallListener: {
    width: 300,
  },
};

const makeWordsElement = (word, id, lemmaId, meanings, pronunciations) => ({
  id: id || '',
  lemmaId: lemmaId || '',
  meanings: meanings || {},
  pronunciations: pronunciations || {},
  studyable: true,
  word: word || {
    normal: '',
    text: '',
    textAfter: '',
    textBefore: '',
  },
});

const emptyWordElement = makeWordsElement();

const WordsField = ({ record = {}, isDirty }) => {
  const [isWordsFieldMade, setIsWordsFieldMade] = useState(
    record.words != null,
  );
  const [isExpanded, setIsExpanded] = useState(false);

  const form = useForm();

  const languageCodeOfCurrentFragment = record.languageCode;
  const onClickExpand = useCallback(
    () => setIsExpanded(!isExpanded),
    [isExpanded],
  );

  const wordValidate = [
    required('Please fill the word.'),
    regex(RegexNoSpace, 'Space is not allowed for word.'),
  ];

  const createWordsField = useCallback(() => {
    if (record.words) {
      return;
    }
    const splitText = JSUtility.nlp(record.text || '');
    const words = splitText.map((word) => makeWordsElement(word));
    form.change('words', words);
    setIsWordsFieldMade(true);
  }, [record.words, record.text, form]);

  const renderJoinedWords = useCallback(() => {
    const { words, keepable } = record;
    if (words) {
      const studyableWords = words.map((w, index) => {
        if (w.word.text === '') {
          return null;
        }

        // Using text to update other fields (normal, textBefore, textAfter) in word field.
        const [wordObject] = JSUtility.nlp(w.word.text || '');
        if (wordObject.normal !== w.word.normal) {
          words[index].word = wordObject;
          form.change('words', words);
        }

        const textAfter = words.length === index + 1 ? '' : ' ';
        const wordText = `${w.word.textBefore}${w.word.text}${textAfter}`;

        const key = `${record.id}-${index}-${w.word.text}`;
        return (
          <span
            key={key}
            style={
              keepable && w.studyable
                ? styles.studyableWord
                : styles.notStudyableWord
            }
          >
            {wordText}
          </span>
        );
      });

      const updatedTextField = words.reduce(
        (acc, w) =>
          // Space is not needed in text when
          // 1. adding the first word.text to acc
          // 2. word has textBefore value (Already contains space in it)
          // 3. word.text is empty string
          // 4. previous word.textAfter is \n (Start of the newline doesn't need space)
          acc === '' ||
          w.word.textBefore !== '' ||
          w.word.text === '' ||
          acc.search(RegexLastNewline) === acc.length - 1
            ? `${acc}${w.word.textBefore}${w.word.text}${w.word.textAfter}`
            : `${acc} ${w.word.text}${w.word.textAfter}`,
        '',
      );
      form.change('text', updatedTextField);

      return studyableWords;
    }
    return null;
  }, [form, record]);

  const studyableWordCount = useMemo(() => {
    const { words } = record;
    if (words) {
      const count = words.reduce(
        (acc, word) => (word.studyable ? acc + 1 : acc),
        0,
      );
      return count;
    }
    return 0;
  }, [record]);

  const studyableWordCountStyle = useMemo(
    () => ({ color: studyableWordCount > 20 ? 'red' : 'black' }),
    [studyableWordCount],
  );

  return (
    <>
      {isWordsFieldMade ? null : createWordsField()}
      <span>
        <IconButton
          style={isExpanded ? styles.expandedArrow : null}
          onClick={onClickExpand}
        >
          <ExpandMoreIcon />
        </IconButton>
        {isExpanded ? 'Hide Words: ' : 'Show Words: '}
        <span style={isDirty ? styles.updated : null}>
          {renderJoinedWords()}
        </span>
        <span style={studyableWordCountStyle}>
          {` (${studyableWordCount} studyable word${
            studyableWordCount > 1 ? 's' : ''
          })`}
        </span>
      </span>
      <Collapse in={isExpanded} timeout="auto">
        <ArrayInput source="words" record={record}>
          <CustomSimpleFormIterator defaultAddValue={emptyWordElement}>
            <TextInput
              label="Word"
              source="word.text"
              validate={wordValidate}
              parse={(v) => v.replace(/’/g, "'")}
            />
            <BooleanInput
              label="Studyable?"
              source="studyable"
              disabled={!record.keepable}
              style={styles.smallListener}
            />
            <LemmaInputWithSearch source="lemmaId" />
            {languageCodeOfCurrentFragment === 'zh' && (
              <TextInput label="Meaning" source="meanings.ko" />
            )}
            {languageCodeOfCurrentFragment &&
              languageCodeOfCurrentFragment !== 'en' && (
                <TextInput label="Pronunciation" source="pronunciations.en" />
              )}
          </CustomSimpleFormIterator>
        </ArrayInput>
      </Collapse>
    </>
  );
};

WordsField.propTypes = {
  // eslint-disable-next-line react/require-default-props
  record: PropTypes.shape({
    text: PropTypes.string,
    languageCode: PropTypes.string,
  }),
  isDirty: PropTypes.bool.isRequired,
};

export default React.memo(WordsField);
