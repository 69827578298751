import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { RadioButtonUncheckedOutlined, Clear } from '@material-ui/icons';

const CustomBooleanField = ({ source, record = {}, placeholder = '-' }) => {
  const value = _.get(record, source);

  if (value == null || (_.isArray(value) && _.isEmpty(value))) {
    return placeholder;
  }

  return value ? (
    <RadioButtonUncheckedOutlined fontSize="small" />
  ) : (
    <Clear fontSize="small" />
  );
};

CustomBooleanField.propTypes = {
  source: PropTypes.string.isRequired,
  // eslint-disable-next-line react/require-default-props
  record: PropTypes.shape({}),
  // eslint-disable-next-line react/require-default-props
  placeholder: PropTypes.string,
};

export default CustomBooleanField;
