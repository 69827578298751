import ApolloClientHelper from './ApolloClientHelper';
import Queries from './graphqlQueries';

export default {
  getIsCompletedContent: async (contentKey, targetUserId) => {
    const apolloClient = ApolloClientHelper.getInstance();

    const {
      data: {
        isCompletedContent: { isCompleted },
      },
    } = await apolloClient.query({
      query: Queries.IS_COMPLETED_CONTENT,
      variables: {
        input: {
          contentKey,
          targetUserId,
        },
      },
    });

    return isCompleted;
  },
};
