import { Edit } from 'react-admin';
import React from 'react';
// import ContentRewardActions from './ContentRewardActions';
import ContentRewardForm from './ContentRewardForm';

const ContentRewardEdit = (props) => (
  <Edit
    // actions={<ContentRewardActions basePath="/pointLogs" />}
    {...props}
  >
    <ContentRewardForm mode="update" />
  </Edit>
);

export default ContentRewardEdit;
