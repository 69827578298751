import React from 'react';
import { Edit } from 'react-admin';

import UserForm from './UserForm';
import UserActions from './UserActions';

const UserEdit = (props) => (
  <Edit {...props} actions={<UserActions basePath="/users" />}>
    <UserForm mode="update" />
  </Edit>
);

export default UserEdit;
