import React, { forwardRef, useCallback } from 'react';
import { useLogout } from 'react-admin';
import MenuItem from '@material-ui/core/MenuItem';
import ExitIcon from '@material-ui/icons/PowerSettingsNew';

const MyLogoutButton = forwardRef((props, ref) => {
  const logout = useLogout();
  const handleClick = useCallback(() => {
    logout('./LoginPage');
  }, [logout]);

  return (
    <MenuItem onClick={handleClick} ref={ref}>
      <ExitIcon />
      Logout
    </MenuItem>
  );
});

export default MyLogoutButton;
