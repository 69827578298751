/* eslint-disable react/require-default-props */
// This code is from react-admin source code.
import React from 'react';
import { Labeled } from 'react-admin';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

const sanitizeRestProps = ({ basePath, record, ...rest }) => rest;

const useStyles = makeStyles(
  (theme) => ({
    input: { width: theme.spacing(32) },
  }),
  { name: 'RaFormInput' },
);

const FormInput = ({ input, classes: classesOverride, ...rest }) => {
  const classes = useStyles({ classes: classesOverride });
  return input ? (
    <div
      className={classnames(
        'ra-input',
        `ra-input-${input.props.source}`,
        input.props.formClassName,
      )}
    >
      {input.props.addLabel ? (
        <Labeled
          id={input.props.id || input.props.source}
          {...input.props}
          {...sanitizeRestProps(rest)}
        >
          {React.cloneElement(input, {
            className: classnames(
              {
                [classes.input]: !input.props.fullWidth,
              },
              input.props.className,
            ),
            id: input.props.id || input.props.source,
            ...rest,
          })}
        </Labeled>
      ) : (
        React.cloneElement(input, {
          className: classnames(
            {
              [classes.input]: !input.props.fullWidth,
            },
            input.props.className,
          ),
          id: input.props.id || input.props.source,
          ...rest,
        })
      )}
    </div>
  ) : null;
};

FormInput.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.shape({
    input: PropTypes.string,
  }),
  input: PropTypes.shape({
    props: PropTypes.shape({
      addLabel: PropTypes.bool,
      className: PropTypes.string,
      formClassName: PropTypes.string,
      fullWidth: PropTypes.bool,
      id: PropTypes.string,
      source: PropTypes.string,
    }),
  }),
};

// TypeScript looses the displayName if it does not set explicitly
FormInput.displayName = 'FormInput';

export default FormInput;
