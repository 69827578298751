import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  SimpleForm,
  BooleanInput,
  CloneButton,
  TextInput,
  ArrayInput,
  SelectInput,
  SimpleFormIterator,
  useGetOne,
  Loading,
  regex,
  DateInput,
} from 'react-admin';
import { useForm } from 'react-final-form';
import _ from 'lodash';
import { Button } from '@material-ui/core';
import {
  YouTube as YouTubeIcon,
  FileCopy as CopyIcon,
  Link as LinkIcon,
} from '@material-ui/icons';
import firebase, { firestore } from 'firebase';

import moment from 'moment';
import {
  CommonAnswersButton,
  OriginalTextCopyButton,
  QuestionsOnSpecificFragment,
  QnAPosting,
} from './components';
import JSUtility from '../../utilities/JSUtility';
import { CustomEditToolbar } from '../../common';
import {
  KOREAN_CODE,
  JAPANESE_CODE,
  AvailableLanguages,
  ContentLanguages,
  RegexNoOnlySpaceOrNewline,
  KST_OFFSET_IN_MIN,
  CommentaryLabelChoices,
} from '../../constants';
import { ContentMembers, QnAUploadToOptions } from './questionsConstants';

const styles = {
  horizontalWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
  },
  updated: {
    backgroundColor: '#fad0c3',
  },
  updatedArray: {
    border: '5px solid',
    borderColor: '#fad0c3',
    padding: 5,
  },
  baseArray: {
    border: '5px solid',
    borderColor: 'white',
    padding: 5,
  },
  fieldBase: {
    width: 250,
    marginRight: 10,
  },
  buttonBase: {
    marginRight: 10,
  },
  wideTextInput: {
    marginRight: 10,
    width: 280,
  },
  subText: {
    marginLeft: 10,
    color: 'charcoal',
  },
  smallListener: {
    width: 200,
  },
};

const validateTextInput = [
  regex(RegexNoOnlySpaceOrNewline, 'Just space or newline is forbidden.'),
];

const MetaDataField = ({ record = {}, mode, dataType }) => {
  const isQuestion = dataType === 'Question';

  const questionDocument = record;
  const answerDocument = record.answer;
  const targetDocument = isQuestion ? questionDocument : answerDocument;

  if (mode === 'create' || _.get(targetDocument, 'id') == null) {
    return null;
  }

  const sourceForId = `${isQuestion ? '' : 'answer.'}id`;

  return (
    <>
      <TextInput
        disabled
        label={`${dataType} Id`}
        source={sourceForId}
        style={{ ...styles.fieldBase, width: 200 }}
      />
      <TextInput
        disabled
        label="Created At"
        format={(v) => v.slice(0, 16)}
        source={`${isQuestion ? '' : 'answer'}.createdAt`}
        style={{ ...styles.fieldBase, width: 160 }}
      />
    </>
  );
};

MetaDataField.propTypes = {
  // eslint-disable-next-line react/require-default-props
  record: PropTypes.shape({
    answer: PropTypes.shape({}),
  }),
  mode: PropTypes.string.isRequired,
  dataType: PropTypes.oneOf(['Question', 'Answer']).isRequired,
};

const ObjectArrayInput = ({ dataType, dirtyFields = {} }) => {
  const isQuestion = dataType === 'Question';

  return (
    <div
      style={
        _.get(
          dirtyFields,
          `${isQuestion ? '' : 'answer.'}originalTextTranslations`,
          null,
        )
          ? styles.updatedArray
          : styles.baseArray
      }
    >
      <ArrayInput
        label={`${dataType} Translation`}
        source={`${isQuestion ? '' : 'answer.'}originalTextTranslations`}
      >
        <SimpleFormIterator>
          <SelectInput
            label="Language Code"
            source="languageCode"
            choices={AvailableLanguages}
          />
          <TextInput
            fullWidth
            multiline
            label="Translation"
            source="text"
            validate={validateTextInput}
          />
        </SimpleFormIterator>
      </ArrayInput>
    </div>
  );
};

ObjectArrayInput.propTypes = {
  dataType: PropTypes.oneOf(['Question', 'Answer']).isRequired,
  // eslint-disable-next-line react/require-default-props
  dirtyFields: PropTypes.shape({}),
};

const FragmentTranslation = ({ fragmentKey }) => {
  const { data, loading, error } = useGetOne('fragments', fragmentKey);
  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <p>ERROR</p>;
  }

  const { textTranslations: translations = {} } = data;

  return (
    <div>
      {Object.keys(translations).map((langCode) => (
        <p key={langCode} style={styles.subText}>
          {`${langCode}: ${translations[langCode]}`}
        </p>
      ))}
      <p style={{ ...styles.subText, color: 'gray' }}>
        (To see translaton from Gengo, please go to fragment document.)
      </p>
    </div>
  );
};

const validateQuestionFormValues = (values) => {
  // It validates code below every time when there is any change.
  const errors = {
    originalTextTranslations: [{}],
    answer: { originalTextTranslations: [{}] },
  };

  const isQuestionPublish = _.get(values, 'published') === true;
  const isAnswerPublish = _.get(values, 'answer.published') === true;

  const questionTranslations = _.get(values, 'originalTextTranslations', []);
  const quesTransLangs = questionTranslations.reduce(
    (acc, v) => acc.concat(_.get(v, 'languageCode')),
    [],
  );

  const answerTranslations = _.get(
    values,
    'answer.originalTextTranslations',
    [],
  );
  const answerTransLangs = answerTranslations.reduce(
    (acc, v) => acc.concat(_.get(v, 'languageCode')),
    [],
  );

  // All question and answer
  // Validate 1: Check duplicate language code
  const isQuesTransLangDuplicated =
    quesTransLangs.length !== _.uniq(quesTransLangs).length;
  const isAnswerTransLangDuplicated =
    answerTransLangs.length !== _.uniq(answerTransLangs).length;

  if (isQuesTransLangDuplicated || isAnswerTransLangDuplicated) {
    alert('Language code of the translation is duplicated.'); // eslint-disable-line no-alert
    return errors;
  }

  const questionLabel = _.get(values, 'label', null);

  // Question to be published
  if (isQuestionPublish) {
    // Validate 2: Answer should be published beforehand.
    if (!isAnswerPublish) {
      errors.published =
        'Answer should be published first to publish the question.';
    }

    // Validate 3: Question and Answer should have 3 translations.
    const mustHaveLanguages = ['en', 'ja', 'ko'];

    const isQuesTransQualified =
      _.difference(mustHaveLanguages, quesTransLangs).length === 0;
    const isAnswerTransQualified =
      _.difference(mustHaveLanguages, answerTransLangs).length === 0;

    if (!isQuesTransQualified) {
      const errorMsg =
        'Question should have Eng, Kor, Jap translation to publish the question.';
      // ToDo: #7023
      errors.published =
        errors.published == null
          ? errorMsg
          : errors.published.concat(` / ${errorMsg}`);
    }

    if (!isAnswerTransQualified) {
      const errorMsg =
        'Answer should have Eng, Kor, Jap translation to publish the question.';
      // ToDo: #7023
      errors.published =
        errors.published == null
          ? errorMsg
          : errors.published.concat(` / ${errorMsg}`);
    }

    // Validation 4: Questioner and Answerer should not be same.
    // const questioner = _.get(values, 'author.id');
    // const answerer = _.get(values, 'answer.author.id');

    // if (questioner != null && answerer != null && questioner === answerer) {
    //   const errorMsg = 'Questioner can\'t be the same person as answerer.';
    //   errors.published = (errors.published == null)
    //     ? errorMsg
    //     : errors.published.concat(` / ${errorMsg}`);
    // }

    if (_.isNil(questionLabel)) {
      const errorMsg = 'Question should be labeled to publish the question.';

      errors.published =
        errors.published == null
          ? errorMsg
          : errors.published.concat(` / ${errorMsg}`);
    }
  }

  // Answer to be published
  const userLanguage = values.userLanguageCode;

  if (isAnswerPublish) {
    // Validate 4: Answer should have user language translation to be published.
    if (userLanguage == null) {
      // Some users do not have language code in the device.
      return errors;
    }

    if ([KOREAN_CODE, JAPANESE_CODE].includes(userLanguage)) {
      // This validation is currently only applied to Japanese and Korean only.

      const isAnswerTransQualified = answerTransLangs.includes(userLanguage);

      if (!isAnswerTransQualified) {
        errors.answer.published = `It should have user's language translation (${userLanguage}) to publish the answer.`;
      }
    }
  }

  return errors;
};

const formatPostingDate = (dateInKST) => {
  if (!dateInKST) {
    return null;
  }
  const transformedDate =
    dateInKST instanceof firestore.Timestamp ? dateInKST.toDate() : dateInKST;
  const kstNormalizedDate =
    moment(transformedDate).utcOffset(KST_OFFSET_IN_MIN);
  return kstNormalizedDate.format('YYYY-MM-DD');
};

const parsePostingDate = (dateInKST) => {
  if (!dateInKST) {
    return null;
  }
  const kstNormalizedDate = moment(dateInKST).utcOffset(
    KST_OFFSET_IN_MIN,
    true,
  );
  return kstNormalizedDate.toDate();
};

const tomorrowDate = parsePostingDate(moment().startOf('day').add(1, 'days'));

const QuestionForm = (props) => {
  const { mode, record = {}, ...otherProps } = props;

  const isCreating = mode === 'create';

  return (
    <SimpleForm
      {...otherProps}
      mode={mode}
      record={{
        ...record,
        qnaPostingDate: _.isNil(record.qnaPostingDate)
          ? null
          : _.isArray(record.qnaPostingDate)
          ? record?.qnaPostingDate.reduce(
              (acc, element) => ({
                ...acc,
                [element.uploadTo]: element.postingDate,
              }),
              {},
            )
          : record.qnaPostingDate,
      }}
      toolbar={
        <CustomEditToolbar {...otherProps} mode={mode} record={record} />
      }
      redirect={false}
      submitOnEnter={false}
      validate={validateQuestionFormValues}
      keepDirtyOnReinitialize={false}
    >
      {isCreating ? null : (
        <CloneButton
          label="Copy"
          icon={<CopyIcon />}
          variant="outlined"
          style={{ marginBottom: 10 }}
          target="_blank"
        />
      )}
      <QuestionFormContent mode={mode} record={record} />
    </SimpleForm>
  );
};

const QuestionFormContent = (props) => {
  const { mode, record = {} } = props;
  const [dirtyFields, setDirtyFields] = useState({});
  const [formData, setFormData] = useState({});

  const form = useForm();

  useEffect(() => {
    const unsubscribe = form.subscribe(
      (s) => {
        setDirtyFields(s.dirtyFields);
        setFormData(s.values);
      },
      { dirtyFields: true, values: true },
    );
    return () => {
      unsubscribe();
    };
  }, [form]);

  const id = _.get(formData, 'id', null);
  const originalText = _.get(formData, 'originalText', '');
  const originalTextTranslations = _.get(
    formData,
    'originalTextTranslations',
    [],
  );
  const answerOriginalText = _.get(formData, 'answer.originalText', '');
  const answerOriginalTextTranslations = _.get(
    formData,
    'answer.originalTextTranslations',
    [],
  );
  const qnaUploadTo = _.get(formData, 'qnaUploadTo', null);

  const formQnaUploadLogData = _.get(formData, 'qnaUploadLog', {});
  const currentFormQnaUploadLog = _.isEmpty(formQnaUploadLogData)
    ? null
    : formQnaUploadLogData;

  const recordQnaUploadLogData = _.get(record, 'qnaUploadLog', []);
  const recordQnaUploadLog = _.find(recordQnaUploadLogData, [
    'uploadTo',
    qnaUploadTo,
  ]);
  const currenRecordQnaUploadLog = _.get(
    recordQnaUploadLog,
    'qnaUploadLogResult',
    null,
  );

  const currentQnaUploadLog =
    currenRecordQnaUploadLog || currentFormQnaUploadLog || null;

  const currentQnaPostingDate = _.get(
    formData,
    `qnaPostingDate.${qnaUploadTo}`,
    null,
  );

  const currentUserId = firebase.auth().currentUser.uid;

  const isQuestionPublish = _.get(formData, 'published', null);

  const defaultQnaPostingDate = _.isNil(qnaUploadTo)
    ? null
    : mode === 'update'
    ? currentQnaUploadLog?.askedAt ?? currentQnaPostingDate ?? tomorrowDate
    : tomorrowDate;

  const qnaPostingDateHelperText = _.isNil(defaultQnaPostingDate)
    ? null
    : _.isNil(currentQnaUploadLog)
    ? "The default date will be tomorrow date / Can't manually post if posting date is selected"
    : null;

  const onAbandonedChange = useCallback(
    (abandoned) => {
      if (abandoned) {
        form.change('published', false);
      }
    },
    [form],
  );

  return (
    <>
      {mode === 'create' ? (
        <>
          <SelectInput
            label="Learning Language Code"
            choices={ContentLanguages}
            source="learningLanguageCode"
            defaultValue="en"
            style={styles.fieldBase}
          />
          <TextInput
            label="Fragment Key"
            source="fragment.key"
            style={styles.fieldBase}
            validate={validateTextInput}
          />
        </>
      ) : (
        <>
          <BooleanInput
            label="Abandoned"
            source="abandoned"
            style={{
              ...styles.buttonBase,
              ...(dirtyFields.abandoned ? styles.updated : null),
            }}
            defaultValue={record.abandoned ?? false}
            onChange={onAbandonedChange}
          />
          <h3>Fragment</h3>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p>
              Text:
              {_.get(formData, 'fragment.text')} (from &quot;
              {_.get(formData, 'fragment.content.title')}
              &quot;)
            </p>
            {/* Reference: https://github.com/airbnb/javascript/pull/1648 */}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link
              to={`/contents/${record.fragment.content.key}/fragments/${record.fragment.key}`}
              target="_blank"
            >
              <Button>
                <LinkIcon />
              </Button>
            </Link>
            <Button
              color="primary"
              href={`https://www.youtube.com/watch?v=${
                props.record.fragment.content.youTubeVideoId
              }&feature=youtu.be&t=${JSUtility.convertTimestampToSeconds(
                props.record.fragment.startTime,
              )}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <YouTubeIcon style={{ color: 'red' }} fontSize="large" />
            </Button>
          </div>
          <FragmentTranslation fragmentKey={props.record.fragment.key} />
          {id && (
            <QuestionsOnSpecificFragment
              questionId={id}
              fragmentKey={props.record.fragment.key}
            />
          )}
        </>
      )}
      <h3>Question</h3>
      <MetaDataField record={formData} mode={mode} dataType="Question" />
      {mode === 'create' ? (
        <TextInput
          disabled
          label="Questioner"
          source="author.id"
          value={currentUserId}
          format={(authorId) => {
            const author = ContentMembers.find(
              (answerer) => answerer.id === authorId,
            );
            return author ? author.name : authorId;
          }}
        />
      ) : (
        <>
          {/* Reference: https://github.com/airbnb/javascript/pull/1648 */}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link to={`/users/${record.author.id}/2`} target="_blank">
            <TextInput
              disabled
              label="Questioner"
              source="author.id"
              style={{ ...styles.fieldBase, width: 200 }}
              format={(authorId) => {
                const author = ContentMembers.find(
                  (answerer) => answerer.id === authorId,
                );
                return author ? author.name : authorId;
              }}
            />
          </Link>
          <TextInput
            disabled
            label="Language"
            source="userLanguageCode"
            style={{ ...styles.fieldBase, width: 100 }}
          />
          <TextInput
            disabled
            label="Paid plan Expired At"
            source="author.user.subscription"
            format={(v) => {
              const premiumExpiredAt = _.get(v, 'premium.expiredAt', null);
              const membershipExpiredAt = _.get(
                v,
                'membership.expiredAt',
                null,
              );

              const paidPlanExpiredAt = JSUtility.getRecentStringDate(
                premiumExpiredAt,
                membershipExpiredAt,
              );

              if (paidPlanExpiredAt == null) {
                return '-';
              }

              return paidPlanExpiredAt.slice(0, 10);
            }}
            style={{ ...styles.fieldBase, width: 150 }}
          />
          <TextInput
            disabled
            label="Last Seen At"
            format={(v) => {
              if (v != null) {
                return v.slice(0, 10);
              }
              return '-';
            }}
            source="author.lastSeenAt"
            style={{ ...styles.fieldBase, width: 110 }}
          />
        </>
      )}
      <div style={styles.horizontalWrapper}>
        <BooleanInput
          label="Question Published"
          source="published"
          style={{
            ...styles.buttonBase,
            ...(dirtyFields.published ? styles.updated : null),
          }}
        />
        <BooleanInput
          label="Will Question Be Public Later?"
          source="willBePublic"
          style={{
            ...styles.buttonBase,
            ...(dirtyFields.willBePublic ? styles.updated : null),
          }}
        />
        <BooleanInput
          label="Is Question Reusable?"
          source="isReusable"
          style={{
            ...styles.buttonBase,
            ...(dirtyFields.isReusable ? styles.updated : null),
          }}
        />
        <SelectInput
          label="Label"
          source="label"
          choices={CommentaryLabelChoices}
          allowEmpty={false}
          style={{
            ...styles.selectInput,
            ...(dirtyFields.label ? styles.updated : null),
            ...styles.buttonBase,
          }}
        />
        <BooleanInput
          label="Is Question Contextual?"
          source="isContextual"
          style={{
            ...styles.buttonBase,
            ...(dirtyFields.isContextual ? styles.updated : null),
          }}
        />
      </div>
      <TextInput
        disabled={mode === 'update'}
        fullWidth
        multiline
        label="Original Question Text"
        source="originalText"
        style={dirtyFields.originalText ? styles.updated : null}
        validate={validateTextInput}
        parse={(value) => JSUtility.hasValidQuote(value)}
      />
      <OriginalTextCopyButton
        propertyName="originalTextTranslations"
        originalText={originalText}
        originalTextTranslations={originalTextTranslations}
      />
      <ObjectArrayInput dataType="Question" />
      <h3>Answer</h3>
      <MetaDataField record={formData} mode={mode} dataType="Answer" />
      {_.get(record, 'answer.author.id', null) ? (
        <>
          <TextInput
            disabled
            label="Answerer"
            source="answer.author.id"
            format={(answerAuthorId) => {
              const answerAuthor = ContentMembers.find(
                (answerer) => answerer.id === answerAuthorId,
              );
              return answerAuthor ? answerAuthor.name : answerAuthorId;
            }}
            style={{ ...styles.fieldBase, width: 200 }}
          />
          <TextInput
            disabled
            label="Likes"
            source="answer.likeCount"
            style={{ ...styles.fieldBase, width: 70 }}
          />
        </>
      ) : null}
      <div style={styles.horizontalWrapper}>
        <BooleanInput
          label="Answer Published"
          source="answer.published"
          style={{
            ...styles.buttonBase,
            ...(_.get(dirtyFields, 'answer.published', null)
              ? styles.updated
              : null),
          }}
        />
      </div>
      <TextInput
        fullWidth
        multiline
        label="Original Answer Text"
        source="answer.originalText"
        style={
          _.get(dirtyFields, 'answer.originalText', null)
            ? styles.updated
            : null
        }
        validate={validateTextInput}
        parse={(value) => JSUtility.hasValidQuote(value)}
      />
      <div style={styles.horizontalWrapper}>
        <OriginalTextCopyButton
          propertyName="answer.originalTextTranslations"
          originalText={answerOriginalText}
          originalTextTranslations={answerOriginalTextTranslations}
        />
        <CommonAnswersButton />
      </div>
      <ObjectArrayInput dataType="Answer" />
      {isQuestionPublish && (
        <>
          <h3>QnA Posting</h3>
          <>
            <div style={styles.horizontalWrapper}>
              <SelectInput
                label="QnA Upload To"
                source="qnaUploadTo"
                choices={QnAUploadToOptions}
                disableValue="disabled"
                style={{
                  ...styles.selectInput,
                  ...(dirtyFields.qnaUploadTo ? styles.updated : null),
                  ...styles.buttonBase,
                }}
              />
              <DateInput
                label="Posting date in KST"
                source={`qnaPostingDate.${qnaUploadTo}`}
                defaultValue={defaultQnaPostingDate}
                style={dirtyFields.postingDate ? styles.updated : null}
                format={formatPostingDate}
                parse={parsePostingDate}
                disabled={
                  _.isNil(qnaUploadTo) ||
                  (mode === 'update' && !_.isNil(currentQnaUploadLog))
                }
                helperText={qnaPostingDateHelperText}
              />
            </div>
            {!_.isNil(qnaUploadTo) && mode === 'update' && (
              <>
                {!_.isNil(currentQnaUploadLog) && (
                  <>
                    <TextInput
                      disabled
                      label="Question URL"
                      format={() => currentQnaUploadLog?.url}
                      style={{ ...styles.fieldBase, width: 350 }}
                    />
                    <TextInput
                      disabled
                      label="Asked At"
                      format={() => currentQnaUploadLog?.askedAt}
                      style={{ ...styles.fieldBase, width: 350 }}
                    />
                    {!_.isNil(currentQnaUploadLog?.answeredAt) && (
                      <TextInput
                        disabled
                        label="Answered At"
                        format={() => currentQnaUploadLog?.answeredAt}
                        style={{ ...styles.fieldBase, width: 350 }}
                      />
                    )}
                  </>
                )}
                <div style={styles.horizontalWrapper}>
                  <QnAPosting
                    id={id}
                    originalText={originalText}
                    uploadTo={qnaUploadTo}
                    questionUrl={currentQnaUploadLog?.url}
                    answerOriginalText={answerOriginalText}
                    answeredAt={currentQnaUploadLog?.answeredAt}
                    currentFormQnaUploadLog={currentFormQnaUploadLog}
                    currentQnaPostingDate={currentQnaPostingDate}
                  />
                </div>
              </>
            )}
          </>
        </>
      )}
    </>
  );
};

FragmentTranslation.propTypes = {
  fragmentKey: PropTypes.string.isRequired,
};

QuestionsOnSpecificFragment.propTypes = {
  questionId: PropTypes.string.isRequired,
  fragmentKey: PropTypes.string.isRequired,
};

const CommonPropTypesForQuestionFormAndContent = {
  mode: PropTypes.oneOf(['create', 'update']).isRequired,
  record: PropTypes.shape({
    fragment: PropTypes.shape({
      startTime: PropTypes.string,
      text: PropTypes.string,
      content: PropTypes.shape({
        youTubeVideoId: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      }),
    }),
    originalTextTranslations: PropTypes.arrayOf(PropTypes.shape({})),
    answer: PropTypes.shape({
      author: PropTypes.shape({
        id: PropTypes.string,
      }),
      originalText: PropTypes.string,
      originalTextTranslations: PropTypes.arrayOf(PropTypes.shape({})),
      published: PropTypes.bool,
    }),
  }),
};

QuestionForm.propTypes = {
  ...CommonPropTypesForQuestionFormAndContent,
};

QuestionFormContent.propTypes = {
  ...CommonPropTypesForQuestionFormAndContent,
};

export default QuestionForm;
