import React from 'react';
import { Create } from 'react-admin';

import SurveyQuestionActions from './SurveyQuestionActions';
import SurveyQuestionForm from './SurveyQuestionForm';

const SurveyQuestionCreate = (props) => (
  <Create
    actions={<SurveyQuestionActions basePath="/surveyQuestions" />}
    {...props}
  >
    <SurveyQuestionForm mode="create" />
  </Create>
);

export default SurveyQuestionCreate;
