import React from 'react';
import {
  EditButton,
  TextField,
  Show,
  SimpleShowLayout,
  BooleanField,
} from 'react-admin';

const SurveyAnswerOptionShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <EditButton {...props} />
      <TextField label="Value to log on GA" source="valueToLog" />
      <TextField label="Answer" source="answer" />
      <TextField label="Description" source="description" />
      <BooleanField label="Published" source="published" />
    </SimpleShowLayout>
  </Show>
);

export default SurveyAnswerOptionShow;
