import TagIcon from '@material-ui/icons/Label';

import TagList from './TagList';
import TagEdit from './TagEdit';
import TagCreate from './TagCreate';

export default {
  list: TagList,
  create: TagCreate,
  edit: TagEdit,
  icon: TagIcon,
};
