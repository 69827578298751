import BookIcon from '@material-ui/icons/MenuBook';

import L2eMonthlyConfigList from './L2eMonthlyConfigList';
import L2eMonthlyConfig from './L2eMonthlyConfigEdit';
import L2eMonthlyConfigCreate from './L2eMonthlyConfigCreate';

export default {
  list: L2eMonthlyConfigList,
  create: L2eMonthlyConfigCreate,
  edit: L2eMonthlyConfig,
  icon: BookIcon,
};
