import React, { useState, useCallback } from 'react';
import { useLogin } from 'react-admin';
import { Link } from 'react-router-dom';
import {
  Button,
  CssBaseline,
  TextField,
  Grid,
  Container,
  makeStyles,
} from '@material-ui/core';
import logo from '../images/RedKiwi_logo.png';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: '10%',
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    width: 300,
    marginRight: 20,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const login = useLogin();
  const submit = useCallback(
    (e) => {
      e.preventDefault();
      login({ email, password }, './Dashboard');
    },
    [login, email, password],
  );

  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs" className={classes.container}>
      <CssBaseline />
      <div className={classes.paper}>
        <img src={logo} alt="RedKiwi" className={classes.logo} />
        <form className={classes.form} onSubmit={submit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            autoFocus
            name="email"
            label="Email Address"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              {/* Reference: https://github.com/airbnb/javascript/pull/1648 */}
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link to="/login/reset">Forgot password?</Link>
            </Grid>
            <Grid item>
              {/* Reference: https://github.com/airbnb/javascript/pull/1648 */}
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link to="/login/verify">Verify your account</Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};

export default LoginPage;
