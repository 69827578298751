import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

import SurveyAnswerOptionList from './SurveyAnswerOptionList';
import SurveyAnswerOptionEdit from './SurveyAnswerOptionEdit';
import SurveyAnswerOptionCreate from './SurveyAnswerOptionCreate';

export default {
  list: SurveyAnswerOptionList,
  create: SurveyAnswerOptionCreate,
  edit: SurveyAnswerOptionEdit,
  icon: AssignmentTurnedInIcon,
};
