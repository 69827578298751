import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  SimpleForm,
  TextInput,
  NumberInput,
  BooleanInput,
  SelectInput,
  required,
  ArrayInput,
  ReferenceInput,
} from 'react-admin';
import _ from 'lodash';

import { useForm } from 'react-final-form';
import {
  CustomEditToolbar,
  TranslationField,
  CustomSimpleFormIterator,
} from '../../common';
import { SurveyQuestionTypes, SurveyQuestionLayouts } from '../../constants';
import ArrayInputEmptyComponent from '../../common/ArrayInputEmptyComponent';
import JSUtility from '../../utilities/JSUtility';

const styles = {
  verticalView: {
    display: 'flex',
    flexDirection: 'column',
  },
  textInput: {
    width: 350,
  },
  questionDescriptionInput: {
    width: 300,
  },
  selectInput: {
    width: 350,
  },
  switch: {
    width: 350,
  },
  updated: {
    backgroundColor: '#fad0c3',
  },
};

const answersFilter = { published: true };
const answersSort = { field: 'answer', order: 'ASC' };
const initialAnswers = [{ answerId: '', order: 1 }];
const initialQuestionDescription = [
  { questionDescription: '', questionDescriptionTranslations: null, order: 1 },
];

const validateSurveyQuestionFormValues = (values) => {
  const { published, userPropertyName, question, questionTranslations } =
    values;
  if (!published) {
    return undefined;
  }
  const errors = {};
  const emptyFieldError = 'Please fill the field';
  if (userPropertyName == null || _.trim(userPropertyName) === '') {
    errors.userPropertyName = emptyFieldError;
  }
  if (question == null || _.trim(question) === '') {
    errors.question = emptyFieldError;
  }

  if (questionTranslations == null) {
    errors.questionTranslations = emptyFieldError;
  } else {
    const missingTranslations = _.filter(
      questionTranslations,
      (text) => text == null || _.trim(text) === '',
    );
    if (missingTranslations.length !== 0) {
      errors.questionTranslations =
        'Please check if all translations are filled';
    }
  }
  return errors;
};

const SurveyQuestionForm = (props) => {
  const { mode, record, ...otherProps } = props;

  return (
    <>
      <SimpleForm
        {...props}
        toolbar={
          <CustomEditToolbar
            {...otherProps}
            mode={mode}
            record={record}
            createButtonLabel="Create survey question"
            saveButtonLabel="Save survey question"
          />
        }
        redirect={false}
        submitOnEnter={false}
        validate={validateSurveyQuestionFormValues}
        keepDirtyOnReinitialize={false}
      >
        <SurveyQuestionFormContent mode={mode} record={record} />
      </SimpleForm>
    </>
  );
};

const SurveyQuestionFormContent = (props) => {
  const { mode } = props;
  const [dirtyFields, setDirtyFields] = useState({});
  const [formData, setFormData] = useState({});

  const form = useForm();

  useEffect(() => {
    const unsubscribe = form.subscribe(
      (s) => {
        setDirtyFields(s.dirtyFields);
        setFormData(s.values);
      },
      { dirtyFields: true, values: true },
    );
    return () => {
      unsubscribe();
    };
  }, [form]);

  const newAnswer = useMemo(() => {
    const lastAnswer = _.last(formData.answers);
    const newAnswerOrder = lastAnswer?.order == null ? 1 : lastAnswer.order + 1;
    return {
      order: newAnswerOrder,
      answerId: '',
    };
  }, [formData.answers]);

  const newQuestionDescription = useMemo(() => {
    const lastQuestionDescription = _.last(formData.questionDescription);
    const newAnswerOrder = _.isNil(lastQuestionDescription?.order)
      ? 1
      : lastQuestionDescription.order + 1;

    return {
      order: newAnswerOrder,
      questionDescription: '',
      questionDescriptionTranslations: null,
    };
  }, [formData.questionDescription]);

  const onPressCreateFirstAnswer = useCallback(() => {
    form.change('answers', initialAnswers);
  }, [form]);

  const onPressCreateFirstQuestionDescription = useCallback(() => {
    form.change('questionDescriptions', initialQuestionDescription);
  }, [form]);

  const isCreating = mode === 'create';
  const validate = [required('Please fill the field')];

  return (
    <div style={styles.verticalView}>
      {!isCreating && (
        <TextInput
          disabled
          label="Survey Question Id"
          source="id"
          style={styles.textInput}
        />
      )}
      <BooleanInput
        label="Published"
        source="published"
        style={{
          ...styles.switch,
          ...(dirtyFields.published ? styles.updated : null),
        }}
        defaultValue={isCreating ? false : undefined}
      />
      <TextInput
        label="User property name in GA"
        source="userPropertyName"
        record={formData}
        style={{
          ...styles.textInput,
          ...(dirtyFields.userPropertyName ? styles.updated : null),
        }}
        parse={(value) => JSUtility.hasValidQuote(value)}
      />
      <TextInput
        label="Question"
        source="question"
        record={formData}
        style={{
          ...styles.textInput,
          ...(dirtyFields.question ? styles.updated : null),
        }}
        parse={(value) => JSUtility.hasValidQuote(value)}
      />
      <TranslationField
        source="questionTranslations"
        record={formData}
        dirtyFields={dirtyFields}
      />
      <ArrayInput
        label="Question Descriptions"
        source="questionDescriptions"
        record={formData}
      >
        <CustomSimpleFormIterator defaultAddValue={newQuestionDescription}>
          <TextInput
            label="Question Description"
            source="questionDescription"
            record={formData}
            style={{
              ...styles.questionDescriptionInput,
              ...(dirtyFields.questionDescriptions ? styles.updated : null),
            }}
            parse={(value) => JSUtility.hasValidQuote(value)}
          />
          <TranslationField
            label="questionDescription"
            source="questionDescriptionTranslations"
            record={formData}
            dirtyFields={dirtyFields}
          />
        </CustomSimpleFormIterator>
      </ArrayInput>
      {_.isEmpty(formData.questionDescriptions) && (
        <ArrayInputEmptyComponent
          label="Question Descriptions"
          onPressCreateButton={onPressCreateFirstQuestionDescription}
        />
      )}
      <SelectInput
        label="Question type"
        source="type"
        choices={SurveyQuestionTypes}
        allowEmpty={false}
        style={{
          ...styles.selectInput,
          ...(dirtyFields.type ? styles.updated : null),
        }}
      />
      <SelectInput
        label="Onboarding Question type"
        source="onboardingType"
        choices={SurveyQuestionTypes}
        allowEmpty={false}
        style={{
          ...styles.selectInput,
          ...(dirtyFields.type ? styles.updated : null),
        }}
      />
      {_.get(formData, 'onboardingType', null) === 'multiple' && (
        <NumberInput
          label="Number of answers required"
          source="numberOfAnswersRequired"
          style={{
            ...styles.selectInput,
            ...(dirtyFields.numberOfAnswersRequired ? styles.updated : null),
          }}
        />
      )}
      <SelectInput
        label="Layout to display answer set"
        source="layout"
        choices={SurveyQuestionLayouts}
        allowEmpty={false}
        style={{
          ...styles.selectInput,
          ...(dirtyFields.layout ? styles.updated : null),
        }}
      />
      <BooleanInput
        label='Has "Other" answer option'
        source="hasOtherAnswerOption"
        initialValue={isCreating ? false : undefined}
        style={{
          ...styles.switch,
          ...(dirtyFields.hasOtherAnswerOption ? styles.updated : null),
        }}
      />
      <BooleanInput
        label="Should shuffle options"
        source="shouldShuffleOptions"
        initialValue={isCreating ? false : undefined}
        style={{
          ...styles.switch,
          ...(dirtyFields.shouldShuffleOptions ? styles.updated : null),
        }}
      />
      <ArrayInput label="Answer Options" source="answers" record={formData}>
        <CustomSimpleFormIterator defaultAddValue={newAnswer}>
          {!formData.shouldShuffleOptions && (
            <NumberInput label="Order" source="order" disabled />
          )}
          <ReferenceInput
            label="Answer"
            source="answerId"
            reference="surveyAnswerOptions"
            filter={answersFilter}
            sort={answersSort}
            perPage={500}
          >
            <SelectInput optionText="answer" validate={validate} />
          </ReferenceInput>
        </CustomSimpleFormIterator>
      </ArrayInput>
      {_.isEmpty(formData.answers) && (
        <ArrayInputEmptyComponent
          label="answer options"
          onPressCreateButton={onPressCreateFirstAnswer}
        />
      )}
    </div>
  );
};

const CommonPropTypesForSurveyQuestionFormAndContent = {
  mode: PropTypes.oneOf(['create', 'update']).isRequired,
  record: PropTypes.shape({}),
};

SurveyQuestionForm.propTypes = {
  ...CommonPropTypesForSurveyQuestionFormAndContent,
};

SurveyQuestionFormContent.propTypes = {
  ...CommonPropTypesForSurveyQuestionFormAndContent,
};

export default SurveyQuestionForm;
