import React from 'react';
import PropTypes from 'prop-types';
import JSUtility from '../../../utilities/JSUtility';

const DEFAULT_WIDTH = 250;
const DEFAULT_HEIGHT = 150;

const YouTubeVideoPlayer = ({
  className,
  record, // eslint-disable-line react/prop-types
  source,
  width = DEFAULT_WIDTH,
  height = DEFAULT_HEIGHT,
  startTime = null,
  endTime = null,
  // ...rest
}) => {
  const convertedTimestampOfStartTime =
    JSUtility.convertTimestampToSeconds(startTime);
  const convertedTimestampOfEndTime =
    JSUtility.convertTimestampToSeconds(endTime);

  return (
    <iframe
      className={className}
      title={`YouTube video ${record[source]}`}
      src={`https://www.youtube.com/embed/${record[source]}?start=${convertedTimestampOfStartTime}&end=${convertedTimestampOfEndTime}`}
      width={width}
      height={height}
      // {...rest} // sanitizeRestProps is omitted
    />
  );
};

YouTubeVideoPlayer.propTypes = {
  className: PropTypes.string,
  // record: ...,
  source: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  startTime: PropTypes.string, // eslint-disable-line react/require-default-props
  endTime: PropTypes.string, // eslint-disable-line react/require-default-props
};

YouTubeVideoPlayer.defaultProps = {
  className: '',
  width: DEFAULT_WIDTH,
  height: DEFAULT_HEIGHT,
};

export default YouTubeVideoPlayer;
