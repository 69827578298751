import React from 'react';
import PropTypes from 'prop-types';
import { SimpleForm, TextInput } from 'react-admin';
import { Link } from 'react-router-dom';

import JSUtility from '../../utilities/JSUtility';

const styles = {
  flexColumnContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
};

const SubscriptionForm = (props) => {
  const { mode, record } = props;
  return (
    <SimpleForm
      {...props}
      toolbar={null}
      redirect={false}
      submitOnEnter={false}
      keepDirtyOnReinitialize={false}
    >
      <SubscriptionFormContent mode={mode} record={record} />
    </SimpleForm>
  );
};

const SubscriptionFormContent = (props) => (
  <>
    <div style={styles.flexColumnContainer}>
      {/* Reference: https://github.com/airbnb/javascript/pull/1648 */}
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link to={`/users/${props.record.userId}`} target="_blank">
        <TextInput disabled label="User Id" source="userId" />
      </Link>
      <TextInput disabled label="Subscription Id" source="id" />
      <TextInput
        disabled
        label="Created At"
        source="createdAt.seconds"
        format={(v) => JSUtility.convertUnixSecondToString(v)}
      />
      <TextInput
        disabled
        label="Platform"
        source="platform"
        format={(v) => v != null && v.toUpperCase()}
      />
      {props.record.platform === 'android' ? (
        <TextInput
          disabled
          label="Expires At"
          source="validatedTransaction.expiryTimeMillis"
          format={(v) => JSUtility.convertUnixMillisecondToString(v)}
        />
      ) : (
        <TextInput
          disabled
          label="Expires At"
          source="validatedTransaction.expiresDate"
        />
      )}
      <TextInput
        disabled
        label="Product"
        source="validatedTransaction.productId"
        format={(v) => v != null && v.slice(v.indexOf('_') + 1).toUpperCase()}
      />
      <TextInput
        disabled
        label="isTrial?"
        source={
          props.record.platform === 'android'
            ? 'validatedTransaction.paymentState'
            : 'validatedTransaction.isTrial'
        }
        format={(v) => {
          // Android Reference: https://developer.android.com/google/play/billing/subs#free-trial
          const trialTrueValue = props.record.platform === 'android' ? 2 : true;
          if (v === trialTrueValue) {
            return 'O';
          }
          return 'X';
        }}
      />
    </div>
  </>
);

const CommonPropTypesForSubscriptionFormAndContent = {
  record: PropTypes.PropTypes.shape({}),
};

SubscriptionForm.propTypes = {
  ...CommonPropTypesForSubscriptionFormAndContent,
};

SubscriptionFormContent.propTypes = {
  ...CommonPropTypesForSubscriptionFormAndContent,
};

export default SubscriptionForm;
