import React, { useState, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  SimpleForm,
  NumberInput,
  TextInput,
  DateInput,
  regex,
  required,
  CloneButton,
  useGetManyReference,
  useGetList,
  Loading,
} from 'react-admin';
import moment from 'moment';
import { useForm } from 'react-final-form';
import {
  FileCopy as CopyIcon,
  AddBox as CreateIcon,
  Cancel as CancelIcon,
  GetApp as DownloadIcon,
} from '@material-ui/icons';
import { Button, Typography } from '@material-ui/core';
import _ from 'lodash';
import { firestore } from 'firebase';
import JSUtility from '../../utilities/JSUtility';
import {
  LINK_TO_TMS,
  RegexYouTubeVideoId,
  EnglishContentMustHaveLanguages,
  NonEnglishContentMustHaveLanguages,
  RegexNoOnlySpaceOrNewline,
  KST_OFFSET_IN_MIN,
} from '../../constants';
import SktFragmentForm, {
  SktFragmentFormContainer,
} from '../sktFragments/SktFragmentForm';
import {
  TimestampInput,
  CustomEditToolbar,
  TranslationField,
  ScrollToTopButton,
  TextInputWithButton,
} from '../../common';
import {
  AgeRestrictionCheckField,
  RegionRestrictionCheckField,
} from '../contents/component';
import YouTubeVideoPlayer from '../contents/component/YouTubeVideoPlayer';

const styles = {
  flex: {
    display: 'flex',
    float: 'left',
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  columnContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
  },
  float: {
    float: 'left',
  },
  updated: {
    backgroundColor: '#fad0c3',
  },
  leftMargin: {
    marginLeft: 20,
  },
  icon: {
    paddingRight: 8,
  },
  button: {
    color: '#ffffff',
    margin: '10px 0px 10px 30px',
    fontSize: 'medium',
    textAlign: 'center',
    paddingRight: '13px',
    backgroundColor: '#ec8a88',
  },
  smallListener: {
    width: 120,
    paddingLeft: 30,
  },
  warningText: {
    color: 'red',
  },
  duplicateContentsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
};

const validateYouTubeVideoId = [
  required('Please fill the YouTube Video ID.'),
  regex(
    RegexYouTubeVideoId,
    "Only 'A-Za-z0-9_-' is allowed. It should be 11 letters.",
  ),
];

const validateTitle = [
  required('Please fill the title of the content.'),
  regex(RegexNoOnlySpaceOrNewline, 'Just space or newline is forbidden.'),
];

const validateSubtitle = [
  required('Please fill the subtitle of the content.'),
  regex(RegexNoOnlySpaceOrNewline, 'Just space or newline is forbidden.'),
];

// Check age and region restriction through youTubeVideoID
const checkAgeAndRegionRestriction = async (youTubeVideoId) => {
  const response = await fetch(
    `https://www.googleapis.com/youtube/v3/videos?part=contentDetails&id=${youTubeVideoId}&key=AIzaSyAA35Wfce-B_ma9T0qPpwKiIWOtVAluEn8`,
  );
  const object = await response.json();

  const result = {};
  const resultForValidYouTubeIdFromApi = _.get(
    object,
    'pageInfo.resultsPerPage',
    '',
  );
  if (resultForValidYouTubeIdFromApi === 1) {
    // Check age restriction
    const ageRestriction = _.get(
      object,
      'items[0].contentDetails.contentRating.ytRating',
      '',
    ); // '' || string
    if (ageRestriction === 'ytAgeRestricted') {
      result.isAgeRestricted = true;
    } else {
      result.isAgeRestricted = false;
    }

    // Check region restriction
    const listOfRestrictedRegion = _.get(
      object,
      'items[0].contentDetails.regionRestriction.blocked',
      [],
    );
    const listOfAllowedRegion = _.get(
      object,
      'items[0].contentDetails.regionRestriction.allowed',
      [],
    );

    const regionRestriction = {
      blocked: listOfRestrictedRegion,
      allowed: listOfAllowedRegion,
    };
    result.regionRestriction = regionRestriction;
    return result;
  }

  // Invalid YouTube ID
  return null;
};

const YouTubeApiHelper = ({
  youTubeVideoId,
  startTime,
  endTime,
  onChangeDurationInMs,
  onChangeAgeRestricted,
  onChangeBlockedRegions,
  onChangeAllowedRegions,
}) => {
  useEffect(() => {
    (async () => {
      const durationInMs =
        youTubeVideoId == null
          ? undefined
          : await JSUtility.calculateDurationInMs(
              youTubeVideoId,
              startTime,
              endTime,
            );
      console.log(`durationInMs is calculated: ${durationInMs}`); // eslint-disable-line no-console

      if (durationInMs != null) {
        onChangeDurationInMs(durationInMs);
      }

      const current = youTubeVideoId;
      const result = await checkAgeAndRegionRestriction(current);
      if (result != null) {
        const { isAgeRestricted, regionRestriction } = result;
        onChangeAgeRestricted(isAgeRestricted);
        onChangeBlockedRegions(regionRestriction.blocked);
        onChangeAllowedRegions(regionRestriction.allowed);
      } else {
        onChangeAgeRestricted(null);
        onChangeBlockedRegions(null);
        onChangeAllowedRegions(null);
      }
    })();
  }, [
    youTubeVideoId,
    startTime,
    endTime,
    onChangeDurationInMs,
    onChangeAgeRestricted,
    onChangeAllowedRegions,
    onChangeBlockedRegions,
  ]);

  return null;
};

const YouTubeApiHelperWrapper = (otherProps) => {
  const form = useForm();

  const onChangeDurationInMs = useCallback(
    (durationInMs) => {
      form.change('durationInMs', durationInMs);
    },
    [form],
  );

  return (
    <YouTubeApiHelper
      onChangeDurationInMs={onChangeDurationInMs}
      {...otherProps}
    />
  );
};

const DuplicateContentsChecker = ({ contentId = '', videoId }) => {
  const { data } = useGetList(
    'sktContents',
    null,
    { field: 'startTime', order: 'ASC' },
    { youTubeVideoId: videoId },
  );
  delete data[contentId];
  const duplicateContents = Object.values(data);

  return _.isEqual(duplicateContents, []) ? null : (
    <>
      <Typography variant="h6">
        Same YouTube video is also used in...
      </Typography>
      {duplicateContents.map((c) => (
        <div
          key={`Duplicate-${c.id}`}
          style={styles.duplicateContentsContainer}
        >
          ●&nbsp;
          <a
            href={`${LINK_TO_TMS}/contents/${c.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {c.title}
          </a>
          &nbsp;- {c.startTime || '00:00:00,000'}~{' '}
          {c.endTime || JSUtility.convertMillisecondToTimestamp(c.durationInMs)}
        </div>
      ))}
    </>
  );
};

DuplicateContentsChecker.propTypes = {
  contentId: PropTypes.string,
  videoId: PropTypes.string.isRequired,
};

const ValidateContentFormValues = (values) => {
  const errors = {};
  const isPublishedContent = values._dev === false;
  const isEnglishContent = values.languageCode === 'en';

  if (
    values.startTime &&
    values.endTime &&
    values.startTime >= values.endTime
  ) {
    errors.startTime = 'Start time should be before End time.';
    errors.endTime = 'End time should be after Start time.';
  }

  if (!isPublishedContent) {
    return errors;
  }

  // Content to be published
  if (values.id != null && isPublishedContent) {
    // Validate 1. Empty subtitle
    if (
      values.subtitle == null ||
      values.subtitle === '' ||
      values.subtitle === '.'
    ) {
      errors.subtitle = 'Valid subtitle is needed to publish the content.';
    }

    const mustHaveLanguages = isEnglishContent
      ? EnglishContentMustHaveLanguages
      : NonEnglishContentMustHaveLanguages;

    // Validate 2. Missing Subtitles' translation
    let missingSubtitleTranslation = mustHaveLanguages.reduce((acc, lang) => {
      const subtitleTranslation = _.get(
        values.subtitleTranslations,
        lang.id,
        null,
      );

      if (
        subtitleTranslation == null ||
        subtitleTranslation === '' ||
        subtitleTranslation === '.'
      ) {
        return [...acc, lang];
      }
      return acc;
    }, []);

    if (_.get(values, '_subtitleTranslationsByGengo.ja') != null) {
      missingSubtitleTranslation = missingSubtitleTranslation.filter(
        (lang) => lang.id !== 'ja',
      );
    }

    missingSubtitleTranslation.forEach((lang) => {
      _.set(
        errors,
        `subtitleTranslations.${lang.id}`,
        `${lang.id} subtitle translation is needed to publish content.`,
      );
    });
  }

  return errors;
};

const SktFragmentFormsInContentForm = ({ record = {} }) => {
  const contentId = record.id;
  const { youTubeVideoId, _dev, languageCode } = record;
  const isPublishedContent = !_dev;

  const [isCreateButtonClicked, setIsCreateButtonClicked] = useState(false);
  const onClickHandler = () => setIsCreateButtonClicked(!isCreateButtonClicked);

  const SktFragmentCreateInContentForm = () => {
    const CreateFragmentButton = () => (
      <Button onClick={onClickHandler} style={styles.button}>
        <CreateIcon style={styles.icon} />
        Make New Fragment
      </Button>
    );

    const CreateCancelButton = () => (
      <Button onClick={onClickHandler} style={styles.button}>
        <CancelIcon style={styles.icon} />
        Delete New Fragment
      </Button>
    );

    const fragmentRecord = {
      contentKey: contentId,
      languageCode,
    };

    return isCreateButtonClicked ? (
      <>
        <SktFragmentForm
          mode="create"
          record={fragmentRecord}
          created={onClickHandler}
        />
        <CreateCancelButton />
      </>
    ) : (
      <CreateFragmentButton />
    );
  };

  const { data, loading, error } = useGetManyReference(
    'sktFragments',
    'contentKey',
    contentId,
    null,
    { field: 'startTime', order: 'ASC' },
    {},
    'sktContents',
  );

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <p>ERROR</p>;
  }

  const fragmentIds = Object.keys(data);

  return (
    <>
      {fragmentIds.map((id, index) => (
        <SktFragmentFormContainer
          key={`${contentId}-${id}`}
          redirect={false}
          mode="update"
          indexFromFragmentList={index}
          recordId={id}
          youTubeVideoId={youTubeVideoId}
          resource="sktFragments"
        />
      ))}
      {isPublishedContent ? null : (
        <SktFragmentCreateInContentForm
          contentKey={contentId}
          languageCode={languageCode}
        />
      )}
    </>
  );
};

SktFragmentFormsInContentForm.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.string.isRequired,
    youTubeVideoId: PropTypes.string.isRequired,
    languageCode: PropTypes.string.isRequired,
  }),
};

const SktContentForm = (props) => {
  const { mode, record = {}, resource = 'sktContents' } = props;

  const isCreating = mode === 'create';

  const jumpToSpecificFragment = useCallback(() => {
    const elementName = window.location.hash.slice(1);
    const elements = document.getElementsByName(elementName);

    if (elements[0] != null) {
      elements[0].scrollIntoView(true);
    }
  }, []);

  return (
    <>
      <SimpleForm
        {...props}
        redirect={false}
        toolbar={<CustomEditToolbar {...props} />}
        submitOnEnter={false}
        validate={ValidateContentFormValues}
        keepDirtyOnReinitialize={false}
      >
        {isCreating ? null : (
          <CloneButton
            label="Copy"
            icon={<CopyIcon />}
            variant="outlined"
            style={{ marginBottom: 10 }}
          />
        )}
        {window.location.hash.includes('/sktFragments/') ? (
          <Button
            variant="outlined"
            onClick={jumpToSpecificFragment}
            style={{ borderColor: '#EE4E48', color: '#EE4E48' }}
          >
            Move To Fragment Directly
          </Button>
        ) : null}
        {/* The wrapper below exists to support `useForm` in the `SktContentFormContent` component */}
        <SktContentFormContent
          mode={mode}
          record={record}
          resource={resource}
        />
      </SimpleForm>
      {isCreating ? null : <SktFragmentFormsInContentForm record={record} />}
      <ScrollToTopButton category={1} />
    </>
  );
};

const formatReleaseDate = (date) => {
  if (!date) {
    return null;
  }
  const transformedDate =
    date instanceof firestore.Timestamp ? date.toDate() : date;
  const kstNormalizedDate =
    moment(transformedDate).utcOffset(KST_OFFSET_IN_MIN);
  return kstNormalizedDate.format('YYYY-MM-DD');
};
const parseReleaseDate = (date) => {
  if (!date) {
    return null;
  }
  const kstNormalizedDate = moment(date).utcOffset(KST_OFFSET_IN_MIN, true);
  return kstNormalizedDate.toDate();
};

const SktContentFormContent = (props) => {
  const [isAgeRestricted, setIsAgeRestricted] = useState(null);
  const [blockedRegions, setBlockedRegions] = useState(null);
  const [allowedRegions, setAllowedRegions] = useState(null);
  const [dirtyFields, setDirtyFields] = useState({});
  const [formData, setFormData] = useState({});

  const { mode, record } = props;

  const { data } = useGetManyReference(
    'sktFragments',
    'contentKey',
    record?.id,
    null,
    { field: 'startTime', order: 'ASC' },
    {},
    'sktContents',
  );

  const fragmentsArray = useMemo(
    () => (data ? Object.values(data) : []),
    [data],
  );
  const invalidLemmaIdExists = useMemo(
    () =>
      fragmentsArray
        .map((el) => el.words?.map((e) => e.lemmaId))
        .flat()
        .some((el) => el === undefined || el.trim().length === 0),
    [fragmentsArray],
  );

  const onChangeAgeRestricted = useCallback((isRestricted) => {
    setIsAgeRestricted(isRestricted);
  }, []);

  const onChangeAllowedRegions = useCallback((allowedRegionResponse) => {
    setAllowedRegions(allowedRegionResponse);
  }, []);

  const onChangeBlockedRegions = useCallback((blockedRegionResponse) => {
    setBlockedRegions(blockedRegionResponse);
  }, []);

  const form = useForm();

  useEffect(() => {
    const unsubscribe = form.subscribe(
      (s) => {
        setDirtyFields(s.dirtyFields);
        setFormData(s.values);
      },
      { dirtyFields: true, values: true },
    );
    return () => {
      unsubscribe();
    };
  }, [form]);

  const {
    id,
    youTubeVideoId,
    startTime,
    endTime,
    languageCode,
    title = '',
  } = formData;

  const isCreating = mode === 'create';

  const onClickCopyIdButton = useCallback(() => {
    JSUtility.copyToClipboard(id);
  }, [id]);

  const onClickDownloadMp3Icon = useCallback(() => {
    JSUtility.copyToClipboard(
      `https://www.youtube.com/watch?v=${youTubeVideoId}`,
    );
  }, [youTubeVideoId]);

  const characterCount = title.length;
  const maxCharacterCount = 50;
  const charCountStyle =
    characterCount > maxCharacterCount ? styles.warningText : null;
  return (
    <>
      <div style={styles.rowContainer}>
        {isCreating || (
          <TextInputWithButton
            source="id"
            label="Content Id"
            onClickHandler={onClickCopyIdButton}
            icon={<CopyIcon fontSize="small" />}
            width={120}
            disabled
          />
        )}
      </div>
      <div style={styles.rowContainer}>
        <TextInputWithButton
          source="youTubeVideoId"
          label="YouTube Video ID"
          onClickHandler={onClickDownloadMp3Icon}
          icon={<DownloadIcon fontSize="small" />}
          width={150}
          href="https://yt2mp3.info/ytmp3.cc/en24/"
        />
        <DateInput
          label="Release date in KST"
          source="releaseDate"
          defaultValue={record.releaseDate ?? null}
          style={dirtyFields.releaseDate ? styles.updated : null}
          format={formatReleaseDate}
          parse={parseReleaseDate}
          disabled={invalidLemmaIdExists}
          helperText={invalidLemmaIdExists ? 'The invalid lemma id exists' : ''}
          FormHelperTextProps={{ style: styles.warningText }}
        />
      </div>
      <br />
      <div style={styles.rowContainer}>
        <YouTubeVideoPlayer
          label="YouTube Video Player"
          record={formData}
          source="youTubeVideoId"
          startTime={record.startTime}
          endTime={record.endTime}
          validate={validateYouTubeVideoId}
        />
        <div style={styles.columnContainer}>
          <AgeRestrictionCheckField
            label="Age Restriction check"
            isAgeRestricted={isAgeRestricted}
            youTubeVideoId={youTubeVideoId}
          />
          <RegionRestrictionCheckField
            label="Region Restriction check"
            blockedRegions={blockedRegions}
            allowedRegions={allowedRegions}
            youTubeVideoId={youTubeVideoId}
          />
        </div>
      </div>
      <br />
      {youTubeVideoId == null ? null : (
        <DuplicateContentsChecker
          contentId={record.id}
          videoId={youTubeVideoId}
        />
      )}
      <div style={styles.flex}>
        <TimestampInput
          source="startTime"
          style={dirtyFields.startTime ? styles.updated : null}
        />
        <TimestampInput
          source="endTime"
          style={dirtyFields.endTime ? styles.updated : null}
        />
      </div>
      <YouTubeApiHelperWrapper
        youTubeVideoId={youTubeVideoId}
        startTime={startTime}
        endTime={endTime}
        onChangeAgeRestricted={onChangeAgeRestricted}
        onChangeAllowedRegions={onChangeAllowedRegions}
        onChangeBlockedRegions={onChangeBlockedRegions}
      />
      <NumberInput
        disabled
        source="durationInMs"
        placeholder="1500"
        style={{
          ...styles.leftMargin,
          ...(dirtyFields.durationInMs ? styles.updated : null),
        }}
      />
      <br />
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <TextInput
          style={dirtyFields.title ? styles.updated : null}
          source="title"
          validate={validateTitle}
          fullWidth
          parse={(value) => JSUtility.hasValidQuote(value)}
        />
        <p style={charCountStyle}>
          ({characterCount}/{maxCharacterCount})
        </p>
      </div>
      <TranslationField
        source="subtitleTranslations"
        label="Title Translation"
        languageCodeIdOfCurrentContent={languageCode}
        record={formData}
        dirtyFields={dirtyFields}
      />
      {record._subtitleTranslationsByGengo == null ? null : (
        <TranslationField
          label="Title Translation By Gengo"
          source="_subtitleTranslationsByGengo"
          languageCodeIdOfCurrentContent={languageCode}
          record={formData}
          dirtyFields={dirtyFields}
        />
      )}
      <TextInput
        style={dirtyFields.subtitle ? styles.updated : null}
        source="subtitle"
        label="English 2nd title"
        validate={validateSubtitle}
        multiline
        fullWidth
        parse={(value) => JSUtility.hasValidQuote(value)}
      />
    </>
  );
};

SktContentForm.propTypes = {
  mode: PropTypes.oneOf(['create', 'update']).isRequired,
  record: PropTypes.shape({
    _dev: PropTypes.bool,
  }),
  resource: PropTypes.string,
};

SktContentFormContent.propTypes = {
  mode: PropTypes.oneOf(['create', 'update']).isRequired,
  record: PropTypes.shape({
    id: PropTypes.string,
    youTubeVideoId: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    subtitleTranslations: PropTypes.shape({}),
    languageCode: PropTypes.string,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    _subtitleTranslationsByGengo: PropTypes.shape({}),
    releaseDate: PropTypes.shape({}),
    _dev: PropTypes.bool,
  }),
};

SktContentFormContent.defaultProps = {
  record: PropTypes.shape({
    id: null,
    youTubeVideoId: null,
    title: null,
    subtitle: null,
    subtitleTranslations: null,
    languageCode: null,
    startTime: null,
    endTime: null,
  }),
};

export default SktContentForm;
