import React, { useEffect } from 'react';
import { List, Datagrid, TextField, EditButton, Pagination } from 'react-admin';

import { ContentLanguages } from '../../constants';
import UserShow from './UserShow';
import { CustomBooleanField, ScrollToTopButton } from '../../common';

const ltcfc = 'langToCompletedFragmentCount';

const UserList = (props) => {
  useEffect(() => {
    document.title = 'TMS: Users';
  }, []);

  return (
    <>
      <List
        pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
        bulkActionButtons={false}
        exporter={false}
        {...props}
      >
        <Datagrid rowClick="expand" expand={<UserShow />}>
          <TextField label="User ID" source="id" />
          <CustomBooleanField label="Anonymous User" source="isAnonymous" />
          {ContentLanguages.map((lang) => (
            <TextField
              key={lang.id}
              source={`${ltcfc}.${lang.id}`}
              label={`Studied ${lang.name.slice(0, 3)} fragments`}
            />
          ))}
          <EditButton />
        </Datagrid>
      </List>
      <ScrollToTopButton category={0} />
    </>
  );
};

export default UserList;
