import React from 'react';
import PropTypes from 'prop-types';

import JSUtility from '../../utilities/JSUtility';

const FragmentCountField = ({ record }) => {
  // eslint-disable-next-line no-underscore-dangle
  const fragmentCount = record._fragmentCount || record.fragmentCount;

  return (
    <p>
      {fragmentCount != null
        ? JSUtility.fragmentCountWithSize(fragmentCount)
        : ''}
    </p>
  );
};

// Workaround: To remove eslint warning
FragmentCountField.propTypes = {
  record: PropTypes.shape({
    _fragmentCount: PropTypes.number,
    fragmentCount: PropTypes.number,
  }),
};

FragmentCountField.defaultProps = {
  record: {
    _fragmentCount: 0,
    fragmentCount: 0,
  },
};

export default FragmentCountField;
