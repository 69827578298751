import React from 'react';
import { List, Datagrid, TextField, EditButton, Pagination } from 'react-admin';
import { ScrollToTopButton, ShortenedDateField } from '../../common';
import { KST_OFFSET_IN_MIN } from '../../constants';
import EventShow from './EventShow';

const EventList = (props) => (
  <>
    <List
      pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
      bulkActionButtons={false}
      exporter={false}
      {...props}
    >
      <Datagrid rowClick="expand" expand={<EventShow />}>
        <TextField source="eventName" />
        <ShortenedDateField
          label="Start Date"
          source="start.seconds"
          type="timestamp"
          utcOffset={KST_OFFSET_IN_MIN}
        />
        <ShortenedDateField
          label="End Date"
          source="end.seconds"
          type="timestamp"
          utcOffset={KST_OFFSET_IN_MIN}
        />
        <EditButton />
      </Datagrid>
    </List>
    <ScrollToTopButton category={0} />
  </>
);

export default EventList;
