import React, { useCallback, useEffect, useState, useRef } from 'react';
import _ from 'lodash';

import SeriesIdSelectInput from './SeriesIdSelectInput';
import withSearchEngine, {
  SearchProps,
} from '../../../../search/modules/withSearchEngine';
import * as ElasticAppSearch from '../../../../search/modules/ElasticAppSearch';

interface SeriesResult {
  id: string;
  title: string;
  href?: string;
}

const connector = ElasticAppSearch.createConnector('series-en');
const searchEngineConfig = {
  apiConnector: connector,
  searchQuery: {
    // eslint-disable-next-line @typescript-eslint/camelcase
    search_fields: {
      title: { weight: 10 },
    },
    // eslint-disable-next-line @typescript-eslint/camelcase
    result_fields: {
      title: {
        raw: {},
        snippet: {},
      },
    },
  },
};

const transformResult = (result): SeriesResult => ({
  id: result.id,
  title: result.title,
  href: `#/series/${result.id}`,
});

interface SeriesInputProps {
  record?: {
    title?: string;
    seriesId?: string;
  };
  source?: string;
}

type SeriesInputWithSearchEngineProps = SeriesInputProps &
  SearchProps<SeriesResult>;

// <Configure hitsPerPage={3} />

const SeriesInput = ({
  source = '',
  record = {},
  search,
  results,
  clearFilters,
  addFilter,
  setResultsPerPage,
  isLoading,
}: SeriesInputWithSearchEngineProps): JSX.Element => {
  const contentTitleRef = useRef(record.title);
  const [initialSeries, setInitialSeries] = useState<string | undefined>();

  useEffect(() => {
    setResultsPerPage(3);
  }, [setResultsPerPage]);

  const searchWithFilter = useCallback(
    (q: string) => {
      clearFilters();
      search(q);
      // `addFilter` applys `all` for root filter.
      // This is a use case to use `any` for root filter.
    },
    [search, clearFilters],
  );

  useEffect(() => {
    if (!_.isEmpty(record.seriesId)) {
      clearFilters();
      addFilter('any', { id: record.seriesId }, 'any');
    }
  }, [record, addFilter, clearFilters, initialSeries]);

  useEffect(() => {
    if (record.seriesId != null) {
      const resultForCurrentSeriesId = _.find(results, ['id', record.seriesId]);
      if (resultForCurrentSeriesId != null) {
        setInitialSeries(
          resultForCurrentSeriesId.title.replace(/<em>|<\/em>/gi, ''),
        );
      }
      return;
    }
    if (!_.isEmpty(contentTitleRef.current)) {
      // preproce title by removing series order text
      const regex = /\s?\(\d\/\d\)\s?/;
      const preprocessedTitle = contentTitleRef.current?.replace(regex, '');
      setInitialSeries(preprocessedTitle);
    }
  }, [initialSeries, record, results]);

  return (
    <SeriesIdSelectInput
      key={`series-input-${initialSeries}`}
      hits={results}
      seriesId={record.seriesId}
      initialSeries={initialSeries}
      source={source}
      search={searchWithFilter}
      loading={isLoading}
    />
  );
};

const SeriesInputWithSearch = withSearchEngine<
  unknown,
  SeriesResult,
  SeriesInputProps
>(searchEngineConfig, transformResult, SeriesInput);

export default React.memo(SeriesInputWithSearch);
