import React from 'react';

export interface FragmentResult {
  id: string;
  text: string;
  contentTitle: string;
  keepable: boolean;
  textTranslations: string[];
  textTranslationsByGengo: string[];
  lemmaIds: string[];
  href: string;
}

interface FragmentResultItemProps {
  result: FragmentResult;
}

const FragmentResultItem = (props: FragmentResultItemProps): JSX.Element => {
  const {
    result: {
      text,
      contentTitle,
      keepable,
      textTranslations,
      textTranslationsByGengo,
      lemmaIds,
    },
  } = props;

  return (
    <div>
      <div className="hit-fragmentText">
        <span dangerouslySetInnerHTML={{ __html: text }} />
      </div>
      <div className="hit-contentTitle">
        <span dangerouslySetInnerHTML={{ __html: contentTitle }} />
      </div>
      <div className="hit-langAndKeepable">
        {keepable ? 'Studyable' : 'Not studyable'}
      </div>
      <div className="hit-textTranslation">
        Text translations:&nbsp;
        {textTranslations.length > 0 ? (
          <span
            dangerouslySetInnerHTML={{
              __html: textTranslations.sort().join('/'),
            }}
          />
        ) : (
          ' - '
        )}
      </div>
      {textTranslationsByGengo.length > 0 ? (
        <div className="hit-textTranslationsByGengo">
          Text translations by Gengo:&nbsp;
          {textTranslationsByGengo.length > 0 ? (
            <span
              dangerouslySetInnerHTML={{
                __html: textTranslationsByGengo.sort().join('/'),
              }}
            />
          ) : (
            ' - '
          )}
        </div>
      ) : null}
      <div className="hit-lemmaIds">
        Lemma IDs:&nbsp;
        <span
          dangerouslySetInnerHTML={{ __html: lemmaIds.sort().join(', ') }}
        />
      </div>
    </div>
  );
};

export default FragmentResultItem;
