import React, { useState } from 'react';
import firebase from 'firebase';
import {
  Button,
  TextField,
  makeStyles,
  CssBaseline,
  Container,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import Popup from '../common/fields/Popup';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(20),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    textAlign: 'center',
    marginTop: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const VerificationPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSent, setIsSent] = useState(false);

  const handleSendCodeClick = (e) => {
    e.preventDefault();

    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(async () => {
        const user = firebase.auth().currentUser;
        if (user.emailVerified) {
          throw new Error('Email is already verified.');
        }
        try {
          await user.sendEmailVerification();
          setIsSent(true);
        } catch (error) {
          throw new Error(error);
        }
      })
      .catch((error) => {
        alert(error); // eslint-disable-line no-alert
        return Promise.reject();
      });
  };

  const classes = useStyles();

  const popupText = (
    <p>
      We have sent verification email for you.
      <br />
      Please check your email &quot;
      {email}
      &quot; to verify your email.
    </p>
  );
  const popupButtonText = 'Back to Login page';
  const popupPath = '/login';

  return (
    <div>
      {isSent ? (
        <Popup
          textElement={popupText}
          buttonText={popupButtonText}
          pathBack={popupPath}
        />
      ) : (
        <Container maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <h2>Do you want to verify your account?</h2>
            <form className={classes.form} onSubmit={handleSendCodeClick}>
              <p>Please write your email and password for verification.</p>
              <TextField
                margin="normal"
                fullWidth
                required
                autoFocus
                type="email"
                label="Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                margin="normal"
                fullWidth
                required
                name="password"
                label="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button
                fullWidth
                className={classes.button}
                type="submit"
                variant="contained"
                color="primary"
                disabled={email.length <= 0 || password.length <= 0}
              >
                Send
              </Button>
            </form>
          </div>
          {/* Reference: https://github.com/airbnb/javascript/pull/1648 */}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link to="/login" className={classes.link}>
            Back to Login page
          </Link>
        </Container>
      )}
    </div>
  );
};

export default VerificationPage;
