import React, { useState } from 'react';
import { DashboardMenuItem, MenuItemLink } from 'react-admin';
import ListIcon from '@material-ui/icons/List';
import FragmentIcon from '@material-ui/icons/LibraryBooks';
import BookIcon from '@material-ui/icons/MenuBook';
import WordIcon from '@material-ui/icons/Class';
import QuestionIcon from '@material-ui/icons/Help';
import RateReviewIcon from '@material-ui/icons/RateReview';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PollIcon from '@material-ui/icons/Poll';
import TagIcon from '@material-ui/icons/Label';
import TopicIcon from '@material-ui/icons/ArtTrack';
import SeriesIcon from '@material-ui/icons/Subscriptions';
import {
  Group as UserIcon,
  EmojiEvents as EventIcon,
  RemoveFromQueue as UserBoardIcon,
} from '@material-ui/icons';
import { Box } from '@material-ui/core';
import CustomSubMenu from './CustomSubMenu';

const CustomMenu = () => {
  const [isOpen, setIsOpen] = useState({
    menuContentTeam: false,
    menuDesignTeam: false,
    menuCXTeam: false,
    menuEvents: false,
    menuL2E: false,
    menuSKT: false,
  });

  const handleToggle = (menu) => {
    setIsOpen((prveState) => ({ ...prveState, [menu]: !isOpen[menu] }));
  };

  return (
    <Box>
      <DashboardMenuItem />
      <CustomSubMenu
        handleToggle={() => handleToggle('menuContentTeam')}
        isOpen={isOpen.menuContentTeam}
        name="RK Contents"
        icon={<ListIcon />}
      >
        <MenuItemLink
          to="/contents"
          primaryText="Contents"
          leftIcon={<ListIcon />}
        />
        <MenuItemLink
          to="/fragments"
          primaryText="Fragments"
          leftIcon={<FragmentIcon />}
        />
        <MenuItemLink
          to="/questions"
          primaryText="Commentaries"
          leftIcon={<QuestionIcon />}
        />
        <MenuItemLink to="/tags" primaryText="Tags" leftIcon={<TagIcon />} />
        <MenuItemLink
          to="/series"
          primaryText="Series"
          leftIcon={<SeriesIcon />}
        />
        <MenuItemLink
          to="/lemmas"
          primaryText="Lemmas"
          leftIcon={<WordIcon />}
        />
        <MenuItemLink
          to="/grammars"
          primaryText="Grammars"
          leftIcon={<BookIcon />}
        />
        <MenuItemLink
          to="/topics"
          primaryText="Topics"
          leftIcon={<TopicIcon />}
        />
      </CustomSubMenu>
      <CustomSubMenu
        handleToggle={() => handleToggle('menuDesignTeam')}
        isOpen={isOpen.menuDesignTeam}
        name="RK App"
        icon={<ListIcon />}
      >
        <MenuItemLink
          to="/surveys"
          primaryText="Surveys"
          leftIcon={<PollIcon />}
        />
        <MenuItemLink
          to="/surveyQuestions"
          primaryText="Surv.Questions"
          leftIcon={<AssignmentIcon />}
        />
        <MenuItemLink
          to="/surveyAnswerOptions"
          primaryText="Surv.Options"
          leftIcon={<AssignmentTurnedInIcon />}
        />
      </CustomSubMenu>
      <CustomSubMenu
        handleToggle={() => handleToggle('menuCXTeam')}
        isOpen={isOpen.menuCXTeam}
        name="RK Customers"
        icon={<ListIcon />}
      >
        <MenuItemLink to="/users" primaryText="Users" leftIcon={<UserIcon />} />
        <MenuItemLink
          to="/reviews"
          primaryText="Reviews"
          leftIcon={<RateReviewIcon />}
        />
      </CustomSubMenu>
      <CustomSubMenu
        handleToggle={() => handleToggle('menuEvents')}
        isOpen={isOpen.menuEvents}
        name="RK Events"
        icon={<ListIcon />}
      >
        <MenuItemLink
          to="/events"
          primaryText="Events"
          leftIcon={<EventIcon />}
        />
        <MenuItemLink
          to="/pointLogs"
          primaryText="User Board"
          leftIcon={<UserBoardIcon />}
        />
        <MenuItemLink
          to="/UserPointRanking"
          primaryText="User Ranking"
          leftIcon={<UserBoardIcon />}
        />
      </CustomSubMenu>
      <CustomSubMenu
        handleToggle={() => handleToggle('menuL2E')}
        isOpen={isOpen.menuL2E}
        name="L2E"
        icon={<ListIcon />}
      >
        <MenuItemLink
          to="/l2eRedemptionHistory"
          primaryText="Redemption History"
          leftIcon={<UserBoardIcon />}
        />
        <MenuItemLink
          to="/l2eMonthlyConfig"
          primaryText="Monthly Configs"
          leftIcon={<UserBoardIcon />}
        />
        <MenuItemLink
          to="/l2eMonthlyPointLogs"
          primaryText="Monthly Logs"
          leftIcon={<UserBoardIcon />}
        />
      </CustomSubMenu>
      <CustomSubMenu
        handleToggle={() => handleToggle('menuSKT')}
        isOpen={isOpen.menuSKT}
        name="SKT"
        icon={<ListIcon />}
      >
        <MenuItemLink
          to="/sktContents"
          primaryText="Contents"
          leftIcon={<ListIcon />}
        />
        <MenuItemLink
          to="/sktFragments"
          primaryText="Fragments"
          leftIcon={<ListIcon />}
        />
      </CustomSubMenu>
    </Box>
  );
};

export default CustomMenu;
