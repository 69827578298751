import React, { useState } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase';

const styles = {
  image: {
    width: 80,
  },
};

const FirebaseImageField = ({ record = {} }) => {
  const [imageUrl, setImageUrl] = useState('');
  const { imagePath } = record;

  if (imagePath == null) {
    return <p>-</p>;
  }

  const imageRef = firebase.storage().ref().child(imagePath);
  imageRef.getDownloadURL().then((url) => setImageUrl(url));

  return (
    <img src={imageUrl} alt={`${record.imagePath}`} style={styles.image} />
  );
};

FirebaseImageField.propTypes = {
  // eslint-disable-next-line react/require-default-props
  record: PropTypes.shape({
    imagePath: PropTypes.string,
    languageCodeToName: PropTypes.shape({
      en: PropTypes.string,
    }),
  }),
};

export default FirebaseImageField;
