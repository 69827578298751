import React from 'react';
import PropTypes from 'prop-types';

const styles = {
  invalidText: { color: 'red' },
  regionListText: { color: 'blue' },
};

const renderRegionList = (youTubeVideoId, regionList) =>
  /* eslint-disable no-nested-ternary */
  youTubeVideoId == null ? null : regionList == null ? (
    <span style={styles.invalidText}>Invalid YouTube video ID</span>
  ) : regionList.length === 0 ? (
    'N/A'
  ) : (
    <span style={styles.regionListText}>{regionList.join(', ')}</span>
  );

const RegionRestrictionCheckField = (props) => {
  const {
    blockedRegions = null,
    allowedRegions = null,
    youTubeVideoId,
  } = props;

  return (
    <>
      <div>
        ● Blocked Regions: &nbsp;
        {renderRegionList(youTubeVideoId, blockedRegions)}
      </div>
      <div>
        ● Allowed Regions: &nbsp;
        {renderRegionList(youTubeVideoId, allowedRegions)}
      </div>
    </>
  );
};

RegionRestrictionCheckField.propTypes = {
  // eslint-disable-next-line react/require-default-props
  blockedRegions: PropTypes.arrayOf(PropTypes.string),
  // eslint-disable-next-line react/require-default-props
  allowedRegions: PropTypes.arrayOf(PropTypes.string),
  addLabel: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
  youTubeVideoId: PropTypes.string,
};

RegionRestrictionCheckField.defaultProps = {
  addLabel: true,
  youTubeVideoId: null,
};

export default RegionRestrictionCheckField;
