import React, { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { fade, makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { InputBase } from '@material-ui/core';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
    marginRight: 25,
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        width: 200,
      },
    },
  },
}));

interface SearchInputProps {
  currentRefinement: string;
  refine: (query: string) => void;
  collection: string;
  onInputChange: (searchResultVisible: boolean) => void;
}

const SearchInput = ({
  currentRefinement,
  refine,
  collection,
  onInputChange,
}: SearchInputProps): JSX.Element => {
  const classes = useStyles();
  const [searchResultVisible, setSearchResultVisible] = useState(false);

  const requestSearch = useCallback(
    (value) => {
      const isQueryEmpty = value.trim() === '';
      const isSameQuery = value.trim() === currentRefinement.trim();
      if (!isQueryEmpty && !isSameQuery) {
        refine(value.trim());
      }
    },
    [currentRefinement, refine],
  );

  const inputValueRef = useRef('');
  useEffect(() => {
    const inputValue = inputValueRef.current;
    if (inputValue != null) {
      requestSearch(inputValue);
    }
  }, [requestSearch]);

  const delayedCallback = _.debounce(requestSearch, 500);

  const handleChange = useCallback(
    (e) => {
      const { value } = e.target;
      inputValueRef.current = value;
      const isEmptyInput = value === '';
      delayedCallback(value);
      setSearchResultVisible(!isEmptyInput);
    },
    [delayedCallback],
  );

  const handleKeyDown = useCallback(
    (e) => {
      e.persist();
      const resultList = document.getElementsByClassName('ais-Hits-item');
      const resultLength = resultList.length;
      if (searchResultVisible && resultLength !== 0) {
        if (e.key === 'Enter') {
          const focusedItem = document.getElementsByClassName('selected')[0];
          return window.open(
            `#/${collection}/${focusedItem.getAttribute('id')}`,
            '_blank',
          );
        }
        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
          let selectedLocation = -1;
          _.map(resultList, (item, index) => {
            if (item.className.includes('selected')) {
              item.classList.remove('selected');
              selectedLocation = index;
            }
          });
          if (selectedLocation < 0) {
            resultList[0].classList.add('selected');
          }
          if (e.key === 'ArrowUp') {
            return selectedLocation === 0
              ? resultList[resultLength - 1].classList.add('selected')
              : resultList[selectedLocation - 1].classList.add('selected');
          }
          return selectedLocation === resultLength - 1
            ? resultList[0].classList.add('selected')
            : resultList[selectedLocation + 1].classList.add('selected');
        }
      }
      return '';
    },
    [collection, searchResultVisible],
  );

  const handleFocus = useCallback((e) => {
    const isEmptyInput = e.target.value === '';
    setSearchResultVisible(!isEmptyInput);
  }, []);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
  }, []);

  useEffect(() => {
    onInputChange(searchResultVisible);
  }, [searchResultVisible, onInputChange]);

  return (
    <form noValidate action="" role="search" onSubmit={handleSubmit}>
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <InputBase
          id="search-input"
          type="search"
          autoComplete="off"
          onChange={handleChange}
          placeholder="Search…"
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          onKeyDown={handleKeyDown}
          onFocus={handleFocus}
          inputProps={{ 'aria-label': 'search' }}
        />
      </div>
    </form>
  );
};

SearchInput.propTypes = {
  currentRefinement: PropTypes.string.isRequired,
  refine: PropTypes.func.isRequired,
  collection: PropTypes.string.isRequired,
  onInputChange: PropTypes.func.isRequired,
};

export default SearchInput;
