import React from 'react';
import { Create } from 'react-admin';

import SeriesActions from './SeriesActions';
import SeriesForm from './SeriesForm';

const SeriesCreate = (props) => (
  <Create actions={<SeriesActions basePath="/series" />} {...props}>
    <SeriesForm mode="create" />
  </Create>
);

export default SeriesCreate;
