import firebase from 'firebase';

const myAuthProvider = {
  login: (params) => {
    const { email, password } = params;

    if (!email.endsWith('@hayanmind.com')) {
      // eslint-disable-next-line no-alert
      alert('Only HayanMind member is allowed to login.');
      return Promise.reject();
    }

    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        if (!firebase.auth().currentUser.emailVerified) {
          firebase.auth().signOut();
          throw new Error('Email is not yet verified.');
        }
      })
      .catch((error) => {
        alert(error); // eslint-disable-line no-alert
        return Promise.reject();
      });
  },
  logout: () => firebase.auth().signOut(),
  checkAuth: () =>
    firebase.auth().currentUser != null ? Promise.resolve() : Promise.reject(),
  checkError: (error) => {
    // TODO: The following logic seems not supported in Firebase SDK
    const { status } = error;
    if (status === 401 || status === 403) {
      // localStorage.removeItem('username');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  getPermissions: () => Promise.resolve(),
};

export default myAuthProvider;
