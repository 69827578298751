import TopicIcon from '@material-ui/icons/ArtTrack';

import TopicList from './TopicList';
import TopicEdit from './TopicEdit';
import TopicCreate from './TopicCreate';

export default {
  list: TopicList,
  create: TopicCreate,
  edit: TopicEdit,
  icon: TopicIcon,
};
