import React from 'react';
import PropTypes from 'prop-types';
import { TopToolbar, ListButton } from 'react-admin';

const SeriesActions = ({ basePath = '', data = {} }) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
  </TopToolbar>
);

SeriesActions.propTypes = {
  // eslint-disable-next-line react/require-default-props
  basePath: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  data: PropTypes.shape({}),
};

export default SeriesActions;
