import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Typography, IconButton } from '@material-ui/core';
import { TopToolbar } from 'react-admin';
import { Link as LinkIcon } from '@material-ui/icons';

const styles = {
  card: {
    margin: 10,
    flex: 1,
  },
  linkIcon: {
    marginRight: 10,
  },
};

const LinkCard = ({ text, link, icon = null }) => {
  const buttonIcon = icon || <LinkIcon />;

  return (
    <Card style={styles.card}>
      <CardContent>
        <Typography variant="h6">{text}</Typography>
      </CardContent>
      <TopToolbar>
        <IconButton
          size="small"
          aria-label="share"
          onClick={() => window.open(link)}
          style={styles.linkIcon}
        >
          {buttonIcon}
        </IconButton>
      </TopToolbar>
    </Card>
  );
};

LinkCard.propTypes = {
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  // eslint-disable-next-line react/require-default-props
  icon: PropTypes.string,
};

export default LinkCard;
