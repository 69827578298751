import React, { useCallback, useRef, useState } from 'react';
import {
  Button,
  Paper,
  Checkbox,
  ListItem,
  Tooltip,
  CircularProgress,
} from '@material-ui/core';
import { useForm } from 'react-final-form'; // eslint-disable-line import/no-extraneous-dependencies
import _ from 'lodash';
import { TextInput } from 'react-admin';
import { Link } from 'react-router-dom';
import { Add as AddIcon, Link as LinkIcon } from '@material-ui/icons';
import SearchableTextInput from './SearchableTextInput';

const styles = {
  listContainer: {
    maxHeight: 200,
    maxWidth: 500,
    overflow: 'auto',
    marginBottom: 20,
  },
  listItemSeriesContainer: {
    marginTop: 5,
    marginLeft: 5,
  },
  listHiddenItemSeriesContainer: {
    marginTop: 5,
    marginLeft: 5,
    color: 'gray',
    textDecoration: 'line-through',
  },
  listItemSeriesAttribute: {
    marginTop: 5,
    fontSize: 14,
  },
  listItemSeriesAttributeName: {
    marginLeft: 2,
    fontWeight: 600,
  },
  listItemHighlightedAttribute: {
    fontSize: 16,
    fontWeight: 700,
  },
  separator: {
    width: '100%',
    height: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.06)',
  },
  addButton: {
    width: '100%',
    paddingTop: 10,
    paddingBottom: 10,
  },
  noResultText: {
    marginTop: 20,
    marginBottom: 20,
    marginRight: 10,
    marginLeft: 10,
    fontSize: 16,
    fontWeight: 600,
  },
  divider: {
    height: 1,
    width: '100%',
    backgroundColor: '#f0f0f0',
  },
  selectSeriesDescription: {
    marginBottom: 5,
    fontSize: 14,
    color: '#495057',
  },
  refreshButton: {
    marginLeft: 100,
    backgroundColor: '#F8F9FA',
  },
  seriesIdInput: {
    width: '190px',
  },
  seriesSelectList: {
    marginTop: 10,
  },
  linkButton: {
    marginTop: 15,
    marginLeft: 5,
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 100,
  },
};

interface SeriesResult {
  id;
  title: string;
}

interface SeriesListItemProps {
  hit: SeriesResult;
  onClick: (result: SeriesResult) => void;
  selected: boolean;
}

const SeriesListItem = ({
  hit,
  onClick: handleClickProp,
  selected,
}: SeriesListItemProps) => {
  const handleClick = useCallback(() => {
    if (handleClickProp != null) {
      handleClickProp(hit);
    }
  }, [hit, handleClickProp]);

  return (
    <ListItem
      selected={selected}
      button
      onClick={handleClick}
      alignItems="flex-start"
      divider
    >
      <Checkbox checked={selected} />
      <div style={styles.listItemSeriesContainer}>
        <div style={styles.listItemSeriesAttribute}>
          <span style={styles.listItemSeriesAttributeName}>Series:</span>
          &nbsp;
          <span style={styles.listItemHighlightedAttribute}>
            <span dangerouslySetInnerHTML={{ __html: hit.title }} />
          </span>
        </div>
        <div style={styles.listItemSeriesAttribute}>
          <span style={styles.listItemSeriesAttributeName}>ID:</span>
          &nbsp;
          {hit.id}
        </div>
      </div>
    </ListItem>
  );
};

// eslint-disable-next-line react/prop-types
const NoResultComponent = ({ query }) => (
  <div>
    <div style={styles.noResultText}>
      {query === ''
        ? 'The Series field is empty. Please type something in the Series field.'
        : `There are no series matching "${query}". Please create a new series.`}
    </div>
    <div style={styles.divider} />
  </div>
);

interface SeriesIdSelectInputProps {
  hits: SeriesResult[];
  initialSeries?: string;
  source: string;
  seriesId?: string;
  search: (query: string) => void;
  loading: boolean;
}

const SeriesIdSelectInput = ({
  hits,
  initialSeries,
  source,
  seriesId,
  search,
  loading,
}: SeriesIdSelectInputProps): JSX.Element => {
  const form = useForm();
  const [series, setSeries] = useState(initialSeries);
  const inputRef = useRef<HTMLInputElement>();

  const updateSeriesId = useRef((id) => {
    form.change(source, id);
  });

  const handleClickItem = useCallback((hit: SeriesResult) => {
    // it focuses text input manually to enable per input validation
    if (inputRef.current != null) {
      inputRef.current.selectionStart = 0;
      inputRef.current.focus();
    }
    updateSeriesId.current(hit.id);
  }, []);

  const updateSeries = useCallback(({ value: newSeries }) => {
    setSeries(newSeries);
  }, []);

  const renderResults = useCallback(
    () =>
      hits.length === 0 ? (
        <NoResultComponent query={series ?? ''} />
      ) : (
        _.map(hits, (hit) => (
          // eslint-disable-next-line react/no-array-index-key
          <SeriesListItem
            key={hit.id}
            hit={hit}
            onClick={handleClickItem}
            selected={seriesId === hit.id}
          />
        ))
      ),
    [hits, handleClickItem, seriesId, series],
  );
  return (
    <div>
      <div>
        <TextInput
          label="Series Id"
          source={source}
          inputRef={inputRef}
          style={styles.seriesIdInput}
        />
        <Tooltip title="Press here to move to series detail page" arrow>
          <Button
            style={styles.linkButton}
            component={Link}
            variant="outlined"
            target="_blank"
            to={{
              pathname: `/series/${seriesId}`,
            }}
          >
            <LinkIcon />
          </Button>
        </Tooltip>
      </div>
      <SearchableTextInput
        refine={search}
        label="Search Keyword"
        // ToDo #10539: It is better to searchableTextInput replace the apostrophe by itself.
        initialValue={initialSeries?.replace(/’/g, "'")}
        onChange={updateSeries}
      />
      <div style={styles.seriesSelectList}>
        <div style={styles.selectSeriesDescription}>
          Select series from the list *
        </div>
        <Paper style={styles.listContainer}>
          {loading ? (
            <div style={styles.loadingContainer}>
              <CircularProgress />
            </div>
          ) : (
            renderResults()
          )}
          <div style={styles.separator} />
          <Button
            style={styles.addButton}
            component={Link}
            to={{
              pathname: '/series/create',
            }}
            target="_blank"
          >
            <AddIcon />
            Add new series
          </Button>
        </Paper>
      </div>
    </div>
  );
};

export default SeriesIdSelectInput;
