import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { QuestionAnswer as AnswerIcon } from '@material-ui/icons';
import { Button, Collapse } from '@material-ui/core';
import { useForm } from 'react-final-form';

import { DefaultKiwiUserId, CommonAnswers } from '../questionsConstants';

const styles = {
  commonAnswerButton: { marginRight: 10 },
  answerButton: { marginTop: 5 },
  iconInsideButton: { marginRight: 5 },
};

const AnswerButton = ({ commonAnswer }) => {
  const form = useForm();
  const { name, originalText, originalTextTranslations } = commonAnswer;

  const onClickAnswerButton = useCallback(() => {
    // ToDo: Integrate these duplicate lines
    // But using multiple update is not supported yet (https://github.com/redux-form/redux-form/issues/3737)
    form.change('answer.author.id', DefaultKiwiUserId);
    form.change('answer.originalText', originalText);
    form.change('answer.originalTextTranslations', originalTextTranslations);
    form.change('answer.published', true);
  }, [form, originalText, originalTextTranslations]);

  return (
    <Button
      variant="outlined"
      style={styles.answerButton}
      onClick={onClickAnswerButton}
      fullWidth
    >
      {name}
    </Button>
  );
};

const CommonAnswersButton = () => {
  const [commonAnswerExpanded, setCommonAnswerExpanded] = React.useState(false);

  const handleCommonAnswerExpandClick = useCallback(() => {
    setCommonAnswerExpanded(!commonAnswerExpanded);
  }, [commonAnswerExpanded]);

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        style={
          commonAnswerExpanded
            ? { ...styles.commonAnswerButton, backgroundColor: 'lightgray' }
            : styles.commonAnswerButton
        }
        onClick={handleCommonAnswerExpandClick}
      >
        <AnswerIcon style={styles.iconInsideButton} />
        Common Answers
      </Button>
      <Collapse in={commonAnswerExpanded} timeout="auto">
        {CommonAnswers.map((commonAnswer) => (
          <AnswerButton key={commonAnswer.name} commonAnswer={commonAnswer} />
        ))}
      </Collapse>
    </>
  );
};

AnswerButton.propTypes = {
  commonAnswer: PropTypes.shape({
    name: PropTypes.string.isRequired,
    originalText: PropTypes.string.isRequired,
    originalTextTranslations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
};

export default CommonAnswersButton;
