import React, { useEffect } from 'react';
import {
  List,
  Datagrid,
  TextField,
  Pagination,
  Filter,
  SelectInput,
  EditButton,
} from 'react-admin';

import ReviewEdit from './ReviewEdit';
import {
  BooleanChoices,
  REVIEW_RATING_CHOICES,
  Languages,
} from '../../constants';

const ReviewFilter = (props) => (
  <Filter {...props}>
    <SelectInput
      label="IsPublished"
      source="published"
      choices={BooleanChoices}
      allowEmpty
      emptyValue=""
    />
    <SelectInput
      label="Language"
      source="languageCode"
      choices={Languages}
      allowEmpty={false}
    />
    <SelectInput
      label="Rating"
      source="rating"
      choices={REVIEW_RATING_CHOICES}
    />
  </Filter>
);

const ReviewList = (props) => {
  useEffect(() => {
    document.title = 'TMS: Reviews';
  }, []);

  return (
    <>
      <List
        filters={<ReviewFilter />}
        pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
        perPage={25}
        sort={{ field: 'date', order: 'DESC' }}
        bulkActionButtons={false}
        exporter={false}
        {...props}
      >
        <Datagrid rowClick="expand" expand={<ReviewEdit />}>
          <TextField label="IsPublished" source="published" />
          <TextField label="Language" source="languageCode" />
          <TextField label="Content" source="content" />
          <TextField label="Date" source="date" />
          <TextField label="Rating" source="rating" />
          <EditButton />
        </Datagrid>
      </List>
    </>
  );
};

export default ReviewList;
