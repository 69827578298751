// *** CONFIGURATION ***
// eslint-disable-next-line no-underscore-dangle
export const __DEV__ = process.env.NODE_ENV === 'development';
export const FIREBASE_APP_NAME = __DEV__ ? 'mimiking-dev' : 'mimiking-8d91c';

export function getFirebaseConfig(isProduction = false) {
  if (isProduction) {
    return {
      apiKey: 'AIzaSyB6KyudGfo-DS-YPZsVos9Oy1FYBihBH18',
      authDomain: 'mimiking-8d91c.firebaseapp.com',
      databaseURL: 'https://mimiking-8d91c.firebaseio.com',
      projectId: 'mimiking-8d91c',
      storageBucket: 'mimiking-8d91c.appspot.com',
      messagingSenderId: '322546126495',
    };
  }

  return {
    apiKey: 'AIzaSyCwSdTaUtA4TMEzJvpyobDcciIeMqNMp6M',
    authDomain: 'mimiking-dev.firebaseapp.com',
    databaseURL: 'https://mimiking-dev.firebaseio.com',
    projectId: 'mimiking-dev',
    storageBucket: 'mimiking-dev.appspot.com',
    messagingSenderId: '284727263546',
  };
}
