import _ from 'lodash';

export default (
  record,
  filter,
  // For array value keys, it filters record that HAS (not equals) the value.
  keysOfArrayValue,
  keyOfNestedValue,
  //  Elements of above two arguments can be added more later.
) => {
  const filterKeys = Object.keys(filter);
  let nestedBooleans = [];

  const listOfBooleans = filterKeys.map((filterKey) => {
    const filterValue = filter[filterKey];
    const isNested = keyOfNestedValue.includes(filterKey); // ['answer']

    if (isNested) {
      const nestedKeys = Object.keys(filter[filterKey]);
      nestedBooleans = nestedKeys.map((nestedKey) => {
        const nestedValue = filterValue[nestedKey];

        if (nestedValue === 'none') {
          // If value is 'none', it returns document that has not this field at all.
          const doesNotExist = keysOfArrayValue.includes(nestedKey)
            ? _.isEqual(_.get(record, `${filterKey}.${nestedKey}`, []), [])
            : _.get(record, `${filterKey}.${nestedKey}`, null) == null;
          return doesNotExist;
        }

        if (record[filterKey] == null) {
          return false;
        }

        const isSatisfied = keysOfArrayValue.includes(nestedKey)
          ? record[filterKey][nestedKey]
              .map((r) => r.languageCode)
              .includes(nestedValue)
          : record[filterKey][nestedKey] === nestedValue;
        return isSatisfied;
      });

      return nestedBooleans.every((item) => item);
    }

    if (filterValue === 'none') {
      // If value is 'none', it returns document that has not this field at all.
      const doesNotExist = keysOfArrayValue.includes(filterKey)
        ? _.isEqual(_.get(record, filterKey, []), [])
        : _.get(record, filterKey, null) == null;
      return doesNotExist;
    }

    const isSatisfied = keysOfArrayValue.includes(filterKey) // ['originalTextTranslations']
      ? record[filterKey].map((r) => r.languageCode).includes(filterValue)
      : record[filterKey] === filterValue;

    return isSatisfied;
  });

  return listOfBooleans.every((item) => item);
};
