import React from 'react';
import { TextInput } from 'react-admin';
import PropTypes from 'prop-types';
import _ from 'lodash';

// LevelInput logic(L8~L30) are from
// redkiwi-server/functions-es6/http_triggers/graphql/user/getStat.js.
const LevelInput = ({ learningLanguageCode, record, validate }) => {
  const levelMinScores = [
    0, 5, 30, 75, 140, 225, 330, 455, 600, 765, 950, 1155, 1380, 1625, 1890,
    2175, 2480, 2805, 3150, 3515, 3900, 4305, 4730, 5175, 5640, 6125, 6630,
    7155, 7700, 8265, 8850, 9455, 10080, 10725, 11390, 12075, 12780, 13505,
    14250, 15015, 15800, 16605, 17430, 18275, 19140, 20025, 20930, 21855, 22800,
    23765, 24750, 25755, 26780, 27825, 28890, 29975, 31080, 32205, 33350, 34515,
    35700, 36905, 38130, 39375, 40640, 41925, 43230, 44555, 45900, 47265, 48650,
    50055, 51480, 52925, 54390, 55875, 57380, 58905, 60450, 62015, 63600, 65205,
    66830, 68475, 70140, 71825, 73530, 75255, 77000, 78765, 80550, 82355, 84180,
    86025, 87890, 89775, 91680, 93605, 95550, 97515,
  ]; // TODO: This will be moved to `@redkiwi/shared` as well as the code in `redkiwi-server`

  const getLevel = (score) => {
    const indexOfLevelMinScores = _.findIndex(
      levelMinScores,
      (minScore, index) => {
        const nextMinScore = levelMinScores[index + 1];
        return (
          minScore <= score && (score < nextMinScore || nextMinScore == null)
        );
      },
    );

    return indexOfLevelMinScores + 1;
  };

  const completedFragmentCount = _.get(
    record,
    ['langToCompletedFragmentCount', learningLanguageCode],
    0,
  );
  const level = getLevel(completedFragmentCount);

  return <TextInput source={`Lv: ${level}`} validate={validate} />;
};

LevelInput.propTypes = {
  learningLanguageCode: PropTypes.string.isRequired,
  record: PropTypes.shape({}).isRequired,
  // eslint-disable-next-line react/require-default-props
  validate: PropTypes.oneOfType([PropTypes.func, PropTypes.array]),
};

export default LevelInput;
