import React from 'react';
import { TextInput } from 'react-admin';
import PropTypes from 'prop-types';
import JSUtility from '../../utilities/JSUtility';

const CustomTextInput = (props) => {
  const { parse, ...otherProps } = props;

  const composedParse = (value) => {
    const tempValue = parse(value) === '' ? null : value;

    const parseSmartQuoteValue = tempValue
      ? JSUtility.hasValidQuote(tempValue)
      : null;

    return parseSmartQuoteValue;
    // return JSUtility.textInputParser(tempValue); // ToDo: #7018
  }; // https://stackoverflow.com/a/50276354/1609620

  return <TextInput {...otherProps} parse={composedParse} />;
};

CustomTextInput.propTypes = {
  parse: PropTypes.func,
};

CustomTextInput.defaultProps = {
  parse: (v) => v,
};

export default CustomTextInput;
