import React from 'react';
import { Create } from 'react-admin';

import QuestionActions from './QuestionActions';
import QuestionForm from './QuestionForm';

const QuestionCreate = (props) => (
  <Create actions={<QuestionActions basePath="/questions" />} {...props}>
    <QuestionForm mode="create" />
  </Create>
);

export default QuestionCreate;
