import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';

import JSUtility from '../../utilities/JSUtility';

const ShortenedDateField = ({
  record = {},
  source,
  type = 'datetime',
  utcOffset,
}) => {
  const originalValue = _.get(record, source, null);

  // eslint-disable-next-line no-nested-ternary
  const originalTextValue =
    type === 'timestamp'
      ? JSUtility.convertUnixSecondToString(originalValue, { utcOffset })
      : // Getting expiredDate for subscription got complicated after August subscription renewal.
      type === 'subscriptionObject'
      ? JSUtility.getRecentStringDate(
          _.get(originalValue, 'premium.expiredAt', null),
          _.get(originalValue, 'membership.expiredAt', null),
        )
      : // type === 'datetime'
        originalValue;

  const twoWeeksBefore = moment().subtract(14, 'days');
  const isChurnOutUser =
    source === 'author.lastSeenAt' && twoWeeksBefore.isAfter(originalValue);
  const isExpiredPaidUser =
    source === 'author.user.subscription' &&
    moment().isAfter(originalTextValue);

  return (
    <p style={isChurnOutUser || isExpiredPaidUser ? { color: 'gray' } : null}>
      {originalTextValue == null ? '-' : originalTextValue.slice(0, 10)}
    </p>
  );
};

// Workaround: To remove eslint warning
ShortenedDateField.propTypes = {
  // eslint-disable-next-line react/require-default-props
  record: PropTypes.shape({}),
  source: PropTypes.string.isRequired,
  utcOffset: PropTypes.number,
  // eslint-disable-next-line react/require-default-props
  type: PropTypes.oneOf(['datetime', 'timestamp', 'subscriptionObject']),
};

ShortenedDateField.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  addLabel: true,
  utcOffset: null,
};

export default ShortenedDateField;
