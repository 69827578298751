import BookIcon from '@material-ui/icons/MenuBook';

import L2eRedemptionHistoryList from './L2eRedemptionHistoryList';
import L2eRedemptionHistory from './L2eRedemptionHistoryEdit';

export default {
  list: L2eRedemptionHistoryList,
  edit: L2eRedemptionHistory,
  icon: BookIcon,
};
