import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  TextField,
  EditButton,
  ShowController,
  ShowView,
  SimpleShowLayout,
} from 'react-admin';

const styles = {
  subtitleLanguage: {
    fontWeight: 'bold',
  },
};

const TextTranslationsField = ({ record }) =>
  record.textTranslations != null
    ? Object.keys(record.textTranslations).map((subtitleLanguage) => (
        <div key={`text-translations-field-${subtitleLanguage}`}>
          <span style={styles.subtitleLanguage}>{subtitleLanguage}</span>
          &nbsp;
          <span>{record.textTranslations[subtitleLanguage]}</span>
        </div>
      ))
    : null;

TextTranslationsField.defaultProps = {
  addLabel: true,
};

const FragmentShow = (props) => (
  <ShowController {...props}>
    {(controllerProps) => (
      <ShowView {...props} {...controllerProps}>
        <SimpleShowLayout>
          <EditButton {...props} />
          <TextField label="Language Code" source="languageCode" />
          <TextField source="text" />
          {_.get(props.record, 'textTranslations', null) ? (
            <TextTranslationsField label="Text Translations" />
          ) : null}
        </SimpleShowLayout>
      </ShowView>
    )}
  </ShowController>
);

FragmentShow.propTypes = {
  record: PropTypes.shape({}),
};

FragmentShow.defaultProps = {
  record: null,
};

export default FragmentShow;
