import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { getMonetaryUserRanking } from '../../dataProvider/firestoreDataProvider';
import Overview from './OverView';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  margin: {
    marginLeft: 20,
  },
  textInput: {
    width: 'fit-content',
  },
  listItemTopicContainer: {
    marginTop: 5,
    marginLeft: 5,
  },
  item: {
    marginLeft: 20,
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },
  eachItem: {
    marginLeft: 20,
  },
});
const UserPointRanking = () => {
  const [records, setRecords] = useState([]);
  const classes = useStyles();
  useEffect(() => {
    const getRanking = async () => {
      const data = await getMonetaryUserRanking(100);
      setRecords(data);
    };
    getRanking();
  }, []);

  return (
    <>
      <div> - Monetary Reward 이벤트 참여 현황</div>
      <div className={classes.wrapper}>
        <div>
          <Overview />
        </div>
        <div className={classes.listItemTopicContainer}>
          {records.length > 0 &&
            records.map(({ user, collectedPoints }, index) => (
              <div className={classes.item} key={user}>
                <span className={classes.eachItem}>{index + 1}</span>
                <span className={classes.eachItem}> {user}</span>
                <span className={classes.eachItem}>[ {collectedPoints} ]</span>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default UserPointRanking;
