import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  SimpleForm,
  TextInput,
  BooleanInput,
  NumberInput,
  ArrayInput,
  required,
  GET_LIST,
} from 'react-admin';
import { useForm } from 'react-final-form';

import { IconButton, Tooltip, Typography } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { CustomEditToolbar, CustomSimpleFormIterator } from '../../common';
import myDataProvider from '../../dataProvider/firestoreDataProvider';
import JSUtility from '../../utilities/JSUtility';

const styles = {
  verticalView: {
    display: 'flex',
    flexDirection: 'column',
  },
  textInput: {
    width: 350,
  },
  updated: {
    backgroundColor: '#fad0c3',
  },
  inlineStyle: {
    display: 'flex',
    alignItems: 'center',
  },
};

const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: theme.typography.pxToRem(14),
    border: '1px solid #dadde9',
  },
}));

const FrequencyTooltip = (props) => {
  const classes = useStyles();
  return <Tooltip classes={classes} {...props} />;
};

const LemmaForm = (props) => {
  const { mode, record, ...otherProps } = props;
  return (
    <SimpleForm
      {...props}
      toolbar={
        <CustomEditToolbar {...otherProps} mode={mode} record={record} />
      }
      redirect={false}
      submitOnEnter={false}
      keepDirtyOnReinitialize={false}
    >
      <LemmaFormContent mode={mode} record={record} />
    </SimpleForm>
  );
};

const LemmaFormContent = (props) => {
  const { mode, record } = props;
  const [dirtyFields, setDirtyFields] = useState({});
  const [formData, setFormData] = useState({});

  const form = useForm();

  useEffect(() => {
    const unsubscribe = form.subscribe(
      (s) => {
        setDirtyFields(s.dirtyFields);
        setFormData(s.values);
      },
      { dirtyFields: true, values: true },
    );
    return () => {
      unsubscribe();
    };
  }, [form]);

  const isCreating = mode === 'create';
  const handleLemmaFrequencyChange = useCallback(
    ({ target: { value } }) => {
      const level = JSUtility.getDifficultyLevelBasedOnFrequency(value);
      form.change('difficultyLevel', level);
    },
    [form],
  );

  const handleTextInputBlur = useCallback(
    ({ target: { value } }) => {
      if (value !== '') {
        form.change('relatedWords', [value]);
      }
    },
    [form],
  );

  const validateLemma = useCallback(
    async (value) => {
      if (value != null) {
        const isPunctuationFreeLemma = (() => {
          const normalizedLemma = JSUtility.normalizeWord(value);
          return normalizedLemma === value;
        })();

        if (!isPunctuationFreeLemma) {
          return 'The lemma should use only lower case characters and not include punctuation except the dash and apostrophe.';
        }

        const isThereThreeSameLemma = await (async () => {
          const response = await myDataProvider(GET_LIST, 'lemmas', {
            filter: { lemma: value },
          });
          const duplicateLemmas = response.data.filter(
            (l) => l.id !== record.id,
          );
          return duplicateLemmas.length > 1;
        })();

        if (isThereThreeSameLemma) {
          return 'The same lemma cannot exist more than 2.';
        }
      }
      return undefined;
    },
    [record.id],
  );

  const validate = [required('Please fill the field.')];
  const lemmaValidate = [required('Please fill the field.'), validateLemma];

  return (
    <div style={styles.verticalView}>
      {!isCreating && (
        <TextInput
          disabled
          label="Lemma Id"
          source="id"
          style={styles.textInput}
        />
      )}
      <TextInput
        label="Lemma"
        source="lemma"
        record={formData}
        style={{
          ...styles.textInput,
          ...(dirtyFields.lemma ? styles.updated : null),
        }}
        onBlur={isCreating ? handleTextInputBlur : undefined}
        validate={lemmaValidate}
      />
      <div style={styles.inlineStyle}>
        <NumberInput
          label="Frequency"
          source="frequency"
          record={formData}
          onChange={handleLemmaFrequencyChange}
          style={{
            ...styles.textInput,
            ...(dirtyFields.frequency ? styles.updated : null),
          }}
          validate={validate}
        />
        <FrequencyTooltip
          placement="right"
          title={
            <>
              <Typography color="inherit">Tooltip for lemma rule</Typography>
              Lv.1 = Google Search results more than <b>3,623,300,000</b>
              {' => '}
              <b>Freq. 176,550</b>
              <br />
              <br />
              Lv.2 = Google Search results between{' '}
              <b>2,561,800,000 - 3,623,300,000</b> {' => '}
              <b>Freq. 21,275</b>
              <br />
              <br />
              Lv.3 = Google Search results between{' '}
              <b>215,060,000 - 2,561,800,000</b> {' => '}
              <b>Freq. 6,940</b>
              <br />
              <br />
              Lv.4 = Google Search results between{' '}
              <b>20,624,000 - 215,060,000</b> {' => '}
              <b>Freq.726</b>
              <br />
              <br />
              Lv.5 = Google Search results between{' '}
              <b>10,000,000 - 20,624,000</b> {' => '}
              <b>Freq.116</b>
              <br />
              <br />
              Lv.6 = Google Search results between <b>
                1,000,000 - 10,000,000
              </b>{' '}
              {' => '}
              <b>Freq.41</b>
              <br />
              <br />
              Google Search results less than <b>1,000,000</b>, it can be kept{' '}
              <b>hidden</b>.
            </>
          }
        >
          <IconButton>
            <Info />
          </IconButton>
        </FrequencyTooltip>
      </div>
      <NumberInput
        label="Difficulty Lv."
        source="difficultyLevel"
        record={formData}
        disabled
        style={{
          ...styles.textInput,
          ...(dirtyFields.difficultyLevel ? styles.updated : null),
        }}
        validate={validate}
      />
      <BooleanInput
        label="Hidden"
        source="hidden"
        record={formData}
        defaultValue={isCreating ? true : undefined}
        style={{
          marginRight: 5,
          ...(dirtyFields.hidden ? styles.updated : null),
        }}
      />
      <ArrayInput source="relatedWords" record={formData}>
        <CustomSimpleFormIterator>
          <TextInput label="Word" />
        </CustomSimpleFormIterator>
      </ArrayInput>
    </div>
  );
};

const CommonPropTypesForLemmaFormAndContent = {
  mode: PropTypes.oneOf(['create', 'update']).isRequired,
  record: PropTypes.PropTypes.shape({}),
};

LemmaForm.propTypes = {
  ...CommonPropTypesForLemmaFormAndContent,
};

LemmaFormContent.propTypes = {
  ...CommonPropTypesForLemmaFormAndContent,
};

export default LemmaForm;
