import React from 'react';
import PropTypes from 'prop-types';

const styles = {
  errorText: { color: 'red' },
};

const AgeRestrictionCheckField = (
  props /* eslint-disable no-nested-ternary */,
) =>
  props.youTubeVideoId == null ? null : props.isAgeRestricted == null ? (
    <div style={styles.errorText}>● Invalid YouTube video ID</div>
  ) : props.isAgeRestricted === true ? (
    <div style={styles.errorText}>
      ● This video is only for adults. Try other video.
    </div>
  ) : (
    '● Age restriction check PASSED.'
  ); /* eslint-enable no-nested-ternary */

AgeRestrictionCheckField.propTypes = {
  isAgeRestricted: PropTypes.bool,
  addLabel: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
};

AgeRestrictionCheckField.defaultProps = { addLabel: true };

export default AgeRestrictionCheckField;
