import React, { useCallback, useEffect, useState } from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Pagination,
  Filter,
  SelectInput,
  GET_LIST,
} from 'react-admin';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { ScrollToTopButton, ShortenedDateField } from '../../common';
import firestoreDataProvider from '../../dataProvider/firestoreDataProvider';
import { L2eRedemptionStatus } from '../../constants';

const L2eRedemptionHistoryList = (props) => {
  const [records, setRecords] = useState([]);
  const [filter, setFilter] = useState({});

  useEffect(() => {
    document.title = 'TMS: L2eRedemptionHistory';
  }, []);

  useEffect(() => {
    const getL2eRedemptionHistoryList = async () => {
      const { data } = await firestoreDataProvider(
        GET_LIST,
        'l2eRedemptionHistory',
        { filter, sort: { field: 'id', order: 'ASC' } },
      );
      setRecords(data);
    };
    getL2eRedemptionHistoryList();
  }, [filter]);

  const L2eRedemptionHistoryFilter = useCallback(
    (filterProps) => (
      <Filter {...filterProps}>
        <SelectInput
          label="Status"
          source="status"
          choices={L2eRedemptionStatus}
          alwayson="true"
          allowEmpty={false}
          onChange={(event) => setFilter({ status: event.target.value })}
        />
      </Filter>
    ),
    [],
  );

  return (
    <>
      <List
        filters={<L2eRedemptionHistoryFilter />}
        filterDefaultValues={{ status: 'requested' }}
        pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
        perPage={25}
        bulkActionButtons={false}
        exporter={false}
        title="L2e Redemption History"
        {...props}
      >
        <Datagrid rowClick="expand">
          <TextField label="User Name" source="userName" />
          <TextField label="User Id" source="userId" />
          <TextField label="Status" source="status" />
          <TextField label="Points" source="redeemedPoints" />
          <TextField label="Bank Name" source="bankName" />
          <TextField label="Account Number" source="accountNumber" />
          <TextField label="Phone Number" source="phoneNumber" />
          <ShortenedDateField
            label="Created"
            source="createdAt.seconds"
            type="timestamp"
          />
          <EditButton />
        </Datagrid>
      </List>
      <CSVLink
        data={records}
        filename={`RedemptionHistory_${moment().format('YYYYMMDD_HHmmss')}`}
      >
        Download Data
      </CSVLink>
      <ScrollToTopButton category={0} />
    </>
  );
};

export default L2eRedemptionHistoryList;
