import React from 'react';
import { Create } from 'react-admin';

import SurveyActions from './SurveyActions';
import SurveyForm from './SurveyForm';

const SurveyCreate = (props) => (
  <Create actions={<SurveyActions basePath="/surveys" />} {...props}>
    <SurveyForm mode="create" />
  </Create>
);

export default SurveyCreate;
