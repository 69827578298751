import React from 'react';
import PropTypes from 'prop-types';
import { TextInput, regex } from 'react-admin';

import JSUtility from '../../utilities/JSUtility';
import { RegexTimestampForm, RegexOnlyNumColonComma } from '../../constants';

const TimestampInput = ({ record, source, validate, style = {}, ...rest }) => {
  const validateTimestampForm = [
    regex(
      RegexTimestampForm,
      "Timestamp should be in a form of '00:00:00,000'",
    ),
  ];
  const validateTimestamp = validate
    ? validateTimestampForm.concat(validate)
    : validateTimestampForm;

  return (
    <TextInput
      source={source}
      placeholder="00:00:02,500"
      validate={validateTimestamp}
      parse={(v) => {
        const convertedNumber = JSUtility.convertStringToOnlyNumberString(v);
        if (!RegexOnlyNumColonComma.test(convertedNumber)) {
          // eslint-disable-next-line no-alert
          alert('Error: Only number, colon, and comma allowed for Timestamp.');
        }
        return JSUtility.formatTimestamp(convertedNumber);
      }}
      style={{
        ...style,
        width: 130,
        marginRight: 10,
      }}
      {...rest}
    />
  );
};

// Workaround: To remove eslint warning
TimestampInput.propTypes = {
  record: PropTypes.shape({}),
  source: PropTypes.string,
  validate: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  style: PropTypes.shape({}),
};

TimestampInput.defaultProps = {
  record: {},
  source: null,
  validate: null,
};

export default TimestampInput;
