import _ from 'lodash';

// *** CONFIGURATION ***
// eslint-disable-next-line no-underscore-dangle
export const __DEV__ = window.location.host !== 'tms.redkiwiapp.com';
const isProduction = process.env.NODE_ENV !== 'development';

// eslint-disable-next-line no-nested-ternary
export const LINK_TO_TMS =
  __DEV__ && !isProduction
    ? 'http://localhost:3000/#'
    : __DEV__ && isProduction
    ? 'https://redkiwi-tms-dev.firebaseapp.com/#'
    : 'http://tms.redkiwiapp.com/#';

export const LINK_TO_INTERCOM = __DEV__
  ? 'https://us-central1-mimiking-dev.cloudfunctions.net/forwardIntercomUserPage?user_id='
  : 'https://us-central1-mimiking-8d91c.cloudfunctions.net/forwardIntercomUserPage?user_id=';

// export const LINK_TO_CHANNELTALK = 'https://desk.channel.io/#/channels/65387/users/';
export const LINK_TO_CHANNELTALK = __DEV__
  ? 'https://us-central1-mimiking-dev.cloudfunctions.net/forwardChannelTalkUserPage?user_id='
  : 'https://us-central1-mimiking-8d91c.cloudfunctions.net/forwardChannelTalkUserPage?user_id=';

// *** REGULAR EXPRESSIONS ***
export const RegexOnlyNormalNumbers = /^[0-9]+$/;
export const RegexOnlyBigNumbers = /^[０-９]+$/;
export const RegexYouTubeVideoId = /^[a-zA-Z0-9_-]{11}$/;
export const RegexTimestampForm = /^([0-9]{2}:){2}[0-9]{2},[0-9]{3}$/;
export const RegexYearMonth = /^\d\d\d\d-\d\d$/i;
export const RegexOnlyNumColonComma = /^[0-9&:&,]+$/;
export const RegexNoSpace = /^\S*$/;
export const RegexNoOnlySpaceOrNewline = /^[^\s\r\n].*$/ms;
export const RegexLastNewline = /\n$/;

// *** LANGUAGE ***
export const ENGLISH_CODE = 'en';
export const KOREAN_CODE = 'ko';
export const JAPANESE_CODE = 'ja';
export const CHINESE_CODE = 'zh-CN';
export const TAIWANESE_CODE = 'zh-TW';
export const VIETNAMESE_CODE = 'vi';
export const SPANISH_CODE = 'es';

const ENGLISH = 'English';
const KOREAN = 'Korean';
const JAPANESE = 'Japanese';
const CHINESE = 'Chinese';
const TAIWANESE = 'Taiwanese';
const VIETNAMESE = 'Vietnamese';
const SPANISH = 'Spanish';

// `SelectInput` refers to it directly, so it must include `id` and `name` prop.
const makeLanguageObj = (id, name) => ({ id, name });

// List of all langauges used in TMS
export const Languages = [
  makeLanguageObj(ENGLISH_CODE, ENGLISH),
  makeLanguageObj(KOREAN_CODE, KOREAN),
  makeLanguageObj(JAPANESE_CODE, JAPANESE),
  makeLanguageObj(VIETNAMESE_CODE, VIETNAMESE),
  makeLanguageObj(SPANISH_CODE, SPANISH),
  makeLanguageObj(CHINESE_CODE, CHINESE),
  makeLanguageObj(TAIWANESE_CODE, TAIWANESE),
];

// List of all languages code used in TMS
export const LanguageCodes = [
  makeLanguageObj(ENGLISH_CODE, ENGLISH_CODE),
  makeLanguageObj(KOREAN_CODE, KOREAN_CODE),
  makeLanguageObj(JAPANESE_CODE, JAPANESE_CODE),
  makeLanguageObj(VIETNAMESE_CODE, VIETNAMESE_CODE),
  makeLanguageObj(SPANISH_CODE, SPANISH_CODE),
  makeLanguageObj(CHINESE_CODE, CHINESE_CODE),
  makeLanguageObj(TAIWANESE_CODE, TAIWANESE_CODE),
];

// List of languages used as a content
export const ContentLanguages = _.differenceBy(
  Languages,
  [
    // Languages that are not supported as a content should be listed below.
    makeLanguageObj(VIETNAMESE_CODE, VIETNAMESE),
    makeLanguageObj(SPANISH_CODE, SPANISH),
    makeLanguageObj(TAIWANESE_CODE, TAIWANESE),
  ],
  'id',
);

// List of languages available by in-house translator
export const AvailableLanguages = _.differenceBy(
  Languages,
  [
    // Languages that does not have in-house translator should be listed below.
    makeLanguageObj(CHINESE_CODE, CHINESE),
  ],
  'id',
);

export const ContentLanguageCodeToName = {
  [ENGLISH_CODE]: ENGLISH,
  [JAPANESE_CODE]: JAPANESE,
  [KOREAN_CODE]: KOREAN,
  [CHINESE_CODE]: CHINESE,
};

export const EnglishContentMustHaveLanguages = [
  makeLanguageObj(KOREAN_CODE, KOREAN),
  makeLanguageObj(JAPANESE_CODE, JAPANESE),
  // makeLanguageObj(VIETNAMESE_CODE, VIETNAMESE),
];

export const NonEnglishContentMustHaveLanguages = [
  makeLanguageObj(ENGLISH_CODE, ENGLISH),
];

// *** FRAGMENT ***
export const LanguageKeyToForeignLanguageText = {
  ko: '[외국어 말하는 중]',
  en: '[Speaking foreign language]',
  ja: '[外国語話し中]',
  zh: '[正在说外语]',
  vi: '[Đang nói ngoại ngữ]',
};

export const JapaneseBigNumberToNormalNumber = {
  '０': '0',
  '１': '1',
  '２': '2',
  '３': '3',
  '４': '4',
  '５': '5',
  '６': '6',
  '７': '7',
  '８': '8',
  '９': '9',
};

export const BooleanChoices = [
  { id: true, name: 'O' },
  { id: false, name: 'X' },
];

// ***PREMIUM***
export const HowManyPremiumDays = ['3', '7', '31', '92', '365'];

export const DB_PATH = {
  // Users collection related path
  // Subscription - Premium
  subscriptionPremiumExpiredAtSeconds: 'subscription.premium.expiredAt.seconds',
  subscriptionPremiumUpdatedAtSeconds: 'subscription.premium.updatedAt.seconds',
  subscriptionPremiumCreatedAt: 'subscription.premium.createdAt',
  subscriptionPremiumExpiredAt: 'subscription.premium.expiredAt',
  subscriptionPremiumSubscriptionId: 'subscription.premium.subscriptionId',
  // Subscription - Membership
  subscriptionMembershipSubscriptionId:
    'subscription.membership.subscriptionId',
  subscriptionMembershipType: 'subscription.membership.type',
  subscriptionMembershipUpdatedAt: 'subscription.membership.updatedAt',
  subscriptionMembershipExpiredAt: 'subscription.membership.expiredAt',
  subscriptionMembershipMigratedFromUserId:
    'subscription.membership.migratedFromUserId',
};

export const ContentsSearchSortBy = {
  TITLE_ASC: 'title_asc',
  TITLE_DESC: 'title_desc',
  CREATED_AT_ASC: 'created_at_asc',
  CREATED_AT_DESC: 'created_at_desc',
  RELEVANCE: null, // Default is relevance
};

// ***LEMMAS***
export const LemmaDifficultyLevels = [
  { id: 1, name: 'Lv. 1' },
  { id: 2, name: 'Lv. 2' },
  { id: 3, name: 'Lv. 3' },
  { id: 4, name: 'Lv. 4' },
  { id: 5, name: 'Lv. 5' },
  { id: 6, name: 'Lv. 6' },
];

// ***Survey***
export const SurveyQuestionTypes = [
  { id: 'single', name: 'Single answer' },
  { id: 'multiple', name: 'Multiple choice' },
];

export const SurveyQuestionLayouts = [
  { id: 'grid', name: 'grid' },
  { id: 'list', name: 'list' },
];

// ***Grammar***
export const GrammarTypes = [{ id: 'phrasalVerbs', name: 'Phrasal Verbs' }];

export const Collections = {
  TAGS: 'tags',
  SURVEY_ANSWER_OPTIONS: 'surveyAnswerOptions',
};

export const ResourceNameToImageField = {
  [Collections.TAGS]: [
    'image',
    'wordMapCategoryImage',
    'wordMapCategoryHeaderImage',
  ],
  [Collections.SURVEY_ANSWER_OPTIONS]: ['defaultImage', 'selectedImage'],
};
export const ImageFieldNameToSubFolder = {
  wordMapCategoryImage: 'wordMap',
  wordMapCategoryHeaderImage: 'wordMap/header',
};

export const REVIEW_RATING_CHOICES = [
  { id: 4, name: '4' },
  { id: 5, name: '5' },
];

export const KST_OFFSET_IN_MIN = 540;

export const LevelChoices = [
  { id: 1, name: 'Level 1' },
  { id: 2, name: 'Level 2' },
  { id: 3, name: 'Level 3' },
];

export const StatusChoices = [
  { id: 'collected', name: 'Collected' },
  { id: 'redeemed', name: 'Redeemed' },
];

export const CommentaryLabelChoices = [
  { id: 'grammar', name: 'Grammar' },
  { id: 'culture', name: 'Culture' },
  { id: 'vocabulary', name: 'Vocabulary' },
  { id: null, name: 'X' },
];

export const L2eRedemptionStatus = [
  { id: 'requested', name: 'Requested' },
  { id: 'inProgress', name: 'In progress' },
  { id: 'completed', name: 'Completed' },
  { id: 'failed', name: 'Failed' },
];

export const CONTENT_AUTOMATION_API_URL =
  window.location.host === 'localhost:3000'
    ? 'http://127.0.0.1:5001/mimiking-dev/us-central1/contentAutomationApi'
    : __DEV__
    ? 'https://contentautomationapi-fcrdg3titq-uc.a.run.app'
    : 'https://contentautomationapi-uvcecditpa-uc.a.run.app';
