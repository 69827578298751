/* eslint-disable no-use-before-define */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Chip } from '@material-ui/core';
import { useForm } from 'react-final-form';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 8,
    marginBottom: 4,
    width: 400,
    '& > * + *': {
      marginTop: theme.spacing(5),
    },
  },
}));
// This Component is not Completed feature.
// And also this Component is not used immediately, but it is better to be left
// because it might be used later.
const TagAutoComplete = ({ choices = [] }) => {
  const classes = useStyles();
  // console.log(choices);
  const categories = choices.map((choice) => {
    const category = choice.category.toLowerCase();
    return {
      title: choice.languageCodeToName.en,
      category: category === null ? 'No categories' : category,
      id: choice.id,
      // ...choice,
    };
  });
  const form = useForm();
  const formState = form.getState();

  return (
    <div className={classes.root}>
      <Autocomplete
        multiple
        autoHighlight
        id="tags-filled"
        options={categories.sort(
          (a, b) => -b.category.localeCompare(a.category),
        )}
        groupBy={(choice) => choice.category}
        getOptionLabel={(choice) => choice.title}
        filterSelectedOptions
        onChange={(event, value) => {
          value.map((items) => form.change('tagKeys', items.id));
        }}
        renderTags={(value, getTagProps) =>
          formState.values.tagKeys === null
            ? null
            : value.map((choice, index) => (
                <Chip
                  size="small"
                  variant="default"
                  label={choice.title}
                  {...getTagProps({ index })}
                />
              ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="filled"
            label="Tags (new)"
            placeholder="Search Tags..."
            fullWidth
          />
        )}
      />
    </div>
  );
};

TagAutoComplete.propTypes = {
  choices: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default TagAutoComplete;
