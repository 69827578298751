import React, { useEffect } from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Pagination,
  BooleanField,
  Filter,
  SelectInput,
} from 'react-admin';

import { ScrollToTopButton, ShortenedDateField } from '../../common';
import { BooleanChoices } from '../../constants';
import SurveyQuestionShow from './SurveyQuestionShow';

const SurveyQuestionFilter = (props) => (
  <Filter {...props}>
    <SelectInput
      label="Published"
      source="published"
      choices={BooleanChoices}
      alwayson="true"
      allowEmpty={false}
    />
  </Filter>
);

const SurveyQuestionList = (props) => {
  useEffect(() => {
    document.title = 'TMS: Surv Questions';
  }, []);

  return (
    <>
      <List
        filters={<SurveyQuestionFilter />}
        filterDefaultValues={{ published: true }}
        pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
        perPage={25}
        bulkActionButtons={false}
        exporter={false}
        title="Survey Questions"
        {...props}
      >
        <Datagrid rowClick="expand" expand={<SurveyQuestionShow />}>
          <TextField label="Question" source="question" />
          <BooleanField label="Published" source="published" />
          <TextField label="Type" source="type" />
          <TextField label="User property" source="userPropertyName" />
          <ShortenedDateField
            label="Created"
            source="createdAt.seconds"
            type="timestamp"
          />
          <ShortenedDateField
            label="Last modified"
            source="updatedAt.seconds"
            type="timestamp"
          />
          <EditButton />
        </Datagrid>
      </List>
      <ScrollToTopButton category={0} />
    </>
  );
};

export default SurveyQuestionList;
