import React, { useCallback, useRef, useState } from 'react';
import { Button, Paper, Checkbox, ListItem, Tooltip } from '@material-ui/core';
import { useForm } from 'react-final-form'; // eslint-disable-line import/no-extraneous-dependencies
import _ from 'lodash';
import { TextInput, required } from 'react-admin';
import { Link } from 'react-router-dom';
import { Add as AddIcon, Link as LinkIcon } from '@material-ui/icons';
import SearchableTextInput from './SearchableTextInput';

const styles = {
  listContainer: {
    maxHeight: 200,
    maxWidth: 500,
    overflow: 'auto',
    marginBottom: 20,
  },
  listItemGrammarContainer: {
    marginTop: 5,
    marginLeft: 5,
  },
  listHiddenItemGrammarContainer: {
    marginTop: 5,
    marginLeft: 5,
    color: 'gray',
    textDecoration: 'line-through',
  },
  listItemGrammarAttribute: {
    marginTop: 5,
    fontSize: 14,
  },
  listItemGrammarAttributeName: {
    marginLeft: 2,
    fontWeight: 600,
  },
  listItemHighlightedAttribute: {
    fontSize: 16,
    fontWeight: 700,
  },
  addButton: {
    width: '100%',
    paddingTop: 10,
    paddingBottom: 10,
  },
  noResultText: {
    marginTop: 20,
    marginBottom: 20,
    marginRight: 10,
    marginLeft: 10,
    fontSize: 16,
    fontWeight: 600,
  },
  divider: {
    height: 1,
    width: '100%',
    backgroundColor: '#f0f0f0',
  },
  selectGrammarDescription: {
    marginBottom: 5,
    fontSize: 14,
    color: '#495057',
  },
  refreshButton: {
    marginLeft: 100,
    backgroundColor: '#F8F9FA',
  },
  grammarIdInput: {
    width: '190px',
  },
  grammarSelectList: {
    marginTop: 10,
  },
  linkButton: {
    marginTop: 15,
    marginLeft: 5,
  },
};

interface GrammarResult {
  id: string;
  definition: string;
  text: string;
}

interface GrammarListItemProps {
  hit: GrammarResult;
  onClick: (result: GrammarResult) => void;
  selected: boolean;
}

const GrammarListItem = ({
  hit,
  onClick: handleClickProp,
  selected,
}: GrammarListItemProps) => {
  const handleClick = useCallback(() => {
    if (handleClickProp != null) {
      handleClickProp(hit);
    }
  }, [hit, handleClickProp]);

  return (
    <ListItem
      selected={selected}
      button
      onClick={handleClick}
      alignItems="flex-start"
      divider
    >
      <Checkbox checked={selected} />
      <div style={styles.listItemGrammarContainer}>
        <div style={styles.listItemGrammarAttribute}>
          <span style={styles.listItemGrammarAttributeName}>Grammar:</span>
          &nbsp;
          <span style={styles.listItemHighlightedAttribute}>
            <span dangerouslySetInnerHTML={{ __html: hit.text }} />
          </span>
        </div>
        <div style={styles.listItemGrammarAttribute}>
          <span style={styles.listItemGrammarAttributeName}>Definition:</span>
          &nbsp;
          {hit.definition}
        </div>
        <div style={styles.listItemGrammarAttribute}>
          <span style={styles.listItemGrammarAttributeName}>ID:</span>
          &nbsp;
          {hit.id}
        </div>
      </div>
    </ListItem>
  );
};

// eslint-disable-next-line react/prop-types
const NoResultComponent = ({ query }) => (
  <div>
    <div style={styles.noResultText}>
      {query === ''
        ? 'The Grammar field is empty. Please type something in the Grammar field.'
        : `There are no grammars matching "${query}". Please create a new grammar.`}
    </div>
    <div style={styles.divider} />
  </div>
);

interface GrammarIdSelectInputProps {
  hits: GrammarResult[];
  initialGrammar?: string;
  source: string;
  grammarId?: string;
  search: (query: string) => void;
}

const GrammarIdSelectInput = ({
  hits,
  initialGrammar,
  source,
  grammarId,
  search,
}: GrammarIdSelectInputProps): JSX.Element => {
  const form = useForm();
  const [grammar, setGrammar] = useState(initialGrammar);
  const inputRef = useRef<HTMLInputElement>();

  const updateGrammarId = useRef((id) => {
    form.change(source, id);
  });

  const handleClickItem = useCallback((hit: GrammarResult) => {
    // it focuses text input manually to enable per input validation
    if (inputRef.current != null) {
      inputRef.current.selectionStart = 0;
      inputRef.current.focus();
    }
    updateGrammarId.current(hit.id);
  }, []);

  const updateGrammar = useCallback(({ value: newGrammar }) => {
    setGrammar(newGrammar);
  }, []);

  const validateGrammarId = useCallback(
    (value) => {
      const grammarByGrammarId = _.find(hits, ['id', value]);
      if (grammarByGrammarId == null) {
        return 'Please select grammar from the list';
      }
      return undefined;
    },
    [hits],
  );

  const grammarIdValidate = [required(), validateGrammarId];

  return (
    <div>
      <div>
        <TextInput
          label="Grammar Id"
          source={source}
          inputRef={inputRef}
          style={styles.grammarIdInput}
          validate={grammarIdValidate}
        />
        <Tooltip title="Press here to move to grammar detail page" arrow>
          <Button
            style={styles.linkButton}
            component={Link}
            variant="outlined"
            target="_blank"
            to={{
              pathname: `/grammars/${grammarId}`,
            }}
          >
            <LinkIcon />
          </Button>
        </Tooltip>
      </div>
      <SearchableTextInput
        refine={search}
        label="Search Keyword"
        // ToDo #10539: It is better to searchableTextInput replace the apostrophe by itself.
        initialValue={initialGrammar?.replace(/’/g, "'")}
        onChange={updateGrammar}
      />
      <div style={styles.grammarSelectList}>
        <div style={styles.selectGrammarDescription}>
          Select grammar from the list *
        </div>
        <Paper style={styles.listContainer}>
          {hits.length === 0 ? (
            <NoResultComponent query={grammar ?? ''} />
          ) : (
            _.map(hits, (hit) => (
              // eslint-disable-next-line react/no-array-index-key
              <GrammarListItem
                key={hit.id}
                hit={hit}
                onClick={handleClickItem}
                selected={grammarId === hit.id}
              />
            ))
          )}
          <Button
            style={styles.addButton}
            component={Link}
            to={{
              pathname: '/grammars/create',
            }}
            target="_blank"
          >
            <AddIcon />
            Add new grammar
          </Button>
        </Paper>
      </div>
    </div>
  );
};

export default GrammarIdSelectInput;
