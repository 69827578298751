import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import _ from 'lodash';
import LemmaIdSelectInput from '../../fragments/component/LemmaIdSelectInput';
import withSearchEngine, {
  SearchProps,
} from '../../../search/modules/withSearchEngine';
import * as ElasticAppSearch from '../../../search/modules/ElasticAppSearch';
import { LemmaResult } from '../../../search/components/results/items/LemmaResultItem';

const connector = ElasticAppSearch.createConnector('lemmas-en');
const searchEngineConfig = {
  apiConnector: connector,
  searchQuery: {
    search_fields: {
      lemma: { weight: 10 },
      related_words: { weight: 5 },
    },
    result_fields: {
      lemma: {
        raw: {},
        snippet: {},
      },
      difficulty_level: { raw: {} },
      hidden: { raw: {} },
      related_words: { raw: {} },
    },
    precision: 8,
  },
};

const transformResult = (result): LemmaResult => ({
  id: result.id,
  lemma: result.lemma,
  difficultyLevel: result.difficulty_level,
  hidden: result.hidden,
  relatedWords: result.related_words ?? [],
  href: `#/lemmas/${result.id}`,
});

interface LemmaInputProps {
  record?: string;
  source?: string;
  initialLemma?: string;
}

type GrammarLemmasInputWithSearchEngineProps = LemmaInputProps &
  SearchProps<LemmaResult>;

// <Configure hitsPerPage={3} />

const LemmaInput = ({
  source = '',
  record = '',
  initialLemma: initialLemmaProp,
  search = () => {},
  results,
  setResultsPerPage,
  addFilter,
  clearFilters,
}: GrammarLemmasInputWithSearchEngineProps) => {
  const [query, setQuery] = useState('');

  const initialLemma = useRef(initialLemmaProp).current ?? '';
  useEffect(() => {
    setResultsPerPage(5);
  }, [setResultsPerPage]);

  const highlightedResults = useMemo(
    () =>
      results.map((result) => {
        const lemma = _.chain(result.lemma)
          .replace(query, `<em>${query}</em>`)
          .value();
        const relatedWords = result.relatedWords.map((w) =>
          _.chain(w).replace(query, `<em>${query}</em>`).value(),
        );
        return {
          ...result,
          lemma,
          relatedWords,
        };
      }),
    [query, results],
  );

  const searchWithFilter = useCallback(
    (q: string) => {
      /*
    Elastic App Search provides stop words. So, stop words (e.g., `is`) is not searched.
    So, we should use filter not to use stopwords.
    */

      clearFilters();

      search('');
      // `addFilter` applys `all` for root filter.
      // This is a use case to use `any` for root filter.
      // filter: { all: { any: [{ any: { lemma: query } }, { any: related_words: query }] } }
      addFilter('any', { lemma: q }, 'any');
      addFilter('any', { related_words: q }, 'any'); // addFilter will requests search with empty string

      setQuery(q);
    },
    [search, addFilter, clearFilters],
  );

  const validateLemmaId = useCallback(
    ({ inputValue }) => {
      const lemmaByLemmaId = _.find(highlightedResults, ['id', inputValue]);
      if (lemmaByLemmaId == null) {
        return 'Please select lemma from the list';
      }
      return undefined;
    },
    [highlightedResults],
  );

  return (
    <LemmaIdSelectInput
      hits={highlightedResults}
      validate={validateLemmaId}
      lemmaId={record}
      initialLemma={initialLemma}
      source={source}
      search={searchWithFilter}
    />
  );
};

const GrammarLemmasInputWithSearch = withSearchEngine<
  unknown,
  LemmaResult,
  LemmaInputProps
>(searchEngineConfig, transformResult, LemmaInput);

export default React.memo(GrammarLemmasInputWithSearch);
