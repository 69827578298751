import React from 'react';
import { Create } from 'react-admin';

import SktFragmentForm from './SktFragmentForm';

const SktFragmentCreate = (props) => (
  <Create {...props}>
    <SktFragmentForm mode="create" />
  </Create>
);

export default SktFragmentCreate;
