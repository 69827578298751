// eslint-disable-next-line import/prefer-default-export
export const TagCategories = [
  { id: 'context', name: 'Content - Context' },
  { id: 'genre', name: 'Content - Genre' },
  { id: 'theme', name: 'Content - Theme' },
  { id: 'channel', name: 'Series - Channel' },
  { id: 'figure', name: 'Series - Figure' },
  { id: 'film', name: 'Series - Film' },
  { id: 'program', name: 'Series - Program' },
  { id: 'others', name: 'Series - Others' },
  { id: 'category', name: 'Category / Type' },
  { id: 'accent', name: "Speakers' Accent" },
  { id: 'gender', name: "Speakers' Gender" },
  { id: 'content', name: '[Legacy] Content / Story' },
  { id: 'series', name: '[Legacy] Series' },
];
