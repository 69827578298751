import ApolloClientHelper from './ApolloClientHelper';
import Queries from './graphqlQueries';

export default {
  create: async (type, resource, params) => {
    const apolloClient = ApolloClientHelper.getInstance();
    const {
      data: { memberId, howManyDays, type: subscriptionType },
    } = params;

    const {
      data: {
        giveSubscriptionToMember: { user: byUser },
      },
    } = await apolloClient.mutate({
      mutation: Queries.CREATE_SUBSCRIPTION,
      variables: {
        input: {
          memberId,
          howManyDays,
          type: subscriptionType,
        },
      },
    });

    return { data: byUser };
  },
};
