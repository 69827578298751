import React from 'react';

import { SearchProvider, WithSearch } from '@elastic/react-search-ui';

import * as ElasticAppSearch from './ElasticAppSearch';

interface ElasticSearchContext<RawResultType> {
  resultSearchTerm: string;
  setSearchTerm: (query: string) => void;
  results: RawResultType[];
  setResultsPerPage: (resultsPerPage: number) => void;
  setFilter: (name, value, filterType) => void;
  addFilter: (name, value, filterType) => void;
  clearFilters: () => void;
  isLoading: boolean;
}

export interface SearchProps<T> {
  resultQuery: string;
  search: (query: string) => void;
  results: T[];
  setResultsPerPage: (resultsPerPage: number) => void;
  setFilter: (name, value, filterType) => void;
  addFilter: (name, value, filterType) => void;
  clearFilters: () => void;
  isLoading: boolean;
}

const withSearchEngine = <
  RawResultType extends unknown,
  ResultType extends unknown,
  ComponentOwnProps extends unknown,
>(
  searchEngineConfig: any,
  transformResult: (rawResult: unknown) => ResultType,
  Component: (
    props: ComponentOwnProps & SearchProps<ResultType>,
  ) => JSX.Element,
): React.FC<ComponentOwnProps> => {
  const mapContextToProps = ({
    resultSearchTerm,
    setSearchTerm,
    results,
    setResultsPerPage,
    setFilter,
    addFilter,
    clearFilters,
    isLoading,
  }: ElasticSearchContext<RawResultType>): ElasticSearchContext<RawResultType> => ({
    // Do not rename here. It is not allowed for elastic sdk. If not, the value can be undefined.
    resultSearchTerm,
    setSearchTerm,
    results,
    setResultsPerPage,
    setFilter,
    addFilter,
    clearFilters,
    isLoading,
  });

  const config = {
    ...searchEngineConfig,
    trackUrlState: searchEngineConfig.trackUrlState ?? false,
  };

  const parseElasticResult = (elasticResult) => {
    const result = ElasticAppSearch.parseResult(elasticResult);
    return transformResult(result);
  };

  const SearchComponent: React.FC<ComponentOwnProps> = React.memo(
    (componentProps) => (
      <SearchProvider config={config}>
        <WithSearch mapContextToProps={mapContextToProps}>
          {(elasticSearchProps: ElasticSearchContext<RawResultType>) => {
            const searchProps = {
              resultQuery: elasticSearchProps.resultSearchTerm,
              search: elasticSearchProps.setSearchTerm,
              results: elasticSearchProps.results.map(parseElasticResult),
              setResultsPerPage: elasticSearchProps.setResultsPerPage,
              setFilter: elasticSearchProps.setFilter,
              addFilter: elasticSearchProps.addFilter,
              clearFilters: elasticSearchProps.clearFilters,
              isLoading: elasticSearchProps.isLoading,
            };

            return <Component {...componentProps} {...searchProps} />;
          }}
        </WithSearch>
      </SearchProvider>
    ),
  );

  return SearchComponent;
};

export default withSearchEngine;
