import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  Grid,
  Snackbar,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { Button } from 'react-admin';
import { Alert } from '@material-ui/lab';
import expandableSelectStyles from './ExpandableSelectStyles';
import { CONTENT_AUTOMATION_API_URL } from '../../../constants';
import { useAutomationContext } from '../../../common/AutomationContextProvider';

const useStyles = expandableSelectStyles;

const fetchUrl = CONTENT_AUTOMATION_API_URL;

const convertTimeIntoSeconds = (time, roundUp = false) => {
  // time is in the format of hh:mm:ss,mmm. Throw away the milliseconds if roundUp is false.
  const timeArray = time.split(':');
  const hours = parseInt(timeArray[0], 10);
  const minutes = parseInt(timeArray[1], 10);
  const seconds = parseInt(timeArray[2].split(`,`)[0], 10);
  const milliseconds = parseInt(timeArray[2].split(`,`)[1], 10);

  return roundUp && milliseconds > 0
    ? hours * 3600 + minutes * 60 + seconds + 1
    : hours * 3600 + minutes * 60 + seconds;
};

const AutoTranscriptionHelper = ({ youTubeVideoId, startTime, endTime }) => {
  const classes = useStyles();

  const [enTranscript, setEnTranscript] = useState(``);
  const [koTranscript, setKoTranscript] = useState(``);
  const [jaTranscript, setJaTranscript] = useState(``);
  const { sonixId, setSonixId, setStartTimeParsed, setEndTimeParsed } =
    useAutomationContext();
  const [sonixPublicUrl, setSonixPublicUrl] = useState('');
  const [
    isForceTranscriptionConfirmDialogOpen,
    setIsForceTranscriptionConfirmDialogOpen,
  ] = useState(false);

  const [areTranslatedTranscriptsEdited, setAreTranslatedTranscriptsEdited] =
    useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('error');
  const [snackbarMessage, setSnackbarMessage] = useState(``);

  const [isTranscriptionInProgress, setIsTranscriptionInProgress] =
    useState(false);
  const [isTranslationInProgress, setIsTranslationInProgress] = useState(false);

  const handleSnackbarClose = (event, reason) => {
    if (reason === `clickaway`) {
      return;
    }

    setIsSnackbarOpen(false);
  };

  const transcribeOnClickHandler = useCallback(
    async (force) => {
      if (!youTubeVideoId) {
        setSnackbarMessage('No YouTube video ID provided!');
        setSnackbarSeverity(`error`);
        setIsSnackbarOpen(true);
        return;
      }
      if (!startTime) {
        setSnackbarMessage('No start time provided!');
        setSnackbarSeverity(`error`);
        setIsSnackbarOpen(true);
        return;
      }
      if (!endTime) {
        setSnackbarMessage('No end time provided!');
        setSnackbarSeverity(`error`);
        setIsSnackbarOpen(true);
        return;
      }

      const start = convertTimeIntoSeconds(startTime);
      const end = convertTimeIntoSeconds(endTime, true);
      setStartTimeParsed(start);
      setEndTimeParsed(end);

      setSnackbarMessage('Transcribing...');
      setSnackbarSeverity(`info`);
      setIsSnackbarOpen(true);

      setIsTranscriptionInProgress(true);

      const response = await fetch(
        `${fetchUrl}/transcribe/${youTubeVideoId}?start=${start}&end=${end}${
          force ? `&force=true` : ``
        }`,
      );
      const results = await response.json();
      if (!results.success) {
        setSnackbarMessage(results.errorMessage);
        setSnackbarSeverity(`error`);
        setIsSnackbarOpen(true);
        setIsTranscriptionInProgress(false);
        return;
      }
      // if results.success is true, then results.status will be `transcribing` or `completed`.
      if (results.status === `transcribing` || results.status === `preparing`) {
        setSnackbarMessage('Transcription in progress. Check back later.');
      }
      if (results.status === `completed`) {
        setSnackbarMessage('Transcription completed!');
        setEnTranscript(results.transcript);
      }
      setSnackbarSeverity(`success`);
      setIsSnackbarOpen(true);
      setIsTranscriptionInProgress(false);

      setSonixId(results.sonixItem.id);
      setSonixPublicUrl(results.sonixItem.public_url);
    },
    [
      youTubeVideoId,
      startTime,
      endTime,
      setSonixId,
      setStartTimeParsed,
      setEndTimeParsed,
    ],
  );

  const updateEnTranscriptOnClickHandler = useCallback(async () => {
    if (!sonixPublicUrl) {
      setSnackbarMessage('No Sonix public URL provided!');
      setSnackbarSeverity(`error`);
      setIsSnackbarOpen(true);
      return;
    }
    window.open(sonixPublicUrl, '_blank');
  }, [sonixPublicUrl]);

  const syncEnTranscriptOnClickHandler = useCallback(async () => {
    if (!sonixId) {
      setSnackbarMessage('No Sonix ID provided!');
      setSnackbarSeverity(`error`);
      setIsSnackbarOpen(true);
      return;
    }

    setSnackbarMessage('Syncing transcript...');
    setSnackbarSeverity(`info`);
    setIsSnackbarOpen(true);

    const response = await fetch(`${fetchUrl}/transcribe/sync/${sonixId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const results = await response.json();

    if (!results.success) {
      setSnackbarMessage(results.errorMessage);
      setSnackbarSeverity(`error`);
      setIsSnackbarOpen(true);
      return;
    }

    setSnackbarMessage('Transcript synced!');
    setSnackbarSeverity(`success`);
    setIsSnackbarOpen(true);

    setEnTranscript(results.transcript);
  }, [sonixId]);

  const translateOnClickHandler = useCallback(async () => {
    if (!sonixId) {
      setSnackbarMessage('No Sonix ID provided!');
      setSnackbarSeverity(`error`);
      setIsSnackbarOpen(true);
      return;
    }

    setSnackbarMessage('Translating...');
    setSnackbarSeverity(`info`);
    setIsSnackbarOpen(true);
    setIsTranslationInProgress(true);

    const response = await fetch(`${fetchUrl}/translate/${sonixId}`);
    const results = await response.json();
    if (!results.success) {
      setSnackbarMessage(results.errorMessage);
      setSnackbarSeverity(`error`);
      setIsSnackbarOpen(true);
      setIsTranslationInProgress(false);
      return;
    }

    setSnackbarMessage('Translation completed!');
    setSnackbarSeverity(`success`);
    setIsSnackbarOpen(true);
    setIsTranslationInProgress(false);

    // console.log(results);
    setKoTranscript(results.transcript.ko);
    setJaTranscript(results.transcript.ja);
  }, [sonixId]);

  const updateTranslatedTranscriptsOnClickHandler = useCallback(async () => {
    if (!sonixId) {
      setSnackbarMessage('No Sonix ID provided!');
      setSnackbarSeverity(`error`);
      setIsSnackbarOpen(true);
      return;
    }

    setSnackbarMessage('Updating translated transcripts...');
    setSnackbarSeverity(`info`);
    setIsSnackbarOpen(true);

    const response = await fetch(`${fetchUrl}/translate/update/${sonixId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        updatedTranscript: {
          ko: koTranscript,
          ja: jaTranscript,
        },
      }),
    });
    const results = await response.json();

    if (!results.success) {
      setSnackbarMessage(results.errorMessage);
      setSnackbarSeverity(`error`);
      setIsSnackbarOpen(true);
      return;
    }

    setSnackbarMessage('Translated transcripts updated!');
    setSnackbarSeverity(`success`);
    setIsSnackbarOpen(true);

    // console.log(results);
    setAreTranslatedTranscriptsEdited(false);
  }, [sonixId, koTranscript, jaTranscript]);

  return (
    <div className={classes.root}>
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.heading}>
            Auto Transcription Helper
          </Typography>
        </ExpansionPanelSummary>
        <div>
          <Grid container spacing={4} className={classes.tree} justify="center">
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                label="Transcribe"
                onClick={() => transcribeOnClickHandler(false)}
                disabled={isTranscriptionInProgress}
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                label="Force Transcribe"
                onClick={() => setIsForceTranscriptionConfirmDialogOpen(true)}
                disabled={isTranscriptionInProgress}
              />
              <Dialog
                maxWidth="xs"
                aria-labelledby="confirmation-dialog-title"
                open={isForceTranscriptionConfirmDialogOpen}
                onClose={() => setIsForceTranscriptionConfirmDialogOpen(false)}
              >
                <DialogTitle id="confirmation-dialog-title">
                  Confirm Force Transcription
                </DialogTitle>
                <DialogContent dividers>
                  Are you sure you want to re-transcribe without checking for
                  existing transcripts? (Use this option if Sonix errors
                  persist.)
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="contained"
                    color="primary"
                    label="Cancel"
                    onClick={() =>
                      setIsForceTranscriptionConfirmDialogOpen(false)
                    }
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    label="Force Transcribe"
                    style={{ backgroundColor: '#f44336' }}
                    onClick={() => {
                      transcribeOnClickHandler(true);
                      setIsForceTranscriptionConfirmDialogOpen(false);
                    }}
                  />
                </DialogActions>
              </Dialog>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                label="Update English Transcript"
                onClick={updateEnTranscriptOnClickHandler}
                disabled={!sonixPublicUrl}
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                label="Sync English Transcript"
                onClick={syncEnTranscriptOnClickHandler}
                disabled={!sonixId}
              />
            </Grid>
          </Grid>
          <Grid container spacing={4} className={classes.tree} justify="center">
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                label="Translate"
                onClick={translateOnClickHandler}
                disabled={enTranscript === '' || isTranslationInProgress}
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                label="Update Translated Transcripts"
                onClick={updateTranslatedTranscriptsOnClickHandler}
                disabled={!areTranslatedTranscriptsEdited}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={4}
            className={`${classes.tree}`}
            justify="center"
          >
            <Grid item>
              <div className={classes.labelRoot}>
                <Typography className={classes.categoryLabelText}>
                  Original Transcript
                </Typography>
              </div>
              <TextField
                multiline
                fullWidth
                variant="outlined"
                rows={10}
                rowsMax={20}
                placeholder="Transcript will be shown here."
                disabled
                value={enTranscript}
                onChange={(event) => {
                  setEnTranscript(event.target.value);
                }}
              />
            </Grid>
            <Grid item>
              <div className={classes.labelRoot}>
                <Typography className={classes.categoryLabelText}>
                  Korean Translation
                </Typography>
              </div>
              <TextField
                multiline
                fullWidth
                variant="outlined"
                rows={10}
                rowsMax={20}
                placeholder="Transcript will be shown here."
                disabled={koTranscript === ''}
                value={koTranscript}
                onChange={(event) => {
                  setAreTranslatedTranscriptsEdited(true);
                  setKoTranscript(event.target.value);
                }}
              />
            </Grid>
            <Grid item>
              <div className={classes.labelRoot}>
                <Typography className={classes.categoryLabelText}>
                  Japanese Translation
                </Typography>
              </div>
              <TextField
                multiline
                fullWidth
                variant="outlined"
                rows={10}
                rowsMax={20}
                placeholder="Transcript will be shown here."
                disabled={jaTranscript === ''}
                value={jaTranscript}
                onChange={(event) => {
                  setAreTranslatedTranscriptsEdited(true);
                  setJaTranscript(event.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Snackbar
            open={isSnackbarOpen}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
            message={snackbarMessage}
          >
            <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </div>
      </ExpansionPanel>
    </div>
  );
};

AutoTranscriptionHelper.propTypes = {
  youTubeVideoId: PropTypes.string,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
};

export default AutoTranscriptionHelper;
