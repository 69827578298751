import gql from 'graphql-tag';

const QuestionsPageAnswer = gql`
  fragment QuestionsPageAnswer on Answer {
    id
    originalText
    text(languageCode: "en")
    originalTextTranslations {
      languageCode
      text
    }
    published
    author {
      id
    }
    createdAt
    likeCount
  }
`;

const QuestionsPageQuestion = gql`
  fragment QuestionsPageQuestion on Question {
    id
    originalText
    text(languageCode: "en")
    originalTextTranslations {
      languageCode
      text
    }
    published
    author {
      id
      lastSeenAt
      user {
        subscription {
          premium {
            expiredAt
          }
          membership {
            expiredAt
          }
        }
      }
    }
    fragment(userLanguageCode: "en") {
      key
      startTime
      text
      content {
        key
        title
        youTubeVideoId
      }
    }
    answer {
      ...QuestionsPageAnswer
    }
    learningLanguageCode
    userLanguageCode
    createdAt
    willBePublic
    isReusable
    qnaUploadLog {
      uploadTo
      qnaUploadLogResult {
        url
        askedAt
        answeredAt
      }
    }
    qnaPostingDate {
      uploadTo
      postingDate
    }
    label
    isContextual
    abandoned
  }
  ${QuestionsPageAnswer}
`;

const LemmaFields = gql`
  fragment LemmaFields on Lemma {
    id
    lemma
    difficultyLevel
    frequency
    hidden
    relatedWords
    createdAt
    updatedAt
  }
`;

const PlayerSessionFields = gql`
  fragment PlayerSessionFields on PlayerSession {
    id
    createdAt
    listeningTimeInSec
    speakingTimeInSec
  }
`;

export default {
  GET_QUESTIONS: gql`
    query GetQuestions($input: QuestionsInput!) {
      questions(input: $input) {
        records {
          ...QuestionsPageQuestion
        }
        pagination {
          nextStartAt
        }
      }
    }
    ${QuestionsPageQuestion}
  `,
  GET_QUESTION: gql`
    query GetQuestion($input: QuestionInput!) {
      question(input: $input) {
        ...QuestionsPageQuestion
      }
    }
    ${QuestionsPageQuestion}
  `,
  CREATE_QUESTION: gql`
    mutation CreateQuestion($input: CreateQuestionInput!) {
      createQuestion(input: $input) {
        ...QuestionsPageQuestion
      }
    }
    ${QuestionsPageQuestion}
  `,
  UPDATE_QUESTION: gql`
    mutation UpdateQuestion($input: UpdateQuestionInput!) {
      updateQuestion(input: $input) {
        ...QuestionsPageQuestion
      }
    }
    ${QuestionsPageQuestion}
  `,
  DELETE_QUESTION: gql`
    mutation DeleteQuestion($input: DeleteQuestionInput!) {
      deleteQuestion(input: $input)
    }
  `,
  CREATE_ANSWER: gql`
    mutation CreateAnswer($input: CreateAnswerOfQuestionInput!) {
      createAnswerOfQuestion(input: $input) {
        ...QuestionsPageAnswer
      }
    }
    ${QuestionsPageAnswer}
  `,
  UPDATE_ANSWER: gql`
    mutation UpdateAnswer($input: UpdateAnswerOfQuestionInput!) {
      updateAnswerOfQuestion(input: $input) {
        ...QuestionsPageAnswer
      }
    }
    ${QuestionsPageAnswer}
  `,
  QNA_BOT_POST: gql`
    mutation QnABotPost($input: QnABotPostInput!) {
      qnaBotPost(input: $input) {
        qnaUploadLog {
          uploadTo
          qnaUploadLogResult {
            url
            askedAt
            answeredAt
          }
        }
      }
    }
  `,
  GET_LEMMAS: gql`
    query GetLemmas($input: LemmasInput!) {
      lemmas(input: $input) {
        records {
          ...LemmaFields
        }
        pagination {
          nextStartAt
        }
      }
    }
    ${LemmaFields}
  `,
  GET_LEMMA: gql`
    query GetLemma($input: LemmaInput!) {
      lemma(input: $input) {
        ...LemmaFields
      }
    }
    ${LemmaFields}
  `,
  CREATE_LEMMA: gql`
    mutation CreateLemma($input: CreateLemmaInput!) {
      createLemma(input: $input) {
        lemma {
          ...LemmaFields
        }
      }
    }
    ${LemmaFields}
  `,
  UPDATE_LEMMA: gql`
    mutation UpdateLemma($input: UpdateLemmaInput!) {
      updateLemma(input: $input) {
        lemma {
          ...LemmaFields
        }
      }
    }
    ${LemmaFields}
  `,
  DELETE_LEMMA: gql`
    mutation DeleteLemma($input: DeleteLemmaInput!) {
      deleteLemma(input: $input)
    }
  `,
  GET_PLAYER_SESSIONS: gql`
    query GetPlayerSessions(
      $inputId: String!
      $inputSessions: PlayerSessionsInput!
    ) {
      user(id: $inputId) {
        playerSessions(input: $inputSessions) {
          records {
            ...PlayerSessionFields
          }
        }
      }
    }
    ${PlayerSessionFields}
  `,
  GET_USER_BY_EMAIL: gql`
    query GetUserByEmail($input: String!) {
      userByEmail(email: $input) {
        id
        firstSeenAt
        photoUrl
        displayName
      }
    }
  `,
  CREATE_SUBSCRIPTION_CREDIT: gql`
    mutation CreateSubscriptionCredit(
      $input: GiveSubscriptionCreditToManagerInput!
    ) {
      giveSubscriptionCreditToManager(input: $input) {
        user {
          id
        }
      }
    }
  `,
  CREATE_SUBSCRIPTION: gql`
    mutation CreateSubscription($input: GiveSubscriptionToMemberInput!) {
      giveSubscriptionToMember(input: $input) {
        user {
          id
        }
      }
    }
  `,
  GET_REVIEWS: gql`
    query GetReviews($input: GetReviewsInput) {
      reviewsV2(input: $input) {
        records {
          id
          author
          content
          rating
          date
          languageCode
          published
        }
        nextStartAt
      }
    }
  `,
  GET_REVIEW: gql`
    query GetReview($input: GetReviewInput) {
      review(input: $input) {
        id
        author
        content
        rating
        date
        languageCode
        published
      }
    }
  `,
  UPDATE_REVIEW: gql`
    mutation UpdateReview($input: UpdateReviewInput!) {
      updateReview(input: $input) {
        review {
          id
          author
          content
          rating
          date
          languageCode
          published
        }
        success
      }
    }
  `,
  IS_COMPLETED_CONTENT: gql`
    query IsCompletedContent($input: IsCompletedContentInput!) {
      isCompletedContent(input: $input) {
        isCompleted
      }
    }
  `,
};
