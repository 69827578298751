import React, { useEffect } from 'react';

import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Pagination,
  BooleanField,
  Filter,
  SelectInput,
} from 'react-admin';

import { ScrollToTopButton, ShortenedDateField } from '../../common';
import { BooleanChoices } from '../../constants';
import SurveyShow from './SurveyShow';

const SurveyFilter = (props) => (
  <Filter {...props}>
    <SelectInput
      label="Published"
      source="published"
      choices={BooleanChoices}
      alwayson="true"
      allowEmpty={false}
    />
  </Filter>
);

const SurveyList = (props) => {
  useEffect(() => {
    document.title = 'TMS: Surveys';
  }, []);

  return (
    <>
      <List
        filters={<SurveyFilter />}
        filterDefaultValues={{ published: true }}
        pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
        perPage={25}
        bulkActionButtons={false}
        exporter={false}
        title="Survey Questions"
        {...props}
      >
        <Datagrid rowClick="expand" expand={<SurveyShow />}>
          <TextField label="Name" source="name" />
          <BooleanField label="Published" source="published" />
          <ShortenedDateField
            label="Published At"
            source="publishedAt.seconds"
            type="timestamp"
          />
          <ShortenedDateField
            label="Created"
            source="createdAt.seconds"
            type="timestamp"
          />
          <ShortenedDateField
            label="Last modified"
            source="updatedAt.seconds"
            type="timestamp"
          />
          <EditButton />
        </Datagrid>
      </List>
      <ScrollToTopButton category={0} />
    </>
  );
};

export default SurveyList;
