import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  List,
  Datagrid,
  EditButton,
  Filter,
  SelectInput,
  Pagination,
} from 'react-admin';
import _ from 'lodash';
import moment from 'moment';

import {
  ArrayTypeField,
  ShortenedTextField,
  ShortenedDateField,
  CustomBooleanField,
  ScrollToTopButton,
} from '../../common';
import QuestionShow from './QuestionShow';
import {
  BooleanChoices,
  ContentLanguages,
  AvailableLanguages,
} from '../../constants';
import JSUtility from '../../utilities/JSUtility';
import { QnAUploadToOptions } from './questionsConstants';

const QuestionFilter = (props) => (
  <Filter {...props}>
    <SelectInput
      label="Content Language"
      source="learningLanguageCode"
      choices={ContentLanguages}
      allowEmpty={false}
    />
    <SelectInput
      label="Q. Published"
      source="published"
      choices={BooleanChoices}
      alwayson="true"
      allowEmpty={false}
    />
    <SelectInput
      label="A. Published"
      source="answer.published"
      choices={[...BooleanChoices, { id: 'none', name: 'None' }]}
      allowEmpty={false}
    />
    <SelectInput
      label="Q. Trans."
      source="originalTextTranslations"
      choices={[...AvailableLanguages, { id: 'none', name: 'None' }]}
      allowEmpty={false}
    />
    <SelectInput
      label="A. Trans."
      source="answer.originalTextTranslations"
      choices={[...AvailableLanguages, { id: 'none', name: 'None' }]}
      allowEmpty={false}
    />
    <SelectInput
      label="Q. Will Be Public?"
      source="willBePublic"
      // Currently, some documents have no `willBePublic`, so it supports only one has this field.
      // ToDO: Migrate all question document
      choices={[{ id: true, name: 'O' }]}
      allowEmpty={false}
    />
    <SelectInput
      label="QnA. Posted?"
      source="qnaPostedTo"
      choices={QnAUploadToOptions}
      allowEmpty={false}
    />
  </Filter>
);

const QuestionTextField = ({ record = {} }) => {
  // To prevent slice error, I put default value to ''
  const originalText = _.get(record, 'originalText', '');
  const originalTextTranslation = _.get(record, 'originalTextTranslations', []);

  const engTranslationIndex = _.findIndex(
    originalTextTranslation,
    (obj) => obj.languageCode === 'en',
  );
  const engTranslation =
    originalTextTranslation[engTranslationIndex] != null
      ? originalTextTranslation[engTranslationIndex].text
      : null;

  const textToRender = engTranslation || originalText;
  const shortenedTextToRender =
    textToRender.length > 40
      ? textToRender.slice(0, 40).concat('...')
      : textToRender;

  const premiumExpiredAt = _.get(
    record,
    'author.user.subscription.premium.expiredAt',
    null,
  );
  const membershipExpiredAt = _.get(
    record,
    'author.user.subscription.membership.expiredAt',
    null,
  );
  // eslint-disable-next-line no-nested-ternary
  const paidPlanExpiredAt = JSUtility.getRecentStringDate(
    premiumExpiredAt,
    membershipExpiredAt,
  );

  const isNotPremiumUser =
    paidPlanExpiredAt == null ? true : moment().isAfter(paidPlanExpiredAt);
  const is3DaysBeforeExpiration =
    moment().isBefore(paidPlanExpiredAt) &&
    moment().add(3, 'days').isAfter(paidPlanExpiredAt);

  const twoWeeksBefore = moment().subtract(14, 'days');
  const isChurnOutUser = twoWeeksBefore.isAfter(
    _.get(record, 'author.lastSeenAt'),
  );

  // eslint-disable-next-line no-nested-ternary
  const conditionalStyle = is3DaysBeforeExpiration
    ? { color: 'red' }
    : isNotPremiumUser || isChurnOutUser
    ? { color: 'gray' }
    : null;

  return <p style={conditionalStyle}>{shortenedTextToRender}</p>;
};

const QuestionList = (props) => {
  useEffect(() => {
    document.title = 'TMS: Question';
  }, []);
  return (
    <>
      <List
        filters={<QuestionFilter />}
        filterDefaultValues={{ learningLanguageCode: 'en', published: false }}
        pagination={<Pagination rowsPerPageOptions={[10, 50, 100, 200, 300]} />}
        perPage={50}
        sort={{ field: 'createdAt', order: 'ASC' }}
        bulkActionButtons={false}
        exporter={false}
        {...props}
      >
        <Datagrid rowClick="expand" expand={<QuestionShow />}>
          <ShortenedDateField
            label="Created At"
            source="createdAt"
            type="datetime"
          />
          <ShortenedDateField
            label="Last Seen At"
            source="author.lastSeenAt"
            type="datetime"
          />
          <ShortenedDateField
            label="Plan Expired At"
            source="author.user.subscription"
            type="subscriptionObject"
          />
          <ShortenedTextField label="Q. Author" source="author.id" />
          <QuestionTextField label="Question" />
          <ArrayTypeField
            label="Q. Trans."
            source="originalTextTranslations"
            objKey="languageCode"
          />
          <CustomBooleanField label="Q. Will Be Public" source="willBePublic" />
          <CustomBooleanField label="Q. Published" source="published" />
          <ShortenedTextField label="A. Author" source="answer.author.id" />
          <ArrayTypeField
            label="A. Trans."
            source="answer.originalTextTranslations"
            objKey="languageCode"
          />
          <CustomBooleanField label="A. Published" source="answer.published" />
          <CustomBooleanField label="QnA. Posted" source="qnaUploadLog" />
          <EditButton />
        </Datagrid>
      </List>
      <ScrollToTopButton category={0} />
    </>
  );
};

QuestionTextField.propTypes = {
  // eslint-disable-next-line react/require-default-props
  record: PropTypes.shape({}),
};

export default QuestionList;
