import moment from 'moment';
import React from 'react';

interface AnswerResult {
  originalText: string;
  createdAt: Date;
  published: boolean;
  originalTextTranslationsLangs: string[];
}

export interface QuestionResult {
  id: string;
  originalText: string;
  fragmentText: string;
  createdAt: Date;
  published: boolean;
  originalTextTranslationsLangs: string[];
  answer?: AnswerResult;
  href: string;
}

interface QuestionResultItemProps {
  result: QuestionResult;
}

const QuestiontResultItem = (props: QuestionResultItemProps): JSX.Element => {
  const {
    result: {
      originalText,
      fragmentText,
      createdAt,
      published,
      originalTextTranslationsLangs,
      answer,
    },
  } = props;

  return (
    <div>
      <div className="hit-originalText">
        <span dangerouslySetInnerHTML={{ __html: originalText }} />
      </div>
      <div className="hit-fragmentTextInQuestion">
        <span dangerouslySetInnerHTML={{ __html: fragmentText }} />
      </div>
      <div className="hit-dateAndPublished">
        Created At:&nbsp;
        {moment(createdAt).format()} /&nbsp;
        {published ? 'Published' : 'Not Published'}
      </div>
      <div className="hit-originalTextTranslations">
        Question translations:&nbsp;
        {originalTextTranslationsLangs.length > 0
          ? originalTextTranslationsLangs.sort().join(', ')
          : ' - '}
      </div>
      {answer == null ? null : (
        <div className="hit-answers">
          <div className="hit-answerText">
            Answer: {answer.originalText.slice(0, 100)}
          </div>
          <div className="hit-dateAndPublished">
            Created At:&nbsp;
            {moment(answer.createdAt).format()} /&nbsp;
            {answer.published ? 'Published' : 'Not Published'}
          </div>
          <div className="hit-originalTextTranslations">
            Answer translations:&nbsp;
            {answer.originalTextTranslationsLangs.length > 0
              ? originalTextTranslationsLangs.sort().join(', ')
              : ' - '}
          </div>
        </div>
      )}
    </div>
  );
};

export default QuestiontResultItem;
