import WordIcon from '@material-ui/icons/Class';

import LemmaList from './LemmaList';
import LemmaEdit from './LemmaEdit';
import LemmaCreate from './LemmaCreate';

export default {
  list: LemmaList,
  create: LemmaCreate,
  edit: LemmaEdit,
  icon: WordIcon,
};
