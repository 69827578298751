import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Collapse,
} from '@material-ui/core';
import { TopToolbar } from 'react-admin';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

const styles = {
  card: {
    margin: 10,
    flex: 1,
    alignItems: 'stretch',
    width: 300,
  },
};

const CollapsableCard = ({ title, content, onClick, isExpanded }) => {
  const arrowButtonStyle = isExpanded ? { transform: 'rotate(180deg)' } : null;

  return (
    <Card style={styles.card}>
      <CardContent>
        <Typography variant="h6">{title}</Typography>
      </CardContent>
      <TopToolbar>
        <IconButton style={arrowButtonStyle} onClick={onClick}>
          <ExpandMoreIcon />
        </IconButton>
      </TopToolbar>
      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography>{content}</Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
};

// Workaround: To remove eslint warning
CollapsableCard.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.shape({}).isRequired,
  onClick: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool.isRequired,
};

export default CollapsableCard;
