import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';

import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import { useRefresh } from 'ra-core';

import { HowManyPremiumDays } from '../../constants';
import JSUtility from '../../utilities/JSUtility';
import { subscribeManually } from '../../dataProvider/firestoreDataProvider';

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));

const styles = {
  button: {
    marginRight: 10,
    marginTop: 10,
    marginBottom: 15,
  },
  grayText: { color: 'gray' },
};

const PremiumDaysButton = ({ num, setPremiumDays }) => {
  const setPremiumDaysToApply = useCallback(
    () => setPremiumDays(num),
    [num, setPremiumDays],
  );

  return (
    <Button
      variant="outlined"
      size="small"
      style={styles.button}
      onClick={setPremiumDaysToApply}
    >
      {`+${num}`}
    </Button>
  );
};

const PremiumInput = ({
  subscriptionObj = {},
  userId,
  premiumExpireDateInSeconds = null,
  currentDateInSeconds,
}) => {
  const classes = useStyles();
  const refresh = useRefresh();

  const [premiumDays, setPremiumDays] = useState('0');

  const startFrom =
    premiumExpireDateInSeconds >= currentDateInSeconds
      ? _.get(subscriptionObj, ['premium', 'expiredAt']).toDate()
      : new Date();

  const newExpireDate = moment(startFrom)
    .add(premiumDays, 'days')
    .format('YYYY-MM-DD, HH:mm:ss');

  const addMinusToBeginningOfString = useCallback(() => {
    if (premiumDays !== '0' && premiumDays.charAt(0) !== '-') {
      setPremiumDays('-'.concat(premiumDays));
    }
  }, [premiumDays]);

  const onChangePremiumDaysWithValidation = useCallback((e) => {
    const { value } = e.target;
    if (!_.isEmpty(value) && value !== '-') {
      const validNumbersString = JSUtility.convertStringToOnlyNumberString(
        value,
      ).replace(/^0+/, '');
      if (!_.isEmpty(validNumbersString)) {
        setPremiumDays(validNumbersString);
      }
    } else {
      setPremiumDays('0');
    }
  }, []);

  const onClickSetPremiumDaysButton = useCallback(
    (num) => setPremiumDays(num),
    [],
  );

  const applyPremium = useCallback(async () => {
    try {
      /* eslint-disable no-alert */
      const updatedData = await subscribeManually(userId, premiumDays);
      const updatedExpiredAt = moment(
        updatedData.subscription.premium.expiredAt,
      ).format('YYYY-MM-DD, HH:mm');
      alert(
        `Apply ${premiumDays} days has been success! \n New expire date:${updatedExpiredAt}`,
      );
      refresh();
    } catch (error) {
      alert('Something went wrong!');
    } /* eslint-enable no-alert */
    setPremiumDays('0');
  }, [premiumDays, refresh, userId]);

  return (
    <div>
      <div>
        <p style={styles.grayText}>
          (The feature below is used to apply Premium (not Basic) to user.
          Applying Basic is not possible.)
        </p>
        <div>
          <InputLabel>Change Premium expire date</InputLabel>
          <Input
            value={premiumDays}
            onChange={onChangePremiumDaysWithValidation}
          />
          <Button
            onClick={addMinusToBeginningOfString}
            disabled={!(premiumDays !== '0' && premiumDays.charAt(0) !== '-')}
            variant="outlined"
            size="small"
            className={classes.margin}
          >
            Subtract Premium Days
          </Button>
        </div>
        <div>
          {HowManyPremiumDays.map((num) => (
            <PremiumDaysButton
              key={num}
              num={num}
              setPremiumDays={onClickSetPremiumDaysButton}
            />
          ))}
        </div>
        <Button
          variant="outlined"
          disabled={premiumDays === '0'}
          onClick={applyPremium}
        >
          Apply Premium
        </Button>
        {premiumDays === '0' ? null : (
          <p>{`New Premium expire date: ${newExpireDate}`}</p>
        )}
      </div>
    </div>
  );
};

PremiumInput.propTypes = {
  // eslint-disable-next-line react/require-default-props
  subscriptionObj: PropTypes.shape({}),
  userId: PropTypes.string.isRequired,
  // eslint-disable-next-line react/require-default-props
  premiumExpireDateInSeconds: PropTypes.number,
  currentDateInSeconds: PropTypes.number.isRequired,
};

PremiumDaysButton.propTypes = {
  num: PropTypes.string.isRequired,
  setPremiumDays: PropTypes.func.isRequired,
};

export default PremiumInput;
