import React from 'react';
import { Create } from 'react-admin';

import SurveyAnswerOptionActions from './SurveyAnswerOptionActions';
import SurveyAnswerOptionForm from './SurveyAnswerOptionForm';

const SurveyAnswerOptionCreate = (props) => (
  <Create
    actions={<SurveyAnswerOptionActions basePath="/surveyAnswerOptions" />}
    {...props}
  >
    <SurveyAnswerOptionForm mode="create" />
  </Create>
);

export default SurveyAnswerOptionCreate;
