import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { QuestionAnswer as AnswerIcon } from '@material-ui/icons';
import { Button } from '@material-ui/core';
import { useForm } from 'react-final-form';
import _ from 'lodash';
import graphqlDataProvider from '../../../dataProvider/graphqlDataProvider';

const styles = {
  commonAnswerButton: { marginRight: 10 },
  answerButton: { marginTop: 5 },
  iconInsideButton: { marginRight: 5 },
};

const QnAPosting = ({
  id,
  originalText,
  answerOriginalText,
  uploadTo,
  questionUrl,
  answeredAt,
  currentFormQnaUploadLog,
  currentQnaPostingDate,
}) => {
  const [loading, setLoading] = useState(false);

  const form = useForm();

  const handlePosting = useCallback(
    async ({ isAsking }) => {
      const params = {
        id,
        originalText,
        answerOriginalText,
        isAsking,
        uploadTo,
        questionUrl,
      };

      const qnaUploadLogData = await graphqlDataProvider(
        'QNA_BOT_POSTING',
        'questions',
        params,
      );

      const qnaUploadLogResult = qnaUploadLogData?.data;

      const qnaUploadLog = {
        ..._.omitBy(currentFormQnaUploadLog, _.isNull),
        ..._.omitBy(qnaUploadLogResult, _.isNull),
      };

      form.change('qnaUploadLog', qnaUploadLog);
    },
    [
      form,
      id,
      originalText,
      answerOriginalText,
      uploadTo,
      questionUrl,
      currentFormQnaUploadLog,
    ],
  );

  const handleAsking = useCallback(async () => {
    setLoading(true);
    await handlePosting({ isAsking: true });
    setLoading(false);
  }, [handlePosting]);

  const handleAnswering = useCallback(async () => {
    setLoading(true);
    await handlePosting({ isAsking: false });
    setLoading(false);
  }, [handlePosting]);

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        style={styles.commonAnswerButton}
        disabled={
          !_.isNil(questionUrl) || loading || !_.isNil(currentQnaPostingDate)
        }
        onClick={handleAsking}
      >
        <AnswerIcon style={styles.iconInsideButton} />
        {loading
          ? 'loading'
          : !_.isNil(questionUrl)
          ? 'Question Posted'
          : 'Post Question'}
      </Button>
      {!_.isNil(questionUrl) && (
        <Button
          variant="outlined"
          size="small"
          style={styles.commonAnswerButton}
          disabled={!_.isNil(answeredAt) || loading}
          onClick={handleAnswering}
        >
          <AnswerIcon style={styles.iconInsideButton} />
          {loading
            ? 'loading'
            : !_.isNil(answeredAt)
            ? 'Answer Posted'
            : 'Post Answer'}
        </Button>
      )}
    </>
  );
};

QnAPosting.propTypes = {
  id: PropTypes.string.isRequired,
  originalText: PropTypes.string.isRequired,
  answerOriginalText: PropTypes.string.isRequired,
  uploadTo: PropTypes.string.isRequired,
  questionUrl: PropTypes.string,
  answeredAt: PropTypes.string,
  currentFormQnaUploadLog: PropTypes.arrayOf(PropTypes.shape({})),
  currentQnaPostingDate: PropTypes.shape({}),
};

export default QnAPosting;
