import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Edit } from 'react-admin';

import SurveyActions from './SurveyActions';
import SurveyForm from './SurveyForm';

const SurveyTitle = ({ record = null }) => (
  <>Survey: {_.get(record, 'name', '')}</>
);

SurveyTitle.propTypes = {
  // eslint-disable-next-line react/require-default-props
  record: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
};

const SurveyEdit = (props) => (
  <Edit
    title={<SurveyTitle />}
    actions={<SurveyActions basePath="/surveys" />}
    {...props}
  >
    <SurveyForm mode="update" />
  </Edit>
);

export default SurveyEdit;
