import React, { useCallback, useEffect, useState } from 'react';
import {
  Toolbar,
  Button,
  useUpdate,
  useRedirect,
  useNotify,
  useDataProvider,
  GET_LIST,
  DeleteButton,
} from 'react-admin';
import { useForm } from 'react-final-form';
import PropTypes from 'prop-types';
import {
  Refresh as ResetIcon,
  Save as SaveIcon,
  AddBox as CreateIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';
import _ from 'lodash';
import firebase from 'firebase';

import myDataProvider from '../../dataProvider/firestoreDataProvider';
import JSUtility from '../../utilities/JSUtility';

const styles = {
  icon: {
    paddingLeft: 8,
  },
  buttonBaseStyle: {
    color: '#ffffff',
    marginLeft: 5,
    fontSize: 'medium',
    textAlign: 'center',
    paddingRight: '13px',
  },
  deleteButton: {
    position: 'absolute',
    right: 20,
  },
};

const baseButton = {
  ...styles.buttonBaseStyle,
  backgroundColor: '#dddddd',
};

const dirtyButton = {
  ...styles.buttonBaseStyle,
  backgroundColor: '#ec8a88',
};

const isContentTimestampAligned = async (value) => {
  const {
    youTubeVideoId,
    startTime = '00:00:00,000',
    endTime = JSUtility.convertMillisecondToTimestamp(value.durationInMs),
    id,
  } = value;
  const isAllowOverlap = true;

  const response = await myDataProvider(GET_LIST, 'contents', {
    sort: {
      field: 'startTime',
      order: 'ASC',
    },
    filter: { youTubeVideoId },
  });
  const duplicateContents = response.data;
  const duplicateOtherContents = duplicateContents.filter(
    (content) => content.id !== id,
  );

  const currentContent = { startTime, endTime };
  const isValidTimestamp = JSUtility.hasValidTimestamp(
    duplicateOtherContents,
    currentContent,
    isAllowOverlap,
  );
  return isValidTimestamp;
};

const isAccentTagIncluded = async (value) => {
  const { tagKeys } = value;
  const response = await myDataProvider(GET_LIST, 'tags', { filter: '' });
  const tagsData = response.data;

  const accentTagsCount = Object.values(tagsData).filter(
    (key) => tagKeys?.includes(key.id) && key.category === 'accent',
  ).length;

  const isValidAccentTag = accentTagsCount > 0;
  return isValidAccentTag;
};

const isEnoughMissionWordsSelected = async (value) => {
  const { lemmas } = value;
  if (lemmas == null) {
    return true;
  }

  const isValidMissionWords = lemmas?.length < 6;

  return isValidMissionWords;
};

const isFragmentTimestampAligned = async (value) => {
  const { contentKey, id, startTime, endTime } = value;

  const response = await myDataProvider(GET_LIST, 'fragments', {
    filter: { contentKey },
  });
  const fragmentsWithSameContent = response.data;
  const otherFragmentsWithSameContent = fragmentsWithSameContent.filter(
    (fragment) => fragment.id !== id,
  );

  const currentFragment = { startTime, endTime };
  const isValidTimestamp = JSUtility.hasValidTimestamp(
    otherFragmentsWithSameContent,
    currentFragment,
  );

  return isValidTimestamp;
};

const isWordAllFilled = (value) => {
  const { words = [] } = value;

  const isAnyEmptyWord = words.some(
    (element) => element.word.text === '' && element.word.lemmaId === '',
  );

  return !isAnyEmptyWord;
};

const doesStudyableWordExist = (value) => {
  if (value.keepable) {
    if (value.words) {
      const count = value.words.reduce(
        (acc, word) => (word.studyable ? acc + 1 : acc),
        0,
      );
      return count >= 1;
    }
  }
  return true;
};

const CustomEditToolbar = (props) => {
  const {
    resource: resourceProp = 'fragments',
    record: previousRecord = {},
    invalid,
    mode,
    created = () => {},
    createButtonLabel = null,
    saveButtonLabel = null,
    customResource,
    ...otherProps
  } = props;
  const [values, setValues] = useState({});

  const resource = customResource || resourceProp;

  const form = useForm();
  const formState = form.getState();
  const currentUserId = firebase.auth().currentUser.uid;

  useEffect(() => {
    const unsubscribe = form.subscribe(
      (s) => {
        setValues(s.values);
      },
      { values: true },
    );
    return () => {
      unsubscribe();
    };
  }, [form]);

  const notify = useNotify();

  const [saveCurrentValues] = useUpdate(
    resource,
    previousRecord.id,
    values,
    previousRecord,
  );
  //  useUpdate needs (resource, id, data, previousData)

  const sanitizeRestProps = (rest) =>
    _.omit(rest, [
      // omit list can be added later
      'save',
      'youTubeVideoId',
      'indexFromFragmentList',
    ]);

  const isInvalidData = async (collection, data, submitType) => {
    if (collection === 'contents') {
      const isValidTimestamp = await isContentTimestampAligned(data);
      if (!isValidTimestamp) {
        return {
          invalidOnSave: true,
          invalidMessage: `${resource.slice(
            0,
            -1,
          )} ${submitType} error: Timestamp overlaps with other contents.`,
        };
      }

      if (data.releaseDate) {
        const isValidAccentTag = await isAccentTagIncluded(data);
        if (!isValidAccentTag) {
          return {
            invalidOnSave: true,
            invalidMessage: `${resource.slice(
              0,
              -1,
            )} ${submitType} error: Accent tag is mandatory.`,
          };
        }

        const isValidMissionWords = await isEnoughMissionWordsSelected(data);
        if (!isValidMissionWords) {
          return {
            invalidOnSave: true,
            invalidMessage: `${resource.slice(
              0,
              -1,
            )} ${submitType} error: Should select less than 6 mission words.`,
          };
        }
      }
    } else if (collection === 'fragments') {
      const isValidTimestamp = await isFragmentTimestampAligned(data);
      if (!isValidTimestamp) {
        return {
          invalidOnSave: true,
          invalidMessage: `${resource.slice(
            0,
            -1,
          )} ${submitType} error: Timestamp is overlapped with other ${resource}.`,
        };
      }

      const isValidWord = isWordAllFilled(data);
      if (!isValidWord) {
        return {
          invalidOnSave: true,
          invalidMessage: 'There is empty word. Remove the empty word first.',
        };
      }

      const isValidKeepableFragment = doesStudyableWordExist(data);
      if (!isValidKeepableFragment) {
        return {
          invalidOnSave: true,
          invalidMessage:
            'At least one word should be studyable if the fragment is studyable.',
        };
      }
    }
    return {
      invalidOnSave: false,
      invalidMessage: null,
    };
  };

  const CustomCreateButton = () => {
    const redirect = useRedirect();
    const dataProvider = useDataProvider();
    const handleCreate = useCallback(async () => {
      const { invalidOnSave, invalidMessage } = await isInvalidData(
        resource,
        values,
        'create',
      );
      if (invalid) {
        notify(
          `${resource.slice(
            0,
            -1,
          )} create error: Please check the data before creating`,
          'warning',
        );
      } else if (invalidOnSave) {
        notify(invalidMessage, 'warning');
      } else {
        try {
          const response = await dataProvider.create(resource, {
            data: values,
          });
          const { id } = response.data;
          // success side effects go here
          if (resource === 'fragments') {
            // created() is a task to do after creating a fragment
            created();
            redirect('/contents/');
            redirect(`/contents/${values.contentKey}`);
          } else {
            redirect(`/${resource}/${id}`);
          }
          notify(`${resource.slice(0, -1)} create success!`);
        } catch (error) {
          // failure side effects go here
          notify(
            `${resource.slice(0, -1)} create error: ${error.message}`,
            'warning',
          );
        }
      }
    }, [dataProvider, redirect]);

    const defaultLabel = `Create ${resource.slice(0, -1)}`;
    return (
      <Button
        style={formState.dirty ? dirtyButton : baseButton}
        label={createButtonLabel != null ? createButtonLabel : defaultLabel}
        onClick={handleCreate}
        disabled={!formState.dirty}
      >
        <CreateIcon style={styles.icon} />
      </Button>
    );
  };

  const CustomSaveButton = () => {
    const handleSave = async () => {
      const { invalidOnSave, invalidMessage } = await isInvalidData(
        resource,
        values,
        'save',
      );
      if (invalid) {
        notify(
          `${resource.slice(
            0,
            -1,
          )} save error: Please check the data before saving`,
          'warning',
        );
      } else if (invalidOnSave) {
        notify(invalidMessage, 'warning');
      } else {
        saveCurrentValues();
        notify(`${resource.slice(0, -1)} save success!`);
      }
    };
    const defaultLabel = `Save ${resource.slice(0, -1)}`;

    return (
      <Button
        style={formState.dirty ? dirtyButton : baseButton}
        label={saveButtonLabel != null ? saveButtonLabel : defaultLabel}
        onClick={handleSave}
        disabled={!formState.dirty}
      >
        <SaveIcon style={styles.icon} />
      </Button>
    );
  };

  const CustomResetButton = () => {
    const resetToOriginalValue = useCallback(() => {
      form.reset();
    }, []);

    return (
      <Button
        style={formState.dirty ? dirtyButton : baseButton}
        label="Reset"
        onClick={resetToOriginalValue}
        disabled={!formState.dirty}
      >
        <ResetIcon style={styles.icon} />
      </Button>
    );
  };

  return (
    <Toolbar
      {...sanitizeRestProps(otherProps)}
      record={previousRecord}
      resource={resource}
      invalid={invalid}
    >
      {mode === 'create' ? <CustomCreateButton /> : <CustomSaveButton />}
      <CustomResetButton />
      {mode !== 'create' &&
      (resource === 'lemmas' ||
        (resource === 'questions' &&
          !previousRecord.answer &&
          previousRecord.author.id === currentUserId)) ? (
        <DeleteButton
          label="Delete"
          icon={<DeleteIcon />}
          style={styles.deleteButton}
        />
      ) : null}
    </Toolbar>
  );
};

CustomEditToolbar.propTypes = {
  // eslint-disable-next-line react/require-default-props
  resource: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  record: PropTypes.shape({}),
  // eslint-disable-next-line react/require-default-props
  invalid: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  created: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  createButtonLabel: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  saveButtonLabel: PropTypes.string,
  mode: PropTypes.string.isRequired,
  customResource: PropTypes.string,
};

export default CustomEditToolbar;
