import { makeStyles } from '@material-ui/core';

const expandableSelectStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    border: '1.5px solid #e5e5e5',
    borderRadius: '8px',
    height: 'fitContent',
  },
  heading: {
    fontSize: theme.typography.pxToRem(24),
    fontWeight: theme.typography.fontWeightBold,
  },
  tree: {
    flexGrow: 1,
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1.5, 0),
  },
  categoryLabelText: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    flexGrow: 1,
  },
  paper: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(0.5),
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

export default expandableSelectStyles;
