import React from 'react';
import { Edit } from 'react-admin';

import ReviewForm from './ReviewForm';
import ReviewActions from './ReviewActions';

const ReviewEdit = (props) => (
  <Edit {...props} actions={<ReviewActions basePath="/reviews" />}>
    <ReviewForm mode="update" />
  </Edit>
);

export default ReviewEdit;
