import React from 'react';
import { Edit } from 'react-admin';

import L2eMonthlyPointLogsActions from './L2eMonthlyPointLogsActions';
import L2eMonthlyPointLogsForm from './L2eMonthlyPointLogsForm';

const L2eMonthlyPointLogsEdit = (props) => (
  <Edit
    actions={<L2eMonthlyPointLogsActions basePath="l2eMonthlyPointLogs" />}
    {...props}
  >
    <L2eMonthlyPointLogsForm mode="update" />
  </Edit>
);

export default L2eMonthlyPointLogsEdit;
