import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  SimpleForm,
  TextInput,
  NumberInput,
  BooleanInput,
  SelectInput,
  ReferenceInput,
  ArrayInput,
  required,
} from 'react-admin';
import _ from 'lodash';
import moment from 'moment';
import { useForm } from 'react-final-form';
import { CustomEditToolbar, CustomSimpleFormIterator } from '../../common';
import ArrayInputEmptyComponent from '../../common/ArrayInputEmptyComponent';
import JSUtility from '../../utilities/JSUtility';

const styles = {
  verticalView: {
    display: 'flex',
    flexDirection: 'column',
  },
  textInput: {
    width: 350,
  },
  selectInput: {
    width: 350,
  },
  switch: {
    width: 350,
  },
  updated: {
    backgroundColor: '#fad0c3',
  },
  icon: {
    paddingRight: 8,
  },
  button: {
    color: '#ffffff',
    margin: '10px 0px 10px 30px',
    fontSize: 'medium',
    textAlign: 'center',
    paddingRight: '13px',
    backgroundColor: '#ec8a88',
  },
};

const questionsFilter = { published: true };
const questionsSort = { field: 'question', order: 'ASC' };
const initialQuestions = [{ questionId: '', order: 1 }];

const SurveyQuestionForm = (props) => {
  const { mode, record, ...otherProps } = props;

  return (
    <>
      <SimpleForm
        {...props}
        toolbar={
          <CustomEditToolbar {...otherProps} mode={mode} record={record} />
        }
        redirect={false}
        submitOnEnter={false}
        keepDirtyOnReinitialize={false}
      >
        <SurveyQuestionFormContent mode={mode} record={record} />
      </SimpleForm>
    </>
  );
};

const SurveyQuestionFormContent = (props) => {
  const { mode } = props;
  const [dirtyFields, setDirtyFields] = useState({});
  const [formData, setFormData] = useState({});

  const form = useForm();

  useEffect(() => {
    const unsubscribe = form.subscribe(
      (s) => {
        setDirtyFields(s.dirtyFields);
        setFormData(s.values);
      },
      { dirtyFields: true, values: true },
    );
    return () => {
      unsubscribe();
    };
  }, [form]);

  const isCreating = mode === 'create';

  const onPublishedChange = useCallback(
    (published) => {
      if (published) {
        form.change('publishedAt', moment().toDate());
      } else {
        form.change('publishedAt', null);
      }
    },
    [form],
  );

  const validate = [required('Please fill the field')];

  const newQuestion = useMemo(() => {
    const lastQuestion = _.last(formData.questions);
    const newQuestionOrder =
      lastQuestion?.order == null ? 1 : lastQuestion.order + 1;
    return {
      order: newQuestionOrder,
      questionId: '',
    };
  }, [formData.questions]);

  const onPressCreateFirstQuestion = useCallback(() => {
    form.change('questions', initialQuestions);
  }, [form]);

  return (
    <div style={styles.verticalView}>
      {!isCreating && (
        <>
          <TextInput
            disabled
            label="Survey Question Id"
            source="id"
            style={styles.textInput}
          />
        </>
      )}
      <BooleanInput
        label="Published"
        source="published"
        onChange={onPublishedChange}
        style={{
          ...styles.switch,
          ...(dirtyFields.published ? styles.updated : null),
        }}
        defaultValue={isCreating ? false : undefined}
      />
      <TextInput
        label="Name"
        source="name"
        record={formData}
        style={{
          ...styles.textInput,
          ...(dirtyFields.name ? styles.updated : null),
        }}
        validate={validate}
        parse={(value) => JSUtility.hasValidQuote(value)}
      />
      <ArrayInput source="questions" record={formData}>
        <CustomSimpleFormIterator defaultAddValue={newQuestion}>
          <NumberInput label="Order" source="order" disabled />
          <ReferenceInput
            label="Question"
            source="questionId"
            reference="surveyQuestions"
            filter={questionsFilter}
            sort={questionsSort}
            perPage={500}
          >
            <SelectInput optionText="question" validate={validate} />
          </ReferenceInput>
        </CustomSimpleFormIterator>
      </ArrayInput>
      {_.isEmpty(formData.questions) && (
        <ArrayInputEmptyComponent
          label="questions"
          onPressCreateButton={onPressCreateFirstQuestion}
        />
      )}
    </div>
  );
};

const CommonPropTypesForSurveyFormAndContent = {
  mode: PropTypes.oneOf(['create', 'update']).isRequired,
  record: PropTypes.shape({}),
};

SurveyQuestionForm.propTypes = {
  ...CommonPropTypesForSurveyFormAndContent,
};

SurveyQuestionFormContent.propTypes = {
  ...CommonPropTypesForSurveyFormAndContent,
};

export default SurveyQuestionForm;
