import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {
  EditButton,
  ShowController,
  ShowView,
  SimpleShowLayout,
  TextField,
  FunctionField,
} from 'react-admin';
import { QnAUploadToOptions } from './questionsConstants';

const styles = {
  languageCode: {
    fontWeight: 'bold',
  },
};

const ObjectTranslationsField = ({ record, dataType }) => {
  const isQuestion = dataType === 'Question';
  const objectTranslations = isQuestion
    ? record.originalTextTranslations
    : record.answer.originalTextTranslations;

  return objectTranslations != null
    ? Object.values(objectTranslations).map((t) => (
        <div key={`text-translations-field-${t}`}>
          <span style={styles.languageCode}>{t.languageCode}</span>
          &nbsp;
          <span>{t.text}</span>
        </div>
      ))
    : null;
};

ObjectTranslationsField.defaultProps = {
  addLabel: true,
};

const QuestionShow = (props) => (
  <ShowController {...props}>
    {(controllerProps) => (
      <ShowView {...props} {...controllerProps}>
        <SimpleShowLayout>
          <EditButton {...props} />
          <TextField label="Content" source="fragment.content.title" />
          <TextField label="Fragment Text" source="fragment.text" />
          {_.isEqual(_.get(props.record, 'originalTextTranslations'), []) ? (
            <TextField label="Original Question Text" source="originalText" />
          ) : (
            <ObjectTranslationsField
              label="Question Translations"
              dataType="Question"
            />
          )}
          {
            // eslint-disable-next-line no-nested-ternary
            _.get(props.record, 'answer', null) ? (
              _.isEqual(
                _.get(props.record, 'answer.originalTextTranslations'),
                [],
              ) ? (
                <TextField
                  label="Original Answer Text"
                  source="answer.originalText"
                />
              ) : (
                <ObjectTranslationsField
                  label="Answer Translations"
                  dataType="Answer"
                />
              )
            ) : null
          }
          {_.get(props.record, 'answer', null) ? (
            <TextField label="Answer Like Count" source="answer.likeCount" />
          ) : null}
          {_.get(props.record, 'qnaUploadLog', []).map((log) => {
            const label = _.find(QnAUploadToOptions, { id: log.uploadTo });
            const url = _.get(log, 'qnaUploadLogResult.url', null);
            return (
              url && (
                <FunctionField
                  label={`${label.name} Posted Url`}
                  render={() => url}
                />
              )
            );
          })}
        </SimpleShowLayout>
      </ShowView>
    )}
  </ShowController>
);

QuestionShow.propTypes = {
  record: PropTypes.shape({}),
};

QuestionShow.defaultProps = {
  record: null,
};

export default QuestionShow;
