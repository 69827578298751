import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

const ObjectKeyTypeField = ({ record = {}, source = null }) => {
  if (_.get(record, source, null)) {
    const objectKeys = Object.keys(record[source]);

    return objectKeys.map((key, index) => {
      const isLastIndex = index === objectKeys.length - 1;

      return (
        <span key={`object-key-field-${key}`}>
          {key}
          {isLastIndex ? '' : ', '}
        </span>
      );
    });
  }

  return null;
};

ObjectKeyTypeField.defaultProps = { addLabel: true };

ObjectKeyTypeField.propTypes = {
  // eslint-disable-next-line react/require-default-props
  record: PropTypes.shape({}),
  // eslint-disable-next-line react/require-default-props
  source: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  addLabel: PropTypes.bool,
};

export default ObjectKeyTypeField;
