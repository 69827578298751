import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import firebase from 'firebase';
import {
  Button,
  TextField,
  makeStyles,
  CssBaseline,
  Container,
} from '@material-ui/core';
import Popup from '../common/fields/Popup';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(20),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
    textAlign: 'center',
  },
  button: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const ResetPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [isSent, setIsSent] = useState(false);

  const handleSendCodeClick = async (e) => {
    e.preventDefault();

    try {
      await firebase.auth().sendPasswordResetEmail(email);
      setIsSent(true);
    } catch (error) {
      // eslint-disable-next-line no-alert
      alert(error.message);
    }
  };

  const classes = useStyles();

  const popupText = (
    <p>
      We have sent password reset email for you.
      <br />
      Please check your email &quot;
      {email}
      &quot; to reset your password.
    </p>
  );
  const popupButtonText = 'Back to login page';
  const popupPath = '/login';

  return (
    <div>
      {isSent ? (
        <Popup
          textElement={popupText}
          buttonText={popupButtonText}
          pathBack={popupPath}
        />
      ) : (
        <Container maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <h2>Forgot your password?</h2>
            <form className={classes.form} onSubmit={handleSendCodeClick}>
              <p>Please write your email to reset your password.</p>
              <TextField
                fullWidth
                required
                type="email"
                autoFocus
                label="Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Button
                fullWidth
                className={classes.button}
                type="submit"
                variant="contained"
                color="primary"
                disabled={email.length <= 0}
              >
                Send
              </Button>
            </form>
          </div>
          {/* Reference: https://github.com/airbnb/javascript/pull/1648 */}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link to="/login" className={classes.link}>
            Back to Login page
          </Link>
        </Container>
      )}
    </div>
  );
};

export default ResetPasswordPage;
