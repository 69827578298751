import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Edit } from 'react-admin';

import TagActions from './TagActions';
import TagForm from './TagForm';

const TagTitle = ({ record = null }) => (
  <>{_.get(record, 'languageCodeToName.en', '')}</>
);

TagTitle.propTypes = {
  // eslint-disable-next-line react/require-default-props
  record: PropTypes.shape({
    languageCodeToName: PropTypes.shape({
      en: PropTypes.string.isRequired,
    }).isRequired,
  }),
};

const TagEdit = (props) => (
  <Edit
    title={<TagTitle />}
    actions={<TagActions basePath="/tags" />}
    {...props}
  >
    <TagForm mode="update" />
  </Edit>
);

export default TagEdit;
