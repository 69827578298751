import React from 'react';
import { regex, maxLength } from 'react-admin';
import _ from 'lodash';

import { AvailableLanguages, RegexNoOnlySpaceOrNewline } from '../../constants';
import CustomTextInput from '../inputs/CustomTextInput';

const styles = {
  base: {
    marginLeft: 30,
  },
  updated: {
    backgroundColor: '#fad0c3',
  },
  textHighlight: {
    borderBottom: '5px solid #fffda1',
  },
  warningText: {
    color: 'red',
  },
};

const TranslationField = ({
  source,
  label,
  customStyle,
  languageCodeIdOfCurrentContent,
  lengthLimitByLang = {},
  record,
  dirtyFields,
}) => {
  const translationObj = _.get(record, source, null);

  return AvailableLanguages.map((languageCode) => {
    const sourceForLanguageCode = `${source}.${languageCode.id}`;
    const isGengoTranslation = [
      '_textTranslationsByGengo',
      '_subtitleTranslationsByGengo',
    ].includes(source);

    // Does not render translation field for content language code
    if (languageCodeIdOfCurrentContent === languageCode.id) {
      return null;
    }

    // Does not render empty gengo translation field
    if (isGengoTranslation && _.get(translationObj, languageCode.id) == null) {
      return null;
    }

    const fieldStyle = {
      ...styles.base,
      ...customStyle,
      ...(_.includes(_.get(record, sourceForLanguageCode, ''), '#')
        ? styles.textHighlight
        : null),
      ...(dirtyFields[sourceForLanguageCode] ? styles.updated : null),
    };

    const targetText = _.get(record, sourceForLanguageCode);
    const characterCount = targetText ? targetText.length : 0;
    const maxCharacterCount = lengthLimitByLang[languageCode.id];
    const charCountStyle =
      characterCount > maxCharacterCount ? styles.warningText : null;

    const validateMaxLength = maxLength(maxCharacterCount);
    const validateSpace = regex(
      RegexNoOnlySpaceOrNewline,
      'Just space or newline is forbidden.',
    );
    const validateTranslation = [validateMaxLength, validateSpace];

    return (
      <React.Fragment key={sourceForLanguageCode}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <CustomTextInput
            fullWidth
            multiline
            key={`translation-field-${source}-${languageCode.id}`}
            label={`${languageCode.name} - ${label || source}`}
            source={sourceForLanguageCode}
            style={fieldStyle}
            disabled={isGengoTranslation}
            validate={validateTranslation}
          />
          {maxCharacterCount == null ? null : (
            <p style={charCountStyle}>
              ({characterCount}/{maxCharacterCount})
            </p>
          )}
        </div>
      </React.Fragment>
    );
  });
};

export default TranslationField;
