import React from 'react';
import { Route } from 'react-router-dom';
import ResetPasswordPage from './login/ResetPasswordPage';
import Dashboard from './Dashboard';
import VerificationPage from './login/VerificationPage';
import UserPointRanking from './collections/pointLogs/UserPointRanking';

export default [
  <Route exact path="/login/reset" component={ResetPasswordPage} noLayout />,
  <Route exact path="/login/verify" component={VerificationPage} noLayout />,
  <Route exact path="/Dashboard" component={Dashboard} />,
  <Route exact path="/UserPointRanking" component={UserPointRanking} />,
];
