import ApolloClientHelper from './ApolloClientHelper';
import Question from './Question';
import Answer from './Answer';
import User from './User';
import Lemma from './Lemma';
import PlayerSession from './PlayerSession';
import SubscriptionCredit from './SubscriptionCredit';
import Subscription from './Subscription';
import Review from './Review';
import Content from './Content';

export default (function GraphQlHelper() {
  return {
    initialize: ApolloClientHelper.initialize,
    Question,
    Answer,
    User,
    Lemma,
    PlayerSession,
    SubscriptionCredit,
    Subscription,
    Review,
    Content,
  };
})();
