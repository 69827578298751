import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { SimpleForm, TextInput, NumberInput, GET_MANY } from 'react-admin';
import { useForm } from 'react-final-form';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Link as LinkIcon } from '@material-ui/icons';
import myDataProvider from '../../dataProvider/firestoreDataProvider';
import { CustomEditToolbar } from '../../common';

const styles = {
  verticalView: {
    display: 'flex',
    flexDirection: 'column',
  },
  textInput: {
    width: 350,
  },
  updated: {
    backgroundColor: '#fad0c3',
  },
  horizontalView: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  contentsContainerTitle: {
    marginBottom: 10,
    fontSize: 16,
    fontWeight: 'bold',
  },
  linkButton: {
    marginLeft: 15,
  },
};

const SeriesForm = (props) => {
  const { mode, record, ...otherProps } = props;
  return (
    <SimpleForm
      {...props}
      toolbar={
        <CustomEditToolbar {...otherProps} mode={mode} record={record} />
      }
      redirect={false}
      submitOnEnter={false}
      keepDirtyOnReinitialize={false}
    >
      <SeriesFormContent mode={mode} record={record} />
    </SimpleForm>
  );
};

const SeriesContentItem = ({ content }) => {
  const { id: contentId, title } = content;
  return (
    <div style={styles.horizontalView}>
      <div>{title}</div>
      <Button
        style={styles.linkButton}
        component={Link}
        variant="outlined"
        target="_blank"
        to={{
          pathname: `/contents/${contentId}`,
        }}
      >
        <LinkIcon />
      </Button>
    </div>
  );
};

const SeriesFormContent = (props) => {
  const { mode } = props;
  const [dirtyFields, setDirtyFields] = useState({});
  const [formData, setFormData] = useState({});

  const form = useForm();

  useEffect(() => {
    const unsubscribe = form.subscribe(
      (s) => {
        setDirtyFields(s.dirtyFields);
        setFormData(s.values);
      },
      { dirtyFields: true, values: true },
    );
    return () => {
      unsubscribe();
    };
  }, [form]);

  const isCreating = mode === 'create';
  const [seriesContents, setSeriesContents] = useState([]);

  useEffect(() => {
    (async () => {
      if (!_.isEmpty(formData.contents)) {
        const response = await myDataProvider(GET_MANY, 'contents', {
          ids: formData.contents.map((content) => content.id),
        });
        if (!_.isEmpty(response.data)) {
          const orderedContents = _.chain(response.data)
            .orderBy(['order'], ['asc'])
            .map((content) => ({
              id: content.id,
              title: content.title,
            }))
            .value();
          setSeriesContents(orderedContents);
        }
      }
    })();
  }, [formData.contents]);

  return (
    <div style={styles.verticalView}>
      {!isCreating && (
        <TextInput
          disabled
          label="Series Id"
          source="id"
          style={styles.textInput}
        />
      )}
      <TextInput
        label="Title"
        source="title"
        record={formData}
        style={{
          ...styles.textInput,
          ...(dirtyFields.series ? styles.updated : null),
        }}
      />
      <NumberInput
        label="Content count"
        source="contentCount"
        record={formData}
        disabled
        style={{
          ...styles.textInput,
          ...(dirtyFields.contentCount ? styles.updated : null),
        }}
      />
      {seriesContents != null && (
        <div>
          <div style={styles.contentsContainerTitle}>Contents</div>
          {seriesContents.map((content) => (
            <SeriesContentItem content={content} />
          ))}
        </div>
      )}
    </div>
  );
};

const CommonPropTypesForSeriesFormAndContent = {
  mode: PropTypes.oneOf(['create', 'update']).isRequired,
  record: PropTypes.PropTypes.shape({}),
};

SeriesForm.propTypes = {
  ...CommonPropTypesForSeriesFormAndContent,
};

SeriesFormContent.propTypes = {
  ...CommonPropTypesForSeriesFormAndContent,
};

SeriesContentItem.propTypes = {
  content: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
  }),
};
export default SeriesForm;
