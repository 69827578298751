import React from 'react';
import PropTypes from 'prop-types';
import { TextField, Show, SimpleShowLayout, EditButton } from 'react-admin';
import _ from 'lodash';

const styles = {
  language: {
    fontWeight: 'bold',
  },
};

const TextTranslationsField = ({ record }) =>
  record.textTranslations != null
    ? Object.keys(record.textTranslations).map((languageCode) => (
        <div key={`grammar-translations-field-${languageCode}`}>
          <span style={styles.subtitleLanguage}>{languageCode}</span>
          &nbsp;
          <span>{record.textTranslations[languageCode]}</span>
        </div>
      ))
    : null;

TextTranslationsField.defaultProps = {
  addLabel: true,
};

const GrammarShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <EditButton {...props} />
      <TextField label="Topic" source="text" />
      {_.get(props.record, 'textTranslations', null) ? (
        <TextTranslationsField label="Topic Translations" />
      ) : null}
    </SimpleShowLayout>
  </Show>
);

GrammarShow.propTypes = {
  record: PropTypes.shape({}),
};

GrammarShow.defaultProps = {
  record: null,
};

export default GrammarShow;
