import React from 'react';
import { Edit } from 'react-admin';

import QuestionActions from './QuestionActions';
import QuestionForm from './QuestionForm';

const QuestionEdit = (props) => (
  <Edit actions={<QuestionActions basePath="/questions" />} {...props}>
    <QuestionForm mode="update" />
  </Edit>
);

export default QuestionEdit;
