import React from 'react';
import { Edit } from 'react-admin';

import L2eMonthlyConfigActions from './L2eMonthlyConfigActions';
import L2eMonthlyConfigForm from './L2eMonthlyConfigForm';

const L2eMonthlyConfigEdit = (props) => (
  <Edit
    actions={<L2eMonthlyConfigActions basePath="/l2eMonthlyConfig" />}
    {...props}
  >
    <L2eMonthlyConfigForm mode="update" />
  </Edit>
);

export default L2eMonthlyConfigEdit;
