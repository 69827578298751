import PointLogEdit from './PointLogEdit';
import PointLogList from './PointLogList';
import PointLogShow from './PointLogShow';
import UserPointRanking from './UserPointRanking';

export default {
  list: PointLogList,
  show: PointLogShow,
  edit: PointLogEdit,
  ranking: UserPointRanking,
};
